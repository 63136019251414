var NavType = {
  NavLists: "Lists",
  NavGroups: "NavGroups",
  NavSymbol: "Symbol",
  NavCompare: "Compare",
  NavNews: "NavNews",
  NavResearch: "Research",
  NavAccAnalyzer: "Account-Analyzer"
};

export default NavType;
