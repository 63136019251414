import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import SettingsStore from "SettingsStore";
import { ListExplorerConstants } from "ListExplorerConstants";
import ListApi from "ListApi";
import _, { reject, map, find, each, sortBy, clone, isNull, isUndefined, indexBy, contains, has, isEmpty, findIndex, pluck } from "underscore";

import StringUtil from 'StringUtil';
import GridStore from "GridStore";
import GridActions from "GridActions";
import ListExplorerStore from "ListExplorerStore";
import BrowserFilterStore from "BrowserFilterStore";
import BaseServiceApi from 'BaseServiceApi';
import UserInfoUtil from "UserInfoUtil";
import ListStore from "ListStore";
import ListManagerTabType from "ListManagerTabType";
import BrowserUtil from "BrowserUtil";
import ListSettingHelper from "../../../ConsoleWindow/Settings/Modules/ListManager/ListManagerSettingHelper.js"
import ListManager from "../../../../Constants/ListManager.js";
import ColumnCustomFilterControl from "../../../../RayCustomControls/ListViewCustomFilter/ColumnCustomFilterControl.jsx";
import BasicEsInfo from 'ServiceApi/RequestHelper/BasicEsInfo';
import SummaryStatStore from "../../../../RayCustomControls/ListSummaryStat/SummaryStatStore";
import AlertsApi from 'AlertsApi';
import LocalizationStore from 'LocalizationStore';
import FormatterUtil from "FormatterUtil";
import AdobeUtil from "../../../../Utils/AdobeUtil";
import AdobeConstants from "../../../../Constants/AdobeConstants";
import { SettingsConstants } from "../../../../Constants/SettingsConstants.js";
import ListActions from "ListActions";
import moment from "moment";
import TimeTrackingWindow from "../../../../RayCustomControls/TimeTrackingWindow.js";
import DatagraphDataType from "../../../../Constants/DatagraphDataType.js";
import NavType from "../../../../Constants/NavType.js";
import { GridConstants } from "GridConstants";

let ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
let ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];
let ListColumnStyleType = BaseServiceApi.rayData["ListColumnStyleType"];
let ListColumnDataType = BaseServiceApi.rayData["ListColumnDataType"];
let InstrumentType = BaseServiceApi.rayData["InstrumentType"];
let ListColumnPositionType = BaseServiceApi.rayData["ListColumnPositionType"];
let IntradaySourceType = BaseServiceApi.rayData["IntradaySourceType"];
let cancelablePromise = null

const cellWidth = require("text-width");
const CHANGE_EVENT = "change";
/*eslint-disable */
const DataItemIdType = -7, DataItemIdName = -6, DataItemIdSymbol = -5, DataItemIdLocalSymbol = 4421, SnapshotIdName = "snapshotdt", DataItemIdIndustryGroup = 50, DataItemIdRowid = -4, DataItemIdFlag = -3,
  DataItemIdNotImportedSymbol = 6300, DataItemIdNotImportedDate = 6301,
  ModelStockId = -120,
  DataItemIdFlagddate = -12,
  PricePercDataItemId = 64,
  PricePercWeekItemId = 2167,
  PricepercDayItemId = 500,
  CloseRangeWeekDataItemId = 4792,
  CloseRangeDayDataItemId = 4791,
  CloseRangeDataItemId = 4793,
  ColNameSymbol = "Symbol",
  ColNameFlag = "F",
  ColNameName = "CompanyName",
  ColNameRowid = "R",
  ColNameInstrumentid = "Identifier",
  ColNameIsactiverow = "IsActiveRow",
  ColNameType = "I",
  ColNameHiddenrowid = "HiddenRowId",
  ColNameMsid = "MSID",
  ColNameSnapshotdate = "SNAPSHOTDATE",
  ColNameSnapshotdatestring = "SNAPSHOTDATESTRING",
  ColNameFlagDate = "FD",
  ColNameColumnreorderId = "ColumReorderId",
  StatsRowHeader = "AggregateHeader",
  _symbolColId = -5,
  pricePercentageChangeColumnId = 64,
  flagColumnWidth = 15,
  defaultDistColumnId = 61;
/*eslint-enable */

let w, isScollBarCallRunning = false, sourceSymbolsList = null;

class ONeilViewStore extends EventEmitter {
  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
    //Bug fixes - Max Limit error
    this.setMaxListeners(0);
    this.initialCall = true;
    this._state = {
      basic: {},
      columns: {},
      browserFilterData: {},
      browserIgOneilFilterData: {},
      browserIgGicsFilterData: {},
      columnSetId: null,
      columnSetName: null,
      isSharedColumnSet: false,
      listId: null,
      actualListId: null,
      cacheId: null,
      searchKeyword: '',
      needToCallAdobe: false,
      pickListData: {},
      alertMessage: null,
      removeItemsFromUserList: { listId: null, cacheId: null, msids: null, snapshotdates: null, currentNodeId: null },
      undoOperationForPaste: {},
      //For Export Data
      exportPageData: null,
      exportBasic: {},
      exportDetails: {},
      allListSymbolMsids: [],
      highlightSymbolMsIds: [],
      distributiontChartSelectedColumnId: defaultDistColumnId,
      IsNASDAQBasic: false,
      showChart: false,
      isListAccessChanged: false,
    };
    //For Export Data
    this._sourceForExport = [];
    this._tempSourceForExport = [];
    this._singleRowData = {};
    this._source = [];
    this._defaultSource = [];
    this._tempSource = [];
    this._removedColumns = [];
    this._sortCols = [];
    this._currentAction = null;
    this._frozenColumnsSpliter = false;
    this._columnInfo = null;
    this._columnFilters = null;
    this._prevColumnFilters = null;
    this._prevSortCols = null;
    this._exceptionSymbols = null;
    this._tempDragDataSource = [];
    this._details = {};
    this._detailsAfterAddSymbol = {};
    this.EntitlementType = BaseServiceApi.rayData["EntitlementType"];
    this.PortfolioListWeightType = BaseServiceApi.rayData["PortfolioListWeightType"];
    this.loadBrowserFilterData = false;
    this.isListLoaded = false;
    this.loadFilterDataStatus = false;
    this.disableDelete = false;
    // this.autoIncreament = 1;
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }


  setLoadFilterDataStatus(status){
    this.loadFilterDataStatus = status;
  }

  getLoadFilterDataStatus(){
    return this.loadFilterDataStatus;
  }

  getSummaryStatDistColumnIdFromSettings() {
    let distColId;
    if ( this.getSettingsObject().SummaryStatSettings !== undefined) {
     distColId = this.getSettingsObject() ? this.getSettingsObject().SummaryStatSettings.summaryStatDistColumnId : null;
    }
    return distColId ? distColId : defaultDistColumnId;
  }

  getState() {
    return this._state;
  }

  get showChart(){
    return this._state.showChart;
  }
  set showChart(value){
    this._state.showChart = value;
    this._currentAction = ListExplorerConstants.ActionTypes.SET_SHOW_CHART
    this.emit(CHANGE_EVENT);
  }
  getCurrentAction() {
    return this._currentAction;
  }

  clearCurrentAction() {
    this._currentAction = null;
  }

  getSourceData() {
    let rows = reject(this._source, (row) => {
      if (!row.isRowVisible) return true;
    });
    return rows;
  }

  updateSourceData(newSourceData) {
    if (this.isListLoaded) {
      this._source = newSourceData;
    }
  }

  getDefaultSourceData() {
    let rows = reject(this._defaultSource, (row) => {
      if (!row.isRowVisible) return true;
    });
    return rows;
  }

  getIsSharedColumnSet() {
    return this._state.isSharedColumnSet;
  }

  storePrevColumnFilterAndSortsColumnsForUndo(data) {
    //for-filters
    if (this._columnFilters !== null && this._columnFilters !== undefined){
      let res=this._columnFilters?.filter((item)=>item.dataItemId==data?.dataItemId)
      if(res && res.length){
        this._prevColumnFilters = clone(res);
        this._columnFilters=this._columnFilters?.filter((item)=>item.dataItemId!==data?.dataItemId)
      }
      else
      this._prevColumnFilters=[]
    }
    // for-sorting
    if (this._sortCols !== null && this._sortCols !== undefined)
      this._prevSortCols = clone(this._sortCols);
  }

  undoColumnFilters() {
    //for-filters
      let filterTemp=[];
      let previousFilterTemp=[];
      let uniqueFilterTemp=[];
      if(this._prevColumnFilters?.length && this._columnFilters?.length)
      {
        previousFilterTemp=[...this._prevColumnFilters]
        let flag;
        let uniqueArr=[];
          for(var i=0; i<=this._columnFilters.length-1; i++) {
                let find_temp=this._columnFilters[i]
                flag = 0;
                for(var j=0; j<=this._prevColumnFilters?.length-1; j++) {
                    if(find_temp.dataItemId === this._prevColumnFilters[j].dataItemId)
                      flag=1
                }
            if(flag==0)
              uniqueArr.push(find_temp)
          }
        uniqueFilterTemp=[...uniqueArr]
        filterTemp=[...previousFilterTemp,...uniqueFilterTemp]
      }
      else if(this._prevColumnFilters?.length)
        filterTemp=[...this._prevColumnFilters]
      
      else if(this._columnFilters?.length)
        filterTemp=[...this._columnFilters]
      else
        filterTemp=[...this._columnFilters]
      
      this._columnFilters = clone(filterTemp);
      ColumnCustomFilterControl.setColumnInfoFromStore();
      this._prevColumnFilters = null;
    //for-sorting
    if (this._prevSortCols !== null && this._prevSortCols !== undefined) {
      this._sortCols = clone(this._prevSortCols);
      this._prevSortCols = null;
    }
  }

  getFlagCount() {
    let flagCount = 0;
    let sourceData = this.getSourceData();
    map(sourceData, (rows) => {
      map(rows, (recordDatas, key) => {
        if (key == 'recordDatas') {
          map(recordDatas, (item) => {
            if (item && item.dataItemId == -3) { //Flag
              if (item.displayValue) flagCount++;
            }
          });
        }
      });
    });
    return flagCount;
  }

  getSelectedIndexesFlagCount() {
    let selectedIndexes = GridStore.getSelectedIndexes();
    let sourceData = this.getSourceData();
    let len = selectedIndexes.length;
    if (len >= sourceData.length) return this.getFlagCount();
    let i = 0, flagCount = 0;
    while (i < len) {
      if (sourceData[selectedIndexes[i]]) {
        let recordDatas = sourceData[selectedIndexes[i]]['recordDatas'];
        map(recordDatas, (item) => {
          if (item && item.dataItemId == -3) { //Flag
            if (item.displayValue) flagCount++;
          }
        });
      }
      i++;
    }
    return flagCount;
  }

  getExceptionSymbols() {
    return this._exceptionSymbols;
  }

  removeInvisibleColumns(columnInfos) {
    this._removedColumns = [];
    let columns = reject(columnInfos, (column) => {
      if (!column.isColumnVisible) {
        this._removedColumns.push(column.dataItemId.toString());
        return true;
      } else if (this._state.listId == -1 && column.dataItemId == DataItemIdRowid) {
        this._removedColumns.push(column.dataItemId.toString()); return true;
      } else return false;
    });
    return columns;
  }

  getRemovedColumns(){
    return this._removedColumns;
  }

  setSortColumns() {
    this._sortCols = [];
    map(this._state.columns, (column) => {
      if (column.sortInfo) {
        let obj = {};
        obj.dataItemId = column.dataItemId;
        obj.sortAsc = column.sortInfo.sortAsc;
        obj.sortOrderNum = column.sortInfo.sortOrderNum;
        this._sortCols.push(obj);
      }
    });
    if (this._sortCols.length > 0) this._sortCols = sortBy(this._sortCols, 'sortOrderNum');
  }

  getSortColumns() {
    return this._sortCols;
  }

  async setNewCustomOrder(listId) {
    /*eslint-disable */
    let setCutomOrderExc = await this.setCustomOrder(listId);
    /*eslint-enable */
    if (StringUtil.isEmpty(this._sortCols)) this._sortCols = [];
    let sortItem = []
    sortItem.push({ dataItemId: -4, sortAsc: true, sortOrderNum: 1 });
    return sortItem;
  }

  async setCustomOrder(activeListId, isReset = false) {
    if (ListExplorerStore.isUserListOrFavoriteUserList()) {
/*eslint-disable */
let setReorder = await this.setReOrderBeforeCustomOrder();
/*eslint-enable */
await ListApi.setCustomOrder(activeListId, isReset);
    }
  }

  async setReOrderBeforeCustomOrder() {
    let msidList = this.getSourceData().map((c) => c.msId);
    let stringmsids = msidList.join(";");
    /*eslint-disable */
    let t = await this.reorderOrdinalNumbers(this._state.listId, "", 0, stringmsids);
    /*eslint-enable */
  }

  clearSortColumns() {
    map(this._state.columns, (column) => {
      if (column.sortInfo) {
        column.sortInfo = null;
      }
    });
    this._sortCols = [];
  }

  isListEditable() {
    if (this.isUserList() || this.isAPLIST() || this.isSharedListWriteMode() || ListExplorerStore.isUserListOrFavoriteUserList()) {
      return true;
    }
    return false;
  }

  getCurrentNode(nodeId) {
    let ParentListTreeItem = [];
    ListExplorerStore.findParentNode(nodeId, ParentListTreeItem);
    let currentNode = reject(ParentListTreeItem, (item) => item.childNodes.length > 0);
    return currentNode;
  }

  isSharedList() {
    let nodeType = ListExplorerStore.nodeType;
    return nodeType == ListCategoryType.SHAREDLIST_ListCategory;
  }

  isAPLIST() {
    let nodeType = ListExplorerStore.nodeType;
    return nodeType == ListCategoryType.APLIST_ListCategory;
  }

  isSharedListWriteMode(nodeId = ListStore.getSelectedListItem().SelectedActualListId) {
    let SharedListWriteMode = false;
    let currentNode = this.getCurrentNode(nodeId);
    if (currentNode.length > 0)
      SharedListWriteMode = !StringUtil.isEmpty(currentNode[0].shareAccess) ? (currentNode[0].shareAccess == ShareAccessType.SHARE_READANDEDIT) : false;
    return SharedListWriteMode;
  }

  isUserList(nodeId = ListStore.getSelectedListItem().SelectedActualListId) {
    let currentNode = this.getCurrentNode(nodeId);
    if (currentNode.length > 0 && currentNode[0].categoryType == ListCategoryType.USER_ListCategory) {
      return true;
    }
    return false;
  }

  updateSortColums(colData, e) {
    let existCol = find(this._sortCols, (key) => colData.dataItemId === key.dataItemId);

    if (e.ctrlKey || e.metaKey) {
      if (existCol) {
        if (existCol.sortAsc) existCol.sortAsc = false;
        else existCol.sortAsc = true;
      }
      else {
        let obj = {};
        obj.dataItemId = colData.dataItemId;
        obj.sortAsc = colData.defaultSortAsc;
        obj.sortOrderNum = this._sortCols.length + 1;
        this._sortCols.push(obj);
      }
    }
    else {
      let obj = {};
      this._sortCols = [];
      obj.dataItemId = colData.dataItemId;
      obj.sortAsc = colData.defaultSortAsc;
      if (existCol && existCol.sortAsc) obj.sortAsc = false;
      else if (existCol && !existCol.sortAsc) obj.sortAsc = true;
      obj.sortOrderNum = 1;
      this._sortCols.push(obj);
    }
  }

  getListAlertTriggeredInstruments(listId) {
    return new Promise((resolve) => {

      this._state.highlightSymbolMsIds = [];

      AlertsApi.getListAlertTriggeredInstruments(listId).then((result) => {
        let sourceData = this.getSourceData()
        if (result == null || result.responseHeader == null || result.responseHeader.error || result.ListAlertTriggeredInstruments <= 0)
          resolve(result);
        else {
          let listAlertTriggeredInstrumentDatas = result.ListAlertTriggeredInstruments;

          if (!StringUtil.isEmpty(sourceData)) {
            _.each(listAlertTriggeredInstrumentDatas, (triggereditem) => {
              _.each(sourceData, (item) => {
                if (item.msId == triggereditem.MsId && StringUtil.isEmpty(item.snapshotDt)) {
                  _.extend(item, { 'isTriggeredInstrument': true });
                }
              });
            });
          }

          this._state.highlightSymbolMsIds = _.chain(listAlertTriggeredInstrumentDatas)
            .groupBy('MsId')
            .map((value, key) => ({ HighlightMsId: key, TriggerMessages: pluck(value, 'ListAlertMessage') }))
            .value();

          resolve(result)
        }
      });
    });
  }

  cancelPromise(){
    if(cancelablePromise)cancelablePromise.cancel();
  }
  async processServiceCalls(data) { //exportData = false
    let prevNodeId;
    let listBrowser;
    try {
      prevNodeId = ListExplorerStore.activeNode.nodeId;
      const ownershipRecordInfo = data.esBasicCatalogData.ownershipRecordInfo ? JSON.parse(data.esBasicCatalogData.ownershipRecordInfo) : [];
      const isColumnFilterEnabled = this.getScreenOnOffValue();
      this.setSortColumns();
      listBrowser = clone(this._state.basicEsInfo);
      listBrowser.cacheId = this._state.cacheId;
      listBrowser.useColFilter = isColumnFilterEnabled;
      listBrowser.isBrowserCount = true;
      listBrowser.listId = listBrowser.actualListId;
      cancelablePromise = ListApi.getListBrowserData_Cancelable(listBrowser)
      let selectQuoteServiceOption = SettingsStore.getConsoleSettings().NavDatagraphSettings.PreferenceSettings.QuoteServiceSettings.SelectQuoteServiceOption;
      if (!isNaN(selectQuoteServiceOption)) {
        if (selectQuoteServiceOption === IntradaySourceType.IntradaySource_NASDAQ) {
          this._state.IsNASDAQBasic = true;
        } else {
          this._state.IsNASDAQBasic = false;
        }
      }
      SummaryStatStore.clearSummaryStatData();
      /*eslint-disable */
      if (ownershipRecordInfo.length > 0) {
        let trigger = await this.getListAlertTriggeredInstruments(this._state.listId, ownershipRecordInfo);
        let resetReorderNumAllColumnsStatus = this.resetOrderNumAllColumns();
        if (resetReorderNumAllColumnsStatus) {
          // this.getFullListMsids(ListExplorerStore.activeNode, this._state.basicEsInfo);
          const msidData = this.getMsIdsFromResponData(ownershipRecordInfo);
          this._state.allListSymbolMsids = msidData.allListSymbolMsids;
          this._state.allMsidList = msidData.msidList;
        }
        this.setPageListEsBasic(ownershipRecordInfo);
      }else{
        this.isListLoaded = true;
      }
      const listState = ListStore.getSelectedListItem();
      window.setTimeout(() => {
        ListActions.dataLoadingComplete(listState);
      }, 0);
      TimeTrackingWindow.endListContextRenderTimeTracker();
      let currentNodeId = ListExplorerStore.activeNode.nodeId;
      
      if (currentNodeId == prevNodeId) {
        if (!cancelablePromise) {
          cancelablePromise = null;
          return;
        }
        cancelablePromise.promise.then((catalogData) => {
          currentNodeId = ListExplorerStore.activeNode.nodeId;

          try {
            if (catalogData.catalog.igOneilFilters != null) this._state.browserIgOneilFilterData = catalogData.catalog.igOneilFilters;
            if (catalogData.catalog.igFilters != null) this._state.browserIgGicsFilterData = catalogData.catalog.igFilters;
            if (catalogData.catalog.browser != null) this._state.browserFilterData = catalogData.catalog.browser;

            if (currentNodeId == prevNodeId) {
              ListStore.resetRefresh();
              if (this.getLoadFilterDataStatus()) {
                this.setLoadFilterDataStatus(false);
              this.loadBrowserFilterData = false;
            }
            
              this._currentAction = ListExplorerConstants.ActionTypes.GET_LIST_CATALOG;
              this.emit(CHANGE_EVENT);
              this._currentAction = ListExplorerConstants.ActionTypes.GET_BROWSER_FILTER;
              this.emit(CHANGE_EVENT);
              // SettingsStore.saveSettings();
            }
            cancelablePromise = null
          }
          catch (e) {
            cancelablePromise = null
            console.error(e);
          }
        });
      }else{
        this._currentAction = ListExplorerConstants.ActionTypes.GET_LIST_CATALOG;
        this.emit(CHANGE_EVENT);
      }

    } 
    catch (error) {
      console.error(error);
      TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.ListContent, false, NavType.NavLists);
    }
    // SettingsStore.saveSettings();

   }

  async setPageListEsBasic(ownershipRecordInfo) {
    /*eslint-disable */
    //Reset all column order number
    if (this.resetOrderNumAllColumns()) {
    let t = this.addPageData(ownershipRecordInfo, 0, -1);
      if (this.isRefreshView) {
        const t =  this.populateSummaryView(true, true).then(()=>{
          this.isRefreshView = false;
          SummaryStatStore.emitChange();
        })

      }
      else {
        const t =  this.populateSummaryView(false, true).then(()=>{
          SummaryStatStore.emitChange();
        });
      }
      
      return true;
    }
    else {
      return false;
    }
    /*eslint-enable */
  }

  findRowId(rowId) {
    let row = find(this.getSourceData(), (key) => rowId.toString() === key.rowId.toString());
    if (row && !(isNull(row) || isUndefined(row)))
      return row.recordDatas;
  }

  async getUpdateFlagStatus(listId, msidList) {
    try {
      const msidStr = msidList.join(";");
      if (this._state.basicEsInfo.isUserList) {
        let stringmsids = "";
        if (msidList.length > 0) {
          stringmsids = msidList.join(';');
        }
        /*eslint-disable */
        let t = await this.reorderOrdinalNumbers(this._state.listId, "", 0, stringmsids);
        /*eslint-enable */
      }
      let response = await ListApi.getMsidFlagRequest(listId, msidStr);

      if (response == null || response.flagData == null || response.flagData.length == 0) return true;
      for (let item of response.flagData) {
        if (!item) continue;
        map(this._source, (listModel) => {
          if (listModel.msId == StringUtil.convertFromLongValueToInt(item.msid)) {
            listModel.isFlagged = item.isflag;
            const recordData = indexBy(listModel.recordDatas, 'dataItemId');
            let colItem = recordData[DataItemIdFlag.toString()];
            if (colItem) {
              colItem.displayValue = item.isflag;
              if (listModel.isFlagged)
                colItem.className = (colItem.className && !colItem.className.includes('icn-flag') ? colItem.className + ' icn-flag active' : 'icn-flag active');
              else colItem.className = (colItem.className && !colItem.className.includes('icn-flag') ? colItem.className + ' icn-flag' : 'icn-flag');
            }
            else {
              let colDataSet = {};
              colDataSet.textWidth = flagColumnWidth;
              colDataSet.displayValue = item.isflag;
              if (listModel.isFlagged)
                colDataSet.className = (colDataSet.className ? colDataSet.className + ' icn-flag active' : 'icn-flag active');
              else colDataSet.className = (colDataSet.className ? colDataSet.className + ' icn-flag' : 'icn-flag');
              listModel.recordDatas.push(colDataSet);
            }
          }
        });
      }
      return true;
    }
    catch (e) {
      console.log(e);
    }
  }

  getAlignment(columnInfo) {
    let alignment = 'center';
    if (columnInfo.dataItemId == DataItemIdType || columnInfo.dataItemId == DataItemIdNotImportedSymbol)
      return alignment;
    else {
      // 3 - Left, 4 - Center, 5 - Right
      switch (columnInfo.dataType) {
        case ListColumnDataType.STRING_ListColumnData:
          alignment = 'left';
          break;
        case ListColumnDataType.DOUBLE_ListColumnData:
        case ListColumnDataType.INT_ListColumnData:
          alignment = 'center'; //alignment = 'right'; fix - PANWEB-2310
          break;
        case ListColumnDataType.BOOL_ListColumnData:
        case ListColumnDataType.DATE_ListColumnData:
        default:
          alignment = 'center';
          break;
      }
    }
    return alignment;
  }

  async addPageData(listDatas, seqNum, insertIndex = -1) { //symbolAdded = false
    if (insertIndex == -1 && seqNum == 0) this._source = [];
    try {
      let startIndex = seqNum;
      const colDist = indexBy(this._state.columns, 'dataItemId');
      const colKeys = Object.keys(colDist);
      const ownerShipRecordData = listDatas;
      let rowIndex = 0;
      let msidList = [];

      for (let record of ownerShipRecordData) {
        let listdataItem = {};
        try {
          ++rowIndex;
          if (rowIndex < ownerShipRecordData.length) {
            listdataItem =
              {
                isOwnership: false,
                positionIndex: startIndex,
                isSelected: false
              };
          }
          else {
            listdataItem =
              {
                isOwnership: false,
                positionIndex: startIndex,
                isSelected: false,
                isFocus: true
              };

          }
        } catch (e) {
          console.log('error', e);
        }
        listdataItem.instrumentType = InstrumentType.STOCK_Instrument;
        listdataItem.isRowVisible = true;
        listdataItem.isActive = false;
        listdataItem.recordDatas = [];
        listdataItem.isStreming = false;
        if (record && !(isNull(record) || isUndefined(record))) {
          let recordKeys = Object.keys(record);
          if (!contains(recordKeys, DataItemIdFlag.toString()))
            recordKeys.push(DataItemIdFlag.toString())
          const keys = colKeys;

          if (contains(recordKeys, "msid")) {
            let colValue = record["msid"];
            listdataItem.msId = parseInt(colValue);
            listdataItem.instrumentId = listdataItem.msId;
            msidList.push(listdataItem.msId);
          }

          if (contains(recordKeys, SnapshotIdName) && (ListExplorerStore.isSnapshotList() || ListExplorerStore.isModelList())) {
            try {
              let colValue = record[SnapshotIdName];
              const isInt = Number.isInteger(colValue);
              listdataItem.snapshotDt = isInt ? Number.parseInt(colValue) : StringUtil.formatDate(colValue);
            }
            catch (exc) {
              listdataItem.snapshotDt = null;
            }
          }
          for (let key of keys) {
            let col = "";
            if (key == DataItemIdFlag && !record[key]) {
              col = false;
            }
            else if (record[key] == undefined || record[key] == null) {
              col = "";
            }
            else {
              col = record[key];
            }
            let colDataSet = {};
            if (has(colDist, key) && !contains(this._removedColumns, key.toString())) {
              colDataSet.className = "";
              colDataSet.dataType = colDist[key].dataType;
              if (!Number.parseInt(key))
                continue;
              const colId = Number.parseInt(key);
              switch (colId) {
                case DataItemIdFlag:
                  {
                    if (col && col.toLowerCase() == "true") {
                      listdataItem.isFlagged = true;
                      colDataSet.className = (colDataSet.className ? colDataSet.className + ' icn-flag active' : 'icn-flag active');
                    }
                    else {
                      listdataItem.isFlagged = false;
                      colDataSet.className = (colDataSet.className ? colDataSet.className + ' icn-flag' : 'icn-flag');
                    }
                  }
                  break;
                case DataItemIdSymbol:
                  listdataItem.symbol = col;
                  colDataSet.className = colDataSet.className + 'symbol';
                  break;
                case DataItemIdName:
                  listdataItem.coName = col;
                  break;
                case DataItemIdRowid:
                  if (col) {
                    listdataItem.rowId = parseInt(col);
                  }
                  else {
                    listdataItem.rowId = rowIndex;

                  }
                  break;
                case ModelStockId:
                  listdataItem.modelStockId = col.toString().trim();
                  break;
                default:
                  break;
              }

              if (has(colDist, colId)) {
                if (!isEmpty(ListExplorerStore.activeNode))
                  listdataItem.isModelStock = ListExplorerStore.isModelList();
                const columnInfo = colDist[colId];

                let strVal = "";
                col = Number.POSITIVE_INFINITY == col || Number.NEGATIVE_INFINITY == col ? FormatterUtil.convertToNan() : col;
                if (col !== "") {
                  strVal = this.getCellStrVal(columnInfo, col, colDataSet);
                }

                colDataSet.displayValue = strVal;
                colDataSet.isVisualizationOn = columnInfo.isVisualizationOn;
                if (columnInfo.usePosNegColoring && !colDataSet.isNegative && col != 0)
                  colDataSet.className = 'grid-cell-positive';
                else if (columnInfo.usePosNegColoring && colDataSet.isNegative)
                  colDataSet.className = 'grid-cell-negative';


                if (colId == DataItemIdRowid) { // #
                  strVal = listdataItem.rowId;
                }
                else if (colId == DataItemIdSymbol) {
                  strVal = listdataItem.symbol;
                }
                else if (colId == DataItemIdFlag) {
                  strVal = listdataItem.isFlagged;
                }
                else if (colId == DataItemIdType) {
                  strVal = col;
                  if (listdataItem.isModelStock) {
                    strVal = "M";
                  }
                  else {
                    strVal = this.getInstrumentTypeString(parseInt(col));
                    listdataItem.instrumentType = parseInt(col);
                  }
                }
                else {
                  if (colId == CloseRangeDataItemId) {
                    if (record[PricePercDataItemId] !== null || record[PricePercDataItemId] !== undefined) {
                      listdataItem.isPricePositive = parseFloat(record[PricePercDataItemId]) >= 0;
                    }
                  }
                  if (colId == CloseRangeWeekDataItemId) {
                    if (record[PricePercWeekItemId] !== null || record[PricePercWeekItemId] !== undefined) {
                      listdataItem.isPricePositive = parseFloat(record[PricePercWeekItemId]) >= 0;

                    }
                  }
                  if (colId == CloseRangeDayDataItemId) {
                    if (record[PricepercDayItemId] !== null || record[PricepercDayItemId] !== undefined) {
                      listdataItem.isPricePositive = parseFloat(record[PricepercDayItemId]) >= 0;
                    }
                  }
                  if (columnInfo.canVisualize && colDataSet.isVisualizationOn) {
                    colDataSet.className = listdataItem.isPricePositive ? 'grid-visualize-rect-positive' : 'grid-visualize-rect-negative';
                  }
                }
                if (listdataItem.snapshotDt) {
                  if (listdataItem.symbol != null)
                    listdataItem.snapshotDt = StringUtil.formatDate(listdataItem.snapshotDt, "MM/DD/YYYY");
                }
                colDataSet.alignment = 'center';
                colDataSet.alignment = this.getAlignment(columnInfo);
                colDataSet.formatter = columnInfo.formatter;
                colDataSet.displayValue = strVal;
                if (colId == DataItemIdFlag) // Flag
                  colDataSet.textWidth = flagColumnWidth;
                else
                  colDataSet.textWidth = this.getCellTextWidth(columnInfo, colDataSet.displayValue, listdataItem.snapshotDt);

              }
              colDataSet.dataItemId = colId;
              if(colId === DataItemIdLocalSymbol){
                listdataItem.localSymbol = colDataSet.displayValue
              }
              const index = this.findColumnIndex(colDataSet.dataItemId);
              if (colKeys && index >= 0 && index < colKeys.length) {
                listdataItem.recordDatas[index] = colDataSet;
              }
            }

          }

          // //Alert trigger message - Start
          let triggeredinstrument = this._state.highlightSymbolMsIds.length > 0
            ? _.find(this._state.highlightSymbolMsIds, (item) =>
              item.HighlightMsId == listdataItem.msId)
            : null;

          let recordDataItem = _.find(listdataItem.recordDatas, (item) => item.dataItemId == -5)

          if (triggeredinstrument != null) {
            listdataItem.isTriggeredInstrument = true;

            if (!recordDataItem.className.includes('summary-list-symbol-triggered'))
              recordDataItem.className += ' summary-list-symbol-triggered'

            if (StringUtil.isEmpty(listdataItem.ListAlertMessage))
              listdataItem.ListAlertMessage = '';
            const len = triggeredinstrument.TriggerMessages;
            _.each(triggeredinstrument.TriggerMessages, (triggeredmsg, i) => {
              listdataItem.ListAlertMessage += `${triggeredmsg}
                ${i != len
                  ? "<br/>"
                  : ''}`;
            });
          }
          else {
            if(recordDataItem) {
              recordDataItem.className = recordDataItem.className.replace(new RegExp('summary-list-symbol-triggered', 'g'), '');
            }
          }
          //Alert trigger message - End

          if (insertIndex > -1) {
            this._source.splice(insertIndex, 0, listdataItem);
          }
          else {
            this._source.push(listdataItem);
          }
          }
          startIndex++;
        }

      /*eslint-disable */
      let t = msidList.length >0 ? await this.getUpdateFlagStatus(this._state.listId, msidList):'';
      this._currentAction = ListExplorerConstants.ActionTypes.LIST_ONEIL_FLAGGED;
      this.emit(CHANGE_EVENT);
      /*eslint-enable */
      return true;
    }
    catch (e) {
      console.log('error', e);
    }
  }

  getCellStrVal(columnInfo, col, colDataSet) {

    let strVal = "";
    if (columnInfo.dataType == ListColumnDataType.DATE_ListColumnData) {
      const localTimeZone = columnInfo.dataItemId === -1 ? true : false; // dataItemId = -1 is Date Added metric - Display system Date & Time.
      const formatedDate = StringUtil.formatDate(col, columnInfo.formatter.toUpperCase(), localTimeZone);
      if (formatedDate != LocalizationStore.getTranslatedData("cal_invaliddate", "Invalid date")) {
        strVal = formatedDate;
      }
    }
    else if (columnInfo.dataType == ListColumnDataType.STRING_ListColumnData) {
      let value = col.toString();
      if (value && contains(value, ":") && columnInfo.dataItemId == -6000004) {
        let sArray = value.split(':');
        let subStr = sArray[0];
        let num = Number.parseFloat(subStr);
        if (!isNaN(num)) {
          value = num.toFixed(2) + ":" + sArray[1];
        }
      }

      strVal = this.getCellStringValue(value, columnInfo.formatter, columnInfo.isZeroVisible) //CellGenerator.GetCellStringValue(value, columnInfo.FormatString, columnInfo.IsZeroDisplayed);
    }
    else {
      strVal = this.getCellDoubleStringVal(col, columnInfo.formatter, columnInfo.isZeroVisible, columnInfo.isCustomColumn, columnInfo.styleType, colDataSet);

    }

    return strVal;
  }

  getCellStringValue(cellVal, format, isZeroDisplayed) {

    let suffix = contains(format, "%") ? "%" : "";
    let retValue;
    if (isZeroDisplayed && !cellVal) {
      retValue = "0" + suffix;
    }
    else {
      retValue = !cellVal ? "" : cellVal;
    }
    return retValue;
  }

  getCellDoubleStringVal(cellVal, formatString, isZeroDisplayed, iscustomcolumn, styleType, colDataSet) {

    colDataSet.isNegative = false;
    let doubleValue = parseFloat(cellVal);

    if (!isNaN(doubleValue)) {

      if (doubleValue < 0)
        colDataSet.isNegative = true;

      if (!formatString) {
        doubleValue = doubleValue.toFixed(2);
        return doubleValue.toString();
      }

      // if (formatString && contains(formatString, "%") && !iscustomcolumn)

      //   doubleValue /= 100;
      // }

      if (iscustomcolumn) {
        // let format = stringUtil.formatter(formatString);
        // let decimals = stringUtil.getDecimals(formatString);
        if (formatString && formatString.includes("K"))
          doubleValue /= 1000;
        else if (formatString && formatString.includes("M"))
          doubleValue /= 1000000;
        else if (formatString && formatString.includes("B"))
          doubleValue /= 1000000000;
      }
      if (isZeroDisplayed) {
        if (doubleValue == 0 || doubleValue == '')
          doubleValue = 0;
        doubleValue = this.setNumberFormatter(doubleValue, formatString, styleType)
        return doubleValue;
      }


      let stringval = '';
      if (iscustomcolumn) {
        let format = StringUtil.formatter(formatString);
        stringval = this.setNumberFormatter(doubleValue, format, styleType);
        // stringval = doubleValue.toString();
      }
      else {
        let checkZero = 0;
        if(doubleValue != '')
         checkZero = this.setNumberFormatter(doubleValue, formatString, styleType);
         stringval = checkZero == 0 || doubleValue == '' ? "" : doubleValue.toString();
      }

      // if (isEqual(stringval, "K") ||isEqual(stringval, "M")  || isEqual(stringval, "B") )
      // {
      //     return formatString.Replace("#", "0");
      // }

      if (stringval === "") return stringval;
      if (!iscustomcolumn) {
      stringval = this.setNumberFormatter(doubleValue, formatString, styleType);
      }
      return stringval;

    }
    return "";
  }

  findPriceChangePercetageColumnValue(recordDatas) {
    let cell = find(recordDatas, (key) => {
      if (key && !(isNull(key) || isUndefined(key))) {
        return key.dataItemId == pricePercentageChangeColumnId;
      }
    });

    if (cell) {
      return (cell.doubleValue >= 0) ? 'grid-visualize-rect-positive' : 'grid-visualize-rect-negative';
    }
    return 'grid-visualize-rect-negative';
  }

  updateDataSourceTextWidth() {
    let obj = this.getSourceData();
    each(obj, (item) => {
      each(item.recordDatas, (recordData, key) => {
        if (recordData && !(isNull(recordData) || isUndefined(recordData)) && this._state.columns[key] != undefined) {
          if (this._state.columns[key].dataItemId == -3) // Flag
            recordData['textWidth'] = flagColumnWidth;
          else
            recordData['textWidth'] = this.getCellTextWidth(this._state.columns[key], recordData['displayValue'], recordData['snapshotDt']);
        }
      });
    });
  }

  updateDataSourceVisualization(dataItemId, isVisualizationOn) {
    let obj = this.getSourceData();
    const records = pluck(obj, 'recordDatas');
    each(records, (rowData, key) => {
      const colData = find(rowData, (col) => col.dataItemId == dataItemId);
      if (colData) {
        colData.isVisualizationOn = isVisualizationOn;
        if (colData.isVisualizationOn) {
          colData.className = obj[key] && obj[key].isPricePositive ? 'grid-visualize-rect-positive' : 'grid-visualize-rect-negative';
        }
        else {
          colData.className = "";
        }
      }
    });
  }

  getCellTextWidth(colData, displayValue, snapshotDt) {
    if (displayValue) {
      let width = cellWidth(displayValue.toString(), {
        family: 'calibri',
        size: ListStore.getTextFontSize()
      });
      if (colData) {
        if (snapshotDt && colData.dataItemId == -5) { // symbol
          let subscriptWidth = cellWidth(displayValue.toString(), {
            family: 'calibri',
            size: 10
          });
          width += subscriptWidth;
        }
        // AutosizeForSummaryStats check is pending
        if (parseInt(colData.textWidth) < width) {
          colData.textWidth = width;
        }
      }
      return width;
    }
    return 0;
  }

  async sortColumnsOnOrderNum(obj) {
    let colPosition = 0;
    if (!obj) obj = this.getSourceData();
    map(obj, (recordDatas) => {
      map(recordDatas, (recordData, key) => {
        if (key == 'recordDatas') {
          let tempRecordDatas = [];
          map(recordData, (item) => {
            if (item && item.dataItemId) {
              colPosition = this.findColumnOrderNum(item.dataItemId);
              tempRecordDatas[colPosition] = item;
            }
          });
          recordDatas[key] = tempRecordDatas;
        }
      });
    });
    return true;
  }

  reorderColumns(dataItemId, previousColumnDataItemId) {
    this.isListLoaded = false;
    return new Promise((resolve) => {
      let draggedIndex = this.findColumnIndex(dataItemId);
      let dropIndex = this.findColumnIndex(previousColumnDataItemId) + 1;
      this._state.columns.move(draggedIndex, dropIndex);
      if (this.resetOrderNumAllColumns()) {
        this.sortColumnsOnOrderNum(this._source).then((orderCmplt1) => {
          if (orderCmplt1) resolve(true);
        });
      }
    });
  }

  findColumnIndex(dataItemId) {
    return findIndex(this._state.columns, (key) => dataItemId.toString() === key.dataItemId.toString());
  }

  findColumn(dataItemId, fromColumnInfos = false) {
    let obj = fromColumnInfos ? this._state.basic.columnInfos : this._state.columns;
    let column = find(obj, (key) => dataItemId.toString() === key.dataItemId.toString());

    if (!StringUtil.isEmpty(column)) {
      return column;
    }
    return -1;
  }

  resetOrderNumAllColumns() {
    let i = 0;
    map(this._state.columns, (key) => {
      key.id = i;
      key.orderNum = i + 1;
      i++;
    });
    return true;
  }

  findColumnOrderNum(dataItemId) {
    let column = find(this._state.columns, (key) => dataItemId.toString() === key.dataItemId.toString());
    if (!StringUtil.isEmpty(column)) {
      return column.orderNum - 1;
    }
    return -1;
  }

  updateColumnWidth(colSeq) {
    return new Promise((resolve) => {
      let columnsArr = colSeq.split(';');
      each(columnsArr, (col) => {
        if (col) {
          let colData = col.split(',');
          find(this._state.columns, (column) => {
            if (colData[0] == column.dataItemId) {
              column.customWidth = parseInt(colData[1]);
              return true;
            }
            else {
              return false;
            }
          })
        }
      });
      this.updateDataSourceTextWidth();
      resolve(true);
    });
  }


  updateVisualizeColumn(colSeq) {
    return new Promise((resolve) => {
      let arr = colSeq.split(',');
      const dataItemId = clone(arr[0]);
      const colVisualization = clone(arr[1]);
      let column = find(this._state.columns, (colItem) => dataItemId.toString() == colItem.dataItemId.toString());
      column.isVisualizationOn = colVisualization == "1" ? true : false;

      this.updateDataSourceVisualization(dataItemId, column.isVisualizationOn);
      resolve(true);
    });
  }

  getCellValue(cellData, formatter, styleType) {
    let cellValue = '';
    const dataItemId = cellData.dataItemId;
    if (cellData.dateValue) {
      //Note: Bug - 1302 fixes - temp fix, Date Type formmater issue
      if (formatter.toUpperCase() == "#,#000" || formatter.toUpperCase() == "#,##0" || formatter.toUpperCase() == "#,##0.00" || formatter.toUpperCase() == "(#,##0)" || formatter.toUpperCase() == "#,##0.0%" || formatter.toUpperCase() == "#,##0.00") {
        formatter = "MM/dd/yyyy";
      }
      const formatedDate = StringUtil.formatDate(cellData.dateValue, formatter.toUpperCase());
      if (formatedDate != LocalizationStore.getTranslatedData("cal_invaliddate", "Invalid date")) {
        cellValue = formatedDate;
        cellData.stringValue = formatedDate;
      }
    }
    else if (cellData.doubleValue) {
      if (dataItemId == -3) {
        cellValue = (cellData.doubleValue == 1 ? true : false); // Flag column returns double value 0 or 1 instead of returning boolvalue true or false in details.pb call. for ex, "mm"
        cellData.boolvalue = cellValue;
      }
      else cellValue = this.setNumberFormatter(cellData.doubleValue, formatter, styleType);
    }
    else if (cellData.longValue) {
      cellValue = cellData.longValue.low;
      if (dataItemId == -4) cellValue = cellData.rowId; // #
      else if (dataItemId == -7) cellValue = this.getInstrumentTypeString(cellValue); // Type
    }
    else if (cellData.boolValue) {
      cellValue = cellData.boolValue;
    }
    else if (cellData.stringValue) {
      if (cellData.dataType == 1) { // bool
        cellValue = cellData.stringValue;
      }
      else if (cellData.dataType == 2) { // int
        cellValue = cellData.stringValue;
      }
      else if (cellData.dataType == 4) { // date
        //Note: Bug - 1302 fixes - temp fix, Date Type formmater issue
        if (formatter.toUpperCase() == "#,#000" || formatter.toUpperCase() == "#,##0" || formatter.toUpperCase() == "#,##0.00" || formatter.toUpperCase() == "(#,##0)" || formatter.toUpperCase() == "#,##0.0%" || formatter.toUpperCase() == "#,##0.00") {
          formatter = "MM/dd/yyyy";
        }
        const formatedDate = StringUtil.formatDate(Date.parse(cellData.stringValue), formatter.toUpperCase());
        if (formatedDate != LocalizationStore.getTranslatedData("cal_invaliddate", "Invalid date")) {
          cellValue = formatedDate;
        }
      }
      else if (cellData.dataType == 5) { // double
        cellValue = this.setNumberFormatter(cellData.stringValue, formatter, styleType);
      }
      else cellValue = cellData.stringValue;
    }
    return cellValue;
  }

  setNumberFormatter(doubleValue, formatter, styleType) {
    let cellValue = '';
    let strFormatter = formatter.split(";");
    if (styleType === ListColumnStyleType.POSITIVENEGATIVEPARENTHESIS_ListColumnStyle) {
      formatter = Math.sign(doubleValue) >= 0 ? strFormatter[0] : strFormatter[1];
    }

    switch (formatter) {
      case '#,##0%':
        cellValue = `${FormatterUtil.formatNumber(Number(doubleValue).toFixed(0))}%`;
        break;

      case '#,##0':
        cellValue = FormatterUtil.formatNumber(Number(doubleValue).toFixed(0));
        break;

        case '#,##0.':
          cellValue = FormatterUtil.formatNumber(Number(doubleValue).toFixed(0));
          break;

      case '(#,##0)':
        cellValue = `(${FormatterUtil.formatNumber(Math.abs(doubleValue))})`;
        break;

  case '#,##0.0':
        cellValue = FormatterUtil.formatNumber(doubleValue, 1);
        break;

      case '#,##0.00':
        cellValue = FormatterUtil.formatNumber(doubleValue, 2);
        break;

        case '#,##0.000':
        cellValue = FormatterUtil.formatNumber(doubleValue, 3);
        break;

      case '#,##0.0000':
        cellValue = FormatterUtil.formatNumber(doubleValue, 4);
        break;

      case '#,##0%':
        cellValue = `${FormatterUtil.formatNumber(Number(doubleValue).toFixed(0))}%`;
        break;

      case '#,##0.%':
        cellValue = `${FormatterUtil.formatNumber(doubleValue, 0)}%`;
        break;

      case '#,##0.0%':
        cellValue = `${FormatterUtil.formatNumber(doubleValue, 1)}%`;
        break;

        case '#,##0.00%':
        cellValue = `${FormatterUtil.formatNumber(doubleValue, 2)}%`;
        break;

      case '#,##0.000%':
        cellValue = `${FormatterUtil.formatNumber(doubleValue, 3)}%`;
        break;

      case '#,##0.0000%':
        cellValue = `${FormatterUtil.formatNumber(doubleValue, 4)}%`;
        break;

      case '#,##0K':
        cellValue = `${FormatterUtil.formatNumber(Number(doubleValue).toFixed(0))}K`;
        break;

      case '#,##0.K':
        cellValue = `${FormatterUtil.formatNumber(doubleValue, 0)}K`;
        break;

      case '#,##0.0K':
        cellValue = `${FormatterUtil.formatNumber(doubleValue, 1)}K`;
        break;

      case '#,##0.00K':
        cellValue = `${FormatterUtil.formatNumber(doubleValue, 2)}K`;
        break;

      case '#,##0.000K':
        cellValue = `${FormatterUtil.formatNumber(doubleValue, 3)}K`;
        break;

      case '#,##0.0000K':
        cellValue = `${FormatterUtil.formatNumber(doubleValue, 4)}K`;
        break;

      case '#,##0M':
        cellValue = `${FormatterUtil.formatNumber(Number(doubleValue).toFixed(0))}M`;
        break;

      case '#,##0.M':
        cellValue = `${FormatterUtil.formatNumber(doubleValue, 0)}M`;
        break;

      case '#,##0.0M':
        cellValue = `${FormatterUtil.formatNumber(doubleValue, 1)}M`;
        break;

      case '#,##0.00M':
        cellValue = `${FormatterUtil.formatNumber(doubleValue, 2)}M`;
        break;

      case '#,##0.000M':
        cellValue = `${FormatterUtil.formatNumber(doubleValue, 3)}M`;
        break;

      case '#,##0.0000M':
        cellValue = `${FormatterUtil.formatNumber(doubleValue, 4)}M`;
        break;

      case '#,##0B':
        cellValue = `${FormatterUtil.formatNumber(Number(doubleValue).toFixed(0))}B`;
        break;

      case '#,##0.B':
        cellValue = `${FormatterUtil.formatNumber(doubleValue)}B`;
        break;

      case '#,##0.0B':
        cellValue = `${FormatterUtil.formatNumber(doubleValue, 1)}B`;
        break;

      case '#,##0.00B':
        cellValue = `${FormatterUtil.formatNumber(doubleValue, 2)}B`;
        break;

      case '#,##0.000B':
        cellValue = `${FormatterUtil.formatNumber(doubleValue, 3)}B`;
        break;

      case '#,##0.0000B':
        cellValue = `${FormatterUtil.formatNumber(doubleValue, 4)}B`;
        break;

      case '####':
        cellValue = doubleValue.toFixed(0).toString();
        break;

      case '#':
        cellValue = doubleValue.toFixed(0).toString();
        break;


      default:
        cellValue = FormatterUtil.formatNumber(doubleValue);
        break;
    }
    const value = FormatterUtil.isNegetiveZeroValue(cellValue) ? cellValue.replace("-", "") : cellValue;
    return value;
  }

  // setNumberFormatter(doubleValue, formatter, styleType) {
  //   let cellValue = '';
  //   let strFormatter = formatter.split(";");
  //   if (styleType === ListColumnStyleType.POSITIVENEGATIVEPARENTHESIS_ListColumnStyle) {
  //     formatter = Math.sign(doubleValue) >= 0 ? strFormatter[0] : strFormatter[1];
  //   }

  //   switch (formatter) {
  //     case '#,##0%':
  //       cellValue = Number(doubleValue).toFixed(0) + '%';
  //       break;

  //     case '#,##0':
  //       cellValue = new Intl.NumberFormat().format(Number(doubleValue).toFixed(0));
  //       cellValue = FormatterUtil.formatNumber(Number(doubleValue).toFixed(0));
  //       break;
  //     case '(#,##0)':
  //       cellValue = numberFormatter("(#,##)", Math.abs(doubleValue));
  //       cellValue = `(${FormatterUtil.formatNumber(Math.abs(doubleValue))})`;
  //       break;

  //     case '#,##0.00':
  //     case '#,##0.0%':
  //       cellValue = numberFormatter(formatter, doubleValue);
  //       cellValue = FormatterUtil.formatNumber(doubleValue);
  //       break;

  //     default:
  //       cellValue = numberFormatter(formatter, doubleValue);
  //       cellValue = FormatterUtil.formatNumber(doubleValue);
  //   }
  //   return cellValue;
  // }


  getInstrumentTypeString(instrumentType) {
    switch (instrumentType) {
      case 19: return "C";
      case 14: return "I&MI";
      case 105: return "IMA";
      case 104: return "IMI";
      case 17: return "IG";
      case 100: return "MF";
      case 118: return "MFF";
      case 120: return "MFI";
      case 121: return "NO CI";
      case 119: return "SI";
      case 1: return "S";
      case 102: return "FC";
      case 103: return "CS";
      case 160: return "CC";
      default: return "";
    }
  }

  setListCatalogSearchKeyword(keyword) {
    this._state.searchKeyword = keyword.trim();
    if (!keyword.trim()) {
      this._state.needToCallAdobe = true;
  }
}

  async getMoreDataOnScroll(listCataLog) {
    if (this.getSourceData().length >= this._state.basic.count) return;
    if (!isScollBarCallRunning) {
      const isMiniLisOnScroll = listCataLog.isMiniLisOnScroll != undefined ? listCataLog.isMiniLisOnScroll : false;
      !isMiniLisOnScroll && TimeTrackingWindow.resetTimeTracker(true, true, NavType.NavLists);
      !isMiniLisOnScroll && TimeTrackingWindow.initTimeTracker();
      !isMiniLisOnScroll && TimeTrackingWindow.beginTimeTracker(NavType.NavLists);
      isScollBarCallRunning = true;
      let pageBasicEsInfoData = clone(this._state.basicEsInfo);
      pageBasicEsInfoData.needBrowserCounts = false;
      pageBasicEsInfoData.sortCols = null;
      pageBasicEsInfoData.needColumnsInfo = true;
      pageBasicEsInfoData.saveColFilters = false;
      pageBasicEsInfoData.customerOrder = false;
      pageBasicEsInfoData.addlCatalogRequests = null;
      pageBasicEsInfoData.cacheId = this._state.cacheId;
      pageBasicEsInfoData.pageIndex = parseInt(listCataLog.pageIndex);
      pageBasicEsInfoData.isMiniLisOnScroll = listCataLog.isMiniLisOnScroll;
      try {
        if(!pageBasicEsInfoData.isMiniLisOnScroll){
          BrowserUtil.disableEvents();
        }
        const isUserList = ListExplorerStore.isUserListOrFavoriteUserList();
        !isMiniLisOnScroll && TimeTrackingWindow.beginListContextApiTimeTracker();
        const esBasicData = await ListApi.getListCatalogEsData(pageBasicEsInfoData, false, isUserList).promise;
        !isMiniLisOnScroll && TimeTrackingWindow.endListContextApiTimeTracker();
        !isMiniLisOnScroll && TimeTrackingWindow.beginListContextRenderTimeTracker();
        const ownershipRecordInfo = JSON.parse(esBasicData.catalog.ownershipRecordInfo);
        this._state.cacheId = this._state.basic.cacheId = esBasicData.catalog.cacheId;
        if (this.resetOrderNumAllColumns()) {
          this.sortColumnsOnOrderNum();
          /*eslint-disable */
          let t = await this.addPageData(ownershipRecordInfo, this.getSourceData().length, -1);
          const listState = ListStore.getSelectedListItem();
          window.setTimeout(() => {
            ListActions.dataLoadingComplete(listState);
          }, 0);
          /*eslint-enable */
          isScollBarCallRunning = false;
          BrowserUtil.enableEvents();
          this._currentAction = ListExplorerConstants.ActionTypes.GET_MORE_DATA_ON_SCROLL;
          this.emit(CHANGE_EVENT);
        }
        !isMiniLisOnScroll && TimeTrackingWindow.endListContextRenderTimeTracker();
      }
      catch (e) {
        !isMiniLisOnScroll && TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.ListContent, false, NavType.NavLists);
        BrowserUtil.enableEvents();
        console.log(e);
      }
    }
  }


  startWorker(data) {

    if (typeof (Worker) !== "undefined") {
      if (typeof (w) != "undefined") {
        w.terminate();
        w = undefined;
      }
      if (typeof (w) == "undefined") {
        w = new Worker("webWorkerBundle.js");
        w.postMessage(data);
      }
      w.onmessage = (event) => {
        _this._details = clone(event.data.rows.rows);
        this._state.details = event.data.rows.rows;
        w.terminate();
        w = undefined;
        if (this.resetOrderNumAllColumns()) {
          this.sortColumnsOnOrderNum();
          this.setSourceData();
          this.emit(CHANGE_EVENT);
        }
      };
    } else {
      console.log("Sorry! No Web Worker support.");
    }
  }

  getSymbolFromMsIds(msIds) {
    try {
      const sourceData = this.getSourceData();
      if (sourceData && this._state.allListSymbolMsids.length > 0 && this._state.basic.count >= sourceData.length && !Array.isArray(msIds)) {
        const msidSymbol = find(this._state.allListSymbolMsids, (item) => item.msId == msIds); //indexBy(this._state.allListSymbolMsids, 'msId');
        return msidSymbol ? msidSymbol.symbol : null;
      }
      else if (Array.isArray(msIds)) {
        let symbols = [];
        if (msIds.length >= this._state.basic.count && this._state.allListSymbolMsids.length > 0) {
          each(this._state.allListSymbolMsids, (row) => {
            if (contains(msIds, row.msId.toString())) {
              symbols.push(row.symbol);
            }
          });
          return symbols.join(';');
        }
        else {
          each(sourceData, (row) => {
            if (contains(msIds, row.msId.toString())) {
              symbols.push(row.symbol);
            }
          });
        }
        return symbols.join(';');
      }
      else {
        let row = find(sourceData, (item) => item.msId.toString() === msIds.toString());
        if (row) {
          return row.symbol;
        }
        else {
          return null;
        }
      }
    }
    catch (e) {
      console.log('error', this._state.allListSymbolMsids, e)
    }
  }
  
  getCurrencyCodeFromMsIds(msIds){
    try {
      const sourceData = this.getSourceData();
      if (sourceData && this._state.allListSymbolMsids.length > 0 && this._state.basic.count >= sourceData.length && !Array.isArray(msIds)) {
        const msidSymbol = find(this._state.allListSymbolMsids, (item) => item.msId == msIds); //indexBy(this._state.allListSymbolMsids, 'msId');
        return msidSymbol ? msidSymbol.currencyCode : null;
      } else {
        let row = find(sourceData, (item) => item.msId.toString() === msIds.toString());
        return row ? row.currencyCode : null
      }
    }
    catch (e) {
      console.log('error', this._state.allListSymbolMsids, e)
    }
  }
  
  getPageItemsFromMsIds(msIds) {
    let recordInfos = this.getSourceData();
    if (Array.isArray(msIds)) {
      let pageItems = [];
      each(recordInfos, (row) => {
        if (contains(msIds, row.msId.toString())) {
          pageItems.push(row);
        }
      });
      return pageItems;
    }
    else {
      let row = find(recordInfos, (item) => item.msId.toString() === msIds.toString());
      return row;
    }
  }
  //Drag and Drop required instrumentIds
  getInstrumentIdFromMsIds(msIds) {
    let recordInfos = this.getSourceData();
    if (Array.isArray(msIds)) {
      let instrumentIds = [];
      each(recordInfos, (row) => {
        if (contains(msIds, row.msId.toString())) {
          instrumentIds.push(row.instrumentId.toString());
        }
      });
      return instrumentIds;
    }
    else {
      let row = find(recordInfos, (item) => item.msId.toString() === msIds.toString());
      return row.instrumentId;
    }
  }

  getCurrentListItemList() {
    return this.getSourceData();
  }
  getallMsidList() {
    return this._state.allListSymbolMsids;
  }
  formatSnapshotDt(snapshotDt) {
    if (snapshotDt) return StringUtil.formatDate(snapshotDt, 'M/D/YYYY');
    else return null;
  }

  getSnapshotDateFromMsIds(msIds) {
    const sourceData = this.getSourceData();
    if (sourceData && this._state.allListSymbolMsids.length > 0 && this._state.basic.count >= sourceData.length && !Array.isArray(msIds)) {
      const msidSymbol = find(this._state.allListSymbolMsids, (item) => item.msId == msIds); //indexBy(this._state.allListSymbolMsids, 'msId');
      return msidSymbol ? msidSymbol.snapshotDt : null;
    }
    else if (Array.isArray(msIds)) {
      let snapshotDt = [];
      if (msIds.length >= this._state.basic.count && this._state.allListSymbolMsids.length > 0) {
        each(this._state.allListSymbolMsids, (row) => {
          if (contains(msIds, row.msId.toString())) {
            snapshotDt.push(row.snapshotDt);
          }
        });
        return snapshotDt.join(';');
      }
      else {
        each(sourceData, (row) => {
          if (contains(msIds, row.msId.toString())) {
            snapshotDt.push(row.snapshotDt);
          }
        });
        return snapshotDt.join(';');
      }
    }
    else {
      let row = find(sourceData, (item) => item.msId.toString() === msIds.toString());
      return row && (row.snapshotDt) ? row.snapshotDt : '';
    }
  }

  async getMsIds(selectedIndexes) {
    try {
      let sourceData = this.getSourceData();
      if (this._state.basic.count <= GridStore.getSelectedIndexes().length) {
        let basicCount = this._state.basic.count > 10000 ? 10000 : this._state.basic.count;
        if(!this._state.allMsidList || this._state.allMsidList.length === 0 || this._state.allMsidList.length != basicCount){
          await this.getFullListMsidsForSelectAll(ListExplorerStore.activeNode,this._state.basicEsInfo);
        }

        let msIds = this._state.allMsidList;
        return msIds.join(';');
      }
      else {
        let msIds = [];
        map(selectedIndexes, (row) => {
          if (sourceData[row]) msIds.push(sourceData[row].msId);
        });
        return msIds.join(';');
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  setVisibleRows(msIds, isRowVisible = true) {
    msIds = msIds.split(';');
    map(this._source, (row) => {
      if (contains(msIds, row.msId.toString())) {
        row.isRowVisible = isRowVisible;
      }
    });

    // map(this._tempSource, (row) => {
    //   if (contains(msIds, row.msId.toString())) {
    //     row.isRowVisible = isRowVisible;
    //   }
    // });

    if (isRowVisible) {
      this._state.basic.total = this._state.basic.total + msIds.length;
      this._state.basic.count = this._state.basic.count + msIds.length;
    }
    else {
      this._state.basic.total = this._state.basic.total - msIds.length;
      this._state.basic.count = this._state.basic.count - msIds.length;

    }
  }

  getListColumnPickList(data) {
    let consoleId = 0;
    let listId = this._state.actualListId;
    let cacheId = this._state.cacheId;
    ListApi.getListColumnPickList(consoleId, listId, cacheId, data.columnId, parseInt(ListExplorerStore.activeNode.categoryType)).then((result) => {
      if (!result.responseHeader.error) {
        this._state.pickListData = result.pickList;
        this._currentAction = ListExplorerConstants.ActionTypes.GET_COLUMN_PICKLIST;
        this.emit(CHANGE_EVENT);
      }
    });
  }

  updateAllFlagIn(isDelete) {
    let sourceData = this.getSourceData();
    this.updateAllFlagInSourceData(sourceData, isDelete);
  }

  updateAllFlagInSourceData(obj, isDelete) {
    map(obj, (rows) => {
      map(rows, (recordDatas, key) => {
        if (key == 'recordDatas') {
          map(recordDatas, (item) => {
            if (item && item.dataItemId == -3) { //Flag
              rows.isFlagged = !isDelete;
              if (isDelete) {
                item.className = 'icn-flag';
                item.displayValue = false;
              }
              else {
                item.className = 'icn-flag active';
                item.displayValue = true;
              }
            }
          });
        }
      });
    });
  }

  updateFlagIn(isDelete) {
    let sourceData = this.getSourceData();
    const selectedIndexes = GridStore.getSelectedIndexes();
    let i = 0;
    const length = sourceData.length < selectedIndexes.length ? sourceData.length : selectedIndexes.length;
    while (i < length) {
      sourceData[selectedIndexes[i]].isFlagged = !isDelete;
      this.updateFlagInSourceData(sourceData[selectedIndexes[i]]['recordDatas'], isDelete);
      i++;
    }
  }

  updateFlagInFromSymbol(msid, isDelete) {
    let sourceData = this.getSourceData();
    let row = find(sourceData, (item) => item.msId == msid);
    if (row) {
      row.isFlagged = !isDelete;
      this.updateFlagInSourceData(row['recordDatas'], isDelete);
    }
  }

  updateFlagInSourceData(obj, isDelete) {
    let row = find(obj, (item) => item.dataItemId == -3); //Flag
    if (isDelete) {
      row.displayValue = false;
      row.className = 'icn-flag';
    }
    else {
      row.displayValue = true;
      row.className = 'icn-flag active';
    }
  }

  reOrderColumnPos() {
    let colPosition = 0;
    let tempRecordDatas = [];
    each(this._singleRowData.recordDatas, (recordData) => {
      colPosition = this.findColumnOrderNum(recordData.dataItemId);
      tempRecordDatas[colPosition] = recordData;
    });
    this._singleRowData.recordDatas = tempRecordDatas;
  }

  async updateDataSourceAfterAdd(result) {
    try {
      const responseStringData = JSON.parse(result.responseData.responseString);
      if (responseStringData.length <= 0) {
        return 'exception';
      }
      if (this.resetOrderNumAllColumns()) {
        this.sortColumnsOnOrderNum();
        this.reOrderColumnPos();
      }
      //PANWEB-6856-fix
      // this.clearSortColumns();
      
      /*eslint-disable */
      // let t = await this.addPageData(responseStringData, this.getSourceData().length, -1); // PANWEB-6856-fix
      /*eslint-enable */
      const total = this._state.basic.total;
      const count = this._state.basic.count;
      
      this._state.basic.total = total + 1;
      this._state.basic.count = count + 1;
      return true;
    }
    catch (e) {
      console.log(e);
      return false;
    }

  }


  async filterCall(isUndo = false) {
    const isColumnFilterEnabled = this.getScreenOnOffValue();
    let listBrowser = clone(this._state.basicEsInfo);
    listBrowser.cacheId = this._state.cacheId;
    listBrowser.useColFilter = isColumnFilterEnabled;
    listBrowser.isBrowserCount = true;
    const msids = await this.getFullListMsids(ListExplorerStore.activeNode,this._state.basicEsInfo, false, isUndo);
    try {
      return ListApi.getListBrowserData(listBrowser).then((catalogData) => {
        try {
          if (catalogData.catalog.igOneilFilters != null) this._state.browserIgOneilFilterData = catalogData.catalog.igOneilFilters;
          if (catalogData.catalog.igFilters != null) this._state.browserIgGicsFilterData = catalogData.catalog.igFilters;
          if (catalogData.catalog.browser != null) this._state.browserFilterData = catalogData.catalog.browser;
        }
        catch (e) {
          console.error(e);
        }
      });
    }
    catch (e) {
      console.error(e);
    }
  }

  setDragedObjectDataSource(dragItems) {
    let sourceData = this.getSourceData();
    let sourceDataClone = clone(sourceData);
    this._tempDragDataSource = [];
    each(sourceDataClone, (sourceItem) => {
      each(dragItems, (item) => {
        if (sourceItem.msId == item.msId) {
          this._tempDragDataSource.push(sourceItem);
        }
      })
    });
  }

  setMoredataLoaded() {
    let destDragedInfo = null;
    setTimeout(() => {
      destDragedInfo = GridStore.getDragedDestInfo();
      if (!(isEmpty(destDragedInfo)) && this._currentAction == ListExplorerConstants.ActionTypes.GET_MORE_DATA_ON_SCROLL) {
        BrowserUtil.disableEvents();
        setTimeout(() => {
          GridActions.dropInListManager(destDragedInfo.dragedInfo, destDragedInfo.destInfo);
        }, 2000);
      }
    }, 0);


  }

  updateDataSourceAfterDrop(currentListItem) {
    if (currentListItem) {
      const currentListItems = currentListItem.filter((item) => item.msId);

      let items = map(currentListItems, (item, i) => ({ msId: item.msId, posIndex: i }));
      let itemsClone = Object.assign({}, items);
      this.clearSortColumns();
      this._source = this.updateDataSourceByReorder(itemsClone, this._source);
    }
  }

  updateDataSourceByReorder(Items, sourceData) {
    let sourceDataClone = clone(sourceData);
    let reorderDataSource = [];
    each(sourceDataClone, (sourceItem) => {
      each(Items, (item) => {
        if (sourceItem.msId == item.msId) {
          reorderDataSource[item.posIndex] = sourceItem
          each(this._tempDragDataSource, (tmpDragItem) => {
            if (tmpDragItem.msId == item.msId) {
              reorderDataSource[item.posIndex] = tmpDragItem;
            }
          });
        }
      });
    });
    return reorderDataSource;
  }

  async reorderOrdinalNumbers(activeListId, range, destIndex, msid = null) {
    if (ListExplorerStore.isUserListOrFavoriteUserList()) {
      let stringmsids = null;
      if (msid == null) {
        let isDataItemIdRowid = find(this._sortCols, (sortItem) => sortItem.dataItemId == DataItemIdRowid);
        if (isDataItemIdRowid || this._sortCols.length == 0) {
          let msidList = this.getSourceData().map((c) => c.msId);
          stringmsids = msidList.join(";");
        }
      }
      else {
        stringmsids = msid;
      }
      let consoleId = 0; //States.consoleId
      let success = false;
      this._state.basicEsInfo.msidSortList = stringmsids != null ? stringmsids : "";
      let basicEsInfoCopy = clone(this._state.basicEsInfo);
      basicEsInfoCopy.cacheId = this._state.basicEsInfo.cacheId;
      basicEsInfoCopy.actualListId = this._state.basicEsInfo.actualListId;
      basicEsInfoCopy.userId = this._state.basicEsInfo.userId;
      let result = await ListApi.reorderOrdinalNumbers(activeListId, -1, consoleId, range, destIndex, basicEsInfoCopy);
      success = !result.responseHeader.error;
      return success;
    }
  }

  async toggleFrozenColumns(isSplit) {
    this._frozenColumnsSpliter = isSplit;
    map(this._state.columns, (column) => {
      if (column.isFrozen && column.dataItemId != -4 && column.dataItemId != -3 && column.dataItemId != -5) { // #, Flag, Symbol
        column.isCollapsed = this._frozenColumnsSpliter;
      }
    });
    const viewSettings = this.getSettingsObject();
    ListSettingHelper.updateFrozenColSplitter(viewSettings, isSplit);
    SettingsStore.saveSettings();
  }

  toggleScreenOnOff(isOnOrOff) {
    let NavListManagerSettings = ListStore.getNavListManagerSettings();
    let screenOnOff = isOnOrOff ? ListManager.ScreenOnOff : "Off";
    let tabType = ListStore.getTabType(NavListManagerSettings.SelectedTabKey);
    switch (tabType) {
      case ListManagerTabType.Oneil:
        ListSettingHelper.updateScreenOnOff(NavListManagerSettings.TabONeilSettings, screenOnOff);
        break;
      case ListManagerTabType.Ownership:
        ListSettingHelper.updateScreenOnOff(NavListManagerSettings.TabOwnershipSettings, screenOnOff);
        break;
      default:
        return;
    }
  }

  getScreenOnOffValue() {
    try {
      let screenOnOff = null;
      let NavListManagerSettings = ListStore.getNavListManagerSettings();
      let tabType = ListStore.getTabType(NavListManagerSettings.SelectedTabKey);
      switch (tabType) {
        case ListManagerTabType.Oneil:
          screenOnOff = NavListManagerSettings.TabONeilSettings.ScreenOnOff;
          break;
        case ListManagerTabType.Ownership:
          screenOnOff = NavListManagerSettings.TabOwnershipSettings.ScreenOnOff;
          break;
        default:
          break;
      }
      if (screenOnOff != ListManager.ScreenOnOff) return false;
      else return true;
    }
    catch (e) {
      console.error('error', e);
      return false;
    }
  }

  setColumnFilterData(data) {
    try {
      this._state.basic = data.catalog;
      this._state.cacheId = this._state.basic.cacheId;
      this._state.columnSetId = this._state.basic.columnSet.nodeId.low;
      this._state.isSharedColumnSet = this._state.basic.columnSet.isShared;
      this._state.columnSetName = this._state.isSharedColumnSet ? "Shared - " + this._state.basic.columnSet.name : this._state.basic.columnSet.name;
      this._columnFilters = data.catalog.columnFilters;
      ColumnCustomFilterControl.setColumnInfoFromStore();
      this._currentAction = ListExplorerConstants.ActionTypes.GET_SCREEN_OFF_LIST_CATALOG;
      this.emit(CHANGE_EVENT);
      TimeTrackingWindow.endListContextRenderTimeTracker();
    } 
    catch (error) {
      console.error(error);
      TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.ListContent, false, NavType.NavLists);
    }
  }

  getColumnInfo() {
    return this._columnInfo;
  }

  getcolumnFilters() {
    return this._columnFilters;
  }

  async processColumns() {
    const settings = this.getSettingsObject();
    const frozenColumnsSpliter = settings && settings.frozenColumnsSpliter;
    map(this._state.columns, (item, i) => {
      if (item.isFrozen && item.dataItemId != -4 && item.dataItemId != -3 && item.dataItemId != -5) { // #, Flag, Symbol
        item.isCollapsed = frozenColumnsSpliter ? frozenColumnsSpliter : false;
      }
      else {
        item.isCollapsed = false;
      }
      item.isSortingEnable = true;
      item.isContextMenuEnable = true;
      if (item.dataItemId.toString() === DataItemIdFlag.toString()) {
        item.displayName = '';
        item.className = 'icn-flag-head';
      }
      else item.className = '';
      //Fixes 75301 - Flag Sorting
      if ((this.isOneilDb() || ListExplorerStore.isMorgueList() || ListExplorerStore.isSnapshotList() || ListExplorerStore.isModelList()) && item.dataItemId.toString() === DataItemIdFlag.toString()) {
        item.isSortingEnable = false;
        item.isContextMenuEnable = false;
      }

      item.id = i;
      item.textWidth = 0;
    });
    //const t = await this.toggleFrozenColumns(this._frozenColumnsSpliter);
    return;
  }

  isOneilDb() {
    return this._state.listId == -1;
  }

  //---- Remove rows Operation for OneilListView ----
  removeGridRows(data) {
    let selectedItem = ListStore.getSelectedListItem();
    let removedRows = {
      _alertMessage: null,
      _removedRow: {}
    }
    this.disableDelete = true;
    const sIndexs = GridStore.getSelectedIndexes();
    try {
      return ListApi.removeItemsFromUserList(data.listId, data.cacheId, data.msIDS, data.snapshotDates).then((result) => {
        if (!result.responseHeader.error) {
          this.disableDelete = false;
          GridStore.resetSelectedIndexes();
          let msIds = data.msIDS.split(';');
          let _alertMessage = null;
          //Updating update date field in List property section in List explorer
          ListExplorerStore.findNodeByIdAndUpdateDate(data.listId, result.responseHeader.asOfDate);

          if (msIds.length > 1) {
            _alertMessage = LocalizationStore.getTranslatedData('lp_mes_1', '{0} items were removed from {1}.').replace('{0}', msIds.length).replace('{1}', LocalizationStore.getTranslatedData("TreeExplorer_LST_" + selectedItem.SelectedListName.replace(/ /ig, ""), selectedItem.SelectedListName));
          }
          else {
            _alertMessage = LocalizationStore.getTranslatedData('lp_mes_20', '{0} was removed from {1}.').replace('{0}', this.getSymbolFromMsIds(msIds)).replace('{1}', LocalizationStore.getTranslatedData("TreeExplorer_LST_" + selectedItem.SelectedListName.replace(/ /ig, ""), selectedItem.SelectedListName));
          }

          let _removedRow = removedRows._removedRow;
          _removedRow.selectedIndexes = sIndexs;
          _removedRow.listId = data.listId;
          _removedRow.cacheId = data.cacheId;
          _removedRow.consoleId = 0;
          _removedRow.symbols = this.getSymbolFromMsIds(msIds);
          _removedRow.msids = data.msIDS;
          _removedRow.snapshotdates = data.snapshotDates;
          _removedRow.insertSeqNum = msIds.length;
          _removedRow.includeDetails = false;
          _removedRow.undoAdd = true;
          _removedRow.selectedIndexes = clone(GridStore.getSelectedIndexes());

          let obj = {};
          obj.msIds = data.msIDS.toString();
          obj.symbols = _removedRow.symbols.toString();
          obj.snapshotDts = _removedRow.snapshotdates.toString();

          const InfoProto = BaseServiceApi.rayData["ListInstrumentInfoData"];
          let listInstrumentInfoData = new InfoProto();
          listInstrumentInfoData.symbols = obj.symbols;
          listInstrumentInfoData.msIds = obj.msIds;
          listInstrumentInfoData.snapshotDts = obj.snapshotDts;

          const ReqProto = BaseServiceApi.rayData["ListInstrumentsRequestData"];
          let listInstrumentsRequestData = new ReqProto();
          listInstrumentsRequestData.instrumentsInfo = listInstrumentInfoData;

          _removedRow.bulkInsertData = listInstrumentsRequestData.encode();
          removedRows._alertMessage = _alertMessage;
          this.setVisibleRows(data.msIDS, false);
          return Promise.all([this.filterCall(), this.populateSummaryView()]).then(() => removedRows);
        }
        else {
          //Exception in Server
          this.disableDelete = false;
          return result.responseHeader;
        }
      });
    }
    catch (e) {
      console.log(e);
    }
  }

  processDeleteAllAlerts(n) {
    return Promise.all([this.filterCall(), this.populateSummaryView()]).then(() => {
      this._state.alertMessage = 'All alerts deleted for ' + n + (n == 1 ? ' company' : ' companies');
      this.emit(CHANGE_EVENT);
    });
  }
  //---- Undo Operation for OneilListView Remove Grid Rows  ----

  removeGridRowsUndo(removedRow) {
    return ListApi.addInstrumentsRequest(removedRow.listId, removedRow.cacheId, removedRow.consoleId, removedRow.symbols, removedRow.msids, removedRow.snapshotdates, removedRow.insertSeqNum, removedRow.includeDetails, removedRow.bulkInsertData, removedRow.undoAdd).then((result) => {
      if (!result.responseHeader.error) {
        let msIds = [];
        //Updating update date field in List property section in List explorer
        ListExplorerStore.findNodeByIdAndUpdateDate(removedRow.listId, result.responseHeader.asOfDate);

        map(result.responseData.symbolListData.statusData, (row) => {
          msIds.push(StringUtil.convertFromLongValueToInt(row.msid));
        });
        if(this._state.basic.count === 0){
          this._state.allMsidList = msIds;
        }
        else{
          map(msIds, (msid) => {
            this._state.allMsidList.push(msid);
          });
        }
        this.setVisibleRows(msIds.join(';'), true);
        GridStore.setSelectedIndexes(removedRow.selectedIndexes);
        return Promise.all([this.filterCall(true), this.populateSummaryView()]).then(() => true);
      }
      else {
        return;
      }
    });
  }


  //---- addSymbol Operation for OneilListView ----

  async addSymbol(symbol, listId, cacheId, listCount, listName, snapShot = "") {
    let includeDetails = true;
    let consoleId = 0;
    let resultObj = {
      navMessage: null,
      error: false,
      result: null
    };
    try {
      return ListApi.addInstrumentsRequest(listId, cacheId, consoleId, symbol, "", snapShot, listCount, includeDetails, null, false).then((result) => {
        if (!result.responseHeader.error) {
          let addSymbolListData = result.responseData.symbolListData;
          // let isListEditable = result.responseData.isListEditable;
          let addSymbolsObj = this.processAddServiceResult(addSymbolListData, listName);
          resultObj.navMessage = addSymbolsObj;

          //Updating update date field in List property section in List explorer
          ListExplorerStore.findNodeByIdAndUpdateDate(listId, result.responseHeader.asOfDate);

          if (addSymbolsObj.addSymbols && addSymbolsObj.isUndoable) {
            if (addSymbolsObj.isUndoable) {
              let addSymbols = addSymbolsObj.addSymbols;
              if (!StringUtil.isEmpty(addSymbols) && addSymbols.length > 0) {
                let undoMsIds = map(addSymbols, (row) => row.msId);
                let stringMsids = "";
                if (undoMsIds != null && undoMsIds.length > 0) {
                  if (undoMsIds[undoMsIds.length - 1] == 0)
                    undoMsIds.removeAt(undoMsIds.length - 1);
                  stringMsids = undoMsIds.join(";");
                }
                let undoSnapDates = map(addSymbols, (row) => row.snapshotDate);
                let snpDateStringBuilder = "";
                if (undoSnapDates != null) {
                  for (let index = 0; index < undoSnapDates.length; index++) {
                    if (undoSnapDates[index] != null) {
                      snpDateStringBuilder = snpDateStringBuilder.concat(this.formatSnapshotDt(undoSnapDates[index]));
                    }
                    let lastIndex = index + 1;
                    if (lastIndex == undoSnapDates.length)
                      continue;

                    snpDateStringBuilder = snpDateStringBuilder.concat(";");
                  }
                }
                this._state.undoOperationForPaste.listId = listId;
                this._state.undoOperationForPaste.cacheId = cacheId;
                this._state.undoOperationForPaste.msids = stringMsids.toString();
                this._state.undoOperationForPaste.snapshotdates = snpDateStringBuilder;
                return this.updateDataSourceAfterAdd(result).then((result) => {
                  if (result) {
                    resultObj.loadDataIfNORowReturn = false;
                    return Promise.all([this.filterCall(), this.populateSummaryView()]).then(() => resultObj);
                  }
                  else {
                    resultObj.loadDataIfNORowReturn = true;
                    return resultObj;
                  }
                }).catch(() => {
                  resultObj.loadDataIfNORowReturn = true;
                  return resultObj;
                })

              }
            }
          }

          resultObj.error = false;
          return resultObj;
        }
        else {
          //Exception in Server
          return result.responseHeader;
        }

      });
    }
    catch (e) {

    }
  }


  //---- add symbols to list ----
  addSymbolsToList(data) {
    try {
      let outObj = {};
      let NavListManagerSettings = ListStore.getNavListManagerSettings();
      let tabType = ListStore.getTabType(NavListManagerSettings.SelectedTabKey);

      switch (tabType) {
        case ListManagerTabType.Oneil:
          {
            if (ListExplorerStore.sourceType == 'Grid') {
              let dragInfo = GridStore.getDragInfo();
              if (!StringUtil.isEmpty(dragInfo) && dragInfo.itemInfo.length > 0) {
                outObj = {
                  msidAsString: dragInfo.itemInfo.map((e) => { if (!StringUtil.isEmpty(e.msId)) return e.msId; }).join(';'),
                  snapshotAsString: dragInfo.itemInfo.map((e) => { if (!StringUtil.isEmpty(e.snapshotDt)) return e.snapshotDt; }).join(';'),
                  symbolsAsString: dragInfo.itemInfo.map((e) => { if (!StringUtil.isEmpty(e.symbol)) return e.symbol; }).join(';'),
                  sequenceNumber: dragInfo.sequenceNumber
                };
              }
              else {
                outObj = {
                  msidAsString: '',
                  snapshotAsString: '',
                  symbolsAsString: '',
                  sequenceNumber: 0
                };
              }
            }
            else {
              outObj = {
                msidAsString: !StringUtil.isEmpty(data.msids) ? data.msids.join(';') : '',
                snapshotAsString: '',
                symbolsAsString: '',
                sequenceNumber: 0
              };
            }

            let outObjBlkData = { bulkInsertData: null };
            GridStore.getAddInstrumentsBulkRequest(outObj.msidAsString, outObj.snapshotAsString, outObj.symbolsAsString, outObjBlkData);
            let consoleId = 0;
            let includeDetails = true;
            ListApi.addInstrumentsRequest(data.targetListId, 0, consoleId, outObj.symbolsAsString, outObj.msidAsString, outObj.snapshotAsString, outObj.sequenceNumber, includeDetails, outObjBlkData.bulkInsertData, false, "-1,-1,-1", 3).then((result) => {
              if (!result.responseHeader.error) {
                this._state.removeItemsFromUserList.msids = null;
                this._state.removeItemsFromUserList.targetListId = null;
                this._state.removeItemsFromUserList.snapshotdates = null;
                this._state.removeItemsFromUserList.currentNodeId = null;

                //Updating update date field in List property section in List explorer
                ListExplorerStore.findNodeByIdAndUpdateDate(data.targetListId, result.responseHeader.asOfDate);
                ListExplorerStore.getListAlertStatus({ listId: data.targetListId });
                let addSymbolListData = result.responseData.symbolListData;
                let addSymbolsobj={};
                if(addSymbolListData!=null&&addSymbolListData.statusData!=null&&addSymbolListData.statusData.length==0){
                  addSymbolsobj.displayMessage=LocalizationStore.getTranslatedData("lp_mes_13", "0 items has been added to {0}",data.targetNodeName);
                  addSymbolsobj.showMessage=true
                }else{
                  addSymbolsobj = this.processAddServiceResult(addSymbolListData, data.targetNodeName);
                }
                

                if (addSymbolsobj && addSymbolsobj.isUndoable) {
                  let addSymbols = addSymbolsobj.addSymbols;
                  this._state.removeItemsFromUserList.targetListId = data.targetListId;
                  this._state.removeItemsFromUserList.currentNodeId = data.currentNodeId;
                  this._state.removeItemsFromUserList.cacheId = 0;
                  if (!StringUtil.isEmpty(addSymbols) && addSymbols.length > 0) {
                    this._state.removeItemsFromUserList.msids = addSymbols.map((e) => { if (!StringUtil.isEmpty(e.msId)) return e.msId; }).join(';');
                    this._state.removeItemsFromUserList.snapshotdates = this.getSnapshotDateFromMsIds(this._state.removeItemsFromUserList.msids.split(';'));
                  }
                }

                if (addSymbolsobj.showMessage) {
                  if (!StringUtil.isEmpty(addSymbolsobj.displayMessage))
                    this._state.alertMessage = addSymbolsobj.displayMessage;
                  else if (!StringUtil.isEmpty(addSymbolsobj.rejectionMessage))
                    this._state.alertMessage = addSymbolsobj.rejectionMessage;
                }
                this.emit(CHANGE_EVENT);
                return;
              }
              else {
                return;
              }
            });
          }
          break;
        case ListManagerTabType.Ownership:
          //Call Ownership API here
          break;
        default:
          break;
      }
    } catch (error) {
      console.log('Error while adding symbols to List.')
    }
  }


  //addSymbolList for paste Operation
  addSymbolsToListForPaste(listId, listName, cacheIdForPaste, outObj, outObjBlkData, symbols, msIds, snapShots, sequenceNumber, showMessage = true, doRefreshList = true, includeDetails = false) {
    let consoleId = 0;
    let addType = 1;
    // let BrowserSelectedFilters = clone(this._state.basicEsInfo.browserFilter);// PANWEB-7070 fix
    let resultObj = {
      refreshGrid: false,
      navMessage: null,
      error: false
    };
    const isLocalSymbolEntitlement = UserInfoUtil.IsUserEntitled(this.EntitlementType.RAY_LOCALSYMBOLS_Entitlement)
    if (isLocalSymbolEntitlement) {
      addType = 3;
    }
    sourceSymbolsList = outObj.symbolsAsString.split(';');
    try {
      return ListApi.addInstrumentsRequest(listId, (this._state.listId == listId) ? cacheIdForPaste : 0, consoleId, outObj.symbolsAsString, outObj.msidAsString, outObj.snapshotAsString, sequenceNumber, includeDetails, outObjBlkData.bulkInsertData, false).then((result) => {
        if (!result.responseHeader.error) {
          let addSymbolListData = result.responseData.symbolListData;
          if (sourceSymbolsList && addSymbolListData.statusData) {
            addSymbolListData.statusData.sort((a, b) => {
              return sourceSymbolsList.indexOf(a.inputString) - sourceSymbolsList.indexOf(b.inputString);
            });
          }
          let addSymbolsObj = this.processAddServiceResult(addSymbolListData, listName);
          resultObj.navMessage = addSymbolsObj;

          //Updating update date field in List property section in List explorer
          ListExplorerStore.findNodeByIdAndUpdateDate(listId, result.responseHeader.asOfDate);

          if (this._state.listId == listId && doRefreshList && addSymbolsObj.addSymbols) {
            resultObj.refreshGrid = true;
          }
          if (addSymbolsObj.addSymbols && addSymbolsObj.isUndoable) {
            if (addSymbolsObj.isUndoable) {
              let addSymbols = addSymbolsObj.addSymbols;
              if (!StringUtil.isEmpty(addSymbols) && addSymbols.length > 0) {
                let undoMsIds = map(addSymbols, (row) => row.msId);
                let stringMsids = "";
                if (undoMsIds != null && undoMsIds.length > 0) {
                  if (undoMsIds[undoMsIds.length - 1] == 0)
                    undoMsIds.removeAt(undoMsIds.length - 1);
                  stringMsids = undoMsIds.join(";");
                }
                let undoSnapDates = map(addSymbols, (row) => row.snapshotDate);
                let snpDateStringBuilder = "";
                if (undoSnapDates != null) {
                  for (let index = 0; index < undoSnapDates.length; index++) {
                    if (undoSnapDates[index] != null) {
                      snpDateStringBuilder = snpDateStringBuilder.concat(this.formatSnapshotDt(undoSnapDates[index]));
                    }
                    let lastIndex = index + 1;
                    if (lastIndex == undoSnapDates.length)
                      continue;

                    snpDateStringBuilder = snpDateStringBuilder.concat(";");
                  }
                }
                this._state.undoOperationForPaste.listId = listId;
                this._state.undoOperationForPaste.cacheId = cacheIdForPaste;
                this._state.undoOperationForPaste.msids = stringMsids.toString();
                this._state.undoOperationForPaste.snapshotdates = snpDateStringBuilder;
              }
            }
          }
          resultObj.error = false;
        }
        else {
          //Exception in Server
          resultObj.error = true;
        }
        return resultObj;
      });
    }
    catch (e) {
      console.log(e);
    }
  }

  // Undo operation for paste
  pasteGridRowsUndo() {
    if (this._state.undoOperationForPaste.msids) {
      try {
        return ListApi.removeItemsFromUserList(this._state.undoOperationForPaste.listId, this._state.undoOperationForPaste.cacheId, this._state.undoOperationForPaste.msids, this._state.undoOperationForPaste.snapshotdates).then((result) => {
          if (!result.responseHeader.error) {
            let undoPasteOperation = true;
            //Updating update date field in List property section in List explorer
            ListExplorerStore.findNodeByIdAndUpdateDate(this._state.undoOperationForPaste.listId, result.responseHeader.asOfDate);
            ListExplorerStore.getListAlertStatus({ listId: this._state.undoOperationForPaste.listId });
            return undoPasteOperation;
          }
          else {
            return;
          }
        });
      }
      catch (e) {

      }
    }

  }

  // Process data after addSymbolsToList/addSymbol/CopyList/MoveList
  processAddServiceResult(addSymbolListData, listName) {
    this._exceptionSymbols = null;

    let successCount = 0, duplicateCount = 0, invalidCount = 0, invalidSnapShotDateCount = 0, notallowedCount = 0;
    let exceptionItems = [], successMsg = "", rejectMessage = "", addSymbols = [], refreshGrid = false;
    let addSymbolStatusType = BaseServiceApi.rayData["AddSymbolStatusType"];
    if (addSymbolListData == null) return;

    let listSymbolData = addSymbolListData.statusData;

    let totalCount = listSymbolData.length;

    if (listSymbolData == null)
      return;
    let duplicateDataSource = {};
    const duplicateSymbolStatus = listSymbolData.filter((item) => item.status === addSymbolStatusType.Duplicate);
    const duplicateSymbolCount = duplicateSymbolStatus.length;

    each(listSymbolData, (addSymbolStatusData) => {
      switch (addSymbolStatusData.status) {
        case addSymbolStatusType.Duplicate:
          // exceptionItems.push(addSymbolStatusData.symbol + ((addSymbolStatusData.snapshotDt) ? "" + addSymbolStatusData.snapshotDt.toString() : ""));
          const symbol = addSymbolStatusData.symbol;
          if(!duplicateDataSource[symbol]){
            duplicateDataSource[symbol] = addSymbolStatusData;
            duplicateCount++;
          }
          break;
        case addSymbolStatusType.Successful:
          if(!addSymbols.some((item) => item.msId === addSymbolStatusData.msid.toString())) {
            addSymbols.push({ msId: addSymbolStatusData.msid.toString(), symbol: addSymbolStatusData.symbol, snapshotDate: ((addSymbolStatusData.snapshotDt) ? "" + addSymbolStatusData.snapshotDt.toString() : null) });
            successCount++;
          }
          break;
        case addSymbolStatusType.Invalid:
          exceptionItems.push(addSymbolStatusData.symbol + ((addSymbolStatusData.snapshotDt) ? "" + addSymbolStatusData.snapshotDt.toString() : ""));
          invalidCount++;
          break;
        case addSymbolStatusType.InvalidSnapshotDate:
          exceptionItems.push(addSymbolStatusData.symbol + ((addSymbolStatusData.snapshotDt) ? "" + addSymbolStatusData.snapshotDt.toString() : ""));
          invalidSnapShotDateCount++;
          break;

        case addSymbolStatusType.NotAllowed:
          // exceptionItems.push(addSymbolStatusData.symbol + ((addSymbolStatusData.snapshotDt) ? "" + addSymbolStatusData.snapshotDt.toString() : ""));
          exceptionItems.push(addSymbolStatusData.inputString);
          notallowedCount++;
          break;
        default:
          break;
      }
    });
    duplicateDataSource = null;

    if (successCount > 0) refreshGrid = true;

    //When one or all the symbols are duplicate
    if ((totalCount == 1 && duplicateCount == 1) || (totalCount == duplicateSymbolCount)) {
      if (duplicateCount > 1) {
        rejectMessage = LocalizationStore.getTranslatedData('lp_mes_11', 'Symbols already exist in list. Not added.');
      } else {
        rejectMessage = LocalizationStore.getTranslatedData('lp_mes_12', 'Symbol already exists in list. Not added.');
      }
    }

    if(successCount == 0 && duplicateCount > 0 && invalidCount > 0) {
      rejectMessage = LocalizationStore.getTranslatedData('lp_mes_24', `{0} ${duplicateCount > 1 ? 'items' : 'item'} already ${duplicateCount > 1 ? 'exist' : 'exists'} in list.`, duplicateCount) + LocalizationStore.getTranslatedData("LM_AddItemsToList_2", `&nbsp;<a id=\"notAddedSymbol\">{1} ${invalidCount > 1 ? 'items' : 'item'}</a>&nbsp;not added.`).replace('{1}', invalidCount);
    }

    //Partial success
    if (successCount > 0 && (duplicateCount > 0 || invalidCount > 0 || notallowedCount > 0)) {
      rejectMessage = '';//LM_AddItemsToList_1
      successMsg = LocalizationStore.getTranslatedData("LM_AddItemsToList_1", `{0} ${successCount > 1 ? 'items' : 'item'} added.`, successCount);
      if (duplicateCount > 0) {
        successMsg += LocalizationStore.getTranslatedData('lp_mes_24', `{0} ${duplicateCount > 1 ? 'items' : 'item'} already ${duplicateCount > 1 ? 'exist' : 'exists'} in list.`, duplicateCount)
      }
      if (invalidCount > 0 || notallowedCount > 0)
      successMsg += LocalizationStore.getTranslatedData("LM_AddItemsToList_2", `&nbsp;<a id=\"notAddedSymbol\">{1} ${(invalidCount + notallowedCount) > 1 ? 'items' : 'item'}</a>&nbsp;not added.`).replace('{1}', (invalidCount + notallowedCount));
    }

    //Success
    if (successCount > 0 && duplicateCount == 0 && invalidCount == 0 && notallowedCount == 0) {
      if (successCount == 1) {
        successMsg = `${addSymbols[0].symbol.trim() + ((addSymbols[0].snapshotDt) ? " (" + addSymbols[0].snapshotDt + ")" : "")} ${LocalizationStore.getTranslatedData('ch_wat', 'was added to')} ${LocalizationStore.getTranslatedData("TreeExplorer_LST_" + listName.replace(/ /ig, ""), listName)}`;
      }
      else
        successMsg = LocalizationStore.getTranslatedData('lp_mes_8', '{0} items have been added to {1}').replace('{0}', successCount).replace('{1}', LocalizationStore.getTranslatedData("TreeExplorer_LST_" + listName.replace(/ /ig, ""), listName));
    }

    //Invalid symbols
    if (successCount == 0 && (invalidCount == totalCount || notallowedCount == totalCount)) {
      if (invalidCount > 1) {
        rejectMessage = LocalizationStore.getTranslatedData('LM_AddItemsToList_1', `{0} item added.`, successCount) + LocalizationStore.getTranslatedData('LM_AddItemsToList_2', `&nbsp;<a id=\"notAddedSymbol\">{1} ${invalidCount > 1 ? 'items' : 'item'}</a>&nbsp;not added.`).replace('{1}', invalidCount);
      } else {
        rejectMessage = LocalizationStore.getTranslatedData('lp_mes_7', 'Symbol is invalid. Not added.');
      }
    }

    let totalInvalidCount = invalidCount + invalidSnapShotDateCount + notallowedCount;
    //All or some invalid date
    if (successCount == 0 && invalidCount !== totalCount && totalInvalidCount > 0 && duplicateCount == 0) {
      rejectMessage = LocalizationStore.getTranslatedData('LM_AddItemsToList_1', `{0} item added.`, successCount) + LocalizationStore.getTranslatedData("LM_AddItemsToList_2", `&nbsp;<a id=\"notAddedSymbol\">{1} ${totalInvalidCount > 1 ? 'items' : 'item'}</a>&nbsp;not added.`).replace('{1}', totalInvalidCount);
    }
    else if (successCount == 0 && invalidCount !== totalCount && totalInvalidCount > 0 && duplicateCount > 0) {
      rejectMessage = LocalizationStore.getTranslatedData('lp_mes_24', `{0} ${duplicateCount > 1 ? 'items' : 'item'} already ${duplicateCount > 1 ? 'exist' : 'exists'} in list.`, duplicateCount) + LocalizationStore.getTranslatedData("LM_AddItemsToList_2", `&nbsp;<a id=\"notAddedSymbol\">{1} ${totalInvalidCount > 1 ? 'items' : 'item'}</a>&nbsp;not added.`).replace('{1}', totalInvalidCount);
    }

    //All are "not allowed"
    // if (successCount == 0 && notallowedCount > 0 && notallowedCount == totalCount) {
    //   if (notallowedCount == 1) {
    //     rejectMessage = LocalizationStore.getTranslatedData('lp_mes_3', 'Symbol is not allowed. Not added.');
    //   } else {
    //     rejectMessage = LocalizationStore.getTranslatedData('lp_mes_2', 'Symbols are not allowed. Not added.');
    //   }
    // }


    let navMessage = {
      rejectionMessage: rejectMessage,
      displayMessage: successMsg,
      isException: (invalidCount > 0 || notallowedCount > 0 || invalidSnapShotDateCount > 0),
      showMessage: true,
      isUndoable: successCount > 0,
      addSymbols: addSymbols,
      exceptionItems: exceptionItems,
      refreshGrid: refreshGrid
    };

    if (navMessage.isException) {
      this._exceptionSymbols = exceptionItems;
    }

    return navMessage;
  }


  async setDistributionDefaultColumn(colId) {
    this._state.distributiontChartSelectedColumnId = colId;
    if (!this._columnInfo) return;
    const column = this._columnInfo.find((c) => c.dataItemId == this._state.distributiontChartSelectedColumnId);
    if (!column) {
      let priceCol = this._columnInfo.find( c => c.dataItemId == defaultDistColumnId )
      if(!priceCol){
        let nextAvailableColumn = this._columnInfo.find((c) => (c.dataItemId != this._state.distributiontChartSelectedColumnId && !c.isFrozen && c.positionType == ListColumnPositionType.SCROLLABLE_ListColumnPosition && (c.dataType == ListColumnDataType.DOUBLE_ListColumnData || c.dataType == ListColumnDataType.INT_ListColumnData)));

        if (!nextAvailableColumn)
          return;
        this._state.distributiontChartSelectedColumnId = nextAvailableColumn.dataItemId;
      }else{
        this._state.distributiontChartSelectedColumnId = defaultDistColumnId;
      }
    }
  }

  async populateSummaryView(refreshlist = false, isSetPageListEsBasic = false) {
    const colId = this.getSummaryStatDistColumnIdFromSettings();
    return Promise.all([this.getListSummaryData(this._state.actualListId, refreshlist, isSetPageListEsBasic), this.getListColumnDistribution(this._state.actualListId, refreshlist, colId, !isSetPageListEsBasic)])
  }

  async loadHistStat(colId = defaultDistColumnId) {
    const column = this._columnInfo.find((c) => c.dataItemId == colId);
    if (column && (!column.isFrozen && column.positionType == ListColumnPositionType.SCROLLABLE_ListColumnPosition && (column.dataType == ListColumnDataType.DOUBLE_ListColumnData || column.dataType == ListColumnDataType.INT_ListColumnData)) && colId != this._state.distributiontChartSelectedColumnId) {
      /*eslint-disable */
      let t = await this.getListColumnDistribution(this._state.actualListId, false, colId, true);
      /*eslint-enable */
      setTimeout(() => {
        this.updateSummaryStateSettings()
      }, 0);
      return true;
    }
    else if (colId == this._state.distributiontChartSelectedColumnId) {
      return false;
    }
    else {
      this._state.distributiontChartSelectedColumnId = colId;
      this._state.distribData = null;
      SummaryStatStore.setDistData();
      return false;
    }

  }

  async getListColumnDistribution(activeListId, refreshlist = false, colId = defaultDistColumnId, loadHistStat = false) {
    try {
      if (!this._columnInfo || !this._state.basicEsInfo)
        return;
      /*eslint-disable */
      const d = await this.setDistributionDefaultColumn(colId);
      /*eslint-enable */
      let basicinfo;
      basicinfo = _.clone(this._state.basicEsInfo);

      if (refreshlist) {
        basicinfo.cacheId = -1;
      }

      if (this._state) {
        TimeTrackingWindow.beginSummaryStats_HistogramApiTimeTracker();
        if (basicinfo.listId != activeListId)
          basicinfo.setListId(activeListId) //fix bug PANWEB-2198
        const distributionResult = await ListApi.getListColumnDistribution(basicinfo, this._state.distributiontChartSelectedColumnId);
        TimeTrackingWindow.endSummaryStats_HistogramApiTimeTracker();
        if (!distributionResult.responseHeader.error) {
          this._state.distribData = distributionResult.distributionResultData;
        }
        else {
          this._state.distribData = null;
        }
        TimeTrackingWindow.beginSummaryStats_HistogramRenderTimeTracker();
        try {
          SummaryStatStore.setDistData();
          TimeTrackingWindow.endSummaryStats_HistogramRenderTimeTracker(loadHistStat);
        } 
        catch (error) {
          console.error(error);
          TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.SummaryStats_Histogram, false, NavType.NavLists);
        }
      }
    } catch (error) {
      TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.SummaryStats_Histogram, true, NavType.NavLists);
      console.error(error);
    }
  }

  async getListSummaryData(activeListId, refreshlist = false, isSetPageListEsBasic = false) {
    try {
      let CurrentActiveListTreeItem = ListExplorerStore.activeNode;
      if (!CurrentActiveListTreeItem) return;
      let basicinfo;
      basicinfo = _.clone(this._state.basicEsInfo);
      if (refreshlist) {
        basicinfo.cacheId = -1;
      }
      if (!basicinfo) return;
      if(ListExplorerStore.isFavoriteList()){
        basicinfo.listId = activeListId;
      }
      TimeTrackingWindow.beginSummaryStats_DataApiTimeTracker();
      this._state.summaryListData = await ListApi.getSummaryStats(basicinfo, activeListId);
      TimeTrackingWindow.endSummaryStats_DataApiTimeTracker();
      //Note: benchmarkState impl pending (Only panweb-943)

      // if (CurrentActiveListTreeItem && CurrentActiveListTreeItem.listCategory ==
      //   ListCategoryType.PORTFOLIOLIST_ListCategory && UserInfoUtil.IsUserEntitled(this.EntitlementType.PORTFOLIO_BENCHMARK_Entitlement)) {
      //   weightType = this.PortfolioListWeightType.WeightType_Portfolio;
      //   this._state.benchmarkStatsData = await ListApi.getListBenchmarkStats(activeListId,
      //     this._state.cacheId,
      //     weightType);
      // }
      /* eslint-disable */
      TimeTrackingWindow.beginSummaryStats_DataRenderTimeTracker();
      try {
        const t = SummaryStatStore.setDataSourceItems();
        TimeTrackingWindow.endSummaryStats_DataRenderTimeTracker(isSetPageListEsBasic);
      } 
      catch (error) {
        console.error(error);
        TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.SummaryStats_Data, false, NavType.NavLists);
      }
      /* eslint-enable */
      return true;
    }
    catch (exc) {
      TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.SummaryStats_Data, true, NavType.NavLists);
      console.log('exception in summary stat', exc);
      return false;
    }

  }

  isBenchmarkTabVisible() {
    let CurrentActiveListTreeItem = ListExplorerStore.activeNode;
    return CurrentActiveListTreeItem && CurrentActiveListTreeItem.listCategory ==
      ListCategoryType.PORTFOLIOLIST_ListCategory && UserInfoUtil.IsUserEntitled(this.EntitlementType.PORTFOLIO_BENCHMARK_Entitlement)
  }

  getSettingsObject() {
    const NavListManagerSettings = ListStore.getNavListManagerSettings();
    const tabType = ListStore.getTabType(NavListManagerSettings.SelectedTabKey);
    switch (tabType) {
      case ListManagerTabType.Oneil:
        return NavListManagerSettings.TabONeilSettings;
      case ListManagerTabType.Ownership:
        return NavListManagerSettings.TabOwnershipSettings
      case ListManagerTabType.External:
        return NavListManagerSettings.TabExternalSettings
      default:
        break;
    }
  }


  updateSummaryStateSettings() {
    const viewSettings = this.getSettingsObject();
    setTimeout(() => {
      const state = {
        isSummaryStatWindowOpen: SummaryStatStore.state.summaryStatIsOpen,
        summaryStatWindowHeight: SummaryStatStore.state.summaryStatHeight,
        summaryStatDistColumnId: this._state.distributiontChartSelectedColumnId
      };
      ListSettingHelper.updateSummaryStatData(viewSettings, state);
      SettingsStore.saveSettings();
    }, 0)
  }


  getSaveScreenedResultsVisibilty() {
    let isSaveScreenedResultsVisible = false;
    let screenSummaryDetails = [];
    screenSummaryDetails = ColumnCustomFilterControl.getScreenSummary();
    let screenOnOff = this.getScreenOnOffValue();
    isSaveScreenedResultsVisible = (screenSummaryDetails.length != 0 && screenOnOff);
    const listCategoryType = ListExplorerStore.activeNode && ListExplorerStore.activeNode.hasOwnProperty("categoryType") ? ListExplorerStore.activeNode.categoryType : null;
    const sourceListCategoryType = ListExplorerStore.activeNode && ListExplorerStore.activeNode.hasOwnProperty("sourceCategoryType") ? ListExplorerStore.activeNode.sourceCategoryType : null;
    const isDisableSaveScreenResults = listCategoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory || sourceListCategoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory;

    return (!isDisableSaveScreenResults && (isSaveScreenedResultsVisible || !StringUtil.isEmpty(BrowserFilterStore.getFilterBadge()) || !StringUtil.isEmpty(this._state.searchKeyword)))
  }

  getBasicEsInfo() {
    return this._state.basicEsInfo;
  }

  /*
  //For Export Data
  getSourceDataForExport() {
    let rows = _.reject(this._sourceForExport, (row) => {
      if (!row.isRowVisible) return true;
    });
    return rows;
  }
  sortColumnsOnOrderNumForExport(obj) {
    let colPosition = 0;

    if (!obj) obj = this._state.exportDetails;
    _.map(obj, (recordDatas) => {
      _.map(recordDatas, (recordData, key) => {
        if (key == 'recordDatas') {
          let tempRecordDatas = [];
          _.map(recordData, (item) => {
            if (item && item.dataItemId) {
              colPosition = this.findColumnOrderNum(item.dataItemId);
              tempRecordDatas[colPosition] = item;
            }
          });
          recordDatas[key] = tempRecordDatas;
        }
      });
    });
    return true;
  }

  setPageLisForExport(result) {
    this._state.exportBasic =  _.clone(this._state.basic);
    if (result !== undefined && !result.responseHeader.error) { // Details.pb call
      this._state.exportDetails = result.rows.rows;

      //Removed Invisible Columns from this._details object
      _.map(_.state.exportDetails, (recordDatas) => {
       // _.map(recordDatas.recordDatas, (recordData) => {
          recordDatas.recordDatas = _.omit(recordDatas.recordDatas, (records, k) =>
            records && _.contains(this._removedColumns, records.dataItemId.toString())
          );
       // });
        });

      //Reset all column order number
      //this.resetOrderNumAllColumns();
      // set column order positions
      this.sortColumnsOnOrderNumForExport();
      this._tempSourceForExport = [];

      //Load frozen columns data into temp source array
      for (let i = 0; i < this._state.basic.recordInfos.length; i++) {
        this._state.basic.recordInfos[i].snapshotDt = this.formatSnapshotDt(this._state.basic.recordInfos[i].snapshotDt);
        let obj = this.fillFrozenColumns(i);
        this._tempSourceForExport.push(obj);
      }

      // process temp source array and insert into source array
      this.setSourceDataForExport(true);

      // process details call records and update source array
      this.setSourceDataForExport();
    }
  }

  setSourceDataForExport(tempSourceData = false) {
    let obj;
    if (!tempSourceData) {
      this._sourceForExport = _.clone(this._tempSourceForExport);  // when fetch more records on scroll happens, reset prepared source array with initial temp source array.
      obj = this._state.exportDetails;
    }
    else {
      obj = this._tempSourceForExport;
    }
    _.map(obj, (item) => {

      // let tempItem = [];
      // _.each(item, (recordDatas, record) => {
      //   if(record == 'recordDatas'){
      //     _.each(recordDatas, (it, key) => {
      //       if(it) {
      //         tempItem[key] = it;
      //       }
      //       else {
      //         tempItem[key] = null;
      //       }
      //     })
      //    }
      // });
      // item['recordDatas'] = tempItem;

      let msId = this._state.exportBasic.recordInfos[item.rowId - 1].msId;
      item.msId = msId;

      let snapshotDt = this._state.exportBasic.recordInfos[item.rowId - 1].snapshotDt;
      item.snapshotDt = snapshotDt;

      item.isRowVisible = true;

      this._sourceForExport[item.rowId - 1] = item;
      _.map(item, (recordDatas, record) => {
        if (record == 'recordDatas') {
          _.map(recordDatas, (recordData, key) => {
            let rowId = this._state.basic.recordInfos[item.rowId - 1].rowId;
            let formatter = '';
            let styleType = '';
            if (recordData && !(_.isNull(recordData) || _.isUndefined(recordData)) && this._state.columns[key] != undefined) {
              formatter = this._state.columns[key].formatter;
              styleType = this._state.columns[key].styleType;
              let dataType = this._state.columns[key].dataType;

              recordData['rowId'] = rowId;
              recordData['msId'] = msId;
              recordData['snapshotDt'] = snapshotDt;
              recordData['dataType'] = dataType;
              recordData['displayValue'] = this.getCellValue(recordData, formatter, styleType);
              recordData['className'] = "";
              recordData['isVisualizationOn'] = this._state.columns[key].isVisualizationOn;

              if (this._state.columns[key].usePosNegColoring && recordData.doubleValue > 0)
                recordData['className'] = 'grid-cell-positive';
              else if (this._state.columns[key].usePosNegColoring && recordData.doubleValue < 0)
                recordData['className'] = 'grid-cell-negative';

              if (this._state.columns[key].dataItemId == -4) { // #
                recordData['displayValue'] = recordData['rowId'];
              }
              else if (this._state.columns[key].dataItemId == -3) { // Flag
                if (recordData['displayValue'] == true)
                  recordData['className'] = (recordData['className'] ? recordData['className'] + ' icn-flag active' : 'icn-flag active');
                else
                  recordData['className'] = (recordData['className'] ? recordData['className'] + ' icn-flag' : 'icn-flag');
              }
              else if (this._state.columns[key].dataItemId == -5) { // Symbol
                recordData['className'] = recordData['className'] + 'symbol';
              }

              if (!this._state.columns[key].isZeroVisible && this._state.columns[key].dataType != 3 && parseFloat(recordData['displayValue']) == 0) recordData['displayValue'] = "";
              if (this._state.columns[key].dataItemId == -3) // Flag
                recordData['textWidth'] = flagColumnWidth;
              else
                recordData['textWidth'] = this.getCellTextWidth(this._state.columns[key], recordData['displayValue'], recordData['snapshotDt']);

              if (recordData.dataItemId === _symbolColId) {
                item.symbol = recordData.displayValue;
              }
            }
          });
        }
      });
    });
  }


  exportDataCall() {
    BrowserUtil.disableEvents();
    let selectedItem = ListStore.getSelectedListItem();
    let fileName = selectedItem.SelectedListName + ".csv";
    this.processServiceCalls(this._state.exportPageData, true).then(() => {
      let columns = "";
      let result = "";
      _.each(this._state.columns, (column) => {
        if (column.dataItemId == -3) {
          columns += '"' + "Flag" + '"' + ",";
        }
        else {
          columns += '"' + column.displayName + '"' + ",";
        }
      });
      columns += "\n";
      result += columns;
      let rows = this.getSourceDataForExport();
      let records = [];
      _.each(rows, (row) => {
        let rowData = "";
        _.each(row.recordDatas, (record) => {
          rowData += '"' + record.displayValue + '"' + ",";
        })
        rowData += "\n";
        records[row.rowId] = rowData;
        result += rowData;
      })
      this.downloadCsv(result, fileName);
    })

  }

  downloadCsv(csvFile, filename) {
    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    BrowserUtil.enableEvents();
  }
  */

  getEsBasicCacheId() {
    return this._state.esBasicCatalogData && this._state.esBasicCatalogData.cacheId;
  }

  getNasdaqConnection() {
    return this._state.IsNASDAQBasic;
  }

  async getFullListMsids(curractiveList,stateBasicEsInfo,listExplorer = false, isUndo = false) {
    let allListSymbolMsids = [];
    const nodeId = curractiveList.nodeId === undefined ? curractiveList.SelectedListId : typeof (curractiveList.nodeId) === 'object' ? curractiveList.nodeId.low : curractiveList.nodeId;
    try {
      return new Promise((resolve) => {
        this.isNupesBasicLoaded = false;
        setTimeout(() => {
          let msidList = [];
          const basicEsInfo = clone(stateBasicEsInfo);
          const browserFilters = basicEsInfo.browserFilter;
          const igOneilbrowserFilters = basicEsInfo.oneilBrowserFilter;
          const igGicsbrowserFilters = basicEsInfo.gicsBrowserFilter;
          const isOneilselected = basicEsInfo.isOneilSel;
          const isGicsselected = basicEsInfo.isGicsSel;
          const actualListId = curractiveList.sourceListId ? curractiveList.sourceListId : nodeId;
          const esbasicinfo = this.getBasicEsInfoforCopy(nodeId, actualListId, -1, isOneilselected, isGicsselected, browserFilters, igOneilbrowserFilters, igGicsbrowserFilters, curractiveList);
          let catalogdata = null;
          const isUserList = ListExplorerStore.isUserListOrFavoriteUserList();
          const activeNodeId = ListExplorerStore.activeNode && ListExplorerStore.activeNode.nodeId ? typeof (ListExplorerStore.activeNode.nodeId) === 'object' ? ListExplorerStore.activeNode.nodeId.low : ListExplorerStore.activeNode.nodeId : null;
          ListApi.getListCatalogEsData(esbasicinfo, false, isUserList, activeNodeId).promise.then((result) => {
            catalogdata = result.catalog.ownershipRecordInfo;
            if (!StringUtil.isEmpty(catalogdata)) {
              let ownersshipList = JSON.parse(catalogdata);
              let msidData = this.getMsIdsFromResponData(ownersshipList);
              allListSymbolMsids = msidData.allListSymbolMsids;
              msidList = msidData.msidList;
              if(GridStore.getGridBodyAction() === GridConstants.ActionTypes.ADD_SYMBOL_LIST_MANAGER){
                this.addPageData(ownersshipList , 0 , -1) // PANWEB-6856-Fix
              }
              
            }
            
            if (!listExplorer) this._state.allListSymbolMsids = allListSymbolMsids;
            if(!isUndo){
              this._state.allMsidList = msidList;
            }
            // if(this._state.basic.count <= msidList.length){
            // }
            resolve(msidList);

          });

        });
      });
    }
    catch (e) {
      console.log('error', e)
    }
  }

  getMsIdsFromResponData(ownersshipList){
    if(!ownersshipList || ownersshipList.length === 0){
      return { msidList: [], allListSymbolMsids : [] };
    }

    let msidList = [];
    let allListSymbolMsids = [];
    for (let row of ownersshipList) {
      if (!StringUtil.isEmpty(row)) {
        let keys = Object.keys(row);
        let rowData = {};
        rowData.snapshotDt = null;
        for (let key of keys) {
          let col = row[key];
          if (key == "msid") {
            msidList.push(col);
            rowData.msId = col;
          }
          else if (key == "-5") {
            //Symbol
            rowData.symbol = col;
          }
          else if (key == SnapshotIdName && (ListExplorerStore.isSnapshotList() || ListExplorerStore.isModelList())) {
            try {
              const isInt = Number.isInteger(col);
              rowData.snapshotDt = isInt ? Number.parseInt(col) : StringUtil.formatDate(col);

            }
            catch (exc) {
              rowData.snapshotDt = null;
            }
          }
          else if (key == "2433") {
            rowData.currencyCode = col
          }
        }
        allListSymbolMsids.push(rowData);
      }
    }
    return { msidList: msidList, allListSymbolMsids : allListSymbolMsids };
  }

  getBasicEsInfoforCopy(listId, actualListId, colsetId, isOneilselected, isGicsselected, browserFilterText, igOneilbrowserFilters, igGicsbrowserFilters, destItem, isColumnFilterEnabled = this.getScreenOnOffValue()) {
    return BasicEsInfo.getBasicEsInfoforCopy(listId, actualListId, colsetId, isOneilselected, isGicsselected, browserFilterText, igOneilbrowserFilters, igGicsbrowserFilters, destItem, isColumnFilterEnabled)
  }

  noFilters() {
    return (this._columnFilters.length == 0 &&
      this._state.basicEsInfo.browserFilter == "-1;-1;-1;" &&
      this._state.basicEsInfo.gicsBrowserFilter == "-1;-1;-1;-1;" &&
      this._state.basicEsInfo.oneilBrowserFilter == "-1;-1;-1;");
  }

  clearSourecData() {
    this._source = [];
  }


  pushDataToAdobe() {
    if (this._state.needToCallAdobe) {
      this._state.needToCallAdobe = false;
      // Push data to DTM while loading login page
      const setting = SettingsStore.getConsoleSettings().NavListManagerSettings.TabONeilSettings.SelectedListInfo;
      const selectedListFolderPath = this.getSelectedListFolderPath(setting);
      const listViewCount = this._state.esBasicCatalogData.count;

      AdobeUtil.DTMTrack({
        userIdRequired: true,
        listSelected: setting.SelectedListName,
        listCategorySelected: selectedListFolderPath,
        listViewCount: listViewCount
      },
        AdobeConstants.LIST_SELECTED);
    }
  }

  getSelectedListFolderPath(setting) {
    let ParentListTreeItem = [];
    let path = '';

    ListExplorerStore.findParentNode(setting.SelectedListId, ParentListTreeItem);
    for (var i = ParentListTreeItem.length - 1; i >= 0; i--) {
      path = path ? `${path}|${ParentListTreeItem[i].name}` : ParentListTreeItem[i].name;
    }
    return path;
  }

  pushDataToAdobeWhilePageLoad() {
    // Push data to DTM while loading login page
    const setting = SettingsStore.getConsoleSettings().NavListManagerSettings.TabONeilSettings.SelectedListInfo;
    const selectedListFolderPath = this.getSelectedListFolderPath(setting);

    AdobeUtil.DTMTrack({
      pageName: "Panaray : Lists",
      siteSection: "Panaray : Lists",
      userIdRequired: true,
      selectedList: setting.SelectedListName,
      selectedListFolderPath: selectedListFolderPath
    },
      AdobeConstants.LIST_VIEW_CLICK);
  }
  findCustomColumn(dataItemId) {
    const obj = ListStore.getState().metricData;
    const column = find(obj?.itemData, (key) => dataItemId.toString() === key.MsItemID.toString());
    if (!StringUtil.isEmpty(column)) {
      return true;
    }
    return false;
  }

  getRemoveColumns() {
    this._removedColumns;
  }

  async getFullListMsidsForSelectAll(curractiveList, stateBasicEsInfo) {
    const nodeId = curractiveList.nodeId === undefined ? curractiveList.SelectedListId : typeof (curractiveList.nodeId) === 'object' ? curractiveList.nodeId.low : curractiveList.nodeId;
    try {
      this.isNupesBasicLoaded = false;
      const basicEsInfo = clone(stateBasicEsInfo);
      const browserFilters = basicEsInfo.browserFilter;
      const igOneilbrowserFilters = basicEsInfo.oneilBrowserFilter;
      const igGicsbrowserFilters = basicEsInfo.gicsBrowserFilter;
      const isOneilselected = basicEsInfo.isOneilSel;
      const isGicsselected = basicEsInfo.isGicsSel;
      const actualListId = curractiveList.sourceListId ? curractiveList.sourceListId : nodeId;
      const esbasicinfo = this.getBasicEsInfoforCopy(nodeId, actualListId, -1, isOneilselected, isGicsselected, browserFilters, igOneilbrowserFilters, igGicsbrowserFilters, curractiveList);
      const isUserList = ListExplorerStore.isUserListOrFavoriteUserList();
      const result = await ListApi.getListCatalogEsData(esbasicinfo, false, isUserList).promise;
      let catalogdata = result && result.catalog && result.catalog.ownershipRecordInfo;
      let allListSymbolMsids = [];
      let msidList = [];
      if (!StringUtil.isEmpty(catalogdata)) {
        let ownersshipList = JSON.parse(catalogdata);
        let msidData = this.getMsIdsFromResponData(ownersshipList);
        allListSymbolMsids = msidData.allListSymbolMsids;
        msidList = msidData.msidList;
      }
      this._state.allListSymbolMsids = allListSymbolMsids;
      this._state.allMsidList = msidList;
      return msidList;
    }
    catch (e) {
      console.log('getFullListMsidsForSelectAll error', e)
    }
  }

  dispatcherCallback(payload) {
    const action = payload.action;
    const data = action.data;
    switch (action.actionType) {

      case ListExplorerConstants.ActionTypes.GET_LIST_CATALOG:
        TimeTrackingWindow.beginListContextRenderTimeTracker();
        this._state.listId = data.listId;
        this._state.actualListId = data.actualListId;
        try{
          if (data.esBasicCatalogData) {
            this._source = [];
            this._currentAction = ListExplorerConstants.ActionTypes.GET_LIST_CATALOG;
            GridStore.resetSelectedIndexes();
            GridStore.clearCurrentAction();
            this._state.esBasicCatalogData = data.esBasicCatalogData;
            this._state.basicEsInfo = data.basicEsInfo;
            if(this._state.basicEsInfo && this._state.basicEsInfo.actualListId === 0){
              this._state.basicEsInfo.actualListId = data.actualListId;
            }
            const currentNode = this.getCurrentNode(data.listId);
            if(currentNode[0] && currentNode[0].shareAccess !== data.esBasicCatalogData.shareAccess){
              this._state.isListAccessChanged = true
              this._state.alertMessage = LocalizationStore.getTranslatedData("lp_mes_17", "The list accessed was changed.");
              currentNode[0].shareAccess = data.esBasicCatalogData.shareAccess;
            }
            //this._state.basic = {...this._state.esBasicCatalogData};
            this._state.basic = this._state.esBasicCatalogData;//data.result.catalog;
            this._state.cacheId = this._state.basic.cacheId;
            this._state.basicEsInfo.cacheId = this._state.cacheId;
            this._state.columnSetId = StringUtil.convertFromLongValueToInt(this._state.basic.columnSet.nodeId);//this._state.basic.columnSet.nodeId.low;
            this._state.isSharedColumnSet = this._state.basic.columnSet.isShared;
            this._state.columnSetName = this._state.isSharedColumnSet ? "Shared - " + this._state.basic.columnSet.name : this._state.basic.columnSet.name;
            this._state.columns = this.removeInvisibleColumns(clone(this._state.basic.columnInfos));
            this._columnInfo = this._state.columns;
            this._columnFilters = this._state.basic.columnFilters;

            //export data save
            this._state.exportPageData = data;
            this.processColumns().then(() => {
              ColumnCustomFilterControl.setColumnInfoFromStore();
              this.processServiceCalls(data);
              SettingsStore.saveSettings();
            });
          }
        }
        catch(e){
          console.error(e);
          TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.ListContent, false, NavType.NavLists);
        }
        this.pushDataToAdobe();
        break;

      case ListExplorerConstants.ActionTypes.GET_MORE_DATA_ON_SCROLL:
        this.getMoreDataOnScroll(data.listCatalog);
        break;

      case ListExplorerConstants.ActionTypes.RELOAD_LIST_CATALOG:
        this._currentAction = ListExplorerConstants.ActionTypes.RELOAD_LIST_CATALOG;
        this.emit(CHANGE_EVENT);
        break;

      case ListExplorerConstants.ActionTypes.GET_SEARCH_TEXT:
        this._currentAction = ListExplorerConstants.ActionTypes.GET_SEARCH_TEXT;
        this.setListCatalogSearchKeyword(action.data);
        this.emit(CHANGE_EVENT);
        break;
      case ListExplorerConstants.ActionTypes.CLEAR_SEARCH_TEXT:
        this._currentAction = ListExplorerConstants.ActionTypes.CLEAR_SEARCH_TEXT;
        this._state.searchKeyword = action.data.trim();
        this.emit(CHANGE_EVENT);
        break;

        case ListExplorerConstants.ActionTypes.SELECT_COLUMN_SET:
          this._currentAction = ListExplorerConstants.ActionTypes.SELECT_COLUMN_SET;
          this._state.columnSetId = action.data;
          this.emit(CHANGE_EVENT);
          break;

      case ListExplorerConstants.ActionTypes.GET_COLUMN_PICKLIST:
        this.getListColumnPickList(action.data);
        break;

      case ListExplorerConstants.ActionTypes.SCREEN_ON_OFF:
        this._currentAction = ListExplorerConstants.ActionTypes.SCREEN_ON_OFF;
        this.toggleScreenOnOff(action.data);
        this.emit(CHANGE_EVENT);
        SettingsStore.saveSettings();
        break;

      // case ListExplorerConstants.ActionTypes.RELOAD_LIST_CATALOG:
      //   this._currentAction = ListExplorerConstants.ActionTypes.RELOAD_LIST_CATALOG;
      //   this.emit(CHANGE_EVENT);
      //   break;

       /* redundant case */
      // case ListExplorerConstants.ActionTypes.SELECT_COLUMN_SET:
      //   this._currentAction = ListExplorerConstants.ActionTypes.SELECT_COLUMN_SET;
      //   this._state.columnSetId = action.data;
      //   this.emit(CHANGE_EVENT);
      //   break;

      // case ListExplorerConstants.ActionTypes.GET_COLUMN_PICKLIST:
      //   this.getListColumnPickList(action.data);
      //   break;

      // case ListExplorerConstants.ActionTypes.SCREEN_ON_OFF:
      //   this._currentAction = ListExplorerConstants.ActionTypes.SCREEN_ON_OFF;
      //   this.toggleScreenOnOff(action.data);
      //   this.emit(CHANGE_EVENT);
      //   SettingsStore.saveSettings();
      //   break;

      case ListExplorerConstants.ActionTypes.GET_SCREEN_OFF_LIST_CATALOG:
        this._currentAction = ListExplorerConstants.ActionTypes.GET_SCREEN_OFF_LIST_CATALOG;
        TimeTrackingWindow.beginListContextRenderTimeTracker();
        this.setColumnFilterData(action.data.result);
        break;

      case ListExplorerConstants.ActionTypes.ADD_SYMBOLS_TO_LIST:
        this._currentAction = ListExplorerConstants.ActionTypes.ADD_SYMBOLS_TO_LIST;
        this.addSymbolsToList(data);
        break;
      case ListExplorerConstants.ActionTypes.GET_REFRESHED_VIEW:
        this.isRefreshView = true;
        break;
      case SettingsConstants.ActionTypes.DDE_STATUS_CHANGED:
        if (this._state == null) return;
        this._state.IsNASDAQBasic = action.data.SelectedDDeConnectType === IntradaySourceType.IntradaySource_NASDAQ;
        break;

      default:
        break;
    }
  }
}

const oNeilViewStore = new ONeilViewStore();
export default oNeilViewStore;
