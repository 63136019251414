import { DataGraphConstants } from "../Constants/DataGraphConstants";
const { ActionTypes } = DataGraphConstants;

export const handlePricePanelDataReady = (pricePanelData, headerData, nodeCount) => ({
    type: ActionTypes.PRICE_PANEL_RESPONSE_READY,
    pricePanelData,
    headerData,
    nodeCount
});

export const initPeriodicityButton = (userSettings) => ({
    type: ActionTypes.INIT_PERIODICITY_BUTTON,
    userSettings
})

export const initSettingsToReducer = (userSettings) =>({
    type: ActionTypes.INIT_SETTINGS_TO_REDUCER,
    userSettings
})
export const changePeriodicityButtonPeriodicity = (item, button) => ({
    type: ActionTypes.PERIODICITY_CHANGE,
    item, button
})

export const onPlotSymbol = (symbol, isSymbolChange = true, nodeCount = 500, isMiniListPlay = false, isMiniListChart = false, isViewChart = false, isNavChange = false) => ({
    type: ActionTypes.ON_PLOT_SYMBOL,
    symbol, isSymbolChange, nodeCount, isMiniListPlay, isMiniListChart, isViewChart, isNavChange
})

export const clearFRData = () =>({
    type: ActionTypes.CLEAR_FR_DATA
})

export const updateSymbolEntryStates = (states)=>({
    type: ActionTypes.UPDATE_SYMBOL_ENTRY_STATES,
    states
})
export const updateSymbolEntryFlag = (isFlagged)=>({
    type: ActionTypes.UPDATE_SYMBOL_ENTRY_FLAG,
    isFlagged
})
export const onSymbolConvertedDialogAvailableChange = ()=>({
    type: ActionTypes.UPDATE_SYMBOL_CONVERTED_DIALOG_AVAILABLE,
})
export const handleSymbolEntrySymbolChange = (symbol)=>({
    type: ActionTypes.HANDLE_SYMBOL_ENTRY_SYMBOL_CHANGE,
    symbol
})
export const handleSymbolEntryChange = (isMounseDown, symbol, isOutsideClick)=>({
    type: ActionTypes.HANDLE_SYMBOL_ENTRY_CHANGE,
    isMounseDown, symbol, isOutsideClick
})
export const closeSymbolConvertedDialog = ()=>({
    type: ActionTypes.CLOSE_SYMBOL_CONVERTED_DIALOG,
})
export const closeSymbolEntryErrorDialog = ()=>({
    type: ActionTypes.CLOSE_SYMBOL_ENTRY_ERROR_DIALOG,
})
export const updateSymbolEntryLoading = (disablePeriodicityButton, isLoading)=>({
    type: ActionTypes.UPDATE_SYMBOL_ENTRY_LOADING,
    disablePeriodicityButton, isLoading
})
export const updateFactorTileClick = (newSelectedFactorKey, newSelectedEvent, newGraphOpenList, newGraphOpenListHistory)=>({
    type: ActionTypes.FR_BUTTON_TILE_CLICK,
    newSelectedFactorKey, newSelectedEvent, newGraphOpenList, newGraphOpenListHistory
})
export const getRIPanelFactorInfo = (event)=>({
    type: ActionTypes.CALL_FACTOR_RI_API,
    event
})
export const updateDataGraphTabHeight = (dataGraphViewHeight)=>({
    type: ActionTypes.UPDATE_DATAGRAPH_TAB_HEIGHT,
    dataGraphViewHeight
})
export const updateAvailableComponent = (availableComponents)=>({
    type: ActionTypes.UPDATE_AVAILABLE_COMPONENT,
    availableComponents
})
export const toggleCorpEventsClick = (IsCollapsed = false)=>({
    type: ActionTypes.TOGGLE_CORP_EVENTS,
    IsCollapsed
})
export const updateShowToolTip = (showToolTip)=>({
    type: ActionTypes.UPDATE_SHOW_TOOL_TIP,
    showToolTip
})
export const updateIsHistoric = (isHistoric)=>({
    type: ActionTypes.UPDATE_IS_HISTORIC,
    isHistoric
})
export const callPriceChartApi = ()=>({
    type: ActionTypes.CALL_PRICE_CHART_API,
})
export const updateShowNQBox = () => ({
    type: ActionTypes.UPDATE_NQBOX_STATE
})

export const toggleMiniList = (isMiniListOpen) => ({
    type: ActionTypes.TOGGLE_MINI_LIST,
    isMiniListOpen
})

export const updateCommonAboutDialog = (isCommonAboutDialogOpen, commonAboutDialogUrl= '', commonAboutDialogTitle= '') => ({
    type: ActionTypes.UPDATE_COMMON_ABOUT_DAILOG,
    isCommonAboutDialogOpen, commonAboutDialogUrl, commonAboutDialogTitle
})

export const updateAnalyzerUrl = (url = null) => ({
    type: ActionTypes.UPDATE_ANALYZER_URL,
    url
})
