import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import { GridConstants } from "GridConstants";
import {ComparisonAndRotationMenuConstants} from '../../../../Constants/RotationGraphConstants';
import ListApi from "ListApi";
// import ConsoleActions from "Actions/ConsoleActions";
import store from '../../../../Redux/store';
import ContextMenuConstants from "ContextMenuConstants";
import ONeilViewStore from "ONeilViewStore";
import OwnershipViewStore from "OwnershipViewStore";
import ListStore from "ListStore";
import MiniListStore from "MiniListStore";
import { reject, some, each, find, contains, isEmpty, difference } from "underscore";
import StringUtil from "StringUtil";
import BaseServiceApi from "BaseServiceApi";
import ListManagerTabType from "ListManagerTabType";
import ListExplorerStore from "ListExplorerStore";
import ConsoleStore from 'ConsoleStore';
import LocalizationStore from 'LocalizationStore';
import BrowserUtil from "BrowserUtil";
import UserInfoUtil from "UserInfoUtil";
import { ExportExcelConstants } from "../../../../Constants/ExportExcelConstants";
import ListActions from "ListActions";
import { SettingsConstants } from "../../../../Constants/SettingsConstants";
import WebSyncUtil from "../../../../Utils/WebSyncUtil";
import { isEditableList } from "../../../../Utils/OwnerShipUtil.js";
import ErrorCodes from "../../../../Constants/ErrorCodes";
import { ShareAccessType } from "../../../../Constants/ShareConstants";
import TabONeil from "../../../../Components/NavModules/NavList/TabONeil/TabONeil";
import ListTabControl from "../../../../Components/NavModules/NavList/Explorer/ListTabControl";
import NavType from "../../../../Constants/NavType";
import SettingsStore from "SettingsStore";
import TabONeilSettings from "../../../ConsoleWindow/Settings/Modules/ListManager/TabONeilSettings";
import SummaryStatStore from "../../../../RayCustomControls/ListSummaryStat/SummaryStatStore";
import panarayDefault from "../../../../ServiceApi/RequestHelper/PanarayDefault";
let InstrumentType = BaseServiceApi.rayData["InstrumentType"];
let IntradaySourceType = BaseServiceApi.rayData["IntradaySourceType"];

const CHANGE_EVENT = "change";
const SELECT_LIMIT_EXCEED_EVENT = "SELECT_LIMIT_EXCEED_EVENT"

let ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
let _currentAction = null;
let _highlightedRow = { index: null, highlight: false };
let _selectedRow = { rowIndex: null, visibleIndex: null, keyType: null };
let _gridBodyAction = null;
let _removedColumn = {}
let _alertMessage = null;
let _selectedIndexes = {ONEIL:[],OWNERSHIP:[]};
let _removedRow = {};
let _copySymTxt = null;
let _cacheIdForPaste = null;
let _refreshGrid = false;
let _undoPasteOperation = false;
let _isUndoable = false;
let _maxCopySymbols = 10000;
let _maxOwnershipCopySymbols = 250;
let _currentListItems = null;
let _loadDataIfNORowReturn = false;
let _dragInfo = {};
let _indexes = [];
let _scroll = { scrollTop: 0, scrollLeft: 0 };
let dragDestInfo = {};
let _displayWarning = false;
let _limitExceedType = null;
let _allRowsSelected = false;
let _streamingSymbols = null;
class GridStore extends EventEmitter {
  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
    this.OnSharePermissionChanged = this.OnSharePermissionChanged.bind(this);
    //Bug fixes - Max Limit error
    this.setMaxListeners(0);
    this.isPasteEvent = false;
    this.isNASDAQBasic = false
  }

  addChangeListener(callback, event = CHANGE_EVENT) {
    this.on(event, callback);
  }

  removeChangeListener(callback, event = CHANGE_EVENT) {
    this.on(event, callback);
  }

  getCurrentAction() {
    return _currentAction;
  }

  getSelectedNavTab() {
   return ListStore.getselectedTabKey();
  }

  clearCurrentAction() {
    _currentAction = null;
  }

  getHighlightedRow() {
    return _highlightedRow;
  }

  getSelectedRow() {
    return _selectedRow;
  }

  getGridBodyAction() {
    return _gridBodyAction;
  }

  clearGridBodyAction() {
    _gridBodyAction = null;
  }

  setSelectedIndexes(indexes, miniList = false) {
    _selectedIndexes[this.getSelectedNavTab()] = indexes;
    if (!miniList) {
      MiniListStore.setSelectedRowIndexs(_selectedIndexes[this.getSelectedNavTab()]);
    }
  }

  resetSelectedIndexes() {
      _selectedIndexes[this.getSelectedNavTab()] = [];
      MiniListStore.clearSelectedRowIndexes();
  }

  getIndexs() {
    return _indexes;
  }

  getSelectedIndexes() {
    return _selectedIndexes[this.getSelectedNavTab()];
  }

  getRemovedColumn() {
    return _removedColumn;
  }

  getRemovedRow() {
    return _removedRow;
  }

  getDisplayWarning() {
    return _displayWarning;
  }

  getLimitExceedType() {
    return _limitExceedType;
  }

  getAlertMessage() {
    return _alertMessage;
  }

  resetAlertMessage() {
    _alertMessage = null;
  }

  setScrollPos(scrollTop, scrollLeft = null) {
    if (scrollTop != null) _scroll.scrollTop = scrollTop;
    if (scrollLeft != null) _scroll.scrollLeft = scrollLeft;
  }

  setDragDestInfo(dragedInfo, destInfo, updateDragInfo = true) {
    if (updateDragInfo) {
      dragDestInfo.dragedInfo = dragedInfo;
      dragDestInfo.destInfo = destInfo;
    }
    else {
      dragDestInfo = {};
    }
  }

  getDragedDestInfo() {
    return dragDestInfo;
  }

  getScrollPos() {
    return _scroll;
  }

  isLoadDataIfNORowReturn() {
    return _loadDataIfNORowReturn;
  }

  getViewStore() { //OneilView Or OwnerShipView tabType
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    let viewStore = ONeilViewStore;
    switch (tabType) {
      case ListManagerTabType.Oneil:
        viewStore = ONeilViewStore;
        break;
      case ListManagerTabType.Ownership:
        viewStore = OwnershipViewStore;
        break;
      default:
        break;
    }
    return viewStore;
  }

  updateColSetWidth(data) {
    let self = this;
    ListApi.addColumnSetWidth(data.listId, data.colSeq, data.quoteBoard).then((result) => {
      if (!result.responseHeader.error) {
        let selectedTab = ListStore.getState().SelectedTabKey;
        let tabType = ListStore.getTabType(selectedTab);
        switch (tabType) {
          case ListManagerTabType.Oneil:
            ONeilViewStore.updateColumnWidth(data.colSeq).then((result) => {
              self.clearCurrentAction();
              _gridBodyAction = GridConstants.ActionTypes.UPDATE_COL_SET_WIDTH;
              if (result) self.emit(CHANGE_EVENT);
            });
            break;
          case ListManagerTabType.Ownership:
            OwnershipViewStore.updateColumnWidth(data.colSeq).then((result) => {
              self.clearCurrentAction();
              _gridBodyAction = GridConstants.ActionTypes.UPDATE_COL_SET_WIDTH;
              if (result) self.emit(CHANGE_EVENT);
            });
            break;
          default:
            break;
        }
      }
    });
  }


  addColumnToList(data) {
    let self = this;
    ListApi.addColumnToList(data.listId, data.dataItemId, data.previousColumnDataItemId, data.isFrozen, data.isQuoteboard).then((result) => {
      _currentAction = data.action;
      if (!result.responseHeader.error) {
        if (data.action == GridConstants.ActionTypes.ADD_COLUMN_TO_LIST) {
          let selectedTab = ListStore.getState().SelectedTabKey;
          let tabType = ListStore.getTabType(selectedTab);
          let temp;
          switch (tabType) {
            case ListManagerTabType.Oneil:
              if(!data.isColumnReorder)
                temp = ONeilViewStore.reorderColumns(data.dataItemId, data.previousColumnDataItemId);
              break;
            case ListManagerTabType.Ownership:
              temp = OwnershipViewStore.reorderColumns(data.dataItemId, data.previousColumnDataItemId);
              break;
            default:
              break;
          }
          if(!data.isColumnReorder){
            temp.then((result) => {
              if (result) { _currentAction = data.action; self.emit(CHANGE_EVENT); }
            })
          }
        }
        else {
          _currentAction = data.action; self.emit(CHANGE_EVENT);
        }
      }
    });
  }


  removeColumnToList(data) {
    let self = this;
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    ListApi.removeColumnToList(data.listId, data.dataItemId, data.isQuoteboard).then((result) => {
      if (!result.responseHeader.error) {
        switch (tabType) {
          case ListManagerTabType.Oneil:
            ONeilViewStore.storePrevColumnFilterAndSortsColumnsForUndo(data);
            break;
          case ListManagerTabType.Ownership:
            OwnershipViewStore.storePrevColumnFilterAndSortsColumnsForUndo(data);
            break;
          default:
            break;
        }
        _removedColumn = {};
        _removedColumn.listId = data.listId;
        _removedColumn.dataItemId = data.dataItemId;
        _removedColumn.isQuoteboard = data.isQuoteboard;
        _removedColumn.isFrozen = data.isFrozen;
        _removedColumn.previousColumnDataItemId = data.previousColumnDataItemId;
        _alertMessage = LocalizationStore.getTranslatedData("lp_mes_19", 'Column has been removed from Column Set.');
        _currentAction = GridConstants.ActionTypes.REMOVE_COLUMN_TO_LIST;
        _gridBodyAction = GridConstants.ActionTypes.REMOVE_COLUMN_TO_LIST;
        self.emit(CHANGE_EVENT);
      }
    });
    //log usage
    ConsoleStore.trackUsage('ListColumnDeleted', '', ['ListView']);
  }

  updateGlobalFlagAll(data) {
    if (data.isDelete) _gridBodyAction = ContextMenuConstants.REMOVE_ALL_FLAGS;
    else _gridBodyAction = ContextMenuConstants.FLAG_ALL_SYMBOLS;
    ListApi.updateGlobalFlagAll(data.listId, data.cacheId, data.isDelete, data.dbType).then((result) => {
      if (!result.responseHeader.error) {
        this.emit(CHANGE_EVENT);
      }
    });
  }

  updateGlobalFlag(data) {
    _selectedIndexes[this.getSelectedNavTab()] = data.selectedIndexes;

    if (data.isDelete) _gridBodyAction = ContextMenuConstants.ROW_REMOVE_FLAG;
    else _gridBodyAction = ContextMenuConstants.ROW_FLAG;
    data.dbType = data.dbType !== undefined && data.dbType !== null ? data.dbType : 1;
    ListApi.updateGlobalFlag(data.msids, data.snapshotdates = "", data.isDelete, data.cacheId, data.dbType).then((result) => {
      if (!result.responseHeader.error) {
        if (data.selectedIndexes.length == 0) {
          if (data.dbType == 1) ONeilViewStore.updateFlagInFromSymbol(data.msids, data.isDelete);
          if (data.dbType == 2) OwnershipViewStore.updateFlagInFromSymbol(data.msids, data.isDelete);
        }
        else {
          const sourceData = OwnershipViewStore.getSourceData();
          if (data.dbType == 2 && sourceData.length === data.selectedIndexes.length) {
            OwnershipViewStore.updateFlagAll(data.isDelete);
          }
        }

        store.dispatch({
          type:ComparisonAndRotationMenuConstants.ActionTypes.UPDATE_FLAGGED_LIST_ITEMS,
          item:{isFlagged:data.isDelete,msid:data.msids}
        });

        _currentAction = GridConstants.ActionTypes.UPDATE_GLOBAL_FLAG;
        this.emit(CHANGE_EVENT);
      }
    });
  }

  visualizeGridColumn(data) {
    let self = this;

    ListApi.changeVisualizationSettingRequest(data.listId, data.colSeq, data.quoteBoard).then((result) => {
      if (!result.responseHeader.error) {
        let selectedTab = ListStore.getState().SelectedTabKey;
        let tabType = ListStore.getTabType(selectedTab);
        let temp;
        switch (tabType) {
          case ListManagerTabType.Oneil:
            temp = ONeilViewStore.updateVisualizeColumn(data.colSeq);
            break;
          case ListManagerTabType.Ownership:
            temp = OwnershipViewStore.updateVisualizeColumn(data.colSeq);
            break;
          default:
            break;
        }
        temp.then((res) => {
          if (res) { _currentAction = null; _gridBodyAction = GridConstants.ActionTypes.VISUALIZE_GRID_COLUMN; self.emit(CHANGE_EVENT) };
        })
      }
    });
  }


  async splitFrozenColumns(data) {
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    switch (tabType) {
      case ListManagerTabType.Oneil:
        /*eslint-disable */
        let t = await ONeilViewStore.toggleFrozenColumns(data.isSplit);
        /*eslint-enable */
        break;
      case ListManagerTabType.Ownership:
        /*eslint-disable */
        let tt = await OwnershipViewStore.toggleFrozenColumns(data.isSplit);
        /*eslint-enable */
        break;
      default:
        break;
    }
    _currentAction = GridConstants.ActionTypes.SPLIT_FROZEN_COLUMNS;
    this.emit(CHANGE_EVENT);
  }

  removeGridRows(data) {
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    let removedGridRows;
    switch (tabType) {
      case ListManagerTabType.Oneil:
        removedGridRows = ONeilViewStore.removeGridRows(data);
        break;
      case ListManagerTabType.Ownership:
        removedGridRows = OwnershipViewStore.removeGridRows(data);
        break;
      default:
        break;
    }
    removedGridRows.then((result) => {
      _currentAction = null;
      _gridBodyAction = GridConstants.ActionTypes.REMOVE_GRID_ROWS;
      if (!result.error) {
        _isUndoable = true
        _alertMessage = result._alertMessage;
        _removedRow = result._removedRow;
        if (tabType == ListManagerTabType.Oneil)
          ListExplorerStore.getListAlertStatus(data);
        this.emit(CHANGE_EVENT);
      }
      else {
        _alertMessage = this.OnSharePermissionChanged(result, data.listId);
        this.emit(CHANGE_EVENT);
      }
    });
  }

   OnSharePermissionChanged(result, nodeId) {
       const code = result && result.errorCode;
         if (code === ErrorCodes.LIST_NOT_EXISTED || code === ErrorCodes.SHARED_PERMISSION_REMOVED) {
          _currentAction = GridConstants.ActionTypes.REMOVE_UNSHARED_LIST;                         
          return LocalizationStore.getTranslatedData("lp_mes_16", "The list accessed is not available.");
        } 
        else if (code === ErrorCodes.SHARED_PERMISSION_CHANGEDTO_READONLY) {
          const currentNode = ONeilViewStore.getCurrentNode(nodeId);
          currentNode[0].shareAccess = ShareAccessType.SHARE_READONLY;
          _isUndoable = false;
          return LocalizationStore.getTranslatedData("lp_mes_17", "The list accessed was changed to ReadOnly.");
        }
        }

  removeGridRowsUndo() {
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    let undoOperationForRemove;
    switch (tabType) {
      case ListManagerTabType.Oneil:
        undoOperationForRemove = ONeilViewStore.removeGridRowsUndo(_removedRow);
        break;
      case ListManagerTabType.Ownership:
        undoOperationForRemove = OwnershipViewStore.removeGridRowsUndo(_removedRow);
        break;
      default:
        break;
    }
    undoOperationForRemove.then(() => {
      _removedRow && this.setSelectedIndexes(_removedRow.selectedIndexes);
      _gridBodyAction = GridConstants.ActionTypes.REMOVE_GRID_ROWS_UNDO;
      if (tabType == ListManagerTabType.Oneil)
        ListExplorerStore.getListAlertStatus(_removedRow);
      this.emit(CHANGE_EVENT);
    });
  }

  //copy paste logic separate

  canExecCopySelectedSymbols() {
    return !(_selectedIndexes[this.getSelectedNavTab()] == null || _selectedIndexes[this.getSelectedNavTab()].length == 0);
  }

  getSelectedItemForCopy(msIDS) {
    let viewStore = this.getViewStore();
    let msIds = msIDS ? msIDS.split(";") : [];
    let selectedItems = [];
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    let snapshotDt;

    for (let i = 0; i < msIds.length; i++) {
      // if (i >= _maxCopySymbols - 200) break; // SQL Error encounter when 10000 symbol set for copy and paste --- check with backend team. (hardcoding 201 and its work)

      let symbol = viewStore.getSymbolFromMsIds(msIds[i]);

      if (symbol !== null && symbol !== undefined) {
        switch (tabType) {
          case ListManagerTabType.Oneil:
            snapshotDt = viewStore.getSnapshotDateFromMsIds(msIds[i]);
            selectedItems.push({
              symbol: symbol,
              snapshotDt: snapshotDt
            });
            break;
          case ListManagerTabType.Ownership:
            selectedItems.push({
              symbol: symbol,
              msId: msIds[i]
            });
            break;
          default:
            break;
        }

      }
    }
    return selectedItems;
  }

  clearCopyValue() {
     _copySymTxt = null;
  }

  getCopyValue() {
    return _copySymTxt;
  }

  execCopySelectedSymbols(data) {
    let self = this;
    let copiedSymbols = [];
    let selectedItem = this.getSelectedItemForCopy(data.msIDS);
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    let symTxt;
    switch (tabType) {
      case ListManagerTabType.Oneil:
        each(selectedItem, (selItem) => {
          if (selItem == null) return;
          const snapShotDt = selItem.snapshotDt == null ? "" : selItem.snapshotDt.toString();
          copiedSymbols.push(`${selItem.symbol}  ${snapShotDt}`)
        });
        if (copiedSymbols.length > 0 && copiedSymbols.length <= _maxCopySymbols) {
          // copy the list object to the clipboard
          symTxt = copiedSymbols.join("\r\n");
          _copySymTxt = symTxt;
        }
        break;
      case ListManagerTabType.Ownership:
        if (selectedItem?.length && selectedItem.length > _maxOwnershipCopySymbols) {
          selectedItem = selectedItem.slice(0, _maxOwnershipCopySymbols)
        }
        each(selectedItem, (selItem) => {
          if (selItem == null) return;
          copiedSymbols.push(`${selItem.msId};${selItem.symbol}`)
        });
        if (copiedSymbols.length > 0 && copiedSymbols.length <= _maxOwnershipCopySymbols) {
          // copy the list object to the clipboard
          symTxt = copiedSymbols.join("\r\n");
          _copySymTxt = symTxt;
        }
        break;
      default:
        break;
    }
    if (BrowserUtil.getBrowserName() == "Chrome" || BrowserUtil.getBrowserName() == "Safari" || BrowserUtil.getBrowserName() == "Firefox"){
      navigator.clipboard.writeText(_copySymTxt)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch(err => {
        // This can happen if the user denies clipboard permissions:
        console.error('Could not copy text: ', err);
      });
    }else if (BrowserUtil.isIEBrowser()) {
      window.clipboardData.setData("Text", _copySymTxt);
    }
    _currentAction = null;
    _gridBodyAction = GridConstants.ActionTypes.COPY_GRID_ROWS;
    self.emit(CHANGE_EVENT);
  }

  verifySelectedSymbolsExceed(action = GridConstants.ActionTypes.COPY_GRID_ROWS) {
    const selectedTab = ListStore.getState().SelectedTabKey;
    const tabType = ListStore.getTabType(selectedTab);
    const _max_row_copy_count = tabType === ListManagerTabType.Ownership ? _maxOwnershipCopySymbols : _maxCopySymbols
    switch (action) {
      case GridConstants.ActionTypes.COPY_GRID_ROWS:
        if (_selectedIndexes[this.getSelectedNavTab()].length > _max_row_copy_count) {
          _limitExceedType = GridConstants.ActionTypes.COPY_GRID_ROWS;
          _displayWarning = true;
          this.emit(SELECT_LIMIT_EXCEED_EVENT);
        }
        break;
      case GridConstants.ActionTypes.UPDATE_GLOBAL_FLAG:
        if (_selectedIndexes[this.getSelectedNavTab()].length > 10000) {
          _limitExceedType = GridConstants.ActionTypes.UPDATE_GLOBAL_FLAG;
          _displayWarning = true;
          this.emit(SELECT_LIMIT_EXCEED_EVENT);
        }
        break;
      case GridConstants.ActionTypes.REMOVE_GRID_ROWS:
        if (_selectedIndexes[this.getSelectedNavTab()].length > 10000) {
          _limitExceedType = GridConstants.ActionTypes.REMOVE_GRID_ROWS;
          _displayWarning = true;
          this.emit(SELECT_LIMIT_EXCEED_EVENT);
        }
        break;
      default:
        if (_selectedIndexes[this.getSelectedNavTab()].length > _max_row_copy_count) {
          _limitExceedType = GridConstants.ActionTypes.COPY_GRID_ROWS;
          _displayWarning = true;
          this.emit(SELECT_LIMIT_EXCEED_EVENT);
        }
        break;
    }
  }

  isNullOrWhitespace(input) {
    if (typeof input === 'undefined' || input == null) return true;
    return !/\S/.test(input); // Does it fail to find a non-whitespace character?
  }

  isEditableGrid() {
    let viewStore = this.getViewStore();
    return viewStore.isListEditable();
  }
  getCurrentNode(nodeId) {
    let ParentListTreeItem = [];
    ListExplorerStore.findParentNode(nodeId, ParentListTreeItem);
    let currentNode = reject(ParentListTreeItem, (item) => item.childNodes.length > 0);
    return currentNode;
  }
  isScheduledList(nodeId = ListStore.getSelectedListItem().SelectedActualListId) {
    let currentNode = this.getCurrentNode(nodeId);
    if (currentNode.length > 0 && currentNode[0].categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory) {
      return true;
    }
    return false;
  }
  //separate

  CloseSymbolsSelectLimitExceedWarningDialog() {
    _limitExceedType = null;
    _displayWarning = false;
  }

  execPasteSelectedSymbols(data) {
    let viewStore = this.getViewStore();
    _isUndoable = false;
    let self = this;
    _refreshGrid = false;
    _undoPasteOperation = false;
    _cacheIdForPaste = data.cacheId;

    if (viewStore.isListEditable() == false && !this.isScheduledList())
      return;

    _currentAction = null;
    _gridBodyAction = GridConstants.ActionTypes.PASTE_GRID_ROWS;
    let symbolArr = this.getMultipleSymbolsFromClipboard(data.content);
    if (symbolArr == null || symbolArr.length == 0) {
      _alertMessage = LocalizationStore.getTranslatedData("LM_NoSymbolSelected", 'No Symbols are selected.');
      self.emit(CHANGE_EVENT);
      return;
    }

    let symbolList = [];
    let snapshotList = [];
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    switch (tabType) {
      case ListManagerTabType.Oneil:
        each(symbolArr, (item) => {
          let symbolValue = "";
          if (item.indexOf(";") != -1) {
            let symString = item.split(";");
            if(symString.length > 1){
              symbolValue = symString[1].trim();
            }
          }
          if(!symbolValue || symbolValue === ""){
            symbolValue = item;
          }
          if (symbolValue.indexOf("  ") != -1 || symbolValue.indexOf("+") != -1) {
            let delimeter = (symbolValue.indexOf("  ") != -1 ? ["  "] : (symbolValue.indexOf("+") != -1 ? ["+"] : []));
            let symString = symbolValue.split(delimeter[0]);
            if(symbolList.indexOf(symString[0].trim()) === -1){
              symbolList.push(symString[0].trim());
            }
            snapshotList.push(symString[1]);
          }
          else if (symbolValue != null || symbolValue != 'undefined' || symbolValue != '') {
            if(symbolList.indexOf(symbolValue.trim()) === -1){
              symbolList.push(symbolValue.trim());
            }
            snapshotList.push(null);
          }
        });

        if (symbolList.length > _maxCopySymbols) {
          _alertMessage = LocalizationStore.getTranslatedData("LM_NoSymbolSelected", 'Only {0} symbols may be selected for this operation.', _maxCopySymbols);
          _gridBodyAction = GridConstants.ActionTypes.PASTE_GRID_ROWS;
          self.emit(CHANGE_EVENT);
          return;
        }
        this.addSymbolsToList(symbolList, snapshotList, null, true);
        break;
      case ListManagerTabType.Ownership:
        let msIds = [];
        each(symbolArr, (item) => {
          if (item.indexOf(";") != -1) {
            let symString = item.split(";");
            const symStr = symString[0].trim().replace(/,/g,'')
            if(msIds.indexOf(symStr) === -1){
              msIds.push(symStr);
            }
          }
          else{
            const nitem = item.replace(/,/g,'');
            if(msIds.indexOf(nitem) === -1){
              msIds.push(nitem);
            }
          }
        });
        if (symbolArr.length > _maxCopySymbols) {
          _alertMessage = LocalizationStore.getTranslatedData("LM_NoSymbolSelected", 'Only {0} symbols may be selected for this operation.', _maxCopySymbols);
          _gridBodyAction = GridConstants.ActionTypes.PASTE_GRID_ROWS;
          self.emit(CHANGE_EVENT);
          return;
        }
        this.addSymbolsToList("", snapshotList, msIds, true);
        break;
      default:
        break;
    }

  }

  addSymbolsToList(symbolList, snapshotList, msIds = null, showMessage = true) {
    let selectedItem = ListStore.getSelectedListItem();
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    let symbolCount;
    switch (tabType) {
      case ListManagerTabType.Oneil:
        symbolCount = (symbolList != null) ? symbolList.length : 0;
        if (symbolCount == 0) return;

        if (symbolCount > _maxCopySymbols) {
          _alertMessage = LocalizationStore.getTranslatedData("LM_NoSymbolSelected", 'You are attempting to import {0} symbols. The maximum number of symbols allowed per import is {1}. If you would like to import more than {2} symbols, please copy and paste no more than {3} symbols at a time.', symbolCount, _maxCopySymbols, _maxCopySymbols, _maxCopySymbols);
          this.emit(CHANGE_EVENT);
          return;
        }
        this.addSymbolsToListForPaste(selectedItem.SelectedActualListId, selectedItem.SelectedListName, symbolList, null, snapshotList, symbolCount, showMessage);
        break;
      case ListManagerTabType.Ownership:
        symbolCount = (msIds != null) ? msIds.length : 0;
        if (symbolCount == 0) return;

        if (symbolCount > _maxCopySymbols) {
          _alertMessage = LocalizationStore.getTranslatedData("LM_NoSymbolSelected", 'You are attempting to import {0} symbols. The maximum number of symbols allowed per import is {1}. If you would like to import more than {2} symbols, please copy and paste no more than {3} symbols at a time.', symbolCount, _maxCopySymbols, _maxCopySymbols, _maxCopySymbols);
          this.emit(CHANGE_EVENT);
          return;
        }
        this.addSymbolsToListForPaste(selectedItem.SelectedActualListId, selectedItem.SelectedListName, symbolList, msIds, snapshotList, symbolCount, showMessage);
        break;
      default:
        break;
    }

  }

  addSymbolsToListForPaste(listId, listName, symbols, msIds, snapShots, sequenceNumber, showMessage = true, doRefreshList = true, includeDetails = false) {
    let outObj = {
      msidAsString: null,
      snapshotAsString: null,
      symbolsAsString: null
    };
    this.getAddInstrumnetsRequestStringBuilder(symbols, msIds, snapShots, outObj);
    let outObjBlkData = { bulkInsertData: null };
    this.getAddInstrumentsBulkRequest(outObj.msidAsString, outObj.snapshotAsString, outObj.symbolsAsString, outObjBlkData);
    let self = this;
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    let addSymbolsResultforPaste;
    switch (tabType) {
      case ListManagerTabType.Oneil:
        addSymbolsResultforPaste = ONeilViewStore.addSymbolsToListForPaste(listId, listName, _cacheIdForPaste, outObj, outObjBlkData, symbols, msIds, snapShots, sequenceNumber, showMessage, doRefreshList, includeDetails)
        break;
      case ListManagerTabType.Ownership:
        if (msIds.length === 1 && !this.isInputSymbolValid(msIds[0])) {
          _gridBodyAction = GridConstants.ActionTypes.ADD_SYMBOL_LIST_MANAGER;
          self.emit(CHANGE_EVENT);
          return;
        }
        addSymbolsResultforPaste = OwnershipViewStore.addSymbolsToListForPaste(listId, listName, _cacheIdForPaste, outObj, outObjBlkData, symbols, msIds, snapShots, sequenceNumber, /*showMessage,*/ doRefreshList, /*includeDetails*/)
        break;
      default:
        break;
    }
    try {
      addSymbolsResultforPaste.then((result) => {
        if (!result.error) {
          self.setDisplayAlertMessage(result.navMessage);
          _refreshGrid = result.navMessage.refreshGrid;
          if (tabType == ListManagerTabType.Oneil)
            ListExplorerStore.getListAlertStatus({ listId: listId });
          self.emit(CHANGE_EVENT);
        }
      })
    }
    catch (e) {
      console.log(e);
    }
  }

  isUndo() {
    return _isUndoable;
  }

  processAdd(addSymbolListData, listId, listName) {
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    let result;
    switch (tabType) {
      case ListManagerTabType.Oneil:
        result = ONeilViewStore.processAddServiceResult(addSymbolListData, listName);
        break;
      case ListManagerTabType.Ownership:
        result = OwnershipViewStore.processAddServiceResult(addSymbolListData, listId, listName);
        break;
      default:
        break;
    }
    _refreshGrid = result.refreshGrid;
    this.setDisplayAlertMessage(result);
    if (result.addSymbols.length > 0) {
      return result.addSymbols;
    }
    else {
      return null
    }
  }

  setDisplayAlertMessage(navMessage) {
    if (navMessage.showMessage) {
      ListExplorerStore.setAlertMessage(null);
      _alertMessage = navMessage.isUndoable ? navMessage.displayMessage : navMessage.rejectionMessage;
      _isUndoable = navMessage.isUndoable;
    }
     
  }

  isRefreshGrid() {
    return (_refreshGrid || _undoPasteOperation);
  }

  getAddInstrumnetsRequestStringBuilder(symbolList, msIds, snapShotDates = "", outObj) {
    let snpDateStringBuilder = "";
    let symbolStringBuilder = "";
    let self = this;
    if (symbolList != null) {
      for (let index = 0; index < symbolList.length; index++) {
        if (symbolList[index] != null || symbolList[index] != 'undefined' || symbolList[index] != '') {
          symbolStringBuilder = symbolStringBuilder.concat(symbolList[index]);
        }
        let lastIndex = index + 1;
        if (lastIndex == symbolList.length)
          continue;
        symbolStringBuilder = symbolStringBuilder.concat(";");
      }
    }
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    switch (tabType) {
      case ListManagerTabType.Oneil:
        if (snapShotDates != null) {
          for (let index = 0; index < snapShotDates.length; index++) {
            if (snapShotDates[index] != null) {
              snpDateStringBuilder = snpDateStringBuilder.concat(self.formatSnapshotDt(snapShotDates[index]));
            }
            let lastIndex = index + 1;
            if (lastIndex == snapShotDates.length)
              continue;

            snpDateStringBuilder = snpDateStringBuilder.concat(";");
          }
        }
        break;
      case ListManagerTabType.Ownership:
        snpDateStringBuilder = ""
        break;
      default:
        break;
    }

    outObj.msidAsString = "";
    if (msIds != null && msIds.length > 0) {

      if (msIds[msIds.length - 1] == 0)
        msIds.removeAt(msIds.length - 1);

      outObj.msidString = msIds.join(";");
    }
    outObj.symbolsAsString = symbolStringBuilder.toString();
    outObj.snapshotAsString = snpDateStringBuilder.toString();
  }


  getAddInstrumentsBulkRequest(msidAsString, snapshotAsString, symbolsAsString, outObjBlkData) {
    const InfoProto = BaseServiceApi.rayData["ListInstrumentInfoData"];
    let listInstrumentInfoData = new InfoProto();
    listInstrumentInfoData.symbols = symbolsAsString;
    listInstrumentInfoData.msIds = msidAsString;
    listInstrumentInfoData.snapshotDts = snapshotAsString;

    const ReqProto = BaseServiceApi.rayData["ListInstrumentsRequestData"];
    let listInstrumentsRequestData = new ReqProto();
    listInstrumentsRequestData.instrumentsInfo = listInstrumentInfoData;
    outObjBlkData.bulkInsertData = listInstrumentsRequestData.encode();
  }
  async getClipboardContents() {
    console.log("Store var content(!clipboard) ---", _copySymTxt);
    let clipBoardContent;
    if (BrowserUtil.getBrowserName() == "Chrome") {
      return navigator.permissions.query({
        name: 'clipboard-read', allowWithoutGesture: false
      }).then(async (permissionStatus) => {
        console.log("clipboard api response", permissionStatus);
        
        if (permissionStatus.state !== 'granted') {
          console.log('Clipboard permission denied');
          clipBoardContent = '';  //If Permission_Denied sending null
          return clipBoardContent;
        }

        try {
          clipBoardContent = await navigator.clipboard.readText();
          console.log("clipboard content ---", clipBoardContent);
          console.log("clipboard navigator obj", navigator);
          return clipBoardContent;
         } catch (err) {
          console.log('Failed to read clipboard contents ', err);
          clipBoardContent = '';
          return clipBoardContent;
        }
      });
    }
    else if (BrowserUtil.getBrowserName() == "Safari") {
      try {
        clipBoardContent = window.SafariClipboard;//e.clipboardData.getData('text/plain');
      } catch (err) {
        console.log('Failed to read clipboard contents: ');
      }
    }
    else if (BrowserUtil.getBrowserName() == "IE" || BrowserUtil.getBrowserName() == 'Netscape') {
      try {
        clipBoardContent = window.clipboardData.getData("Text");
      } catch (err) {
        console.log('Failed to read clipboard contents: ');
      }
    }
    else if (BrowserUtil.getBrowserName() == "EDGE") {
      if (StringUtil.isEmpty(_copySymTxt)) {
        clipBoardContent = "";
      }
      else
        clipBoardContent = _copySymTxt;
    }
    return clipBoardContent
  }
  displayAlertMessageforNull() {
    _gridBodyAction = GridConstants.ActionTypes.PASTE_GRID_ROWS;
    _isUndoable = false;
    _alertMessage = LocalizationStore.getTranslatedData('lp_mes_7', 'Symbol is invalid. Not added.');
    this.emit(CHANGE_EVENT);
  }

  displayAlertMessage() {  
    _gridBodyAction = GridConstants.ActionTypes.CLIPBOARD_DATA_ACCESS_DENIED;
    _isUndoable = false;
    _alertMessage = LocalizationStore.getTranslatedData('List_ccpd', 'Clipboard copy permission denied.');
    this.emit(CHANGE_EVENT);
  }

  getMultipleSymbolsFromClipboard(content) {
    let result = [];
    let clipboardData = null;
    // clipboardData = this.getCopyValue();
    // clipboardData = clipboardData ? clipboardData : content;
    clipboardData = content;

    if (clipboardData == null) {
      return result;
    }

    let itemIds = '';
    let idList = clipboardData.match(/[^\r\n]+/g);
    idList = this.removeNullEntry(idList);
    if (idList[0].includes(';')) {
      idList = idList.map((item) => {
        return item.split(';')[0] ? item.split(';')[0] : '';
      })
    }
    if (idList.length == 0) return result;

    if (idList.length == 1 && !idList[0].includes(',') && !this.isInputSymbolValid(idList[0])) {
      itemIds = clipboardData.trim().replace(/\s/g, ",");
      idList = itemIds.split(',');
      idList = this.removeNullEntry(idList);
      if (idList.length > 0) return result = idList.slice(0, 1);
    }

    if (this.isNullOrWhitespace(clipboardData) || clipboardData.trim().length == 0)
      return result;

    if (!this.isNullOrWhitespace(clipboardData)) {
      result = this.removeNullEntry(idList);
    }

    return result;
  }

  removeNullEntry(array) {
    array = array.map((item) => item.trim());
    return array.filter((item) => item);
  }

  pasteGridRowsUndo() {
    _undoPasteOperation = false;
    _refreshGrid = false;
    let self = this;
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    let pasteUndo;
    switch (tabType) {
      case ListManagerTabType.Oneil:
        pasteUndo = ONeilViewStore.pasteGridRowsUndo();
        break;
      case ListManagerTabType.Ownership:
        pasteUndo = OwnershipViewStore.pasteGridRowsUndo();
        break;
      default:
        break;
    }
    try {
      pasteUndo.then((result) => {
        _undoPasteOperation = result;
        _currentAction = null;
        _gridBodyAction = GridConstants.ActionTypes.PASTE_GRID_ROWS_UNDO;
        self.emit(CHANGE_EVENT);
      });
    }
    catch (e) {
      console.log(e);
    }
  }

  isAddSymbolEnableList() {
    let viewStore = this.getViewStore();

    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    if (tabType === ListManagerTabType.Oneil) {
      return (viewStore.isUserList() || viewStore.isSharedListWriteMode() || viewStore.isAPLIST() || ListExplorerStore.isUserListOrFavoriteUserList());
    }
    else {
      return (viewStore.isUserList() || isEditableList(viewStore.activeNode));
    }
  }

  isSharedList() {
    let viewStore = this.getViewStore();
    return viewStore.isSharedList();
  }

  isUserList() {
    let viewStore = this.getViewStore();
    return viewStore.isUserList();
  }


  isAPLIST() {
    let viewStore = this.getViewStore();
    return viewStore.isAPLIST();
  }

  getColumnsHeaderContextMenu(dataItemId) {
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    let viewStore = this.getViewStore();
    let column = viewStore.findColumn(dataItemId);
    let contextMenu = [];
    const listCategoryType = ListExplorerStore.activeNode && ListExplorerStore.activeNode.hasOwnProperty("categoryType") ? ListExplorerStore.activeNode.categoryType : null;
    const sourceListCategoryType = ListExplorerStore.activeNode && ListExplorerStore.activeNode.hasOwnProperty("sourceCategoryType") ? ListExplorerStore.activeNode.sourceCategoryType : null;
    const isDisableCREATE_CUSTOM_METRIC = listCategoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory || sourceListCategoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory ;

    if (dataItemId == -4 ) {
      if (dataItemId == -4 && this.isUserList()) {
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData("LM_SetNewCusOrd", ContextMenuConstants.SET_NEW_CUSTOM_ORDER), action: ContextMenuConstants.SET_NEW_CUSTOM_ORDER });
      }
      contextMenu.push({ contextName: LocalizationStore.getTranslatedData("LM_AutColWid", ContextMenuConstants.AUTO_SIZE_COLUMN_WIDTH), action: ContextMenuConstants.AUTO_SIZE_COLUMN_WIDTH });

      if (column) {
        if (column.canVisualize) contextMenu.push({ contextName: LocalizationStore.getTranslatedData("LM_ColVisualization", ContextMenuConstants.COLUMN_VISUALIZATION), action: ContextMenuConstants.COLUMN_VISUALIZATION });
      }
    }
    else if (dataItemId == -3) { // Flag
      let flagAllEnabled, flagNoneEnabled = true;
      switch (tabType) {
        case ListManagerTabType.Oneil:
            if (ONeilViewStore.getFlagCount() === ONeilViewStore.getSourceData().length) {
              flagAllEnabled = false, flagNoneEnabled = true;
            }
             if (ONeilViewStore.getFlagCount() == 0) {
              flagAllEnabled = true, flagNoneEnabled = false;
            }
            break;
            case ListManagerTabType.Ownership:
                if (OwnershipViewStore.getFlagCount() === OwnershipViewStore.getSourceData().length) {
                  flagAllEnabled = false, flagNoneEnabled = true;
                }
               if (OwnershipViewStore.getFlagCount() == 0) {
                  flagAllEnabled = true, flagNoneEnabled = false;
                }
              break;
            default:
              break;
          }
      contextMenu.push({ menuEnabled: flagNoneEnabled, contextName: LocalizationStore.getTranslatedData("LM_RemAllFla", ContextMenuConstants.REMOVE_ALL_FLAGS), action: ContextMenuConstants.REMOVE_ALL_FLAGS });
      contextMenu.push({ menuEnabled: flagAllEnabled, contextName: LocalizationStore.getTranslatedData("LM_FlaAllSym", ContextMenuConstants.FLAG_ALL_SYMBOLS), action: ContextMenuConstants.FLAG_ALL_SYMBOLS });
      contextMenu.push({ menuEnabled: true, contextName: LocalizationStore.getTranslatedData("LM_AutColWid", ContextMenuConstants.AUTO_SIZE_COLUMN_WIDTH), action: ContextMenuConstants.AUTO_SIZE_COLUMN_WIDTH });
    }
    else {
      contextMenu.push({ contextName: LocalizationStore.getTranslatedData("LM_AutColWid", ContextMenuConstants.AUTO_SIZE_COLUMN_WIDTH), action: ContextMenuConstants.AUTO_SIZE_COLUMN_WIDTH });

      if ((!this.getLocalSymbolColumnSet() || dataItemId != 4421) && dataItemId != 6300 && dataItemId != 6301 && dataItemId != -4000004  && dataItemId != -4000012 && dataItemId != -5 && dataItemId != -6) {
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData("LM_RemCol", ContextMenuConstants.REMOVE_COLUMN), action: ContextMenuConstants.REMOVE_COLUMN });
      }
      if ((!this.getLocalSymbolColumnSet() || dataItemId != 4421) && dataItemId != 6300 && dataItemId != 6301 && (dataItemId != -5 && dataItemId != -6)) {
        switch (tabType) {
          case ListManagerTabType.Oneil:
            let custColumn = viewStore.findCustomColumn(dataItemId);
            if (column.isCustomColumn && custColumn) {
              contextMenu.push({ contextName: LocalizationStore.getTranslatedData("cm_ecm", ContextMenuConstants.EDIT_CUSTOM_METRIC), action: ContextMenuConstants.EDIT_CUSTOM_METRIC });
            } else {
              if (!isDisableCREATE_CUSTOM_METRIC) {
                contextMenu.push({ contextName: LocalizationStore.getTranslatedData("cm_ccm", ContextMenuConstants.CREATE_CUSTOM_METRIC), action: ContextMenuConstants.CREATE_CUSTOM_METRIC });
              }
            }
            break;
          case ListManagerTabType.Ownership:
            break;
          default:
            break;
        }

      }
      if (column) {
        if (column.canVisualize) contextMenu.push({ contextName: LocalizationStore.getTranslatedData("LM_ColVisualization", ContextMenuConstants.COLUMN_VISUALIZATION), action: ContextMenuConstants.COLUMN_VISUALIZATION });
      }
      if (!column.isCustomColumn && dataItemId != -3 && dataItemId != 6300 && dataItemId != 6301) {
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData("MetDef_Menu", ContextMenuConstants.VIEW_METRIC_DEFINITION), action: ContextMenuConstants.VIEW_METRIC_DEFINITION });
      }
    }
    return contextMenu;
  }

  getLocalSymbolColumnSet() {
    if (!UserInfoUtil.hasLocalSymbol()) { return false; }

    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    let columnSet;
    let columnSetName;
    switch (tabType) {
      case ListManagerTabType.Oneil:
        columnSet = ONeilViewStore.getState().basic.columnSet;
        columnSetName = ONeilViewStore.getState().columnSetName;
        break;
      case ListManagerTabType.Ownership:
        columnSet = OwnershipViewStore.getState().basic.columnSet;
        columnSetName = OwnershipViewStore.getState().columnSetName;
        break;
      default:
        break;
    }


    if (columnSetName.indexOf("Local Symbol") != -1 &&
      !columnSet.canEdit) { return true; }

    return false;
  }

  getCustomFilterContextMenu() {
    let contextMenu = [];
    contextMenu.push({ contextName: LocalizationStore.getTranslatedData("ipc_abt", ContextMenuConstants.ABOUT), action: ContextMenuConstants.ABOUT });
    return contextMenu;
  }

  getFlagCell() {
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    let sourceData;
    switch (tabType) {
      case ListManagerTabType.Oneil:
        sourceData = ONeilViewStore.getSourceData();
        break;
      case ListManagerTabType.Ownership:
        sourceData = OwnershipViewStore.getSourceData();
        break;
      default:
        break;
    }
    let rowData = sourceData[_selectedRow.rowIndex];
    if (rowData) {
      return find(rowData['recordDatas'], (item) => item.dataItemId == -3);
    }
  }
  //Grid Drag and Drop

  getListFromMsIds(msIds) {
    let viewStore = this.getViewStore();
    let rowDatas = '';
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    switch (tabType) {
      case ListManagerTabType.Oneil:
        if (_allRowsSelected) {
          rowDatas = viewStore.getallMsidList();
        }
        else {
          rowDatas = viewStore.getCurrentListItemList();
        }
        break;
      case ListManagerTabType.Ownership:
        if (_allRowsSelected) {
          rowDatas = viewStore.getallMsidList();
        }
        else {
          rowDatas = viewStore.getCurrentListItemList();
        }
        break;
      default:
        break;
    }

    if (Array.isArray(msIds)) {
      let listsData = [];

      switch (tabType) {
        case ListManagerTabType.Oneil:
          each(rowDatas, (row) => {
            if (contains(msIds, row.msId.toString())) {
              listsData.push(row);
            }
          });
          break;
        case ListManagerTabType.Ownership:
          each(rowDatas, (row) => {
            if (contains(msIds, row.sponsorId.toString())) {
              listsData.push(row);
            }
          });
          break;
        default:
          break;
      }
      return listsData;
    }
    else {
      return find(rowDatas, (item) => item.msId.toString() === msIds.toString());
    }
  }

  async getSymbolAndName(sourceIndex) {
    if (sourceIndex.length > 0) {
      let viewStore = this.getViewStore();
      let currentLists = viewStore.getCurrentListItemList();
      let sourceMsids = await viewStore.getMsIds(sourceIndex);
      let selectedTab = ListStore.getState().SelectedTabKey;
      let tabType = ListStore.getTabType(selectedTab);
      let listToDisplay;
      switch (tabType) {
        case ListManagerTabType.Oneil:
          listToDisplay = find(currentLists, (listItem) => listItem.msId == sourceMsids);
          break;
        case ListManagerTabType.Ownership:
          listToDisplay = find(currentLists, (listItem) => listItem.sponsorId == sourceMsids);
          break;
        default:
          break;
      }
      return `${listToDisplay.symbol}   ${listToDisplay.coName}`;
    }
    return null;
  }

  //---> Draged inforamation
  async setDragInfo(sourceIndexs) {
    let viewStore = this.getViewStore();
    let sourceMsids = null;
    let sourceDragInfoData = null;
    sourceMsids = await viewStore.getMsIds(sourceIndexs);
    sourceDragInfoData = this.getListFromMsIds(sourceMsids.split(';'));
    _dragInfo = {
      sequenceNumber: viewStore.getSourceData() ? (viewStore.getSourceData()).length : 0,
      itemInfo: sourceDragInfoData
    }
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    switch (tabType) {
      case ListManagerTabType.Oneil:
        ONeilViewStore.setDragedObjectDataSource(_dragInfo.itemInfo);
        ListExplorerStore.sourceType = 'Grid';
        break;
      case ListManagerTabType.Ownership:
        OwnershipViewStore.setDragedObjectDataSource(_dragInfo.itemInfo);
        OwnershipViewStore.sourceType = 'Grid';
        break;
      default:
        break;
    }
  }

  //---> fetch dragged items information
  getDragInfo() {
    return _dragInfo;
  }
  //--> Execute Paste operation
  async execDropInListManager(data) {
    let viewStore = this.getViewStore();
    let destIndex = data.destIndex;
    let destMsid = await viewStore.getMsIds(destIndex);
    let args = [];
    let sourceDragInfoData = _dragInfo.itemInfo;
    let destDroppedInfoData = this.getListFromMsIds(destMsid.split(';'));
    _currentListItems = viewStore.getCurrentListItemList();
    args.push({ sourceItems: sourceDragInfoData });
    args.push(destDroppedInfoData);
    if (!this.listManagerDrop(args)) {
      _currentAction = null;
      _gridBodyAction = null;
      BrowserUtil.enableEvents();
    }
  }

  listManagerDrop(args) {
    let viewStore = this.getViewStore();
    let argsArray = args;
    if (argsArray == null || argsArray.length != 2)
      return;
    let dragInfo = argsArray[0];
    if (dragInfo == null || isEmpty(dragInfo)) {
      return;
    }

    let droppedItems = dragInfo.sourceItems;
    let draggedOverItem = argsArray[1];

    if (draggedOverItem == null || (droppedItems && droppedItems.length == 0) || droppedItems.containsAny(draggedOverItem)) { //|| (draggedOverItem.IsNewItemRow && this.CurrentListItems.Count > 1)
      return;
    }


    if (droppedItems.length > _maxCopySymbols) {
      _alertMessage = `Only ${_maxCopySymbols} symbols may be selected for this operation`;
      return;
    }
    let currentListItems = _currentListItems.slice(0);
    let startRemoveIndex = currentListItems.indexOf(droppedItems[0]);
    //return when drag the last some rows to the blank row, because it will not change the position of the items
    let selectedTab = ListStore.getState().SelectedTabKey;
          let tabType = ListStore.getTabType(selectedTab);
          switch (tabType) {
            case ListManagerTabType.Oneil:
              if (draggedOverItem[0].instrumentId == null && startRemoveIndex + droppedItems.length == currentListItems.indexOf(draggedOverItem[0])) {
                return;
              }
              break;
            case ListManagerTabType.Ownership:
              if (draggedOverItem[0].sponsorId == null && startRemoveIndex + droppedItems.length == currentListItems.indexOf(draggedOverItem[0])) {
                return;
              }
              break;
            default:
              break;
          }

    let aboveBelowIndex = 1; //(draggedOverItem.IsAbove.HasValue && draggedOverItem.IsAbove.Value) ? 0 : 1;
    let dropIndex = currentListItems.indexOf(draggedOverItem[0]) + aboveBelowIndex;
    if (this.reorderOrdinalNumbers(droppedItems, dropIndex)) {

      let withoutDropedElement = difference(currentListItems, droppedItems);
      _currentListItems = withoutDropedElement;
      let insertIndex = _currentListItems.indexOf(draggedOverItem[0]) + aboveBelowIndex;
      for (let index = droppedItems.length - 1; index >= 0; index--) {
        this.insertItemIntoCurrentList(index, droppedItems, insertIndex);
      }
      viewStore.updateDataSourceAfterDrop(_currentListItems);
      let indexs = [];
      for (let index = 0; index < droppedItems.length; index++) {
        indexs.push(insertIndex + index);
      }
      let self = this;
      _indexes = indexs;
      self.setDragDestInfo(null, null, false);
      _currentAction = null;
      _gridBodyAction = GridConstants.ActionTypes.DROP_IN_LIST_MANAGER;
      self.emit(CHANGE_EVENT);
      return true;
    }

  }

  insertItemIntoCurrentList(index, droppedItems, insertIndex) {
    _currentListItems.insert(insertIndex, droppedItems[index]);
  }

  reorderOrdinalNumbers(selectedList, destIndex, indexBeforeDrop = null) {
    let viewStore = this.getViewStore();
    let ordinalRange = "";
    let startRangeIndex = -1;
    let endRangeIndex = -1;
    let selectedItemIndex = 0;
    each(selectedList, (item) => {
      let singleItem;
      if(item.isOwnership){
        singleItem = _currentListItems.filter((i) => i.sponsorId == item.sponsorId);// && i.snapshotDt == item.snapshotDt);
      }
      else{
        singleItem = _currentListItems.filter((i) => i.instrumentId == item.instrumentId);
      }

      let currIndex = (indexBeforeDrop != null)
        ? (indexBeforeDrop + selectedItemIndex) :
        _currentListItems.indexOf(singleItem[0]) + 1;

      if (currIndex - endRangeIndex == 1) {
        if (endRangeIndex < 0)
          startRangeIndex = currIndex;

        endRangeIndex = currIndex;
      }
      else {
        if (startRangeIndex >= 0) {
          ordinalRange += `${startRangeIndex},${endRangeIndex}|`;
        }
        startRangeIndex = endRangeIndex = currIndex;
      }
      selectedItemIndex += 1;
    });
    if (startRangeIndex >= 0) {
      ordinalRange += `${startRangeIndex},${endRangeIndex}|`;
    }
    let selectedItem = ListStore.getSelectedListItem();
    return viewStore.reorderOrdinalNumbers(selectedItem.SelectedActualListId, ordinalRange.toString(), destIndex);
  }

  formatSnapshotDt(snapshotDt) {
    if (snapshotDt) return StringUtil.formatDate(snapshotDt, 'M/D/YYYY');
    else return null;
  }

  //Add New Symbol in Editable List
  addSymbol(symbol, listId, cacheId, listCount, listName, snapShot = "") {
    //let viewStore = this.getViewStore();
    let self = this;
    _refreshGrid = false;
    _gridBodyAction = GridConstants.ActionTypes.ADD_SYMBOL_LIST_MANAGER;
    if (symbol == null) {
      _gridBodyAction = GridConstants.ActionTypes.ADD_SYMBOL_LIST_MANAGER;
      self.emit(CHANGE_EVENT);
      return;
    }
    if (!this.isInputSymbolValid(symbol)) {
      _gridBodyAction = GridConstants.ActionTypes.ADD_SYMBOL_LIST_MANAGER;
      self.emit(CHANGE_EVENT);
      return;
    }
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    let addSymbolPromise;
    switch (tabType) {
      case ListManagerTabType.Oneil:
        addSymbolPromise = ONeilViewStore.addSymbol(symbol, listId, cacheId, listCount, listName, snapShot);
        break;
      case ListManagerTabType.Ownership:
        addSymbolPromise = OwnershipViewStore.addSymbol(symbol, listId, cacheId, listCount, listName, snapShot);
        break;
      default:
        break;
    }
    addSymbolPromise.then((result) => {
      //let addedSymbolData = result.data;
      if (!result.error) {
        _loadDataIfNORowReturn = result.loadDataIfNORowReturn;
        if (tabType == ListManagerTabType.Oneil)
          ListExplorerStore.getListAlertStatus({ listId: listId });
        self.setDisplayAlertMessage(result.navMessage);
        _refreshGrid = result.navMessage.refreshGrid;
        let listState = ListStore.getSelectedListItem();
        let source = tabType == ListManagerTabType.Oneil ? ONeilViewStore.getSourceData() : OwnershipViewStore.getSourceData();
        if (tabType === ListManagerTabType.Ownership) {
          const ownerState = OwnershipViewStore.getState();
          if (result.navMessage && result.navMessage.addSymbols && result.navMessage.addSymbols.length > 0) {
            result.navMessage.addSymbols.forEach((rowData) => {
              ownerState.allMsidList.push(rowData.msId);
            });
          }
        }
        ListActions.addSymbolComplete(source, listState);
      _gridBodyAction = GridConstants.ActionTypes.ADD_SYMBOL_LIST_MANAGER;
      }
      else{
        const alertMessage = this.OnSharePermissionChanged(result, listId);
        const navMessage = {
          rejectionMessage: alertMessage,
          displayMessage: "",
          showMessage: true,
          isUndoable: false
        };
        self.setDisplayAlertMessage(navMessage);
      }
      self.emit(CHANGE_EVENT);
    });
  }

  //test user input value for add Symbol operation
  isInputSymbolValid(symbol) {
    if (symbol == null) return false;
    let specialChars = new RegExp("^[a-zA-Z0-9]+$");
    let addSymbolRegex = new RegExp("^(,|[a-zA-Z0-9\\-!.*\\s/@#+% ,$&]+)$");
    if (!addSymbolRegex.test(symbol) || (symbol == null && symbol == '')
      || ((symbol.length == 1 && !specialChars.test(symbol)))) {
      let errorMessage =
      {
        rejectionMessage: LocalizationStore.getTranslatedData('lp_mes_7', "Symbol is invalid. Not added."),
        showMessage: true,
        isUndoable: false
      };
      this.setDisplayAlertMessage(errorMessage);
      return false;
    }

    return true;
  }

  getSecHeld(rowIndex){
    const dataOwnership = OwnershipViewStore.secHoldChekData;
    return rowIndex.some((rowItemIndex)=> dataOwnership[rowItemIndex] != undefined && dataOwnership[rowItemIndex]["-4000017"] == 0)
   }

  getRowContextMenu(rowIndex, instrumentType) {
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    // let sourceData;
    // switch (tabType) {
    //   case ListManagerTabType.Oneil:
    //     sourceData = ONeilViewStore.getSourceData();
    //     break;
    //   case ListManagerTabType.Ownership:
    //     sourceData = OwnershipViewStore.getSourceData();
    //     break;
    //   default:
    //     break;
    // }

    let contextMenu = [];
    let currentListData = ListExplorerStore.activeNode;
    let copyClassName = null, alertsClassName = null, selectAllClassName = null, flagClassName = null;
    const isFuturesCashSpotsList = instrumentType === InstrumentType.FUTURECONTRACTS_Instrument || instrumentType === InstrumentType.CASHSPOTS_Instrument;
    if (tabType === ListManagerTabType.Oneil) {
    if(currentListData.parentCategoryType === ListCategoryType.PRESET_ListCategory  && currentListData.categoryType === ListCategoryType.PRESET_ListCategory && currentListData.name === "CryptoCurrency") {
      copyClassName = 'contextMenuItemDisabled';
      alertsClassName = 'contextMenuItemDisabled';
      selectAllClassName = 'contextMenuItemDisabled';
      flagClassName = 'contextMenuItemDisabled';
    }

    if(currentListData.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory) {
      contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_TP_Export', "Export"), action: ExportExcelConstants.ActionTypes.EXPORT_EXCEL_SHOW_DIALOG });
      return contextMenu;
    }

    if (rowIndex != -1 && _selectedIndexes[this.getSelectedNavTab()].length == 1 && instrumentType && instrumentType != -1 && instrumentType != InstrumentType.MUTUALFUND_Instrument)
    {
      if ((currentListData.listCategory == 'Smart' || currentListData.listCategory == 'Favorites') && currentListData.name.includes('Active')) {
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData("mc_alts", ContextMenuConstants.EDIT_ALERT), action: ContextMenuConstants.EDIT_ALERT })
      }
      else if (!isFuturesCashSpotsList && !ONeilViewStore.showChart){
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData("ch_msa_sup_aca", ContextMenuConstants.AND_CONDITION_ALERTS), action: ContextMenuConstants.AND_CONDITION_ALERTS, className: alertsClassName })
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData("ch_msa_sup_oca", ContextMenuConstants.ALERT), action: ContextMenuConstants.ALERT, className: alertsClassName })
      }
    }
      if ((ONeilViewStore.isListEditable() || this.isScheduledList()) && rowIndex == -1 && !StringUtil.isEmpty(this.getCopyValue())) {
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_Paste', ContextMenuConstants.ROW_PASTE), action: ContextMenuConstants.ROW_PASTE });
        return contextMenu;
      }
      else if (rowIndex == -1) {  
          contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_Paste', ContextMenuConstants.ROW_PASTE), action: ContextMenuConstants.ROW_PASTE });
          return contextMenu;
        }
      if (_selectedIndexes[this.getSelectedNavTab()].length > ONeilViewStore.getSourceData().length && ONeilViewStore.getSelectedIndexesFlagCount() === ONeilViewStore.getSourceData().length)
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_RemFlag', ContextMenuConstants.ROW_REMOVE_FLAG), action: ContextMenuConstants.ROW_REMOVE_FLAG });
      else if (ONeilViewStore.getSelectedIndexesFlagCount() === _selectedIndexes[this.getSelectedNavTab()].length)
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_RemFlag', ContextMenuConstants.ROW_REMOVE_FLAG), action: ContextMenuConstants.ROW_REMOVE_FLAG });
      else
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_Flag', ContextMenuConstants.ROW_FLAG), action: ContextMenuConstants.ROW_FLAG, className: flagClassName });

      if (_currentAction == GridConstants.ActionTypes.SELECT_ALL_ROWS || ONeilViewStore.isSharedListWriteMode() || this.isScheduledList()) {
        if(!isFuturesCashSpotsList){
          contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_Copy', ContextMenuConstants.ROW_COPY), action: ContextMenuConstants.ROW_COPY, className: copyClassName });
        }
        if ((ONeilViewStore.isListEditable() || this.isScheduledList()) && !ONeilViewStore.showChart) {         
          if (!StringUtil.isEmpty(this.getCopyValue())) contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_Paste', ContextMenuConstants.ROW_PASTE), action: ContextMenuConstants.ROW_PASTE });
          contextMenu.push({ contextName: LocalizationStore.getTranslatedData('lp_remove', ContextMenuConstants.ROW_REMOVE), action: ContextMenuConstants.ROW_REMOVE });

          if (_selectedIndexes[this.getSelectedNavTab()].length < ONeilViewStore.getSourceData().length) {
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('ri_id46', ContextMenuConstants.ROW_SELECT_ALL), action: ContextMenuConstants.ROW_SELECT_ALL, className: selectAllClassName });
          }
        }
      }
      else {
        if(!isFuturesCashSpotsList)
          contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_Copy', ContextMenuConstants.ROW_COPY), action: ContextMenuConstants.ROW_COPY });
        if (ONeilViewStore.isListEditable() && !StringUtil.isEmpty(this.getCopyValue())) contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_Paste', ContextMenuConstants.ROW_PASTE), action: ContextMenuConstants.ROW_PASTE });
        if (_selectedIndexes[this.getSelectedNavTab()].length < ONeilViewStore.getSourceData().length) {
          contextMenu.push({ contextName: LocalizationStore.getTranslatedData('ri_id46', ContextMenuConstants.ROW_SELECT_ALL), action: ContextMenuConstants.ROW_SELECT_ALL, className: selectAllClassName });
        }
        if (ONeilViewStore.isListEditable()&& !ONeilViewStore.showChart) contextMenu.push({ contextName: LocalizationStore.getTranslatedData('lp_remove', ContextMenuConstants.ROW_REMOVE), action: ContextMenuConstants.ROW_REMOVE });
      }
}
    else {
      // if(!isFuturesCashSpotsList)
      //   contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_Copy', ContextMenuConstants.ROW_COPY), action: ContextMenuConstants.ROW_COPY, className: copyClassName });
      // if (ONeilViewStore.isListEditable() && !StringUtil.isEmpty(this.getCopyValue()))
      //   contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_Paste', ContextMenuConstants.ROW_PASTE), action: ContextMenuConstants.ROW_PASTE });
      // if(_selectedIndexes.length < ONeilViewStore.getSourceData().length)
      // {
      //   contextMenu.push({ contextName: LocalizationStore.getTranslatedData('ri_id46', ContextMenuConstants.ROW_SELECT_ALL), action: ContextMenuConstants.ROW_SELECT_ALL, className: selectAllClassName });
      // }

      // commented beacuse copy,paste and select all was showing two times for user list
      if (OwnershipViewStore.activeNode.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory) {
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_TP_Export', "Export"), action: ExportExcelConstants.ActionTypes.EXPORT_EXCEL_SHOW_DIALOG });
        return contextMenu;
      }
      if ((OwnershipViewStore.isListEditable() || OwnershipViewStore.isSharedListWriteMode()) && rowIndex == -1) {
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_Paste', ContextMenuConstants.ROW_PASTE), action: ContextMenuConstants.ROW_PASTE });
        return contextMenu;
      }
      else if (rowIndex == -1) {
        return contextMenu;
      }
      if (_selectedIndexes[this.getSelectedNavTab()].length > OwnershipViewStore.getSourceData().length && OwnershipViewStore.getSelectedIndexesFlagCount() === OwnershipViewStore.getSourceData().length)
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_RemFlag', ContextMenuConstants.ROW_REMOVE_FLAG), action: ContextMenuConstants.ROW_REMOVE_FLAG });
      else if (OwnershipViewStore.getSelectedIndexesFlagCount() === _selectedIndexes[this.getSelectedNavTab()].length)
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_RemFlag', ContextMenuConstants.ROW_REMOVE_FLAG), action: ContextMenuConstants.ROW_REMOVE_FLAG });
      else
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_Flag', ContextMenuConstants.ROW_FLAG), action: ContextMenuConstants.ROW_FLAG });

      if (_currentAction == GridConstants.ActionTypes.SELECT_ALL_ROWS || OwnershipViewStore.isSharedListWriteMode() || this.isScheduledList()) {
        if(!isFuturesCashSpotsList)
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_Copy', ContextMenuConstants.ROW_COPY), action: ContextMenuConstants.ROW_COPY });
        if (OwnershipViewStore.isListEditable() || this.isScheduledList()) {
          if (!StringUtil.isEmpty(this.getCopyValue())) contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_Paste', ContextMenuConstants.ROW_PASTE), action: ContextMenuConstants.ROW_PASTE });
          contextMenu.push({ contextName: LocalizationStore.getTranslatedData('lp_remove', ContextMenuConstants.ROW_REMOVE), action: ContextMenuConstants.ROW_REMOVE });
        }
      }
      else {
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_Copy', ContextMenuConstants.ROW_COPY), action: ContextMenuConstants.ROW_COPY });
        if ((OwnershipViewStore.isListEditable()) && !StringUtil.isEmpty(this.getCopyValue())) contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_Paste', ContextMenuConstants.ROW_PASTE), action: ContextMenuConstants.ROW_PASTE });

        if (_selectedIndexes[this.getSelectedNavTab()].length < OwnershipViewStore.getSourceData().length) {
          contextMenu.push({ contextName: LocalizationStore.getTranslatedData('ri_id46', ContextMenuConstants.ROW_SELECT_ALL), action: ContextMenuConstants.ROW_SELECT_ALL });
        }
        if (OwnershipViewStore.isListEditable()) contextMenu.push({ contextName: LocalizationStore.getTranslatedData('lp_remove', ContextMenuConstants.ROW_REMOVE), action: ContextMenuConstants.ROW_REMOVE });
      }
      if (!this.getSecHeld(rowIndex)) {
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_View_Constituent', ContextMenuConstants.ROTATION_GRAPH_VIEW_CONSTITUENTS), action: ContextMenuConstants.ROTATION_GRAPH_VIEW_CONSTITUENTS });
     }
    }
    contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_TP_Export', "Export"), action: ExportExcelConstants.ActionTypes.EXPORT_EXCEL_SHOW_DIALOG });
    return contextMenu;
  }

  setStreamingSymbols(symbols = null) {
    _streamingSymbols = symbols;
  }

  getStreamingSymbols() {
    return _streamingSymbols;
  }
  getStreamingSymbols() {
    return _streamingSymbols;
  }

  getNasdaqConnection() {
    return this.isNASDAQBasic;
  }

  getAllRowsSelectedStatus(){
    return _allRowsSelected;
  }

  addNewQuoteSubscription() {
    clearTimeout(this.RTTimeOut);
    if (WebSyncUtil.IsConnected) {
      if (_streamingSymbols) {
        WebSyncUtil.SubscribeSymbolsAsync(_streamingSymbols);
      }
    } else {
      this.RTTimeOut = setTimeout(() =>
        this.addNewQuoteSubscription(), 500);
    }
  }

  removeQuoteSubscription() {
    let QuoteSymbol = null;
    if (_streamingSymbols) {
      QuoteSymbol = _streamingSymbols;
    }
    if (QuoteSymbol) {
      WebSyncUtil.UnsubscribeSymbolsAsync(QuoteSymbol);
      _streamingSymbols = null;
    }
  }

  dispatcherCallback(payload) {
    const action = payload.action;
    const data = action.data;
    switch (action.actionType) {
      case GridConstants.ActionTypes.GET_HIGHLIGHTED_ROW:
        _currentAction = GridConstants.ActionTypes.GET_HIGHLIGHTED_ROW;
        _highlightedRow.index = data.index;
        _highlightedRow.highlight = data.highlight;
        _allRowsSelected = false;
        this.emit(CHANGE_EVENT);
        break;

      case GridConstants.ActionTypes.GET_SELECTED_ROW:
        _currentAction = GridConstants.ActionTypes.GET_SELECTED_ROW;
        _selectedRow.rowIndex = data.rowIndex;
        _selectedRow.visibleIndex = data.visibleIndex;
        _selectedRow.keyType = data.keyType;
        _allRowsSelected = false;
        this.emit(CHANGE_EVENT);
        break;

      case GridConstants.ActionTypes.SELECT_ALL_ROWS:
        _currentAction = GridConstants.ActionTypes.SELECT_ALL_ROWS;
        _allRowsSelected = true;
        this.emit(CHANGE_EVENT);
        break;

      case GridConstants.ActionTypes.ADD_COLUMN_TO_LIST:
      case GridConstants.ActionTypes.ADD_COLUMN_TO_LIST_FROM_METRIC:
      case GridConstants.ActionTypes.ADD_COLUMN_TO_LIST_UNDO:
        _currentAction = data.action;
        this.addColumnToList(data);
        if(action.actionType == GridConstants.ActionTypes.ADD_COLUMN_TO_LIST && !data.isFrozen)
          ONeilViewStore.populateSummaryView(true, true).then(()=>{
          ONeilViewStore.isRefreshView = false;
          SummaryStatStore.emitChange();
        })
        break;

      case GridConstants.ActionTypes.UPDATE_COL_SET_WIDTH:
        _gridBodyAction = GridConstants.ActionTypes.UPDATE_COL_SET_WIDTH;
        this.updateColSetWidth(data);
        break;

      case GridConstants.ActionTypes.REMOVE_COLUMN_TO_LIST:
        _currentAction = null;
        _gridBodyAction = GridConstants.ActionTypes.REMOVE_COLUMN_TO_LIST;
        this.removeColumnToList(data);
        break;

      case GridConstants.ActionTypes.UPDATE_GLOBAL_FLAG_ALL:
        this.updateGlobalFlagAll(data);
        break;

      case GridConstants.ActionTypes.UPDATE_GLOBAL_FLAG:
        _currentAction = GridConstants.ActionTypes.UPDATE_GLOBAL_FLAG;
        this.updateGlobalFlag(data);
        this.verifySelectedSymbolsExceed(GridConstants.ActionTypes.UPDATE_GLOBAL_FLAG);
        break;

      case GridConstants.ActionTypes.AUTO_SIZE_ALL_COLUMNS_WIDTH:
        _currentAction = GridConstants.ActionTypes.AUTO_SIZE_ALL_COLUMNS_WIDTH;
        this.emit(CHANGE_EVENT);
        break;

      case GridConstants.ActionTypes.SPLIT_FROZEN_COLUMNS:
        this.splitFrozenColumns(data);
        break;
      case GridConstants.ActionTypes.REMOVE_GRID_ROWS:
        _currentAction = null;
        _gridBodyAction = GridConstants.ActionTypes.REMOVE_GRID_ROWS;
        this.removeGridRows(data);
        this.verifySelectedSymbolsExceed(GridConstants.ActionTypes.REMOVE_GRID_ROWS);
        break;

      case GridConstants.ActionTypes.REMOVE_GRID_ROWS_UNDO:
        _currentAction = null;
        _gridBodyAction = GridConstants.ActionTypes.REMOVE_GRID_ROWS_UNDO;
        this.removeGridRowsUndo();
        break;

      case GridConstants.ActionTypes.COPY_GRID_ROWS:
        _currentAction = null;
        _gridBodyAction = GridConstants.ActionTypes.COPY_GRID_ROWS;
        this.execCopySelectedSymbols(data);
        this.verifySelectedSymbolsExceed(GridConstants.ActionTypes.COPY_GRID_ROWS);
        break;

      case GridConstants.ActionTypes.PASTE_GRID_ROWS:
        _currentAction = null;
        _gridBodyAction = GridConstants.ActionTypes.PASTE_GRID_ROWS;
        this.execPasteSelectedSymbols(data);
        break;

      case GridConstants.ActionTypes.PASTE_GRID_ROWS_UNDO:
        _currentAction = null;
        _gridBodyAction = GridConstants.ActionTypes.PASTE_GRID_ROWS_UNDO;
        this.pasteGridRowsUndo();
        break;

      case GridConstants.ActionTypes.DROP_IN_LIST_MANAGER:
        _currentAction = null;
        _gridBodyAction = GridConstants.ActionTypes.DROP_IN_LIST_MANAGER;
        this.execDropInListManager(data);
        break;

      case GridConstants.ActionTypes.ADD_SYMBOL_LIST_MANAGER:
        _gridBodyAction = GridConstants.ActionTypes.ADD_SYMBOL_LIST_MANAGER;
        this.addSymbol(data.symbol, data.listId, data.cacheId, data.listCount, data.listName);
        break;

      case GridConstants.ActionTypes.VISUALIZE_GRID_COLUMN:
        _gridBodyAction = GridConstants.ActionTypes.VISUALIZE_GRID_COLUMN;
        this.visualizeGridColumn(data);
        break;

      case GridConstants.ActionTypes.SHIFT_DOWN_SELECTION:
        _currentAction = GridConstants.ActionTypes.SHIFT_DOWN_SELECTION;
        this.emit(CHANGE_EVENT);
        break;

      case GridConstants.ActionTypes.SHIFT_UP_SELECTION:
        _currentAction = GridConstants.ActionTypes.SHIFT_UP_SELECTION;
        this.emit(CHANGE_EVENT);
        break;

      case GridConstants.ActionTypes.EDIT_CUSTOM_METRICS:
        _currentAction = GridConstants.ActionTypes.EDIT_CUSTOM_METRIC_SUCCESS;
        this.emit(CHANGE_EVENT);
        break;
      case GridConstants.ActionTypes.REMOVE_UNSHARED_LIST:
        if(ConsoleStore.getSettings().ActiveNav === NavType.NavSymbol){
          ONeilViewStore.listCatalog.listId = panarayDefault?.defaultListID;
          ONeilViewStore.listCatalog.actualListId = panarayDefault?.defaultListID;
          const settings = SettingsStore.getConsoleSettings();
          const tabONeilSettings = settings.NavListManagerSettings.TabONeilSettings;
          const defaultTabOneilSetting = new TabONeilSettings()
          tabONeilSettings.SelectedListInfo = defaultTabOneilSetting.getDefaultSelectedListInfo();
          ListActions.getListCatalog(ONeilViewStore.listCatalog, false);
        }else{
          ListExplorerStore.deleteUnShareList()
        }
        break;

      case GridConstants.ActionTypes.CLOSE_SYMBOLS_SELECT_LIMIT_EXCEED_MODAL:
        this.CloseSymbolsSelectLimitExceedWarningDialog();
        this.emit(SELECT_LIMIT_EXCEED_EVENT);
        break;

      case SettingsConstants.ActionTypes.DDE_STATUS_CHANGED:
        this.isNASDAQBasic = action.data.SelectedDDeConnectType === IntradaySourceType.IntradaySource_NASDAQ;
        _currentAction = SettingsConstants.ActionTypes.DDE_STATUS_CHANGED;
        if (this.isNASDAQBasic) {
          this.addNewQuoteSubscription();
        } else {
          this.removeQuoteSubscription();
        }
        this.emit(CHANGE_EVENT);
        break;

      default:
        return;
    }
  }
}
const gridStore = new GridStore();
export default gridStore;


Array.prototype.removeAt = function (iIndex) {
  let vItem = this[iIndex];
  if (vItem) {
    this.splice(iIndex, 1);
  }
  return vItem;
}

Array.prototype.containsAny = function (arr) {
  return this.some((v) => (arr.indexOf(v) >= 0))
}

Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};