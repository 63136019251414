
import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import SettingsStore from "SettingsStore";
import { SettingsConstants } from "../../Constants/SettingsConstants.js";
import NavType from "NavType";
import WebSyncUtil from "../../Utils/WebSyncUtil.js";
import UserInfoUtil from "UserInfoUtil";
import GraphApi from "../../ServiceApi/Apis/GraphApi.js";
import BaseServiceApi from "BaseServiceApi";
import DdeStatusType from "../../Constants/DdeStatusType.js";
import ResearchStore from "../NavModules/NavResearch/ResearchStore.js";
import { ConsoleConstants } from "Constants/ConsoleConstants.js";
import ClientSystemInfo from 'ClientSystemInfo';
import { usageTrackingWorker } from "WebWorker";
import ListExplorerStore from 'ListExplorerStore';
import LocalizationStore from 'LocalizationStore';
import Exceptions from "../../RayCustomControls/ExceptionWindow/Exceptions.js";
import { LogError } from "../../ServiceApi/Apis/LogErrorAPI.js";
import ConsoleApi from "../../ServiceApi/Apis/ConsoleApi.js";
import { debounce, findWhere, without } from "underscore";
import SmartViewType from "../../Constants/SmartViewType";
import PrintStatus from "../../Constants/PrintStatus";
// import ApiWCF from "../../ServiceApi/BaseServiceApiWCF";
import ThemeType from "ThemeType";
import DatagraphStore from "../NavModules/NavDataGraph/DataGraphStore";
import UserApi from "../../ServiceApi/Apis/UserApi.js";
import SettingsActions from "../../Actions/SettingsActions.js";
import { dispatch } from "../../Redux/dispatch.js";
import { PrintMode } from "../../print/printmode.js";
import { setCurrencyData, setESCurrencyData, setInitCurrencySetting } from "../../Actions/CurrencyActions.js";
import { initSettingsToReducer } from "../../Actions/DatagraphActions.js";
import ReduxState from "../../Redux/store.js";

var EntitlementType = BaseServiceApi.rayData.EntitlementType;
var IntradaySourceType = BaseServiceApi.rayData.IntradaySourceType;

const CHANGE_EVENT = "change";
let _consoleSettings = null;
let _states = {
  IsFullScreen: false,
  metricDefinition: undefined,
  endDate: null,
  beginDate: null,
  restMetricDefinition: undefined,
  currency: undefined,
  isHistoric: false,
  currentVersion: "",
  isTabChanged: false,
  isRoutToGraph: false,
  firstLoadedTab: '',
  isPricePanelApiInitiated: false,
  isDGApiCompleted: false,
  isPrintError: false,
  printErrorMsg: ''
};
let _toolTip = {};
let _currentAction = null;
let _defaultSetting = null;
let _supportPhNumber = "(310)448-6199";
let _defaultErrorMsg = `An unexpected error has occurred. If you would like to receive technical support, please call ${_supportPhNumber}`;
let _isSupportDialogOpen = false;
let _isLiveSupportDialogOpen = false;
let _showPrintStatus = true;
let _printClicked = false;
let _showPopUpblockerAlert = false;
let errorObj = {
  headerText: "Error Report",
  exceptionHandleMsg: LocalizationStore.getTranslatedData("er_report_02", _defaultErrorMsg),
  errorReport: null,
  isCustomError: false,
  exceptionSessionExpired: false,
  isDisableWarningSymbolClick: false,
}
let _printJobs = [];
let _printServerUrl = null;
let _isLogin = false;

class ConsoleStore extends EventEmitter {
  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
    this.printStatusTimer = null;
    this.infoList = {};
    this.kpiList = [];
  }

  setSessionExpiredPopupStatus(status) {
    window.sessionStorage.setItem('displaySessionExpiredPopup', status);
  }

  getSessionExpiredPopupStatus() {
    return window.sessionStorage.getItem('displaySessionExpiredPopup');
  }

  removeSessionExpiredPopup() {
    window.sessionStorage.removeItem('displaySessionExpiredPopup');
  }

  getState() {
    return _states;
  }

  getMetricDefinition() {
    return _states.metricDefinition;
  }

  getCurrency() {
    return _states.currency;
  }

  getRestMetricDefinition() {
    return _states.restMetricDefinition;
  }

  getDefaultSetting() {
    if (!_defaultSetting) {
      _defaultSetting = SettingsStore.getDefaultSettingForUser();
    }
    return _defaultSetting;
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getSettings() {
    return _consoleSettings;
  }

  getStates() {
    if (_consoleSettings) {
      _states = {
        IsActiveNavChanged: _consoleSettings.ActiveNav !== _states.ActiveNav,
        ActiveNav: _consoleSettings.ActiveNav,
        Name: _toolTip.Name ? _toolTip.Name : LocalizationStore.getTranslatedData('sc_ct', "CONNECTION STATUS"),
        Desc: _toolTip.Desc ? _toolTip.Desc : LocalizationStore.getTranslatedData('sc_qdelay', "QUOTES DELAYED"),
        Status: _toolTip.Status ? _toolTip.Status : LocalizationStore.getTranslatedData('disconnected', DdeStatusType.NOT_CONNECTED),
        BrushColor: _toolTip.BrushColor,
        QuoteStatusGrid: _toolTip.QuoteStatusGrid ? _toolTip.QuoteStatusGrid : _consoleSettings.NavDatagraphSettings.PreferenceSettings.QuoteServiceSettings.SelectedQuoteStatusGrid ? _consoleSettings.NavDatagraphSettings.PreferenceSettings.QuoteServiceSettings.SelectedQuoteStatusGrid : "icn-red-dot",
        IsPop: _toolTip.IsPop,
        SelectQuoteServiceOption: _consoleSettings.NavDatagraphSettings ? _consoleSettings.NavDatagraphSettings.PreferenceSettings.QuoteServiceSettings.SelectQuoteServiceOption : this.getDefaultSetting().NavDatagraphSettings.PreferenceSettings.QuoteServiceSettings.SelectQuoteServiceOption,
        IsResearchEnabled: UserInfoUtil.IsUserEntitled(EntitlementType.RAY_RESEARCHVIEW_Entitlement),
        IsNewsEnabled: UserInfoUtil.IsUserEntitled(EntitlementType.RAY_NEWSVIEW_Entitlement),
        isAccountAnalyzerEnabled: UserInfoUtil.IsUserEntitled(EntitlementType.RAY_ACCOUNT_ANALYZER_VIEW_Entitlement),
        IsComparisionChartEnabled: UserInfoUtil.IsUserEntitled(EntitlementType.RAY_COMPARISION_CHART_Entitlement),
        metricDefinition: _states.metricDefinition,
        endDate: _states.endDate,
        beginDate: _states.beginDate,
        restMetricDefinition: _states.restMetricDefinition,
        currency: _states.currency,
        userEndDate: _states.userEndDate,
        PrintJobs: _printJobs,
        showPrintStatus: _showPrintStatus,
        showPopUpBlockerAlert: _showPopUpblockerAlert,
        printClicked: _printClicked,
        printServerUrl: _printServerUrl,
        isHistoric: _states.isHistoric,
        currentVersion: _states.currentVersion,
        isTabChanged: _states.isTabChanged,
        isRoutToGraph: _states.isRoutToGraph,
        firstLoadedTab: _states.firstLoadedTab,
        nodeCount: _states.nodeCount,
        isPricePanelApiInitiated: _states.isPricePanelApiInitiated,
        isDGApiCompleted: _states.isDGApiCompleted,
        onSwitchToSymbolTab: _states.onSwitchToSymbolTab,
        isPrintError: _states.isPrintError,
        printErrorMsg: _states.printErrorMsg
      }
    }
    return _states;
  }

  plotSymbol(symbol, navType = NavType.NavSymbol) {
    if (_consoleSettings.ActiveNav !== navType) {
      _states.IsActiveNavChanged = true;
      localStorage.setItem("_activeNav", navType);
      _consoleSettings.ActiveNav = navType;
      _consoleSettings.NavDatagraphSettings.symbol = symbol;
      this.emit(CHANGE_EVENT);
    }
  }

  UpdateRout(symbol, navType) {
    _states.IsActiveNavChanged = true;
    localStorage.setItem("_activeNav", navType);
    _consoleSettings.ActiveNav = navType;
    _states.ActiveNav = navType;
    if (navType === NavType.NavSymbol)
      _consoleSettings.NavDatagraphSettings.symbol = symbol;
    this.emit(CHANGE_EVENT);
  }

  setToolTipMsg(data) {
    _toolTip = data;
  }

  getSymbolFromPath(path) {
    let symbol = '';
    if (/^\/Symbol(\/{1})\w*/i.test(path)) {
      let pathArr = path.split('/');
      if (pathArr.length > 1) {
        symbol = '';
        pathArr.map((p, i) => { if (i > 1 && (i == pathArr.length - 1)) symbol += p; else if (i > 1) symbol += p + "/" })
      }
    }
    if (!symbol) symbol = _consoleSettings.NavDatagraphSettings.symbol;
    return symbol;
  }

  getSymbolUrl(symbol) {
    return NavType.NavSymbol + "/" + symbol;
  }

  createRtSubscriber() {
    if (UserInfoUtil.getUserInfo()) {
      let entitlements = UserInfoUtil.getUserInfo().entitlements;
      if (!WebSyncUtil.IsConnected) {
        GraphApi.GetLiveStreamUrlRequest(IntradaySourceType.IntradaySource_NASDAQ).then((result) => {
          if (entitlements.includes(EntitlementType.NASDAQ_BASIC_WEB_Entitlement)) {
            WebSyncUtil.CreateClient(result.liveStreamRequestData.liveStreamUrl45);
          }
          ResearchStore.updateUrl(result.liveStreamRequestData.researchPageUrl);
        });
      }
    }
  }

  setExceptionHandleMsg(data) {
    const headerText = data.header.toLowerCase() === "error report" ? LocalizationStore.getTranslatedData("er_report", data.header) : data.header;
    errorObj = {
      headerText: headerText,
      exceptionHandleMsg: data.error ? data.error : LocalizationStore.getTranslatedData("er_report_02", _defaultErrorMsg),
      errorReport: data.errorReport,
      isCustomError: data.isCustomError,
      exceptionSessionExpired: data.sessionExpired,
      isDisableWarningSymbolClick: data.error ? true : false,
      isProd: data.isProd
    }
    _currentAction = ConsoleConstants.ActionTypes.HANDLE_EXCEPTION;
    const jsonBody = Exceptions.getEmailBody(true);
    LogError('/errorlog', JSON.stringify(jsonBody));
    this.emit(CHANGE_EVENT);
  }

  setPrintErrorHandleMsg({ errorReport }) {
    _currentAction = ConsoleConstants.ActionTypes.HANDLE_PRINT_ERROR_PAGE;
    _states.printErrorMsg = errorReport;
    _states.isPrintError = true;
    this.emit(CHANGE_EVENT);
  }

  getExceptionErrorObject() {
    return errorObj;
  }

  startPrint(symbols, listName, symbolType, emailData, endDate, printType, periodicity, listId, searchKeyword) {
    let self = this;
    console.log("Called for Print");
    let theme = _consoleSettings.currentTheme == ThemeType.Dark ? "Dark" : "Light";
    let eDate = endDate || this.getUserEndDate();
    let endDateStr = !eDate ? "" : eDate.getDate() + '/' + (eDate.getMonth() + 1) + '/' + eDate.getFullYear();
    const reduxState = ReduxState.getState();
    const headerState = reduxState.DatagraphReducers.HeaderReducer;
    let headerSettings = { EpsState: headerState.showEPSDate, IpoState: headerState.showIPODate };
    let settings = { ..._consoleSettings.NavDatagraphSettings.TabDataGraphSettings, ListName: listName, Theme: theme, EndDate: endDateStr, AppColorSettings: _consoleSettings.AppColorSettings };
    let symbolInfo = DatagraphStore.getState().SymbolInfo;
    if (symbolType && symbolInfo) {
      if (symbolType == SmartViewType.STOCK) {
        settings = { Periodicity: _consoleSettings.NavDatagraphSettings.TabDataGraphSettings.Periodicity, StockViewSettings: { ..._consoleSettings.NavDatagraphSettings.TabDataGraphSettings.StockViewSettings }, ListName: listName, Theme: theme, EndDate: endDateStr, AppColorSettings: _consoleSettings.AppColorSettings };
        settings.StockViewSettings.HeaderSettings = headerSettings;
      }
      else if (symbolType == SmartViewType.FUND)
        settings = { Periodicity: _consoleSettings.NavDatagraphSettings.TabDataGraphSettings.Periodicity, FUNDViewSettings: { ..._consoleSettings.NavDatagraphSettings.TabDataGraphSettings.FUNDViewSettings }, ListName: listName, Theme: theme, EndDate: endDateStr, AppColorSettings: _consoleSettings.AppColorSettings };
      else if (symbolType == SmartViewType.CEF)
        settings = { Periodicity: _consoleSettings.NavDatagraphSettings.TabDataGraphSettings.Periodicity, CEFViewSettings: { ..._consoleSettings.NavDatagraphSettings.TabDataGraphSettings.CEFViewSettings }, ListName: listName, Theme: theme, EndDate: endDateStr, AppColorSettings: _consoleSettings.AppColorSettings };
      else if (symbolType == SmartViewType.INDEX)
        settings = { Periodicity: _consoleSettings.NavDatagraphSettings.TabDataGraphSettings.Periodicity, IndexViewSettings: { ..._consoleSettings.NavDatagraphSettings.TabDataGraphSettings.IndexViewSettings }, ListName: listName, Theme: theme, EndDate: endDateStr, AppColorSettings: _consoleSettings.AppColorSettings };
      else if (symbolType == SmartViewType.ETF)
        settings = { Periodicity: _consoleSettings.NavDatagraphSettings.TabDataGraphSettings.Periodicity, ETFViewSettings: { ..._consoleSettings.NavDatagraphSettings.TabDataGraphSettings.ETFViewSettings }, ListName: listName, Theme: theme, EndDate: endDateStr, AppColorSettings: _consoleSettings.AppColorSettings };
      else if (symbolType == SmartViewType.CURRENCY)
        settings = { Periodicity: _consoleSettings.NavDatagraphSettings.TabDataGraphSettings.Periodicity, CurrencyViewSettings: { ..._consoleSettings.NavDatagraphSettings.TabDataGraphSettings.CurrencyViewSettings }, ListName: listName, Theme: theme, EndDate: endDateStr, AppColorSettings: _consoleSettings.AppColorSettings };
      else if (symbolType == SmartViewType.INDUSTRYGROUP)
        settings = { Periodicity: _consoleSettings.NavDatagraphSettings.TabDataGraphSettings.Periodicity, IndustryGroupViewSettings: { ..._consoleSettings.NavDatagraphSettings.TabDataGraphSettings.IndustryGroupViewSettings }, ListName: listName, Theme: theme, EndDate: endDateStr, AppColorSettings: _consoleSettings.AppColorSettings };
      else if (symbolType == SmartViewType.FUTURES) {
        settings = { Periodicity: _consoleSettings.NavDatagraphSettings.TabDataGraphSettings.Periodicity, FuturesViewSettings: { ..._consoleSettings.NavDatagraphSettings.TabDataGraphSettings.FuturesViewSettings }, ListName: listName, Theme: theme, EndDate: endDateStr, AppColorSettings: _consoleSettings.AppColorSettings };
        settings.FuturesViewSettings.HeaderSettings = { ExpiryState: headerState.ExpiryState };
      }
      else if (symbolType == SmartViewType.CASHSPOTS)
        settings = { Periodicity: _consoleSettings.NavDatagraphSettings.TabDataGraphSettings.Periodicity, FuturesViewSettings: { ..._consoleSettings.NavDatagraphSettings.TabDataGraphSettings.CashSpotsViewSettings }, ListName: listName, Theme: theme, EndDate: endDateStr, AppColorSettings: _consoleSettings.AppColorSettings };
      else
        settings = { Periodicity: _consoleSettings.NavDatagraphSettings.TabDataGraphSettings.Periodicity, StockViewSettings: { ..._consoleSettings.NavDatagraphSettings.TabDataGraphSettings.StockViewSettings }, ListName: listName, Theme: theme, EndDate: endDateStr, AppColorSettings: _consoleSettings.AppColorSettings };

      settings = { ...settings, CountryCode: symbolInfo.CountryCode, ContryCodeIso: symbolInfo.CountryCodeIso };
    }
    else {
      settings.StockViewSettings = { ...settings.StockViewSettings, HeaderSettings: headerSettings };
    }
    ConsoleApi.startPrint(symbols[0], printType, periodicity, listId, searchKeyword).then((result) => {
      if (!emailData) {
        // var dataFromServer = JSON.stringify(result)
        console.log("Checking the error list :", result["response"]["requestid"]);
        _showPrintStatus = true;
        _printJobs.push({
          Symbol: listName,
          PrintRequestId: result["response"]["requestid"],
          Progress: 0,
          Status: 1,
          Pages: self.getStatusText("-"),
          PrintDocument: "-",
          Symbols: symbols,
          curProgress: 0,
          errorList: null
        });
        _printClicked = true;
        this.emit(CHANGE_EVENT);

        console.log('Started print, request id is -' + result["response"]["requestid"]);

        this.getPrintStatus(result["response"]["requestid"]);
      }
      else {
        console.log('Email request submitted, id is - ' + result["response"]["requestid"]);
      }
    }, (error) => {
      console.log(error);
    });
  }

  setPrintAlertClose() {
    _printClicked = false;
    this.emit(CHANGE_EVENT);
  }
  cancelPrint(printRequestId) {
    let self = this;
    ConsoleApi.cancelPrint(printRequestId).then((res) => {
      var result = JSON.parse(res.response.status);
      console.log(result)
      if (result.Status == PrintStatus.Cancelled) {
        _printJobs = without(_printJobs, findWhere(_printJobs, {
          PrintRequestId: result.PrintRequestId
        }));
      }
      else {
        let pntJob = _printJobs.find((job) => job.PrintRequestId === result.PrintRequestId);
        pntJob.Pages = self.getStatusText(result.Status);
        pntJob.Status = result.Status;
      }

      this.emit(CHANGE_EVENT);
    }, (error) => {
      console.log(error);
    });
  }

  //TODO: Lang Translation
  getStatusText(printStatus) {
    switch (printStatus) {
      case PrintStatus.Queued:
        return LocalizationStore.getTranslatedData("tp_pt_queued", 'Queued');
      case PrintStatus.InProcess:
        return LocalizationStore.getTranslatedData("tp_pt_process", 'In Process');
      case PrintStatus.Error:
        return LocalizationStore.getTranslatedData("tp_pt_err", 'Error');
      case PrintStatus.Cancelled:
        return LocalizationStore.getTranslatedData("tp_pt_cancelled", 'Cancelled');
      case PrintStatus.Unknown:
        return LocalizationStore.getTranslatedData("tp_pt_statusN/A", 'Status N/A');
      case PrintStatus.CompletedWithError:
        return 'Completed with Error';
      default:
        return LocalizationStore.getTranslatedData("tp_pt_process", 'In Process');
    }
  }

  closePrintStatus(isOpen) {
    _showPrintStatus = isOpen;
    // _printJobs = without(_printJobs, findWhere(_printJobs, {
    //   Status: PrintStatus.Error
    // }));
    this.emit(CHANGE_EVENT);
  }

  togglePopUpAlert(isOpen) {
    _showPopUpblockerAlert = isOpen;
    this.emit(CHANGE_EVENT);
  }

  getPrintStatus(requestId) {
    let self = this;
    let pntJob = _printJobs.find((job) => job.PrintRequestId === requestId);
    if (pntJob) {
      ConsoleApi.getPrintStatus(requestId).then((result) => {
        var statusResult = JSON.parse(result)
        if (statusResult) {
          if (statusResult.Progress == undefined) {
            statusResult.Progress = 0
          }
          console.log("Print Reply from server..", statusResult)
          console.log('Progress of the print - ' + statusResult.PrintRequestId + ' - ' + statusResult.Progress);

          if (statusResult.Status !== PrintStatus.Complete || statusResult.Status !== PrintStatus.CompletedWithError) {
            this.updateProgressBar(pntJob, statusResult);
          }

          if (statusResult.Status !== PrintStatus.Complete && statusResult.Status !== PrintStatus.Cancelled && statusResult.Status !== PrintStatus.Error && statusResult.Status !== PrintStatus.CompletedWithError) {
            setTimeout(() => self.getPrintStatus(statusResult.PrintRequestId), 1000);
          }
          else if (statusResult.Status === PrintStatus.Cancelled) {
            console.log("Print cancelled by user");
            _printJobs = without(_printJobs, findWhere(_printJobs, {
              PrintRequestId: statusResult.PrintRequestId
            }));
            this.emit(CHANGE_EVENT);
          }
          else if (statusResult.Status === PrintStatus.Error) {
            _showPrintStatus = true;
            if (pntJob.Symbols.length == 1) {
              pntJob.Progress = statusResult.Progress;
              pntJob.Status = statusResult.Status;
              pntJob.Pages = self.getStatusText(statusResult.Status);
            }
            else {
              pntJob.Progress = statusResult.Progress;
              pntJob.Status = statusResult.Status;
              pntJob.Pages = self.getStatusText(statusResult.Status);
              pntJob.ErroredPages = statusResult.ErroredPages;

              this.showPrintFile(statusResult.PrintDocument, statusResult.PrintRequestId);
            }
            this.emit(CHANGE_EVENT);
          }
          else if (statusResult.Status === PrintStatus.CompletedWithError) {
            _showPrintStatus = true;
            if (pntJob.Symbols.length == 1) {
              pntJob.Progress = statusResult.Progress;
              pntJob.Status = statusResult.Status;
              pntJob.Pages = self.getStatusText(statusResult.Status);
            }
            else {
              pntJob.Progress = statusResult.Progress;
              pntJob.Status = statusResult.Status;
              pntJob.Pages = self.getStatusText(statusResult.Status);
              pntJob.ErroredPages = statusResult.ErroredPages;
              pntJob.errorList = statusResult.ErrorList;
              this.updateProgressBar(pntJob, statusResult);
              this.showPrintFile(statusResult.PrintDocument, statusResult.PrintRequestId);
            }
            this.emit(CHANGE_EVENT);
          }
          else {
            console.log("Printing completed");
            pntJob.errorList = statusResult.ErrorList
            this.updateProgressBar(pntJob, statusResult);

            setTimeout(() => {
              _printJobs = without(_printJobs, findWhere(_printJobs, {
                PrintRequestId: statusResult.PrintRequestId
              }));
              this.showPrintFile(statusResult.PrintDocument, statusResult.PrintRequestId);
              // if (!pntJob.errorList){
              this.emit(CHANGE_EVENT);
              // }
            }, 500);

          }
        }
        else {
          console.log("Print Status Found null will check status after 5 secs "+ requestId)
          setTimeout(() => self.getPrintStatus(requestId), 5000);
        }

      }, (error) => {
        if (error.status === 0 || error.status === 504) {
          setTimeout(() => self.getPrintStatus(pntJob.PrintRequestId), 5000);
        }
      });
    }
  }

  updateProgressBar(pntJob, statusResult) {
    pntJob.Progress = statusResult.Progress !== 100 ? pntJob.curProgress : statusResult.Progress;
    pntJob.Status = statusResult.Status;
    pntJob.Pages = statusResult.Progress == 0 ? this.getStatusText(statusResult.Status) : (statusResult.Progress >= 95 ? LocalizationStore.getTranslatedData("tp_pt_creat", "Creating...") : statusResult.Pages);
    if (pntJob.Status === PrintStatus.CompletedWithError) {
      pntJob.Pages = "Completed with errors"
    }
    this.emit(CHANGE_EVENT);

    if (statusResult.Progress <= 95) {
      if (statusResult.Progress <= 60 && pntJob.curProgress <= statusResult.Progress) {
        if (pntJob.curProgress >= 0 && pntJob.curProgress < 20) {
          pntJob.curProgress += 10;
        }
        else if (pntJob.curProgress >= 20 && pntJob.curProgress < 40) {
          pntJob.curProgress += 10;
        }
        else if (pntJob.curProgress >= 40 && pntJob.curProgress < 60) {
          pntJob.curProgress += 3;
        }
      }
      else if (statusResult.Progress >= 60 && pntJob.curProgress < 90) {
        if (pntJob.curProgress >= 60 && pntJob.curProgress < 80) {
          pntJob.curProgress += 10;
        }
        else if (pntJob.curProgress >= 80 && pntJob.curProgress <= 90) {
          pntJob.curProgress += 10;
        }
      }
    }
    else {
      pntJob.curProgress = statusResult.Progress;
    }
  }

  showPrintFile(pdfFilePath, printId = "") {
    // let serverName = 'http://localhost:5000';
    // let serverName = 'https://stageprint.panaray.com/staticfileserver';
    // let serverName = 'http://iis.stage.panaray.com/printfiles/';
    let serverName = BaseServiceApi.getServerName()
    let location = `https://${serverName}/rayweb/g/i/download?type=inline&path=/opt/panaray/Commentary/printfiles/`;
    let win = window.open(location + "/" + pdfFilePath)
    console.log(printId)
    if (!win) {
      let isIE = false || !!document.documentMode;
      if (!isIE)
        this.togglePopUpAlert(true);
      let self = this;
      setTimeout(() => self.showPrintFile(pdfFilePath, isIE), 3000);
    }
  }

  toggleSupportDialog(isOpen) {
    _isSupportDialogOpen = isOpen;
  }

  toggleLiveSupportDialog(isOpen) {
    _isLiveSupportDialogOpen = isOpen;
  }

  getSupportDialogOpen() {
    return _isSupportDialogOpen;
  }

  getLiveSupportDialogOpen() {
    return _isLiveSupportDialogOpen;
  }

  getExceptionSessionExpired() {
    return errorObj.exceptionSessionExpired;
  }

  getErrorReport() {
    return errorObj.errorReport;
  }

  getCurrentAction() {
    return _currentAction;
  }

  clearCurrentAction() {
    _currentAction = null;
  }

  getListDatabaseName() {
    return ListExplorerStore.getListDatabaseName();
  }

  trackUsage(action, value = '', props = []) {
    let workerData = { workerType: 'TrackUsage', clientSystemInfo: ClientSystemInfo.getClientSystemInfo(), action: action, value: value, props: props }

    if (!PrintMode.printing) // disabled usage tracker during print mode
      this.startWorker(workerData);
  }

  startWorker(workerData) {
    var worker = new usageTrackingWorker();
    workerData.url = sessionStorage.getItem("prodServerURL") == null ? window.atob(sessionStorage.getItem("prodServerURL")) : `${decodeURIComponent(escape(window.atob(sessionStorage.getItem("prodServerURL"))))}`;
    worker.postMessage(workerData);
    worker.onmessage = () => {
      worker.terminate();
      worker = undefined;
    }
  }

  UpdateBeginDate(beginDate = null) {
    _states.beginDate = beginDate;
  }


  UpdateConsoleDate(data, userEndDate = null, beginDate = null) {
    if (!_states) {
      _states = this.getState();
    }
    _states.endDate = data;
    _states.userEndDate = userEndDate;
    _states.beginDate = beginDate;
    // const consoleSettings = SettingsStore.getConsoleSettings();
    // consoleSettings.printSettings.userEndDate = userEndDate;
    // _consoleSettings.NavDatagraphSettings.TabDataGraphSettings.userEndDate = userEndDate;
    // SettingsStore.saveSettings();
    this.emit(CHANGE_EVENT);
  }

  updateServiceCallCount() {
    _states.serviceCallCount = _states.serviceCallCount + 1;
    //this.emit(CHANGE_EVENT);
  }

  clearServiceCallCount() {
    _states.serviceCallCount = 0;
    //this.emit(CHANGE_EVENT);
  }


  getUserEndDate() {
    if (!_states) {
      _states = this.getState();
    }

    if (_consoleSettings && _consoleSettings.printSettings.userEndDate && PrintMode.printing) {
      return new Date(_consoleSettings.printSettings.userEndDate);
    }
    if (_states.userEndDate && _states.userEndDate.getFullYear()) {
      return _states.userEndDate;
    }
    else {
      if (_states.userEndDate !== null && _states.userEndDate !== undefined) {
        _states.userEndDate = new Date(Date.parse(_states.userEndDate));
      }
      return _states.userEndDate;
    }
  }

  GetUserAboutModuleInfo() {
    let infoList = [];
    ConsoleApi.GetUserAboutModuleInfo().then((result) => {
      const userAboutModuleInfoList = result.userInfo;
      userAboutModuleInfoList.forEach((info) => {
        infoList.push({ featureCode: info.featureCode, name: info.name, url: info.url, isFirstUse: info.isFirstUse })
      });
      this.infoList = infoList;
      this.emit(CHANGE_EVENT);
    });
  }
  GetKpiDefaultInfo() {
    let kpiList = [];
    ConsoleApi.GetKpiDefaultInfo().then((result) => {
      const kpiDefaultItemData = result.kpiDefaultItemData;
      kpiDefaultItemData.forEach((item) => {
        kpiList.push({
          KpiId: item.KpiId,
          Name: item.defaultColor,
          IsAvailable: true,
          IsChecked: item.isChecked,
          DefaultColor: item.defaultColor,
          SeqNumber: item.SeqNumber,
          DisplayNameLine1: item.displayNameLine1,
          DisplayNameLine2: item.displayNameLine2,
          Definition: item.definition,
          IsIntergerScale: item.isIntegerScale
        })
      });
      this.kpiList = kpiList;
      this.emit(CHANGE_EVENT);
    });
  }
  setQuoteStatusGrid(dotColor) {
    SettingsStore.getConsoleSettings().NavDatagraphSettings.PreferenceSettings.QuoteServiceSettings.SelectedQuoteStatusGrid = dotColor;
  }

  async setEntitlementsInfo() {
    let userData = UserInfoUtil.getUserInfo();
    const result = await UserApi.UserInit();
    // updating user info first to reflect in the places where it has been used and then moving on to settings info
    userData.entitlements = result.userInitData.entitlements;
    userData.entitleIdxUserList = result.userInitData.entitleIdxUserList;
    userData.entitleIdxFullList = result.userInitData.entitleIdxFullList;
    UserInfoUtil.setUserInfo(userData);
    dispatch(setCurrencyData(result.userInitData.currencies));
    dispatch(setESCurrencyData(result.userInitData.esCurrencies));
    SettingsActions.getUserSettings(result.workspacedata);
  }

  UpdateHistoricMode(isHistoric) {
    _states.isHistoric = isHistoric;
  }
  setChartLoaded() {
    _currentAction = ConsoleConstants.ActionTypes.SET_CHART_LOADED;
    this.emit(CHANGE_EVENT);
  }

  updateCurrentVersion(appVersion) {
    _states.currentVersion = appVersion;
  }

  updateNodeCount(nodeCount) {
    _states.nodeCount = nodeCount;
  }

  getIsLogin(){
    return _isLogin;
  }

  dispatcherCallback(payload) {
    AppDispatcher.waitFor([SettingsStore.dispatchToken]);
    const action = payload.action;
    let self = this;
    switch (action.actionType) {
      case SettingsConstants.ActionTypes.GET_USER_SETTINGS:
        _consoleSettings = SettingsStore.getConsoleSettings();
        this.createRtSubscriber();
        _isLogin = action.data && action.data.isLogin;
        action.data = null;
        _currentAction = SettingsConstants.ActionTypes.GET_USER_SETTINGS;
        dispatch(initSettingsToReducer(_consoleSettings))
        this.emit(CHANGE_EVENT);
        _states.ActiveNav = _consoleSettings.ActiveNav;
        _states.firstLoadedTab = _consoleSettings.ActiveNav;
        break;
      case SettingsConstants.ActionTypes.SET_USER_SETTINGS:
        setTimeout(() => {
          _consoleSettings = SettingsStore.getConsoleSettings();
          self.setToolTipMsg(action.data);
        }, 0);
        this.emit(CHANGE_EVENT);
        break;
      case SettingsConstants.ActionTypes.DDE_STATUS_CHANGED:
        this.setToolTipMsg(action.data);
        this.setQuoteStatusGrid(action.data.QuoteStatusGrid);
        SettingsStore.saveSettings();
        this.emit(CHANGE_EVENT);
        break;
      case ConsoleConstants.ActionTypes.HANDLE_EXCEPTION:
        this.setExceptionHandleMsg(action.data);
        break;
      case ConsoleConstants.ActionTypes.TOGGLE_SUPPORT_DIALOG:
        _currentAction = ConsoleConstants.ActionTypes.TOGGLE_SUPPORT_DIALOG;
        this.toggleSupportDialog(action.data.isOpen);
        this.emit(CHANGE_EVENT);
        break;
      case ConsoleConstants.ActionTypes.TOGGLE_LIVE_SUPPORT_DIALOG:
        _currentAction = ConsoleConstants.ActionTypes.TOGGLE_LIVE_SUPPORT_DIALOG;
        this.toggleLiveSupportDialog(action.data.isOpen);
        this.emit(CHANGE_EVENT);
        break;
      case SettingsConstants.ActionTypes.SET_SELECTQUOTESERVICEOPTION:
        _consoleSettings.NavDatagraphSettings.PreferenceSettings.QuoteServiceSettings.SelectQuoteServiceOption = action.data;
        SettingsStore.saveSettings();
        this.emit(CHANGE_EVENT);
        break;
      case ConsoleConstants.ActionTypes.GET_METRIC_DEFINITION:
        _states.metricDefinition = action.data;
        this.emit(CHANGE_EVENT);
        break;
      case ConsoleConstants.ActionTypes.GET_REST_METRIC_DEFINITION:
        _states.restMetricDefinition = action.data;
        this.emit(CHANGE_EVENT);
        break;
      case ConsoleConstants.ActionTypes.ROUT_TO_GRAPH:
        _currentAction = ConsoleConstants.ActionTypes.ROUT_TO_GRAPH;
        this.plotSymbol(action.data.symbol, action.data.navType)
        break;
      case ConsoleConstants.ActionTypes.POPULATE_CURRENCY:
        _states.currency = action.data;
        this.emit(CHANGE_EVENT);
        break;
      case ConsoleConstants.ActionTypes.START_PRINT:
        const { symbols, listName, symbolType, email, printType, periodicity, listId, searchKeyword } = action.data;
        debounce(this.startPrint(symbols, listName, symbolType, email, _states.userEndDate, printType, periodicity, listId, searchKeyword), 200);
        //this.emit(CHANGE_EVENT);
        break;
      case ConsoleConstants.ActionTypes.SHOW_PRINT_POPUP: 
          // _currentAction = ConsoleConstants.ActionTypes.SHOW_PRINT_POPUP;
          _showPrintStatus = false;
          setTimeout(()=>{ 
            _showPrintStatus = true;
            this.emit(CHANGE_EVENT);
           },100);
          this.emit(CHANGE_EVENT);
        break;
      case ConsoleConstants.ActionTypes.HANDLE_PRINT_ERROR_PAGE: 
        this.setPrintErrorHandleMsg(action.data);
        break;
      case ConsoleConstants.ActionTypes.CANCEL_PRINT:
        debounce(this.cancelPrint(action.data.printRequestId), 200);
        break;
      case ConsoleConstants.ActionTypes.CLOSE_PRINT_STATUS:
        this.closePrintStatus(action.data.isOpen);
        break;
      case ConsoleConstants.ActionTypes.TOGGLE_POPUP_ALERT:
        this.togglePopUpAlert(action.data.isOpen);
        break;
      case ConsoleConstants.ActionTypes.SET_ENTITLEMENTS_INFO:
        window.setTimeout(() => { this.setEntitlementsInfo() }, 0);
        break;
      case SettingsConstants.ActionTypes.GET_CURRENT_APPVERSION:
        this.updateCurrentVersion(action.data);
      default:
        break;

    }
  }
}

const consoleStore = new ConsoleStore();
export default consoleStore;
