import AnnotationUtil from "../../../Utils/AnnotationUtil";
import AppDispatcher from "AppDispatcher";
import BaseServiceApi from '../../../ServiceApi/BaseServiceApi';
import ChartApi from "../../../ServiceApi/Apis/ChartApi.js";
import ConsoleApi from "../../../ServiceApi/Apis/ConsoleApi";
import ConsoleStore from "../../../Stores/ConsoleWindow/ConsoleStore.js";
import { CurrencyConversion } from '../../../Constants/CurrencyConversion';
import { DataGraphConstants } from "../../../Constants/DataGraphConstants";
import DatagraphDataType from "../../../Constants/DatagraphDataType.js";
import DatagraphHelper from "../../../Utils/DatagraphHelper.js";
import DataGraphSettings from "../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/DatagraphSettings";
import DatagraphStore from "../../../Stores/NavModules/NavDataGraph/DataGraphStore";
import DateHelper from "DateHelper";
import { dispatch } from "../../../Redux/dispatch.js";
import { FundamentalsConstants } from "../../../Constants/FundamentalsConstants.js";
import { getAlertsData } from '../../../Actions/AlertActions';
import { getKpiIndicatorData } from '../../../Actions/KpiGraphActions';
import { getOwnershipBlockData } from "../../../Actions/OwnerShipBlockActions";
import { getSummaryBlockData } from '../../../Actions/SummaryBlockActions.js';
import GraphType from "GraphType";
import { IndicatorsConstants } from "../../../Constants/NavDataGraph/TabDataGraph/Indicators/IndicatorsConstants";
import { initTimeLine,getFactorData } from "../../../Actions/TabDataGraphAction.js";
import LocalizationStore from "../../../Stores/Localization/LocalizationStore";
import MiniListHelper from "MiniListHelper";
import MiniListStore from "MiniListStore";
import PanarayDefault from "ServiceApi/RequestHelper/PanarayDefault";
import { PriceChartConstants } from "../../../Constants/PriceChartConstants";
import { PrintMode } from "../../../print/printmode.js";
import { SettingsConstants } from "../../../Constants/SettingsConstants";
import SettingsStore from "SettingsStore";
import SymbolType from "SymbolType";
import TabDatagraphSettings from "../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/TabDatagraphSettings.js";
import TabType from "../../../Constants/TabType";
import TimeTrackingWindow from "TimeTrackingWindow";
import UserInfoUtil from '../../../Utils/UserInfoUtil';
import { all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import { clearAnnotations, initAnnotationModel } from "../../../Actions/AnnotationActions";
import { clearExternalData, getExternalDataSubMenu } from "../../../Actions/ExternalDataUploaderActions";
import { clearRIPanelData, populateRIPanelOneilInfo } from "../../../Actions/RIPanelOneilActions.js";
import { getFinancialBlockData } from "../../../Actions/FinancialBlockActions";
import { getSymbolEntryStates, symbolSelect, priceChartReducerselect, getDatagraphStates, getTimeLine } from '../../../Reducers/NavDataGraph/TabDataGraph/selectors';
import { handlePricePanelDataReady, updateSymbolEntryStates, clearFRData, updateSymbolEntryLoading } from '../../../Actions/DatagraphActions';
import { showLoader, getReportExtdata } from "../../../Actions/OwnershipActions.js";
import { expandHsfData } from "./PriceChartSaga"
import { getDgHoldingsReportExtdata, showHoldingsLoader } from "../../../Actions/DgHoldingsAction";
import { getFactorStates } from "../../../Reducers/NavDataGraph/TabDataGraph/selectors";
import moment from "moment";

const { ActionTypes } = DataGraphConstants;
let isDgApiCallsInitiated = false;
const EntitlementType = BaseServiceApi.rayData["EntitlementType"];
function getNumberOfChartNodes(width, nodeWidth) {
  if (PrintMode.printing) {
    width = PrintMode.width; // Hard coding the value since the width of the price chart continer in print mode is 1346px always.
  }
  const nodeCount = width / nodeWidth;
  return Math.ceil(nodeCount);
}
function* handlePeriodicityChange() {
  try {
    const symbol = yield select(symbolSelect);
    yield fork(onPlotSymbol, { symbol: symbol, isSymbolChange: false, isPeriodicityChanged: true });
  }
  catch (error) {
    console.log(`Error orccured in DatagraphSaga.js, handlePeriodicityChange, ${error}`);
  }
}

function fetchExactNodeCount(tabDatagraphSettings) {
  if (tabDatagraphSettings.windowDimension.width !== 0) {
    const width = tabDatagraphSettings.priceChartDimension.width;
    const nodeCount = getNumberOfChartNodes(width, tabDatagraphSettings.NodeWidth);
    ConsoleStore.updateNodeCount(nodeCount);
  }
}
function updateTabSelection(tabKey) {
  AppDispatcher.handleViewAction({
    actionType: SettingsConstants.ActionTypes.DATAGRAPH_UPDATE_TAB,
    data: tabKey
  });

}

function* onPlotSymbol({ symbol, isSymbolChange = true, nodeCount = 500, isMiniListPlay = false, isMiniListChart = false, isViewChart = false, isNavChange = false, isPeriodicityChanged = false, isCalendarChanged = false }) {
  try {
    if (!ConsoleStore.getStates().isTabChanged || isSymbolChange || ConsoleStore.getStates().isRoutToGraph || isMiniListPlay || isMiniListChart) {
      yield put(updateSymbolEntryLoading(true, true));
      const userSettings = SettingsStore.getConsoleSettings();
      const { graphType, periodicity, isIntraday, nodeWidth, IsNASDAQBasic, InitialBufferSize, isHistoric, majorPeriodicity } = yield select(getDatagraphStates);
      // if (!isDgApiCallsInitiated) {
      TimeTrackingWindow.resetTimeTracker(true, true);
      TimeTrackingWindow.initTimeTracker();
      TimeTrackingWindow.beginTimeTracker();
      const [pricePanelResponse, nodeCount_new] = yield call(initTabDatagraph, {
        userSettings, isSymbolChange, symbol, nodeCount, isCalendarChanged, isPeriodicityChanged, isFinancilaBlockUsed: true,
        isMiniListPlay, isNavChange, graphType, periodicity, InitialBufferSize, isHistoric, IsNASDAQBasic, isIntraday, nodeWidth, majorPeriodicity
      });
      if (pricePanelResponse && pricePanelResponse.responseHeader.error && pricePanelResponse.responseHeader.errorCode === 404) {
        // isDgApiCallsInitiated = false;
        TimeTrackingWindow.onSymbolChartFailed();
        yield put(updateSymbolEntryLoading(false, false));
        if (isNavChange) {
          const dataGraphSettings = new DataGraphSettings();
          yield call(onPlotSymbol, { symbol: dataGraphSettings.getDefaultDatagraphSettings().symbol });
        }
        return { ErrorMsg: true };
      }


      if (DatagraphStore.getState().SelectedTabKey === TabType.Fundamental && isViewChart) {
        updateTabSelection("DATAGRAPH");
      }
      ConsoleStore.getStates().isTabChanged = false;
      const pricePanelData = pricePanelResponse.PricePanelData;
      if (pricePanelData) {
        const SymbolInfo = pricePanelData.SymbolInfo;
        if(isSymbolChange){
          AppDispatcher.handleViewAction({
            actionType: SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT,
            data: SymbolInfo
          });
        }
        // if (!isMiniListPlay) {
        //   DatagraphStore.updateDatagraphURL(SymbolInfo.Symbol);
        // }

        processSymbolInfoDates(SymbolInfo);
        
        if (pricePanelData.HsfData && pricePanelData.HsfData.HSFResults && pricePanelData.HsfData.HSFResults.length > 0) {
          SymbolInfo.IsMiniListPlay = isMiniListPlay;

          if (isMiniListPlay) {
            MiniListStore.setSymbolInfo(SymbolInfo);
          }
          SymbolInfo.DefaultCurrency = { Code: SymbolInfo.DefaultCurrency };

          PanarayDefault.symTypeEnum = SymbolInfo.SymTypeEnum
          setFundamentalTabHeader(pricePanelResponse);
          SymbolInfo.finTargetCurrencyCode = DatagraphHelper.selectCurrencyCode();
          // const isIntradayDataSource = pricePanelData.HsfData.isIntradayDataSource;
          // let lastDate = pricePanelData.HsfData.HSFResults[0] ? DateHelper.parseJsonDate(DateHelper.getPSTFromLong(pricePanelData.HsfData.HSFResults[0].Date, SymbolInfo, isIntraday, isIntradayDataSource), SymbolInfo.MomentZoneID) : new Date();
          //register the change to settings
          yield put(handlePricePanelDataReady(pricePanelData, pricePanelResponse.headers, nodeCount));
          if(isSymbolChange){
            yield put({ type: SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT, SymbolInfo });
          }
          //prepare blockMenu for vol, inside, financial, ownership
          if (isSymbolChange || isPeriodicityChanged) {
            yield put({
              type: ActionTypes.SET_BLOCK_MENU_DATA,
            })
          }
          const { isIntraday, factorsEndDate, latestNodeDate, viewsSettings } = yield select(getDatagraphStates);
          const { factorsResultAvailable } = yield select(getFactorStates);
          const endDate = !isIntraday && (viewsSettings?.FactorResearchSettings?.IsVisible && factorsResultAvailable) ? factorsEndDate : latestNodeDate;
          yield put(initTimeLine(endDate, nodeCount_new, InitialBufferSize, false, false));
        }
        else {
          yield put(handlePricePanelDataReady(pricePanelData, pricePanelResponse.headers, nodeCount));
          if(isSymbolChange){
            yield put({ type: SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT, SymbolInfo });
          }
          if (isSymbolChange || isPeriodicityChanged) {
            yield put({
              type: ActionTypes.SET_BLOCK_MENU_DATA,
            })
          }
        }
        if(isSymbolChange){
          setTimeout(onSymbolChanged, 0, pricePanelResponse, isMiniListPlay);
        }
        yield put(updateSymbolEntryLoading(false, false));
        const hsfDataSource = pricePanelData.HsfData.isIntradayDataSource ? 'ES' : 'DB';
        console.log(`%c\n*** ${majorPeriodicity} Price Data is pulled from ${hsfDataSource} ***\n`, 'color:#e60d0d;background-color:#7ac414;font-size:12px;font-family:cursive;');
        const epsrpsSource = pricePanelData.isEPSRPSLinesDataSource ? 'ES' : 'DB';
        console.log(`%c\n*** ESP/RPS data is pulled from ${epsrpsSource} ***\n`, 'color:#e60d0d;background-color:#7ac414;font-size:12px;font-family:cursive;');
        const prSource = pricePanelData.isPatternDataSource ? 'ES' : 'DB';
        console.log(`%c\n*** Pattern Rec data is pulled from ${prSource} ***\n`, 'color:#e60d0d;background-color:#7ac414;font-size:12px;font-family:cursive;');
        const headerSource = pricePanelData.isHeaderDataSource ? 'ES' : 'DB';
        console.log(`%c\n*** Header Data is pulled from ${headerSource} ***\n`, 'color:#e60d0d;background-color:#7ac414;font-size:12px;font-family:cursive;');
        
        if (DatagraphStore.getState().SelectedTabKey === TabType.Datagraph && isMiniListPlay) {
          MiniListStore.setMiniListNextInterval();
        }
        return SymbolInfo;
      }
    }
  }
  catch (error) {
    console.log(`Error occured in DatagraphSaga.js, onPlotSymbol, ${error}`, error);
  }
}

function* initTabDatagraph({ userSettings, isSymbolChange, symbol, nodeCount, isCalendarChanged, isPeriodicityChanged, isFinancilaBlockUsed, isMiniListPlay, graphType, periodicity, InitialBufferSize, isHistoric, IsNASDAQBasic, isIntraday, nodeWidth, majorPeriodicity }) {
  try {
    AnnotationUtil.isAnnotationLoadInitiated = true;
    symbol = symbol ? symbol : userSettings.NavDatagraphSettings.symbol;

    if (nodeCount === 500) {
      // fetching some extra nodes which fits into the entire screen as we can only compare entire screen width on later modifications in zoom level / window size
      const width = document.documentElement.clientWidth - 200;
      nodeCount = getNumberOfChartNodes(width, nodeWidth);
    }

    const expectedEndDate = ConsoleStore.getUserEndDate() ? ConsoleStore.getUserEndDate() : new Date();
    let beginDate = DateHelper.calculateBeginDate(expectedEndDate, periodicity, nodeCount);

    if (beginDate.getFullYear() < 1800) {
      beginDate.setFullYear(1800);
    }
    const endDate = ConsoleStore.getUserEndDate() ? DateHelper.yyyymmdd(expectedEndDate) : null;
    beginDate = DateHelper.yyyymmdd(beginDate);
    TimeTrackingWindow.setCurrentSymbol(symbol);

    if (!(isCalendarChanged || isPeriodicityChanged)) {
      //Get Summary block data
      yield put(getSummaryBlockData({ ...userSettings, symbol, graphType }, ConsoleStore.getUserEndDate() ? expectedEndDate : null));
      yield put(clearExternalData(true));
      yield put(getExternalDataSubMenu(userSettings, !isSymbolChange));
    }
    if (isCalendarChanged) {
      yield put(getSummaryBlockData({ ...userSettings, symbol, graphType }, ConsoleStore.getUserEndDate() ? expectedEndDate : null));
    }
    // //Get Financial/Ownership block data
    if (!isIntraday && majorPeriodicity !== GraphType.Quarterly && majorPeriodicity !== GraphType.Annual && isFinancilaBlockUsed) {
      dispatch(getFinancialBlockData(userSettings, symbol, beginDate, endDate, graphType, periodicity, isPeriodicityChanged, isSymbolChange));
      dispatch(getOwnershipBlockData(userSettings, symbol, beginDate, endDate, graphType, periodicity))
    }
    // let indicatorResponse = "";
    // let pricePanelResponse = '';
    //Get technical indicator, fundamental and performance metrics.

    if (!isIntraday) {
      yield put({ type: IndicatorsConstants.ActionTypes.GET_ALL_INDICATOR_DATA, isSymbolChange, symbol, graphType, endDate, isMiniListPlay });
      const isKPIEntitled = UserInfoUtil.IsUserEntitled(EntitlementType.KPI_Entitlement);
      if (isKPIEntitled && !isCalendarChanged) {
        yield put(getKpiIndicatorData(symbol, periodicity, selectCurrencyCode()))
      }
    }
    const factorEndDate = endDate ? endDate : DateHelper.yyyymmdd(expectedEndDate);
    yield put(getFactorData(symbol, periodicity, factorEndDate));
    // Get Annotations data
    if (!(isCalendarChanged)) {
      AnnotationUtil.isAnnotationApiCallInitiated = true;
      AnnotationUtil.layerData = null;
      AnnotationUtil.annotations = null;
      dispatch(clearAnnotations());
      dispatch(initAnnotationModel(true, symbol, endDate, beginDate));
    }

    // predecting exact number of chart nodes to avoid price chart going blank for few milli seconds.
    fetchExactNodeCount(userSettings.NavDatagraphSettings.TabDataGraphSettings);

    //Get Price panel data  
    yield put({
      type: PriceChartConstants.ActionTypes.UPDATE_IS_PRICE_DATA_READY,
      isPriceDataReady: false
    });
    let pricePanelApi = yield call(getPricePanelData, userSettings, symbol, beginDate, endDate, graphType, isCalendarChanged || isPeriodicityChanged, nodeCount, isHistoric, IsNASDAQBasic, InitialBufferSize);
    const SymbolInfo = pricePanelApi && pricePanelApi.PricePanelData && pricePanelApi.PricePanelData.SymbolInfo ? pricePanelApi.PricePanelData.SymbolInfo : {} ;
    if(endDate === null && SymbolInfo.LastTradeDate && (SymbolType.CASHSPOTS === SymbolInfo.SymTypeEnum || SymbolType.FUTURECONTRACTS === SymbolInfo.SymTypeEnum)){
      const lastTradeDate = DateHelper.parseJsonDate(SymbolInfo.LastTradeDate, SymbolInfo.MomentZoneID);
      let beginDate = DateHelper.calculateBeginDate(lastTradeDate, periodicity, nodeCount);
      if (beginDate.getFullYear() < 1800) {
        beginDate.setFullYear(1800);
      }
      beginDate = DateHelper.yyyymmdd(beginDate);
      const calcBeginDate = DateHelper.yyyymmdd(lastTradeDate);
      pricePanelApi = yield call(getPricePanelData, userSettings, symbol, beginDate, calcBeginDate, graphType, isCalendarChanged || isPeriodicityChanged, nodeCount, isHistoric, IsNASDAQBasic, InitialBufferSize);
    }
    const consoleSettings = SettingsStore.getConsoleSettings();
    if (!PrintMode.printing && isHistoric) {
        consoleSettings.printSettings.userEndDate = moment(endDate).format("ddd DD MMM YYYY");
        SettingsStore.saveSettings();
    }else{
        if(!PrintMode.printing){
          consoleSettings.printSettings.userEndDate = null
          SettingsStore.saveSettings();
        }
    }
    return [pricePanelApi, nodeCount];
  } catch (error) {
    console.log(`Error occurs in DataGraphSaga.js, initTabDatagraph, ${error}`);
  }
  
}
function selectCurrencyCode() {
  const settings = SettingsStore.getConsoleSettings();
  const {
    isCurrencyConversionChecked,
    selectedCurrencyCode
  } = settings;
  if (isCurrencyConversionChecked) {
    return selectedCurrencyCode;
  }
  return null;
}
function* getPricePanelData(settings, symbol, beginDate, endDate, graphType, isPeriodicityOrCalendarChanged, nodeCount, isHistoric, IsNASDAQBasic, InitialBufferSize) {
  try {
    ConsoleStore.getState().isPricePanelApiInitiated = true;
    const tabDatagraphSettings = new TabDatagraphSettings();
    const RSlineReqObj = tabDatagraphSettings.prepareRSlineReqObj(settings.NavDatagraphSettings.TabDataGraphSettings);
    const RTVolume = IsNASDAQBasic && !isHistoric ? 1 : 0;
    const revwononly = settings.NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.RevWonOnly;
    const wononly = settings.NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.WonOnly;
    const { isMatchFinancials } = settings;
    TimeTrackingWindow.beginPricePanelTimeTracker();
    const [pricePanelData] = yield all([call(ChartApi.getPricePanelData, symbol, beginDate, endDate, graphType, RTVolume, InitialBufferSize, RSlineReqObj, wononly, revwononly, selectCurrencyCode(), isMatchFinancials, nodeCount),
    !isPeriodicityOrCalendarChanged && put(getAlertsData(symbol))]);
    TimeTrackingWindow.endPricePanelApiTimeTracker();
    return pricePanelData
  }
  catch (error) {
    TimeTrackingWindow.onSymbolChartFailed();
    TimeTrackingWindow.setApiTimeTrackError(DatagraphDataType.TechnicalIndicators);
    TimeTrackingWindow.setApiTimeTrackError(DatagraphDataType.PricePanelAndFinancial);
    TimeTrackingWindow.endPricePanelApiTimeTracker();
    console.log(`Error occurs in DatagraphSaga.js, getPricePanelData, ${error}`)
  }
  return { responseHeader: {} }
}
function onSymbolChanged(data, isMiniListPlay = false) {
  try {
    const result = data.PricePanelData.SymbolInfo;
    dispatch(updateSymbolEntryLoading(true, false))
    TimeTrackingWindow.setSymbolType(result.SymTypeEnum);


    switch (result.SymTypeEnum) {
      case SymbolType.USSTOCK:
        dispatch(populateRIPanelOneilInfo('Stocks', result, isMiniListPlay)); //Get DG ideas
        getOwnershipReportData(result.Osid);
        onDatagraphSymbolChanged(SettingsConstants.ActionTypes.STOCK_SYMBOL_CHANGED, data, result, isMiniListPlay);
        break;
      case SymbolType.CURRENCY:
        onDatagraphSymbolChanged(SettingsConstants.ActionTypes.CURRENCY_SYMBOL_CHANGED, data, result, isMiniListPlay);
        break;
      case SymbolType.CRYPTOCURRENCY:
        onDatagraphSymbolChanged(SettingsConstants.ActionTypes.CRYPTO_CURRENCY_SYMBOL_CHANGED, data, result, isMiniListPlay);
        break;
      case SymbolType.ETF:
        dispatch(populateRIPanelOneilInfo('ETF', result, isMiniListPlay)); //Get DG ideas
        getOwnershipReportData(result.Osid);
        dispatch(showHoldingsLoader(true));
        dispatch(getDgHoldingsReportExtdata(result.Osid, result.Symbol, result.MsId));
        onDatagraphSymbolChanged(SettingsConstants.ActionTypes.ETF_SYMBOL_CHANGED, data, result, isMiniListPlay);
        break;
      case SymbolType.FundClosedEnd:
        getOwnershipReportData(result.Osid);
        dispatch(showHoldingsLoader(true));
        dispatch(getDgHoldingsReportExtdata(result.Osid, result.Symbol, result.MsId));
        onDatagraphSymbolChanged(SettingsConstants.ActionTypes.CEF_SYMBOL_CHANGED, data, result, isMiniListPlay);
        break;
      case SymbolType.FUND:
        dispatch(showHoldingsLoader(true));
        dispatch(getDgHoldingsReportExtdata(result.Osid, result.Symbol, result.MsId));
        onDatagraphSymbolChanged(SettingsConstants.ActionTypes.FUND_SYMBOL_CHANGED, data, result, isMiniListPlay);
        break;
      case SymbolType.INDEXNOINTRADAYVOLDATA:
      case SymbolType.ECONOMICINDICATOR:
      case SymbolType.INDEXNOINTRADAYDATA:
      case SymbolType.INDEXNOINTRADAYVOL:
      case SymbolType.INTERNATIONALINDEX:
      case SymbolType.HISTORICINDEX:
      case SymbolType.INDEX:
        getOwnershipReportData(result.Osid);
        onDatagraphSymbolChanged(SettingsConstants.ActionTypes.INDEX_SYMBOL_CHANGED, data, result, isMiniListPlay);
        break;
      case SymbolType.FUTURECONTRACTS:
        onDatagraphSymbolChanged(SettingsConstants.ActionTypes.FUTURES_SYMBOL_CHANGED, data, result, isMiniListPlay);
        break;
      case SymbolType.CASHSPOTS:
        onDatagraphSymbolChanged(SettingsConstants.ActionTypes.CASHSPOTS_SYMBOL_CHANGED, data, result, isMiniListPlay);
        break;
      case SymbolType.WONINDUSTRYGROUP197:
      case SymbolType.WONMAJORINDUSTRY89:
      case SymbolType.WONSECTOR11:
      case SymbolType.WONSUBSECTOR33:
        onDatagraphSymbolChanged(SettingsConstants.ActionTypes.INDUSTRYGROUP_SYMBOL_CHANGED, data, result, isMiniListPlay);
        break;
      default:
        dispatch(populateRIPanelOneilInfo('Stocks', result, isMiniListPlay)); //Get DG ideas
        getOwnershipReportData(result.Osid);
        onDatagraphSymbolChanged(SettingsConstants.ActionTypes.STOCK_SYMBOL_CHANGED, data, result, isMiniListPlay);
        break;
    }
    dispatch(updateSymbolEntryLoading(false, false))
  }
  catch (error) {
    console.error(error);
  }
}
function getOwnershipReportData(osid) {
  dispatch(showLoader(true));
  dispatch(getReportExtdata(osid));
}
function onDatagraphSymbolChanged(action, PricePanelData, data, isMiniListPlay = false) {
  data = data ? data : {};
  PricePanelData = PricePanelData ? PricePanelData : {};
  data.isMiniListPlay = isMiniListPlay;
  if (isMiniListPlay) {
    const isActiveSymbolValid = MiniListHelper.ActiveSymbolCheck(data.Symbol, isMiniListPlay);
    if (isActiveSymbolValid) {
      const state = DatagraphStore.getState();
      if (state.SelectedTabKey !== TabType.Holdings) {
        MiniListStore.clearMiniListPlayTimeout();
      }
      setTimeout(() => {
        AppDispatcher.handleServerAction({
          actionType: action,
          data: data,
          PricePanelDataResp: PricePanelData
        });
      })
    }
  }
  else {
    AppDispatcher.handleServerAction({
      actionType: action,
      data: data,
      PricePanelDataResp: PricePanelData
    });
  }
}

function processSymbolInfoDates(stockInfo) {
  stockInfo.LastTradeDate = DateHelper.getPSTFromLong(stockInfo.LastTradeDate);//, pacificOffset);
  stockInfo.ExchangeOpenTime = DateHelper.getPSTFromLong(stockInfo.ExchangeOpenTime);//, pacificOffset);
  stockInfo.ExchangeCloseTime = DateHelper.getPSTFromLong(stockInfo.ExchangeCloseTime);//, pacificOffset);
  stockInfo.IPODate = stockInfo.IPODate && DateHelper.getPSTFromLong(stockInfo.IPODate);//, pacificOffset);
  stockInfo.Earnrptdate = DateHelper.getPSTFromLong(stockInfo.Earnrptdate);//, pacificOffset);
  return stockInfo;
}
function setFundamentalTabHeader(pricePanelData) {
  const symbolInfo = pricePanelData.PricePanelData.SymbolInfo
  const instrumentsIncludeArray = [SymbolType.USSTOCK, SymbolType.HISTORICSTOCK, SymbolType.INTERNATIONALSTOCK, SymbolType.ECONOMICINDICATOR]
  if (instrumentsIncludeArray.includes(symbolInfo.SymTypeEnum)) {
    AppDispatcher.handleViewAction({
      actionType: FundamentalsConstants.ActionTypes.GET_FUNDAMENTAL_HEADER_DATA,
      headerdata: pricePanelData.headers,
      symbolInfo
    })
  }
}

/**************Symbol Enrtry ***************/

function getColumnWidth(item, itemLengths, columnWidths) {
  for (const key in item) {
    const itemValue = item[key] !== null && item[key] !== undefined ? item[key].trim() : '';
    if (!itemLengths[key]) {
      itemLengths[key] = 0;
    }
    if (itemValue.length + 5 >= itemLengths[key]) {
      itemLengths[key] = itemValue.length;
      if (!columnWidths[key]) {
        columnWidths[key] = 0;
      }
      const itemWidth = itemValue.getTextWidthWithStyle("font-zise:14px; font-family:calibrib;") + 15;
      if (columnWidths[key] < itemWidth) {
        columnWidths[key] = itemWidth;
      }
    }
  }
}

function getMenuWidth(columnWidths) {
  let width = 0;
  for (const index in columnWidths) {
    width = width + columnWidths[index];
  }
  if (width < 420) {
    width = 420;
  }
  return width;
}
function* handleChange({ isMounseDown, symbol, isOutsideClick = false }) {
  try {
    const state = yield select(getSymbolEntryStates)
    if(!symbol || symbol.indexOf(':') === -1){
      if (!symbol || symbol.replace(/\s/g, "") === "") {
        const itemLengths = {};
        const columnWidths = {};
        state.recentSymbols.forEach((item) => {
          getColumnWidth(item, itemLengths, columnWidths);
        });
        const searchedSymbolsWidth = getMenuWidth(columnWidths);
        yield put(updateSymbolEntryStates({
          symbol: isMounseDown ? state.symbol : symbol.replace(/\s/g, ""),
          isInputActive: true,
          hasError: false,
          showRecentSymbol: true,
          searchedSymbols: [],
          searchedSymbolsWidth: searchedSymbolsWidth,
          columnWidths: columnWidths
        }));
      }
      else {
        yield put(updateSymbolEntryStates({ symbol, hasError: false, isInputActive: !isOutsideClick }));
        if (!isOutsideClick) {
          yield delay(0)
          if (PrintMode.printing) {
            return;
          }
          const result = yield call(ConsoleApi.getSymbolSearchRequest, symbol.replace(/\s/g, ""));
  
          const itemLengths = {};
          const columnWidths = {};
          let searchedSymbolsWidth = 0;
          let searchResult = result.results.map((item) =>
          ({
            localSymbol: item.localSymbol,
            symbol: item.Symbol,
            companyName: item.CompanyName ? item.CompanyName.trim() : "",
            exgCode: item.exchange ? item.exchange.trim() : ""
          })
          );
          if (searchResult && searchResult.length > 0) {
            searchResult.forEach((item) => {
              getColumnWidth(item, itemLengths, columnWidths);
            });
            searchedSymbolsWidth = getMenuWidth(columnWidths);
            yield put(updateSymbolEntryStates({
              showRecentSymbol: false,
              searchedSymbols: searchResult,
              searchedSymbolsWidth: searchedSymbolsWidth,
              columnWidths: columnWidths
            }))
          }
          else {
            searchResult = [{ symbol: LocalizationStore.getTranslatedData("srh_noMat", "No Matches") }];
            yield put(updateSymbolEntryStates({
              showRecentSymbol: false,
              searchedSymbols: searchResult,
              searchedSymbolsWidth: searchedSymbolsWidth,
              columnWidths: columnWidths
            }));
          }
        }
      }
    }else{
      yield put(updateSymbolEntryStates({ symbol, searchedSymbols: [] }));
    }
    
  }
  catch (error) {
    console.log(`Error occured in DatagraphSaga.js, handleChange, ${error}`);
  }
}

function checkSymbolInfo(info) {
  if (!info) {
    return false;
  }
  if (!info.SymTypeEnum || info.SymTypeEnum === SymbolType.SymbolTypeEnumUndefined) {
    return false;
  }
  if (info.ErrorMsg) {
    return false;
  }
  return true;
}

function* hideErrorMsg() {
  yield delay(10 * 1000);
  const state = yield select(getSymbolEntryStates);
  if (state.hasError) {
    yield put(updateSymbolEntryStates({ hasError: false, symbol: state.loadedSymbol || state.symbol, isInputActive: false }));
    ConsoleStore.trackUsage('InvalidSymbolIsDisplayed');
  }
}
function* handleSymbolChange({ symbol }) {
  try {
    const state = yield select(getSymbolEntryStates);
    if (symbol) {
      ConsoleStore.clearServiceCallCount();
      const isHistoric = ConsoleStore.getStates().isHistoric;
      if (isHistoric) {
        const state = ConsoleStore.getStates();
        SettingsStore.getConsoleSettings().NavDatagraphSettings.TabDataGraphSettings.userEndDate = state.endDate;
        SettingsStore.saveSettings();
      }

      try {
        const result = yield call(onPlotSymbol, { symbol, isSymbolChange: true });
        if (result) {
          if (checkSymbolInfo(result)) {
            let isSymbolConvertedDialogOpen = false;
            if (result.Symbol.toLowerCase() !== symbol.toLowerCase() && state.isSymbolConvertedDialogAvailable) {
              isSymbolConvertedDialogOpen = true;
            }
            yield put(updateSymbolEntryStates({ hasError: false, isSymbolConvertedDialogOpen, symbol: result.Symbol, isInputActive: false }));
          } else {
            yield put(updateSymbolEntryStates({ hasError: true, isInputActive: false }));
            yield call(hideErrorMsg);
          }
        }
      }
      catch {
        yield put(updateSymbolEntryStates({ hasError: true }));
        yield call(hideErrorMsg);
      };
    }
    else {
      yield put(updateSymbolEntryStates({ hasError: true, isInputActive: false }));
      yield call(hideErrorMsg);

    }
  }
  catch (error) {
    console.log(`Error occured in DatagraphSaga.js, handleSymbolChange, ${error}`);
  }
}

function* redrawLine() {
  const priceState = yield select(priceChartReducerselect);
  const datagraphState = yield select(getDatagraphStates);
  const currency = selectCurrencyCode();

  const nodeCount = datagraphState.nodeCount ? datagraphState.nodeCount : 500;

  const settings = SettingsStore.getConsoleSettings();
  const tabDatagraphSettings = new TabDatagraphSettings();
  const lineSettings = tabDatagraphSettings.prepareRSlineReqObj(settings.NavDatagraphSettings.TabDataGraphSettings);
  let RTVolume = 0;
  if (datagraphState.IsNASDAQBasic && !datagraphState.isHistoric) {
    RTVolume = 1;
  }
  const graphType = datagraphState.graphType;
  const { isMatchFinancials } = settings;
  const revwononly = settings.NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.RevWonOnly;
  const wononly = settings.NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.WonOnly;
  const pricePanelResponse = yield call(ChartApi.getPricePanelData, datagraphState.Symbol, DateHelper.yyyymmdd(priceState.startDate), DateHelper.yyyymmdd(datagraphState.endDate), graphType, RTVolume, datagraphState.nodeCount, lineSettings, wononly, revwononly, currency, isMatchFinancials, (nodeCount + datagraphState.InitialBufferSize));

  if (pricePanelResponse && !pricePanelResponse.responseHeader.error) {

    const pricePanelData = pricePanelResponse.PricePanelData;
    if (pricePanelData) {
      if (pricePanelData.HsfData && pricePanelData.HsfData.HSFResults && pricePanelData.HsfData.HSFResults.length > 0) {
        const { SymbolInfo, isIntraday, SymbolType, periodicity, IsNASDAQBasic } = yield select(getDatagraphStates);
        const isIntradayDataSource = pricePanelData.HsfData.isIntradayDataSource;
        const timeLine = yield select(getTimeLine);
        [pricePanelData.HsfData.HSFResults] = yield call(expandHsfData, pricePanelData.HsfData.HSFResults, timeLine, SymbolInfo, isIntraday, isIntradayDataSource, SymbolType, periodicity, IsNASDAQBasic);
        pricePanelData.SymbolInfo.DefaultCurrency = { Code: pricePanelData.SymbolInfo.DefaultCurrency } 
        yield put({
          type: ActionTypes.SAVE_PRICEPANEL_DATA_REDRAW_LINE,
          pricePanelData: pricePanelData
        }
        );
      }
    }
    yield put({
      type: PriceChartConstants.ActionTypes.REDRAW_INDEX_LINE
    });
  }
}
/**************Symbol Enrtry ***************/

/*******************************************/
export function* watchPeriodicityChange() {
  yield takeLatest(ActionTypes.PERIODICITY_CHANGE, handlePeriodicityChange);
}
export function* watchCallPriceChartApi() {
  yield takeLatest(ActionTypes.CALL_PRICE_CHART_API, handlePeriodicityChange);
}
export function* watchonPlotSymbol() {
  yield takeLatest(ActionTypes.ON_PLOT_SYMBOL, onPlotSymbol);
  yield takeLatest(CurrencyConversion.ActionTypes.SET_IS_CURRENCY_CONVERSION_CHECKED, onPlotSymbol);
}

export function* watchSymbolEntrySymbolChange() {
  yield takeLatest(ActionTypes.HANDLE_SYMBOL_ENTRY_SYMBOL_CHANGE, handleSymbolChange);
}
export function* watchSymbolEntryChange() {
  yield takeLatest(ActionTypes.HANDLE_SYMBOL_ENTRY_CHANGE, handleChange);
}
export function* watchRedrawLine() {
  yield takeLatest(ActionTypes.HANDLE_REDRAW_LINE, redrawLine);
}