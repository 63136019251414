import { connect } from 'react-redux';
import DateHelper from "DateHelper";
import { definationSymbols } from "../../../../../Constants/TabDataGraphConstants.js";
import FutureContractsHeaderExpiryStateEnum from "../../../../../Constants/FutureContractsHeaderExpiryStateEnum.js";
import { getNavCompareState } from '../../../../../Reducers/NavCompare/MenuPanelReducers/selectors';
import HeaderDefination from "../../../../../RayCustomControls/MenuControl/HeaderDefination.jsx"
import IndicatorFlyoutMenu from "../Indicators/IndicatorFlyoutMenu.jsx";
import IndustryGroupHeader from "../../../../../RayCustomControls/MenuControl/IndustryGroupHeader.jsx"
import LocalizationStore from 'LocalizationStore';
import { PrintMode } from "../../../../../print/printmode.js";
import SettingsStore from "SettingsStore";
import SmartViewType from "SmartViewType";
import StringUtil from "StringUtil";
import SymbolType from "SymbolType";
import { toggleExtDataItem } from '../../../../../Actions/ExternalDataUploaderActions.js';
import UserInfoUtil from "UserInfoUtil";
import { UpdateTemplisttoExplorer } from '../../../../../Actions/ListActions.js';
import { changeEPSFormat,changeIPOFormat, SwitchexpiryDate } from "../../../../../Actions/HeaderAction.js";
import React, { useEffect, useRef } from 'react';
import { setGroupsMainData } from '../../../../../Actions/RotationGraphActions.js';
import DatagraphHelper from '../../../../../Utils/DatagraphHelper.js';

const isLocalSymbolEntitle = UserInfoUtil.hasLocalSymbol();
const Header = (props) => {
  const headerEl = useRef(null);

  useEffect(() => {
    if (StringUtil.isEmpty(props.groupsMainData)) {
      props.setGroupsMainData();
    }
  });

  const getIPODate = () => {
    const ipoDateText = DateHelper.IpoDateFormat(props.ipoDate);
    return ipoDateText;
  }
  const getCountDownData = (expiryDate, chartDate) => {
    let returnString = "";
    const exDate = new Date(expiryDate.getFullYear(), expiryDate.getMonth(), expiryDate.getDate());
    const chDate = new Date(chartDate.getFullYear(), chartDate.getMonth(), chartDate.getDate());
    const countDown = DateHelper.DaysDiff(exDate, chDate);
    const data = {
      DaysInterval: countDown.Days,
      IsPastDue: (countDown.Ticks > 0),
    };
    const acronym = "Expires ";
    let interval = "";
    if (!isExpire(expiryDate)) {
      interval = getCountDays(data);
      returnString = `${acronym} ${interval}`;
    }
    else {
      if (expiryDate > chartDate && chartDate.getTime() !== props.lastTradeDate.getTime()) {
        interval = getCountDays(data)
        returnString = `${acronym} ${interval}`;
      } else{
        returnString = "Expired";
      }
    }
    return returnString;
  }
  const getCountDays = (data) => {
    let interval = "Today";
    if (Math.abs(data.DaysInterval) === 1) {
      interval = data.IsPastDue ? "1 Day" : "in 1 Day";
    } else if (Math.abs(data.DaysInterval) > 1 && data.IsPastDue) {
      interval = "{0} Days".replace("{0}", Math.abs(data.DaysInterval));
    } else if (Math.abs(data.DaysInterval) > 1 && !data.IsPastDue) {
      interval = "in {0} Days".replace("{0}", Math.abs(data.DaysInterval));
    }
    return interval;
  }
  const isExpire = (expiryDate) => {
    const currentDate = new Date();
    if (expiryDate && expiryDate < currentDate){
      return true;
    }
    else{
      return false;
    }
  }
  const getHistoricShadingVisibility = () => {
    let historicShadingVisibility = false;
    if (isExpire(props.expiryDate)){
      historicShadingVisibility = true;
    }
    else{
      historicShadingVisibility = props.isHistoric;
    }
    return historicShadingVisibility;
  }

  const getExpiryDateText = () => {
    let returnString = "";
    const expiryDate = props.expiryDate;
    const chartDate = props.endDate;
    if (props && expiryDate !== "") {
      if (props.ExpiryState === FutureContractsHeaderExpiryStateEnum.CountDown) {
        returnString = getCountDownData(expiryDate, chartDate);
      }
      else {
        if (isExpire(expiryDate) && chartDate.getTime() === props.lastTradeDate.getTime()) {
          returnString = `Expired ${DateHelper.getDateString(expiryDate)}`;
        }
        else{
          returnString = `Expires ${DateHelper.getDateString(expiryDate)}`;
        }
      }
    }
    return returnString;
  }
  const getHeaderText = () => {

    const settings = SettingsStore.getConsoleSettings();
    const info = props.SymbolInfo;
    if (!info){ 
      return;
    }
    const dymanicViews = [];
    const textClass = "info-wht medium-normal";
    if (!props.isCommonStock && info.SymTypeEnum === SymbolType.ECONOMICINDICATOR) {
      const disp = props.groupRank ? `${LocalizationStore.getTranslatedData("sb_grprnk", 'Group Rank ')} ${props.groupRank}` : " ";
      dymanicViews.push(<div key="1" className={textClass} id="div2" style={{ pointerEvents: 'all' }}>{disp}</div>);
      return dymanicViews;
    }

    let width = props.Width;
    if ((width === undefined || (width && width > 0)) && headerEl.current) {
      const rect = headerEl.current.getBoundingClientRect();
      if (rect){
        width = rect.width;
      }
    }
    width = width - 42;
    let plusRatio = 8.5;
    let minusRatio = 9;

    if (UserInfoUtil.hasChinaEntitlement()) {
      plusRatio = 10.3;
      minusRatio = 13;
    }

    const textIPOStyle = { textDecoration: 'none', borderBottom: '1px dotted', cursor: 'pointer', pointerEvents: 'all' };
    const textEPSStyle = { textDecoration: 'none', borderBottom: '1px dotted', cursor: 'pointer', pointerEvents: 'all' };
    let ipoOff = false;
    let ipoText = PrintMode.printing ? settings.printSettings.showIPODate ? getIPODate() : props.ipo : props.ipo;
    let exchangeOff = false;
    let exchangeText = (props.SymbolType === SmartViewType.STOCK || 
                        props.SymbolType === SmartViewType.FUTURES ||
                        props.SymbolType === SmartViewType.ETF ||
                        props.SymbolType === SmartViewType.CEF) 
                        ? props.exchange : '';
    const excode = props.excode;
    let floatOff = false;
    let floatText = props.float;
    let sharesOff = false;
    let sharesText = props.shares;
    let salesOff = false;
    let salesText = props.sales;
    const mktCapOff = false;
    const mktCapText = props.mktCap;
    let industryGroup = false;
    // let industryGroupText = props.industryGroup;
    let epsDueOff = true;
    let epsDueText = "";
    let isin = false;
    let isinText = props.Isin;
    const exdText = props.externalDataText;
    const exdOff = false;

    const ipoLength = props.ipo ? props.ipo.length : 0;
    const exchangeLength = props.exchange ? props.exchange.length : 0;
    const floatLength = props.float ? props.float.length : 0;
    const sharesLength = props.shares ? props.shares.length : 0;
    const salesLength = props.sales ? props.sales.length : 0;
    const mktCapLength = props.mktCap ? props.mktCap.length : 0;
    const industryGroupLength = props.industryGroup ? props.industryGroup.length : 0;


    let exchangeNameOff = false;
    let exchangeName = props.exchangeName;
    let wonGroupingOff = false;
    let wonGrouping = props.wonGrouping;
    let openInterestOff = false;
    let openInterest = props.openInterest ? `Open Interest (Prior Day) ${props.openInterest}` : "";
    let expiryDateOff = false;
    const textEXTStyle = { textDecoration: 'none', borderBottom: '1px dotted', float: 'right', cursor: 'pointer', pointerEvents: 'all' };
    const textPDRStyle = { textDecoration: 'none', float: 'right', cursor: 'default' };
    let expiryDate = "";
    if (props.expiryDate !== "") {
      expiryDate = getExpiryDateText();
    }

    //INDUSTRY GROUP
    const groupRankLength = props.groupRank ? props.groupRank.length : 0;
    const constituentsHeaderLength = props.constituentsHeader ? props.constituentsHeader.length : 0;
    const constituentsLength = props.constituents ? props.constituents.length : 0;
    const sectorHeaderLength = props.sectorHeader ? props.sectorHeader.length : 0;
    const sectorLength = props.sector ? props.sector.length : 0;
    const isIndustryGroup = props.SymbolType === SmartViewType.INDUSTRYGROUP;
    let groupRankOff = false;
    let groupRankText = props.groupRank;
    let constituentsOff = false;
    let constituentsText = isIndustryGroup ? props.constituents ? props.constituents : "0": "";
    let sectorOff = false;
    let sectorText = props.sector;
    let isIGgrpRk = props.SymbolInfo.Symbol.substring(0, 1) === "G";
    
    //FUTURES

    const exchangeNameLength = exchangeName ? exchangeName.length : 0;
    const wonGroupingLength = wonGrouping ? wonGrouping.length : 0;
    const openInterestLength = openInterest ? openInterest.length : 0;
    const expiryDateLength = expiryDate ? expiryDate.length : 0;

    
    const tiRect = null;
    
    let textLng = ipoLength;
    textLng += exchangeLength;
    textLng += floatLength;
    textLng += sharesLength;
    textLng += salesLength;
    textLng += mktCapLength;
    textLng += industryGroupLength;
    if (props.epsDue) {
      textLng += props.epsDue.length;
      epsDueText = PrintMode.printing ? settings.printSettings.showEPSDate ?DateHelper.EpsDueDateFormat(props.epsDueDate, info.SymTypeEnum) : props.epsDue : props.epsDue;
      epsDueOff = false;
    }

    textLng += isinText ? props.Isin.length : 0;
    
    /*HAS TO be check later*/
    // let tiRect = this.TIChart ? this.TIChart.getBoundingClientRect() : null;
    textLng += tiRect ? tiRect.width : 0;
    // textLng += groupRankLength;
    // textLng += mktCapLength;
    // textLng += constituentsHeaderLength;
    // textLng += constituentsLength;
    // textLng += sectorHeaderLength;
    // textLng += sectorLength;

    // textLng += exchangeNameLength;
    // textLng += wonGroupingLength;
    // textLng += openInterestLength;
    // textLng += expiryDateLength;

    textLng *= plusRatio;
    textLng += props.externalDataText ? 120 : 0;
    if (width < textLng) {
      if (!ipoOff && width < textLng) {
        ipoOff = true;
        textLng -= (ipoLength * minusRatio);
        ipoText = "";
      }
      if (!exchangeOff && width < textLng) {
        exchangeOff = true;
        textLng -= (exchangeLength * minusRatio);
        exchangeText = "";
      }
      if (!floatOff && width < textLng) {
        floatOff = true;
        textLng -= (floatLength * minusRatio);
        floatText = "";
      }
      if (!sharesOff && width < textLng) {
        sharesOff = true;
        textLng -= (sharesLength * minusRatio);
        sharesText = "";
      }
      if (!salesOff && width < textLng) {
        salesOff = true;
        textLng -= (salesLength * minusRatio);
        salesText = "";
      }
      if (!industryGroup && width < textLng) {
        industryGroup = true;
        textLng -= (industryGroupLength * minusRatio);
        // industryGroupText = "";
      }
      if (!epsDueOff && width < textLng) {
        epsDueOff = true;
        textLng -= (props.epsDue.length * minusRatio);
        epsDueText = "";
      }
      if (!isin && width < textLng) {
        isin = true;
        textLng -= (props.Isin.length * minusRatio);
        isinText = "";
      }

      if (!constituentsOff && width < textLng) {
        constituentsOff = true;
        textLng -= ((constituentsHeaderLength + constituentsLength) * 9);
        constituentsText = "";
      }
      if (!sectorOff && width < textLng) {
        sectorOff = true;
        textLng -= ((sectorHeaderLength + sectorLength) * 9);
        sectorText = "";
      }
      if (!groupRankOff && width < textLng && isIndustryGroup) {
        groupRankOff = true;
        textLng -= (groupRankLength * 9);
        groupRankText = "";
      }
      if (!exchangeNameOff && width < textLng) {
        exchangeNameOff = true;
        textLng -= (exchangeNameLength * 9);
        exchangeName = "";
      }
      if (!openInterestOff && width < textLng) {
        openInterestOff = true;
        textLng -= (openInterestLength * 9);
        openInterest = "";
      }
      if (!wonGroupingOff && width < textLng) {
        wonGroupingOff = true;
        textLng -= (wonGroupingLength * 9);
        wonGrouping = "";
      }
      if (!expiryDateOff && width < textLng) {
        expiryDateOff = true;
        textLng -= (expiryDateLength * 9);
        expiryDate = "";
      }
    }

    if (!ipoOff && !StringUtil.isEmpty(ipoText) && ipoText.trim() !== "") {
      dymanicViews.push(<div key="3" className="info-dot medium-normal" id="div3" style={textIPOStyle} onClick={() => props.changeIPOFormat()} >{ipoText}</div>);
      dymanicViews.push(<div key="4" className="divider" />);
    }
    // INDUSTRYGROUP
    if (!groupRankOff && props.groupRank && props.groupRank.length > 0 && isIndustryGroup && isIGgrpRk) {
      dymanicViews.push(<div key="5" className="info-wht medium-normal">{groupRankText}</div>);
      dymanicViews.push(<div key="6" className="divider" />);
    }
    if (!exchangeOff && !StringUtil.isEmpty(exchangeText) && exchangeText.trim() !== "") {
      const nqname = LocalizationStore.getTranslatedData(`Index_Exchange_${excode}`, exchangeText)
        !== exchangeText ? LocalizationStore.getTranslatedData(`Index_Exchange_${excode}`, exchangeText)
        : LocalizationStore.getTranslatedData(`Index_Exchange_${exchangeText}`, exchangeText);

      dymanicViews.push(
        <div key="7" className={textClass}>
          <HeaderDefination
            industryGroup={nqname}
            infoData={props.infoData}
            isStock={true}
            view={'NAQ'}
            definationSymbol={definationSymbols.DEF_EXCHANGE}
          />
        </div>
      );
      dymanicViews.push(<div key="27" className="divider" />);
    }
    if (!isin && !StringUtil.isEmpty(isinText) && isinText.trim() !== "") {
      if(isinText.length === 12 && !isNaN(isinText.slice(-1))) {
        dymanicViews.push(<div key="28" className={textClass}>{isinText}</div>);
        dymanicViews.push(<div key="29" className="divider" />);
      }
    }

    if (!industryGroup && props.industryGroup !== null && !StringUtil.isEmpty(props.industryGroup)) {
      if(props.SymbolInfo.SymTypeEnum === SymbolType.FUTURE || props.SymbolInfo.SymTypeEnum === SymbolType.CASHSPOTS
        || props.SymbolInfo.SymTypeEnum === SymbolType.FUTURECONTRACTS ){
        if (!wonGroupingOff && !StringUtil.isEmpty(wonGrouping) && wonGrouping.trim() !== "") {
          dymanicViews.push(<div key="4" className={textClass}>{wonGrouping}</div>);
       }
      }
      else {
        const smartViewType = DatagraphHelper.getSymbolType(props.SymbolInfo.SymTypeEnum);

          const gicsIndustryGroupName = props.gicsIndustryGroupName
            ? `GICS: ${props.gicsIndustryGroupName}`
            : props.industryGroup
              ? `GICS: ${LocalizationStore.getTranslatedData(`Index_Industry_${props.infoData.industry197Symbol}`, props.industryGroup)}`
              : '';
          dymanicViews.push(
            <div key="8" className={textClass}>
              <IndustryGroupHeader
                industryGroup={props.isGICS ? gicsIndustryGroupName : props.industryGroup}
                infoData={props.infoData}
                isGICS={props.isGICS}
                isStock={smartViewType === SmartViewType.STOCK}
                isConstituent={false}
                view={smartViewType}
                industryListData={!StringUtil.isEmpty(props.groupsMainData) && props.groupsMainData["IndustryList"]}
              />
            </div>
          )
      }
      if(props.SymbolType !== SmartViewType.CASHSPOTS){
        dymanicViews.push(<div key="9" className="divider" />);
      }
    }

    if (!mktCapOff && !StringUtil.isEmpty(mktCapText) && mktCapText.trim() !== "") {
      dymanicViews.push(
        <div key="10" className={textClass}>
          <HeaderDefination
            industryGroup={mktCapText}
            infoData={props.infoData}
            isStock={true}
            view={'NAQ'}
            definationSymbol={definationSymbols.DEF_MARKETCAP}
          />
        </div>
      );
    }
    if (!salesOff && !StringUtil.isEmpty(salesText) && salesText.trim() !== "") {
      dymanicViews.push(
        <div key="11" className={textClass}>
          <HeaderDefination
            industryGroup={salesText}
            infoData={props.infoData}
            isStock={true}
            view={'NAQ'}
            definationSymbol={definationSymbols.DEF_SALES}
          />
        </div>
      );
    }
    if (!mktCapOff && (!StringUtil.isEmpty(mktCapText) && mktCapText.trim() !== "" || !StringUtil.isEmpty(salesText) && salesText.trim() !== "")) {
      dymanicViews.push(<div key="12" className="divider" />);
    }
    if (!sharesOff && !StringUtil.isEmpty(sharesText) && sharesText.trim() !== "") {
      dymanicViews.push(
        <div key="13" className={textClass}>
          <HeaderDefination
            industryGroup={sharesText}
            infoData={props.infoData}
            isStock={true}
            view={'NAQ'}
            definationSymbol={definationSymbols.DEF_SHARES}
          />
        </div>
      );
    }
    if (!floatOff && !StringUtil.isEmpty(floatText) && floatText.trim() !== "") {
      dymanicViews.push(
        <div key="14" className={textClass}>
          <HeaderDefination
            industryGroup={floatText}
            infoData={props.infoData}
            isStock={true}
            view={'NAQ'}
            definationSymbol={definationSymbols.DEF_FLOAT}
          />
        </div>
      );
    }
    if (props.SymbolType !== SmartViewType.CEF && props.SymbolType !== SmartViewType.ETF && (!sharesOff && !StringUtil.isEmpty(sharesText) && sharesText.trim() !== "" || !floatOff && !StringUtil.isEmpty(floatText) && floatText.trim() !== "")) {
      dymanicViews.push(<div key="15" className="divider" />);
    }
    if (!epsDueOff && !StringUtil.isEmpty(epsDueText) && epsDueText.trim() !== "") {
      if(props.SymbolInfo.SymTypeEnum !== SymbolType.ETF && props.SymbolInfo.SymTypeEnum !== SymbolType.FundClosedEnd && props.SymbolInfo.SymTypeEnum !== SymbolType.FUND) {
      let classN = "info-dot medium-normal";
      let epsDef = definationSymbols.DEF_EPSDUEDATE;
      if (props.IsWithinTwoWeeks) {
        epsDef = definationSymbols.DEF_EPSNEARDATE;
        if (props.isHistoric) {
          classN = "info-dot-orange";
        } else {
          classN = "info-dot-yellow";

        }
      }
      dymanicViews.push(
        <div key="16" className={classN} id="div1" style={textEPSStyle} onClick={() => props.changeEPSFormat()}>
          <HeaderDefination
            industryGroup={epsDueText}
            infoData={props.infoData}
            isStock={true}
            isHistoric={props.isHistoric}
            view={'NAQ'}
            definationSymbol={epsDef}
          />
        </div>
      );
     }
    }
    if (!constituentsOff && constituentsText && constituentsText.length > 0) {
      dymanicViews.push(<div key="17" className="info-wht medium-normal">{props.constituentsHeader}</div>);
      let infoData = undefined;
      let view = undefined;

      infoData = {
        industry197Symbol: props.SymbolInfo.Symbol,
        msid: null
      }

      if (!sectorOff && props.sector && props.sector.length > 0) {
        view = SmartViewType.INDUSTRYGROUP;
      }
      else {
        view = SmartViewType.CONSTITUENTS;
      }

      if (info.SymTypeEnum === 21){
        view = SmartViewType.MAJORINDUSTRY;
      }
      dymanicViews.push(<div key="18" className="info-wht medium-normal">
        <IndustryGroupHeader
          industryGroup={constituentsText}
          infoData={infoData}
          isGICS={false}
          isStock={false}
          isConstituent={true}
          view={view}
          industryListData={[]}
        />
      </div>
      )
      // dymanicViews.push(<div key="6" className="info-dot medium-normal" style={textLinkStyle}>{constituentsText}</div>);
      dymanicViews.push(<div key="19" className="divider" />);
    }
    if (parseInt(constituentsText) > 0) {
      if (!sectorOff && props.sector && props.sector.length > 0) {
        dymanicViews.push(<div key="20" className="info-wht medium-normal">{props.sectorHeader}</div>);

        const infoData = {
          industry197Symbol: props.sectorCode,
          msid: null
        }
        dymanicViews.push(<div key="21" className="info-wht medium-normal">
          <IndustryGroupHeader
            industryGroup={sectorText}
            infoData={infoData}
            isGICS={false}
            isStock={false}
            isConstituent={false}
            view={SmartViewType.SECTOR}
            industryListData={[]}
          />
        </div>
        )
      }
    }
    //FUTURES
    if (!openInterestOff && !StringUtil.isEmpty(openInterest) && openInterest.trim() !== "") {
      dymanicViews.push(<div key="22" className={textClass}>{openInterest}</div>);
      dymanicViews.push(<div key="23" className="divider" />);
    }
    const textExpireStyle = { textDecoration: 'none', cursor: 'pointer', pointerEvents: 'all' };

    if (!expiryDateOff && !StringUtil.isEmpty(expiryDate)) {
      const date = props.endDate;//new Date();
      const afterTwoWeeksDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 14)
      const inTwoWeeks = props.expiryDate.getTime() <= afterTwoWeeksDate.getTime();
      let expiryDateClass = inTwoWeeks ? "info-dot-yellow medium-normal" : "info-dot medium-normal";
      if (getHistoricShadingVisibility() === true){
        expiryDateClass = inTwoWeeks ? "info-dot-orange medium-normal" : "info-dot medium-normal";
      }
      dymanicViews.push(<div key="24" className={expiryDateClass} style={textExpireStyle} onClick={() => props.SwitchexpiryDate()}>{expiryDate}</div>);
    }


    if (!exdOff && !StringUtil.isEmpty(exdText) && exdText.trim() !== "") {
      const pDRTxt = exdText.split(':')
      dymanicViews.push(
        <div key="25" className="pdr-place info-wht">
          <span className="pdr-label">{(pDRTxt[0].length>20)?<span className="tooltip tipsy">{pDRTxt[0]}</span>:""}
              <span className="text">{pDRTxt[0]}</span></span>
          <div key="26" className="pdr-value" id="div1" style={textEXTStyle} onClick={props.toggleExtDataItem}>
            <HeaderDefination
              industryGroup={pDRTxt[1]}
              infoData={props.infoData}
              isStock={true}
              isHistoric={props.isHistoric}
              view={'NAQ'}
              definationSymbol={definationSymbols.DEF_EXTERNALDATA}
              isInteractiveData={props.PDR_info.headerData.length > 1}
            />
          </div>
        </div>
      );
    }
    return dymanicViews;
  }

  const setItemActive = () => {
    UpdateTemplisttoExplorer({ symbol: props.SymbolInfo.Symbol, msId: props.SymbolInfo.Osid, view: SmartViewType.INDEX });    
  }
  
  const userInfo = UserInfoUtil.getUserInfo();
  const entitleIdxUserList = userInfo.entitleIdxUserList;
  const result = [];
  if (entitleIdxUserList) {
    entitleIdxUserList.forEach((item) => {
      if (item && item.symbol === props.SymbolInfo.Symbol && item.isActive === true) {  
        result.push(item);
      }
    });
  }
  const isUserEntitled = result.length > 0;
  const headerClass= props.isHistoric ? "header custom-header-picker": "header";
  return (
      <div className={headerClass} ref={headerEl}>
         <IndicatorFlyoutMenu />
        <div className="symbol-full-name">{props.SymbolInfo.CompanyName}</div>     
        <div className="symbol-name">
          {props.SymbolInfo ? isLocalSymbolEntitle && props.SymbolInfo.localSymbol ? `   ${props.SymbolInfo.localSymbol}` : `   ${props.SymbolInfo.Symbol}`: ''}
          <span className={props.isFlagged ? 'icn-flag active' : 'icn-flag'}></span>
          <div className="divider"></div>
        </div>
        {!props.hideHeaderText && getHeaderText()}
        {props.constituentsCount && !props.isHistoric ?
          <div className="info-wht medium-normal"> <span className="constituent-head">{props.constituentsHeader} </span>
            {isUserEntitled ?
              <div className="info-dot medium-normal" style={{ textDecoration: 'none', cursor: 'pointer', pointerEvents: 'all' }} onClick={()=>setItemActive()} >{props.constituentsCount}</div>
              :
              <div className="info-wht medium-normal" >{props.constituentsCount}</div>
            }
          </div> : ""}
      </div>
  );
};

const mapStateToProps = (state) => {
  const { hideHeaderText, endDate, Isin, ipo, exchange, excode,
    industryGroup, groupRank, targetCurrencyCode, sales, salesValue, shares,
    float, epsDueDate, IsWithinTwoWeeks, epsDue, mktCap, infoData, constituents, sector,
    sectorCode, sectorHeader, constituentsHeader, lastTradeDate,
    exchangeName, wonGrouping, openInterest, expiryDate, ExpiryState, gicsIndustryGroupName, isGICS, 
    updatesize, ipoDate } = state.DatagraphReducers.HeaderReducer;

  const { SymbolInfo, SymbolType, isHistoric, priceDataAvailable, isCommonStock } = state.DatagraphReducers.DataGraphReducer;
  const {  isFlagged } = state.DatagraphReducers.SymbolEntryReducer;
  const { constituentsCount } = state.summaryReducer;
  const { externalDataText, PDR_info, headerdatalength} = state.externalDataUploader.externalDataUploadSymbolReducer;
  const groupsMainData = getNavCompareState(state).groupsMainData;

  return ({
    SymbolInfo, isHistoric, endDate, Isin, ipo, exchange, excode,
    industryGroup, groupRank, targetCurrencyCode, sales, salesValue, shares,
    float, epsDueDate, IsWithinTwoWeeks, epsDue, mktCap, infoData, groupsMainData, 
    constituents, sector, sectorCode,  sectorHeader, constituentsHeader, constituentsCount, externalDataText, PDR_info, headerdatalength,lastTradeDate,
    exchangeName, wonGrouping, openInterest, expiryDate, ExpiryState, gicsIndustryGroupName, isGICS, updatesize, 
    SymbolType, isFlagged, priceDataAvailable, hideHeaderText, isCommonStock, ipoDate
  });
}
const mapDispatchToProps = (dispatch) => ({
    changeEPSFormat: () => dispatch(changeEPSFormat()),
    changeIPOFormat: () => dispatch(changeIPOFormat()),
    SwitchexpiryDate:() => dispatch(SwitchexpiryDate()),
    toggleExtDataItem:()=> dispatch(toggleExtDataItem()),
    setGroupsMainData: ()=> dispatch(setGroupsMainData())   
})
export default connect(mapStateToProps, mapDispatchToProps)(Header);