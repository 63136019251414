import { connect } from 'react-redux';
import { getAnnotations } from '../../../../Reducers/AnnotationReducers/reselectorsdata';
import onClickOutside from "react-onclickoutside";
import { RayAnnotationType } from 'Constants/EAnnotationType';
import stringUtil from '../../../../Utils/StringUtil';
import React, { Component } from 'react';
import {
    updateRiPanelInfo, updateSelectedAnnotationId, clearSelectedAnnotation, handleToggleContextMenu,
    handleTextClick, onAnnotationToolChange, handleEscClick
} from 'Actions/AnnotationActions';

class AnnotationsSvgContent extends Component {
    constructor(props) {
        super(props);
        this.annotationPathFunctions = {};
        this.annotationPathFunctions[RayAnnotationType.LINE_AnnotationType] = this.getLineAnnotPath.bind(this)
        this.annotationPathFunctions[RayAnnotationType.TEXT_AnnotationType] = this.getTextPath.bind(this);
        this.annotationPathFunctions[RayAnnotationType.RECT_AnnotationType] = this.getRectanglePath.bind(this);
        this.annotationPathFunctions[RayAnnotationType.ELLIPSE_AnnotationType] = this.getEllipsePath.bind(this);
        this.annotationPathFunctions[RayAnnotationType.TRIANGLE_AnnotationType] = this.getTrianglePath.bind(this);
        this.annotationPathFunctions[RayAnnotationType.PARALLEL_LINES_AnnotationType] = this.getChannelLinePath.bind(this);
        this.annotationPathFunctions[RayAnnotationType.FIB_AnnotationType] = this.getFibonacciLinePath.bind(this);
        this.annotationPathFunctions[RayAnnotationType.MEASUREMENT_AnnotationType] = this.getMeasurementPath.bind(this);
        this.labelPositionConst = {
            2: 14,
            4: 15,
            6: 16,
            8: 17,
            16: 20
        }
    }

    handleClickOutside(e) {
        if (e.target.id === "dummyDiv") {
            const isEditingText = this.props.textReducer.find((itm) => itm.isEditable === true);
            if (isEditingText) {
                this.props.handleEscClick();
            }
            this.props.toggleContextMenu(false, 0, 0, '');
            this.props.clearSelectedAnnotation();
            this.props.onAnnotationToolChange(this.props.tempToolType); // tempToolType should be cursor/pointer always
        }
        else {
            if (document.querySelector("#relatedInfoBox").contains(e.target) || (document.querySelector("#portaldiv") && document.querySelector("#portaldiv").contains(e.target))) {
                return
            }
            this.props.onAnnotationToolChange(this.props.tempToolType);
        }
    }

    onContextClick = (e, data) => {
        this.props.closeOtherContextMenu();
        this.props.updateRiPanelInfo(data, true, false);

        if (e.nativeEvent.which === 3) {
            const { selectedAnnotId } = this.props;
            if (selectedAnnotId.length > 0) {
                if (!selectedAnnotId.includes(data.id)) {
                    this.props.clearSelectedAnnotation();
                    this.props.updateSelectedAnnotationId(data.id);
                }
            } else {
                this.props.clearSelectedAnnotation();
                this.props.updateSelectedAnnotationId(data.id);
            }
            this.props.toggleContextMenu(true, e.clientX, e.clientY, data.type);
        }
        e.stopPropagation();
        e.preventDefault();
        return false;
    }

    handleMouseOver = () => {
        const annotationDiv = document.getElementsByClassName('annotation-graphics')[0];
        if (stringUtil.isEmpty(annotationDiv)) {
            return;
        }

        annotationDiv.className = "annotation-graphics";
        annotationDiv.style.zIndex = 10;
    }
    getAlertLinePath = (data, layerID) => {
        const startPoint = { x: data.x, y: data.y };

        if (data.isAlertChecked) {
            return (<rect
                width={12}
                height={14}
                x={startPoint.x - 18}
                y={startPoint.y - 7}
                id="annot-text"
                data-disable-track-price="true"
                key={`LineAlert-${layerID}-${data.id}`}
                annot-id={data.id}
                stroke="transparent"
                fill="none"
                cursor="pointer"
                pointerEvents="visible"
                onMouseOver={() => this.handleMouseOver()}
                onFocus={() => this.handleMouseOver()}
                onContextMenu={(e) => this.onContextClick(e, data)}
            />);
        }
    }

    getLinePath = (data, layerID) => {
        let startPoint = { x: data.x, y: data.y }, endPoint = { x: data.endMouseX, y: data.endMouseY };

        if (data.startExtendChecked && !stringUtil.isEmpty(data.startExtendPoint)) {
            startPoint = { x: data.startExtendPoint.x, y: data.startExtendPoint.y };
        }
        if (data.endExtendChecked && !stringUtil.isEmpty(data.endExtendPoint)) {
            endPoint = { x: data.endExtendPoint.x, y: data.endExtendPoint.y };
        }

        const pathData = `M ${startPoint.x} ${startPoint.y} L ${endPoint.x} ${endPoint.y} `;

        return (<path d={pathData}
            id="annot-line"
            data-disable-track-price="true"
            key={`Line-${layerID}-${data.id}`}
            annot-id={data.id}
            stroke="transparent"
            fill="none"
            strokeWidth={data.weight < 6 ? 6 : data.weight}
            cursor="pointer"
            pointerEvents="visible"
            onMouseOver={() => this.handleMouseOver()}
            onFocus={() => this.handleMouseOver()}
            onContextMenu={(e) => this.onContextClick(e, data)}
        />)
    }
    getLineAnnotPath(data, layerID) {
        return <React.Fragment>
            {this.getLinePath(data, layerID)}
            {this.getAlertLinePath(data, layerID)}
        </React.Fragment>
    }

    getChannelLinePath = (data, layerID) => {
        let startPoint1 = { x: data.x, y: data.y }, endPoint1 = { x: data.endMouseX, y: data.endMouseY }, startPoint2 = { x: data.x, y: data.y - data.spreadMiddle }, endPoint2 = { x: data.endMouseX, y: data.endMouseY - data.spreadMiddle };

        if (data.startExtendChecked) {
            startPoint1 = { x: data.startExtendPoint.x, y: data.startExtendPoint.y };
            startPoint2 = { x: data.startExtendPoint.x, y: data.startExtendPoint.y - data.spreadMiddle };
        }
        if (data.endExtendChecked) {
            endPoint1 = { x: data.endExtendPoint.x, y: data.endExtendPoint.y };
            endPoint2 = { x: data.endExtendPoint.x, y: data.endExtendPoint.y - data.spreadMiddle };
        }

        const pathData = `M ${startPoint1.x} ${startPoint1.y} L ${endPoint1.x} ${endPoint1.y} M ${startPoint2.x} ${startPoint2.y} L ${endPoint2.x} ${endPoint2.y} `;

        return (<path d={pathData}
            id="annot-channelLine"
            data-disable-track-price="true"
            key={`Channel-${layerID}-${data.id}`}
            annot-id={data.id}
            stroke="transparent"
            fill="none"
            strokeWidth={data.weight < 6 ? 6 : data.weight}
            cursor="pointer"
            pointerEvents="visible"
            onMouseOver={() => this.handleMouseOver()}
            onFocus={() => this.handleMouseOver()}
            onContextMenu={(e) => this.onContextClick(e, data)}
        />)
    }

    getRectanglePath = (data, layerID) => {
        let width, height, x, y;
        width = height = x = y = 0;
        const borderSpace = data.borderWeight / 2;
        switch (data.direction) {
            case 'SE':
                width = data.endMouseX - data.x;
                height = data.endMouseY - data.y;
                x = data.x; y = data.y;
                break;
            case 'SW':
                width = data.x - data.endMouseX;
                height = data.endMouseY - data.y;
                x = data.endMouseX; y = data.y;
                break;
            case 'NW':
                width = data.x - data.endMouseX;
                height = data.y - data.endMouseY;
                x = data.endMouseX; y = data.endMouseY;
                break;
            case 'NE':
                width = data.endMouseX - data.x;
                height = data.y - data.endMouseY;
                x = data.x; y = data.endMouseY;
                break;
            default: break;
        }
        return (<rect
            width={width + data.borderWeight}
            height={height + data.borderWeight}
            x={x - borderSpace}
            y={y - borderSpace}
            id="annot-rect"
            data-disable-track-price="true"
            key={`Rect-${layerID}-${data.id}`}
            annot-id={data.id}
            stroke="transparent"
            fill="none"
            strokeWidth={data.weight < 6 ? 6 : data.weight}
            cursor="pointer"
            pointerEvents="visible"
            onMouseOver={() => this.handleMouseOver()}
            onFocus={() => this.handleMouseOver()}
            onContextMenu={(e) => this.onContextClick(e, data)}
        />);
    }

    getEllipsePath = (data, layerID) => {
        let rx, ry, x, y;
        rx = ry = x = y = 0;
        x = (data.x + data.endMouseX) / 2;
        y = (data.y + data.endMouseY) / 2;
        const borderSpace = data.borderWeight / 2;
        switch (data.direction) {
            case 'SE':
                rx = (data.endMouseX - data.x) / 2;
                ry = (data.endMouseY - data.y) / 2;
                break;
            case 'SW':
                rx = (data.x - data.endMouseX) / 2;
                ry = (data.endMouseY - data.y) / 2;
                break;
            case 'NW':
                rx = (data.x - data.endMouseX) / 2;
                ry = (data.y - data.endMouseY) / 2;
                break;
            case 'NE':
                rx = (data.endMouseX - data.x) / 2;
                ry = (data.y - data.endMouseY) / 2;
                break;
            default: break;
        }

        return (
            <ellipse
                cx={x}
                cy={y}
                rx={rx + borderSpace}
                ry={ry + borderSpace}
                id="annot-ellipse"
                data-disable-track-price="true"
                key={`Ellipse-${layerID}-${data.id}`}
                annot-id={data.id}
                stroke="transparent"
                fill="none"
                strokeWidth={data.weight < 6 ? 6 : data.weight}
                cursor="pointer"
                pointerEvents="visible"
                onMouseOver={() => this.handleMouseOver()}
                onFocus={() => this.handleMouseOver()}
                onContextMenu={(e) => this.onContextClick(e, data)} />
        );
    }

    getTextPath = (data, layerID) => {
        const { width, height } = data;
        let x = 0, y = 0;
        x = data.x; y = data.y;
        if (data.endMouseX < data.x) {
            x = data.endMouseX;
        }
        const borderSpace = data.borderWeight / 2;
        return (<rect
            width={width + borderSpace}
            height={height + 6 + borderSpace}
            x={x}
            y={y}
            id="annot-text"
            data-disable-track-price="true"
            key={`Text-${layerID}-${data.id}`}
            annot-id={data.id}
            stroke="transparent"
            fill="none"
            strokeWidth={data.weight < 6 ? 6 : data.weight}
            cursor="pointer"
            pointerEvents="visible"
            onMouseOver={() => this.handleMouseOver()}
            onFocus={() => this.handleMouseOver()}
            onContextMenu={(e) => this.onContextClick(e, data)}
        />);
    }

    getTrianglePath = (data, layerID) => {
        const { x, y, endMouseX, endMouseY } = data;
        return (
            <polygon
                points={`${(x + endMouseX) / 2},${y} ${endMouseX},${endMouseY} ${x},${endMouseY}`}
                id="annot-triangle"
                data-disable-track-price="true"
                key={`Triangle-${layerID}-${data.id}`}
                annot-id={data.id}
                stroke="transparent"
                fill="none"
                strokeWidth={data.weight < 6 ? 6 : data.weight}
                cursor="pointer"
                pointerEvents="visible"
                onMouseOver={() => this.handleMouseOver()}
                onFocus={() => this.handleMouseOver()}
                onContextMenu={(e) => this.onContextClick(e, data)} />
        );
    }

    getFibonacciLinePath = (data, layerID) => {
        const startPoint = { x: data.x, y: data.y }, endPoint = { x: data.endMouseX, y: data.endMouseY };
        let pathData = "", extensionPathData = "", lablePath = [];

        data.geometry.map((item) => {
            if (item.lineInfo && item.textInfo) {
                const line = item.lineInfo;
                const textInfo = item.textInfo;
                const textPos = line.isExtensions ? this.labelPositionConst[data.extensionLineWeight] || 13 : this.labelPositionConst[data.weight] || 13;

                if (data.startExtendChecked) {
                    startPoint.x = 0;
                }
                if (data.endExtendChecked) {
                    endPoint.x = this.props.annotationDimension.width - 6;
                }

                if (line.isExtensions) {
                    extensionPathData += `M ${startPoint.x} ${line.y} L ${endPoint.x} ${line.y} `;

                    lablePath += `M ${textInfo.x} ${(line.y - textPos)} h ${textInfo.width} v ${20} h ${-textInfo.width} Z `;
                }
                else {
                    pathData += `M ${startPoint.x} ${line.y} L ${endPoint.x} ${line.y} `;

                    lablePath += `M ${textInfo.x} ${line.y - textPos} h ${textInfo.width} v ${20} h ${-textInfo.width} Z `;
                }
            }
        });

        return (
            <>
                <path d={extensionPathData}
                    id="annot-fibLine-extension"
                    data-disable-track-price="true"
                    key={`Fib-${layerID}-${data.id} - extension`}
                    annot-id={data.id}
                    stroke="transparent"
                    fill="none"
                    strokeWidth={data.extensionLineWeight < 6 ? 6 : data.extensionLineWeight}
                    cursor="pointer"
                    pointerEvents="visible"
                    onMouseOver={() => this.handleMouseOver()}
                    onFocus={() => this.handleMouseOver()}
                    onContextMenu={(e) => this.onContextClick(e, data)}
                />
                <path d={pathData}
                    id="annot-fibLine"
                    data-disable-track-price="true"
                    key={`Fib-${layerID}-${data.id}`}
                    annot-id={data.id}
                    stroke="transparent"
                    fill="none"
                    strokeWidth={data.weight < 6 ? 6 : data.weight}
                    cursor="pointer"
                    pointerEvents="visible"
                    onMouseOver={() => this.handleMouseOver()}
                    onFocus={() => this.handleMouseOver()}
                    onContextMenu={(e) => this.onContextClick(e, data)}
                />
                <path d={lablePath}
                    id="annot-fibLine-lable"
                    data-disable-track-price="true"
                    key={`Fib-${layerID}-${data.id} - lable`}
                    annot-id={data.id}
                    stroke="transparent"
                    fill="none"
                    strokeWidth={1}
                    cursor="pointer"
                    pointerEvents="visible"
                    onMouseOver={() => this.handleMouseOver()}
                    onFocus={() => this.handleMouseOver()}
                    onContextMenu={(e) => this.onContextClick(e, data)}
                />
            </>
        );
    }

    getMeasurementPath = (data, layerID) => {
        let pathData = "";

        if (data.isLeaderlinesChecked && data.snapStartPosition && data.snapEndPosition) {
            // pathData = "M " + data.snapStartPosition.x + " " + data.snapStartPosition.y + " L " + data.x + " " + data.y + " L " + data.endMouseX + " " + data.endMouseY + " L " + data.snapEndPosition.x + " " + data.snapEndPosition.y + " ";
            pathData = `M ${data.snapStartPosition.x} ${data.snapStartPosition.y} L ${data.x} ${data.y} M ${data.x} ${data.y} L ${data.endMouseX} ${data.endMouseY} M ${data.endMouseX} ${data.endMouseY} L ${data.snapEndPosition.x} ${data.snapEndPosition.y} `;
        }
        else {
            pathData = `M ${data.x} ${data.y} L ${data.endMouseX} ${data.endMouseY} `;
        }

        return (<path d={pathData}
            id="annot-measurement"
            data-disable-track-price="true"
            key={`Measure-${layerID}-${data.id}`}
            annot-id={data.id}
            stroke="transparent"
            fill="none"
            strokeWidth={6}
            cursor="pointer"
            pointerEvents="visible"
            onMouseOver={() => this.handleMouseOver()}
            onFocus={() => this.handleMouseOver()}
            onContextMenu={(e) => this.onContextClick(e, data)}
        />)
    }

    render() {
        const { groupedAnnots, LayersList } = this.props;

        return (
            <g>
                {LayersList.map((layer) => {
                    if (layer.isVisible) {
                        return groupedAnnots.hasOwnProperty(layer.layerID) && groupedAnnots[layer.layerID].map((item) => {
                            if (!item.isDeleted && this.annotationPathFunctions[item.type]) {
                                return { ...this.annotationPathFunctions[item.type](item, layer.layerID), key: `${layer.layerID}-${item.id}`}
                                
                            }
                        });
                    }
                })}
            </g>
        );
    }
}

const mapStateToProps = (state) => {
    const groupedAnnots = getAnnotations(state);
    const { LayersList } = state.annotationReducers.annotationLayerManagementReducer;
    const { tempToolType } = state.annotationReducers.annotationMenuReducer;
    const { selectedAnnotId, annotationDimension } = state.annotationReducers.annotationChartModelReducer;
    const { textReducer } = state.annotationReducers

    return ({ groupedAnnots, LayersList, tempToolType, selectedAnnotId, annotationDimension, textReducer });
}

const mapDispatchToProps = (dispatch) => ({
    updateRiPanelInfo: (data, isUpdateSelectedAnnotation, isUpdateRiPanelSettingsView) => dispatch(updateRiPanelInfo(data, isUpdateSelectedAnnotation, isUpdateRiPanelSettingsView)),
    updateSelectedAnnotationId: (id) => dispatch(updateSelectedAnnotationId(id)),
    clearSelectedAnnotation: () => dispatch(clearSelectedAnnotation()),
    toggleContextMenu: (showContext, clientX, clientY, shape) => dispatch(handleToggleContextMenu(showContext, clientX, clientY, shape)),
    handleTextClick: () => dispatch(handleTextClick()),
    onAnnotationToolChange: (selectedToolType) => dispatch(onAnnotationToolChange(selectedToolType)),
    handleEscClick: () => dispatch(handleEscClick())
});


export default connect(mapStateToProps, mapDispatchToProps)(onClickOutside(AnnotationsSvgContent));