import { DataGraphConstants } from '../../../Constants/DataGraphConstants'
import FactorResearchSetting from '../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/FactorResearchSettings';
import { PrintMode } from "../../../print/printmode";
import { RiPanelConstants } from '../../../Constants/RiPanelConstants'
import SettingsStore from "SettingsStore";

const { ActionTypes } = DataGraphConstants;

const resetState = {
    isLoading: false,
    periodicity: "",
    FRData: [],
    recoverList: [],
    selectedEvent: null,
    selectedFactorKey: null,
    graphOpenList: [],
    graphOpenListHistory: [],
    lastEventKey: [],
    removeList: [],
    factorEventInfo: null,
    factorEventData: {},
    oldSelectedFactorKey: [],
    oldSelectedEvent: [],
    Symbol: "",
    periodicity: "",
    endDate: null,
    factorsResultAvailable: null
}
const initialState = {
    visibility: true,
    lastOnly: true,
    factorSettings: new FactorResearchSetting(true),
    ...resetState
}
const FactorResearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FR_DATA_READY:{
            const lastFactor = action.mostRecentNode ? action.mostRecentNode.FactorKey : null;
            const isLastLoaded = (state.periodicity == action.periodicity && state.Symbol == action.Symbol);
            let graphOpen = null;
            if (state.endDate != null && state.endDate.getTime() != action._state.endDate.getTime()) {
                graphOpen = !PrintMode.printing ? lastFactor ? [lastFactor] : [] : action.factorSettings.graphOpenList;
            } else {
                graphOpen = !PrintMode.printing ? state.graphOpenList : action.factorSettings.graphOpenList
            }

            return {
                ...state,
                FRData: action.FRData,
                init: PrintMode.printing ? action.factorSettings.isModified : false,
                // graphOpenList: action.IsResize || isLastLoaded ? state.graphOpenList : lastFactor ? [lastFactor] : [],
                graphOpenList: action.IsResize || isLastLoaded || PrintMode.printing ? graphOpen : lastFactor ? [lastFactor] : [],
                graphOpenListHistory: action.IsResize || isLastLoaded || PrintMode.printing ? graphOpen : [lastFactor],
                removeList: [],
                recoverList: [],
                lastEventKey: lastFactor,
                selectedFactorKey: lastFactor,
                oldSelectedFactorKey: [lastFactor],
                oldSelectedEvent: action.mostRecentNode,
                factorSettings: action.factorSettings,
                visibility: action.factorSettings.IsVisible,
                lastOnly: action.factorSettings.LastOnly,
                Symbol: action.Symbol, 
                periodicity: action.periodicity,
                endDate: action._state.endDate
            }
        }
        case ActionTypes.CLEAR_FR_DATA:
            return {
                ...state,
                ...resetState
            }
        case RiPanelConstants.ActionTypes.TOGGLE_FR_VISIBILITY:
            state.factorSettings.IsVisible = action.visible;
            SettingsStore.saveSettings();
            return {
                ...state,
                visibility: action.visible
            }
        case RiPanelConstants.ActionTypes.TOGGLE_FR_LAST_ONLY:
            state.factorSettings.LastOnly = !state.lastOnly;
            SettingsStore.saveSettings();
            return {
                ...state,
                lastOnly: !state.lastOnly
            }
        case RiPanelConstants.ActionTypes.UPDATE_FR_LOADING:
            return {
                ...state,
                isLoading: action.loading
            }
        case RiPanelConstants.ActionTypes.FACTOREVENT_INFO_DATA:
            return {
                ...state,
                factorEventInfo: action.factorEventInfo,
                selectedEvent: action.event
            }

        case ActionTypes.SAVE_FR_DATA_FOR_RESIZE:
            return {
                ...state,
                factorEventData: action.factorEventData,
                factorsResultAvailable: true
            }

        case ActionTypes.SAVE_FR_DATA_SETTINGS:
            return {
                ...state,
                factorSettings: action.factorSettings,
                visibility: action.factorSettings.IsVisible,
                lastOnly: action.factorSettings.LastOnly,
                init: PrintMode.printing ? action.factorSettings.isModified : false,
            }

        case ActionTypes.FR_BUTTON_TILE_CLICK:
            state.factorSettings.graphOpenList = action.newGraphOpenList;
            SettingsStore.saveSettings();
            return {
                ...state,
                selectedFactorKey: action.newSelectedFactorKey,
                selectedEvent: action.newSelectedEvent,
                graphOpenList: action.newGraphOpenList,
                graphOpenListHistory: action.newGraphOpenListHistory
            }
        
        case ActionTypes.FR_DATA_AVAILABLE:
            return {
                ...state,
                factorsResultAvailable: false
            }
        default:
            return state;
    }
}
export default FactorResearchReducer;