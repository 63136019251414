import React from "react";
import { Provider } from 'react-redux';
import store from '../Redux/store';
import Login from "../Components/Login/Login.jsx";
import Loading from "./ConsoleWindow/Loading.jsx";
import SSOLogin from "../Components/Login/SSOLogin.jsx";
import SSOLoading from "./ConsoleWindow/SSOLoading.jsx";
import LoginActions from "../Actions/LoginActions.js";
import { HashRouter as Router, Route } from 'react-router-dom';
import { Switch, Redirect } from "react-router";
import Cookies from "universal-cookie";
import LoginStore from "LoginStore";
import Logout from "Components/Logout/Logout.jsx";
import UserInfoUtil from "UserInfoUtil";
import queryString from 'query-string';
import {PrintMode} from '../print/printmode';
const history = require("history").createHashHistory();
const cookies = new Cookies();

function loginRequired(loc) {
  const parsed = queryString.parse(loc.search);
  let isLoggedIn = false;
  PrintMode.printing=false;

  if (parsed.print) {
    PrintMode.printing= Boolean(parsed.print);
    PrintMode.workspaceId = Number(parsed.workspaceId);
    if (loc.pathname) {
      const pathArr = loc.pathname.split('/');
      if (/^\/Symbol(\/{1})\w*/i.test(loc.pathname)) {
        PrintMode.ActiveNav = pathArr[1];
        let symbol = '';
        pathArr.map((item, i) => { if (i > 1) symbol += item + "/"; });
        PrintMode.symbol = symbol;
      }
      else {
        PrintMode.ActiveNav = pathArr[1];
      }
    }

    // var cookie = window.localStorage.getItem("_pUserLocal")
    var cookie = cookies.get('_pUser');
    let userData = cookie.split("+");
    let userId = cookie.substr(0, cookie.indexOf("+"));
    let entitlements = userData[1].split(",");
    entitlements = entitlements.map((item) => parseInt(item));
    let user = {};
    user.userId = userId;
    user.userEmail = userData[3];
    user.entitlements = entitlements;
    localStorage.setItem("_pUserLocal", cookie);
    sessionStorage.setItem("_pUser", cookie);
    UserInfoUtil.setUserInfo(user);
    let date = new Date();
    date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
    isLoggedIn = true;
  }

  if(parsed.ErrMessage){
    LoginStore.closeWindow();
    LoginStore.onSSOUserLoginFailed(parsed.ErrMessage);
  }
  else if(parsed.cookie && loc && loc.search){
    LoginStore.closeWindow();
    let splitStringList = loc.search.split("?");
    let parameterString = splitStringList.length > 1 ? splitStringList[1] : null;
    let cookieNew = null;
    if(parameterString && parameterString.length > 0){
      let parameterSplitList = parameterString.split("=");
      let cookieIndex = parameterSplitList.indexOf("cookie");
      cookieNew = parameterSplitList.length > cookieIndex + 1 ? parameterSplitList[cookieIndex + 1] : null ;
    }
    if(cookieNew){
      let result = cookieNew;
      let userData = result.split("+");
      let userId = result.substr(0, result.indexOf("+"));
      let entitlements = userData[1].split(",");
      entitlements = entitlements.map((item) => parseInt(item));
      let user = {};
      user.userId = userId;
      if(userData.length > 3){
        user.userEmail = userData[3];
      }
      user.entitlements = entitlements;
      user.userPassword = null;
      user.providerId = userData[userData.length - 2];
      UserInfoUtil.setUserInfo(user);
      LoginActions.getUserInitInfo(cookieNew, userData);  
    }
  }

  if (loc.pathname && /^\/Symbol(\/{1})\w*/i.test(loc.pathname) && !LoginStore.isUserLoggedIn()) {
    window.localStorage.setItem('emailLinkedSym', loc.pathname);
  }
  if (!LoginStore.isUserLoggedIn() && loc.pathname && isValidPath(loc.pathname)) {
    const states = LoginStore.getState();
    states.returnUrl = loc.pathname;
  }
  if (LoginStore.isUserLoggedIn()) {
    isLoggedIn = true;
  }
  return isLoggedIn;
}

function isValidPath(path) {
  return (/^\/Symbol(\/{1})\w*/i.test(path) || /^\/Lists/i.test(path) || /^\/Compare/i.test(path) || /^\/Research/i.test(path) || /^\/NavNews/i.test(path) || /^\/Account-Analyzer/i.test(path));
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    loginRequired(props.location) ? (
      <div>
        <Component {...props} />
      </div>
    ) : (
        <Redirect to={{
          pathname: "/Login",
          state: { from: "/" }
        }} />
      )
  )} />
);

export default class App extends React.Component {

  render() {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/Login" component={Login} />
            <Route path="/SSO" component={SSOLogin} />
            <Route path="/Logout" component={Logout} />
            <PrivateRoute path="/SSOLogin" component={SSOLoading} />
            <PrivateRoute path="/" component={Loading} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}
