import React from "react";
import ReactDOM from "react-dom";
import ExplorerTop from "../Explorer/ExplorerTop.jsx";
import ExplorerBottom from "../Explorer/ExplorerBottom.jsx";
import Explorer from "../Explorer/Explorer.jsx";
import ONeilListView from "../ListManager/ONeilListView.jsx";
import ONeilViewStore from "ONeilViewStore";
import ListStore from "ListStore";
import ListProperty from "../../../../RayCustomControls/ListExplorer/ListProperty.jsx";
import ListManagerTabType from "ListManagerTabType";
import { ListExplorerConstants } from "ListExplorerConstants";
import ListActions from "ListActions";
import ResizeActions from "Actions/ResizeActions.js";
import BaseServiceApi from 'BaseServiceApi';
import ListExplorerStore from "ListExplorerStore";
import StringUtil from "StringUtil";
import ContextMenuConstants from "ContextMenuConstants";
import ResizableWindow from "RayCustomControls/ResizableWindow/ResizableWindow.jsx";
import GridStore from "GridStore";
import ScrollBar from "ScrollBar";
import ListExplorerUtil from "ListExplorerUtil";
import LocalizationStore from "../../../../Stores/Localization/LocalizationStore";
import TabONeilSettings from '../../../../Stores/ConsoleWindow/Settings/Modules/ListManager/TabONeilSettings.js';
import { SettingsConstants } from "../../../../Constants/SettingsConstants.js";
import SettingsAction from "SettingsAction";
import ConsoleStore from "../../../../Stores/ConsoleWindow/ConsoleStore.js";
import { dispatch } from "../../../../Redux/dispatch.js";
import { updateIsResizing } from "../../../../Actions/NupViewActions.js";
let ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
let ListType = BaseServiceApi.rayData["ListType"];

export default class TabONeil extends React.Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.addNewItem = this.addNewItem.bind(this);
    this.toggleHelp = this.toggleHelp.bind(this);
    this.onExplorerContextMenuClick = this.onExplorerContextMenuClick.bind(this);
    this.fetchListCataglogData = this.fetchListCataglogData.bind(this);
    this.listStoreStateChange = this.listStoreStateChange.bind(this);
    this.listExplorerStateChange = this.listExplorerStateChange.bind(this);
    this.duplicateItem = this.duplicateItem.bind(this);
    this.handleFolderClick = this.handleFolderClick.bind(this);
    this.showOriginalClick = this.showOriginalClick.bind(this);
    this.showOriginal = this.showOriginal.bind(this);
    this.onGridColumnContextMenuClick = this.onGridColumnContextMenuClick.bind(this);
    this.onGridCustomFilterContextMenuClick = this.onGridCustomFilterContextMenuClick.bind(this);
    this.onGridRowContextMenuClick = this.onGridRowContextMenuClick.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onStopResize = this.onStopResize.bind(this);
    this.showExplorer = this.showExplorer.bind(this);
    this.showValidationWindow = this.showValidationWindow.bind(this);
    this.updateScrollBar = this.updateScrollBar.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.loadingListExplorer = this.loadingListExplorer.bind(this);

    this.state = {
      addNewItemToList: false,
      showHelp: false,
      listId: this.props.listId,
      actualListId: this.props.actualListId,
      showContextMenu: false,
      isDeleteEnable: ListExplorerStore.isDeleteAllowed(ListExplorerStore.nodeType),
      deleteNode: ListExplorerStore.activeNode,
      listExplorerWidth: ListStore.getListExplorerSettings().Width,
      resizeHandle: false,
      loading: ListExplorerStore.loadingListExplorer,
      isAlertEnable: ListExplorerStore.isAlertAllowed(ListExplorerStore.nodeType),
      columnSetProperties: null
    }
    this.rootParentId = null;
    this.parentId = null;
    this.selectedNodeId = null;
    this.categoryType = null;
    this.listType = null;
    this.nodeType = null;
    this.listExplorerSettings = ListStore.getListExplorerSettings();
    this.showExplorerWindow = true;
    this.previousNodeId = this.previousSelectedNodeId;
    this.listApiCalled = false;
  }

  UNSAFE_componentWillMount() {
    ListStore.addChangeListener(this.listStoreStateChange);
    ListExplorerStore.addChangeListener(this.listExplorerStateChange);
  }

  componentDidMount() {
    let state = ONeilViewStore.getState();
    let currentAction = ListStore.getCurrentAction();
    let nodeList = [];
    const currentListExplorerAction = ListExplorerStore.getCurrentAction();
    if (ONeilViewStore.initialCall == true || state.listId != this.state.listId || this.props.nodeType == ListCategoryType.TEMPORARYLIST_ListCategory || (this.props.nodeType == ListCategoryType.NOTIMPORTEDLIST_ListCategory && currentListExplorerAction !== ListExplorerConstants.ActionTypes.GET_DELETE_TREE_NODE)) { // make basic call if user switch to list manager immediate after sign in or user has changed list item on mini list
        const tabONeilSettings = new TabONeilSettings();
        const readyData = tabONeilSettings.getDefaultSelectedListInfo();
        let listAvailable = ListExplorerStore.findActiveListAvailibility(this.props.listId);
        const isInit = state.listId == null;
        if(!listAvailable && this.state.listId == readyData.SelectedListId){          
          this.listId = this.state.listId;
          this.fetchListCataglogData(readyData.SelectedListId, readyData.SelectedActualListId, readyData.SelectedListName, true, true, isInit);
          ListExplorerStore.expandAndFindParentNode(readyData.SelectedListId, nodeList);
        }else{
          const listState = ListStore.getSelectedListItem();
          this.listId = listState.SelectedListId;
          ListExplorerStore.expandAndFindParentNode(this.listId, nodeList);
          ListExplorerStore.activeNode = nodeList[0];
          this.refs.ONeilListView.getListCatalog(listState.SelectedListId, listState.SelectedActualListId, listState.SelectedListName, 0, -1, false); //actual ListId appended
        }
        this.listApiCalled = true;     
    }
    else { // Do not make basic call if user switch from symbol to list and has not changed list item on mini list
      if (this.refs.ONeilListView.listCatalog.listId != this.state.listId) this.refs.ONeilListView.listCatalog.searchText = '';

      this.refs.ONeilListView.listCatalog.listId = this.state.listId;
      this.refs.ONeilListView.listCatalog.actualListId = this.state.actualListId;
      this.refs.ONeilListView.listCatalog.listName = this.props.listName;
      this.listApiCalled = true;     
      ListActions.reloadListCatalog();
    }
  }

  componentWillUnmount() {
    ListStore.removeChangeListener(this.listStoreStateChange);
    ListExplorerStore.removeChangeListener(this.listExplorerStateChange);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(!this.listApiCalled){
      let listDataSource = ListExplorerStore.getDataSource();
      if(listDataSource !== null){
        let listAvailable = ListExplorerStore.findActiveListAvailibility(this.props.listId);
        const isInit = this.listId == null;
        if(!listAvailable){
          const tabONeilSettings = new TabONeilSettings();
          const readyData = tabONeilSettings.getDefaultSelectedListInfo();
          this.listId = this.state.listId;
          this.fetchListCataglogData(readyData.SelectedListId, readyData.SelectedActualListId, readyData.SelectedListName, true, true, isInit);
        }else{
          this.listId = this.state.listId;
          this.refs.ONeilListView.getListCatalog(this.state.listId, this.state.actualListId, this.props.listName, 0, -1, isInit); //actual ListId appended
        }
        this.listApiCalled = true;
    }
    }
    if (nextProps.loadCompleteDb == true) {
      this.refs.ONeilListView.resetListCatelogData();
      ONeilViewStore.setListCatalogSearchKeyword('');
      this.refs.ONeilListView.listCatalog.needBrowserCounts = 0;
      this.fetchListCataglogData('-1', '-1', LocalizationStore.getTranslatedData("LM_oneilDB", "O'Neil Database"));
      if (nextProps.selectedTabKey == ListManagerTabType.Oneil) {
        this.setDeleteNode();
        this.setAlertNode();
      }
    }
    this.setState({ addNewItem: nextProps.addNewItem });
  }

  onGridColumnContextMenuClick(e, listId, dataItemId, colSeq, quoteBoard, isFrozen, previousColumnDataItemId, displayName, isVisualizationOn) {
    e.preventDefault();
    let obj = {};
    obj.contextMenuType = 'gridColumnHeader';
    obj.xPos = e.clientX;
    obj.yPos = e.clientY - 25;
    obj.listId = listId;
    obj.dataItemId = dataItemId;
    obj.colSeq = colSeq;
    obj.quoteBoard = quoteBoard;
    obj.isFrozen = isFrozen;
    obj.previousColumnDataItemId = previousColumnDataItemId;
    obj.isVisualizationOn = isVisualizationOn;
    obj.displayName = displayName;
    obj.showContextMenu = true;
    this.props.onContextMenuClick(obj);
  }

  onGridCustomFilterContextMenuClick(e) {
    e.preventDefault();
    let obj = {};
    obj.contextMenuType = 'gridCustomFilter';
    obj.xPos = e.clientX;
    obj.yPos = e.clientY - 25;
    obj.showContextMenu = true;
    this.props.onContextMenuClick(obj);
  }

  onGridRowContextMenuClick(e, selectedIndexes, instrumentType) {
    e.preventDefault();
    let obj = {};
    obj.contextMenuType = 'gridRow';
    obj.xPos = e.clientX;
    obj.yPos = e.clientY - 25;
    obj.listId = this.state.actualListId;
    obj.selectedIndexes = selectedIndexes;
    obj.showContextMenu = true;
    obj.instrumentType = instrumentType;
    this.props.onContextMenuClick(obj);
  }

  onExplorerContextMenuClick(e, existAlerts) {
    let obj = {};
    if (!StringUtil.isEmpty(ListExplorerStore.contextNode)) {
      this.listType = ListExplorerStore.contextNode.listType;
      if (parseInt(this.listType) !== ListType.CATEGORY_List) {
        this.rootParentId = ListExplorerStore.contextNode.rootParentNodeId;
        this.parentId = ListExplorerStore.contextNode.parentNodeId;
        this.categoryType = ListExplorerStore.contextNode.categoryType;
        this.selectedNodeId = ListExplorerStore.contextNode.nodeId;
        this.nodeName = ListExplorerStore.contextNode.name;

        if (!StringUtil.isEmpty(ListExplorerStore.contextNode.sourceListId))
          this.sourceListId = ListExplorerStore.contextNode.sourceListId;

        if (e !== ContextMenuConstants.DELETE && e !== ContextMenuConstants.REMOVE_FROM_FAVORITES && e !== ContextMenuConstants.RENAME && e !== ContextMenuConstants.ALERT && e !== ContextMenuConstants.AND_CONDITION_ALERTS) {
          e.preventDefault();
          obj.xPos = e.clientX;
          obj.yPos = e.clientY - 25;
          obj.showContextMenu = true;

        }
        else {
          obj.showContextMenu = false;
        }

        obj.contextMenuType = 'explorer';
        obj.rootParentId = this.rootParentId;
        obj.categoryType = this.categoryType;
        obj.listType = this.listType;
        obj.selectedNodeId = this.selectedNodeId;
        obj.nodeName = this.nodeName;
        obj.sourceListId = this.sourceListId;
        obj.parentId = this.parentId;
        obj.nodeId = this.selectedNodeId;
        obj.action = ListExplorerStore.contextNode.action;
        obj.alertStatusType = ListExplorerStore.contextNode.alertStatusType;
        obj.spId = ListExplorerStore.contextNode.spId;
        this.props.onContextMenuClick(obj, existAlerts);
      }
    }
  }

  showOriginalClick(e) {
    this.showOriginal(ReactDOM.findDOMNode(e.target).getAttribute('data-sourcelistid'), true);
  }

  showOriginal(sourceListId) {
    return this.props.showOriginal(sourceListId, true);
  }

  updateScrollBar() {
    this.forceUpdate();
  }

  handleFolderClick() {
    this.setDeleteNode();
    this.setAlertNode();
  }

  handleClick() {
    if (!StringUtil.isEmpty(ListExplorerStore.activeNode)) {
      let nodeId = ListExplorerStore.activeNode.nodeId;
      this.nodeType = ListExplorerStore.activeNode.categoryType;
      ListExplorerStore.nodeType = this.nodeType;
      this.previousSelectedNodeId = this.props.listId;
      GridStore.setScrollPos(0, 0);
      if (!this.props.renameNode) {
        if (this.nodeType == ListCategoryType.TEMPORARYLIST_ListCategory) {
          const symbol = ListExplorerStore.activeNode.symbol;
          const activeSymbol = ListExplorerStore.activeSybol.symbol;

          if (symbol != activeSymbol) {
            ListExplorerStore.activeSybol = {
              symbol: ListExplorerStore.activeNode.symbol,
              msId: ListExplorerStore.activeNode.msid
            };
            this.refs.ONeilListView.resetListCatelogData();
            ONeilViewStore.setListCatalogSearchKeyword('');
            this.refs.ONeilListView.listCatalog.needBrowserCounts = 0;
            this.fetchListCataglogData(nodeId, nodeId, ListExplorerStore.activeNode.name);
          }
        }
        else if (this.nodeType == ListCategoryType.NOTIMPORTEDLIST_ListCategory) {
          this.refs.ONeilListView.resetListCatelogData();
          ONeilViewStore.setListCatalogSearchKeyword('');
          this.refs.ONeilListView.listCatalog.needBrowserCounts = 0;
          this.fetchListCataglogData(nodeId, nodeId, ListExplorerStore.activeNode.name);
        }
        else if (this.nodeType == ListCategoryType.FAVORITE_ListCategory && this.props.actualListId != nodeId) { //Favorite List
          this.refs.ONeilListView.resetListCatelogData();
          ONeilViewStore.setListCatalogSearchKeyword('');
          this.refs.ONeilListView.listCatalog.needBrowserCounts = 0;
          let sourceListId = ListExplorerStore.activeNode.sourceListId;
          this.fetchListCataglogData(nodeId, sourceListId, ListExplorerStore.activeNode.name);
        }
        else if (this.props.listId != nodeId) {
          this.refs.ONeilListView.resetListCatelogData();
          ONeilViewStore.setListCatalogSearchKeyword('');
          this.refs.ONeilListView.listCatalog.needBrowserCounts = 0;
          this.fetchListCataglogData(nodeId, nodeId, ListExplorerStore.activeNode.name);
        }
      }
    }
  }

  clearGridData(){
    ONeilViewStore.isListLoaded = false;  //disabled context menu delete untill the grid loads data
    GridStore.isPasteEvent = false;
    this.refs.ONeilListView.clearGridData();
  }

  fetchListCataglogData(listId, actualListId, nodeName, loading = true, updateNewList = false, isInit = false) {
    ONeilViewStore.isListLoaded = false;  //disabled context menu delete untill the grid loads data

    if (this.state.showHelp) {
      const nodeType= ListExplorerStore.isAnnoationList() ? ListType.ANNOTATIONLAYER_List : ListType.LIST_List
      ListActions.getNodeDetails(actualListId,nodeType);
      this.previousNodeId = listId;
      this.setState({ columnSetProperties: null });
    }

    GridStore.isPasteEvent = false;
    GridStore.setScrollPos(0);
    let nodeList = [];
    if(updateNewList){
      const tabONeilSettings = new TabONeilSettings();
      const readyData = tabONeilSettings.getDefaultSelectedListInfo();
      ListStore.updateSelectedListItem(readyData.SelectedListId,
        readyData.SelectedActualListId,
        readyData.SelectedListName,
        readyData.SelectedCategoryType,
        readyData.SelectedParentNodeId,
        readyData.SelectedParentCategoryType,
        escape(readyData.SelectedParentCategoryName),
        readyData.SelectedParentListType,
        readyData.SelectedSourceCategoryType,
        readyData.SelectedSourceParentCategoryName);
      ListExplorerStore.expandAndFindParentNode(listId, nodeList);
    }else{
      ListStore.updateSelectedListItem(listId, actualListId, nodeName, ListExplorerStore.activeNode.categoryType, ListExplorerStore.activeNode.parentNodeId, ListExplorerStore.activeNode.parentCategoryType, escape(ListExplorerStore.activeNode.parentCategoryName), ListExplorerStore.activeNode.parentListType, ListExplorerStore.activeNode.sourceCategoryType, ListExplorerStore.activeNode.sourceParentCategoryName);
      ListExplorerStore.expandAndFindParentNode(listId, nodeList);
    }
    this.props.setLoading(listId, nodeName, loading);
    this.refs.ONeilListView.clearGridData();
    this.refs.ONeilListView.getListCatalog(listId, actualListId, nodeName, 0, -1, isInit); //actual ListId appended
    this.setState({ listId: listId, actualListId: actualListId });
  }

  addNewItem(type, nodeId, parentNodeId) {
    this.setState({ addNewItem: type, parentNodeId: parentNodeId })
    return this.props.addNewItem;
  }

  showValidationWindow() {
    return this.props.showValidationWindow();
  }

  duplicateItem(nodeId, nodeName, rootParentId, sourceCategoryType, action) {
    return this.props.duplicateItem(nodeId, nodeName, rootParentId, sourceCategoryType, action);
  }

  listStoreStateChange() {
    let currentAction = ListStore.getCurrentAction();
    let state = ListStore.getState();
    if (currentAction == ListExplorerConstants.ActionTypes.GET_REFRESHED_VIEW) {
      if (state.SelectedTabKey == ListManagerTabType.Oneil && !state.isRefresh) {
        let listAvailableRefresh = ListExplorerStore.findActiveListAvailibility(this.props.listId);
        if(!listAvailableRefresh){
          const tabONeilSettings = new TabONeilSettings();
          const readyData = tabONeilSettings.getDefaultSelectedListInfo();
          this.listId = this.state.listId;
          ListExplorerStore.activeNode = readyData;
          this.fetchListCataglogData(readyData.SelectedListId, readyData.SelectedActualListId, readyData.SelectedListName, true, true, true);
        }else{
          let selectedListItem = ListStore.getSelectedListItem()
          this.fetchListCataglogData(selectedListItem.SelectedListId, selectedListItem.SelectedActualListId, selectedListItem.SelectedListName, true, false, true);
        }
      }
    }
    else if (currentAction == ListExplorerConstants.ActionTypes.UPDATE_LIST_EXPLORER_WIDTH) {
      if (state.SelectedTabKey == ListManagerTabType.Oneil) {
        this.setState({ listExplorerWidth: ListStore.getListExplorerWidthForMetricLibrary() });
      }
    }
    else if (currentAction == ListExplorerConstants.ActionTypes.GET_NODE_DETAILS) {
      //Updating update date field in List property section in List explorer
      if(this.state.showHelp && state.columnSetProperties)
        ListExplorerStore.findNodeByIdAndUpdateDate( state.nodeId_colSetProp, state.columnSetProperties.updatedDt,state.columnSetProperties.descTxt);
      this.setState({ columnSetProperties: state.columnSetProperties });
    }
    else if (currentAction == SettingsConstants.ActionTypes.LIST_EXPLORER_UPDATE_TAB){
        const listState = ListStore.getSelectedListItem();
        this.setState({ listId: listState.SelectedListId, actualListId: listState.SelectedActualListId });
        SettingsAction.saveUserSettings();
    }
  }

  listExplorerStateChange() {
    let currentAction = ListExplorerStore.getCurrentAction();
    if (currentAction == ListExplorerConstants.ActionTypes.GET_LIST_EXPLORER) {
      //Enable or Disable Delete button in ExplorerBottom bar in List explorer
      //this.state.isDeleteEnable = ListExplorerStore.isDeleteAllowed(ListExplorerStore.nodeType);
      this.setDeleteNode();

      if (!StringUtil.isEmpty(ListExplorerStore.activeNode)
        && ListExplorerStore.isListAlertEligible(ListExplorerStore.activeNode.rootParentNodeId) /*&& searchValue.listCategory != ListManagerListCategory.POSITIONHISTORYLIST_ListCategory*/) {
        this.setState({ isAlertEnable: true });
      }
      else {
        this.setState({ isAlertEnable: false });
      }
    }
    if (currentAction == ListExplorerConstants.ActionTypes.GET_DELETE_TREE_NODE || currentAction == ListExplorerConstants.ActionTypes.REMOVE_FROM_FAVORITES) {
      ListExplorerStore.selectedFolder = null;
      this.setDeleteNode();
      this.setAlertNode();
      setTimeout(()=>{
        ListExplorerStore.clearCurrentAction();
      }, 100)
      
    }
    else if (currentAction == ListExplorerConstants.ActionTypes.GET_TEMP_LIST_DATA) {
      const activenode = ListExplorerStore.activeNode;

      this.fetchListCataglogData(activenode.nodeId, activenode.nodeId, activenode.name);
    }
    else if (currentAction == ListExplorerConstants.ActionTypes.GET_NOT_IMPORTED_LIST_DATA) {
      const activenode = ListExplorerStore.activeNode;
      this.setState({ listId: activenode.nodeId, actualListId: activenode.nodeId });
      this.fetchListCataglogData(activenode.nodeId, activenode.nodeId, activenode.name);
      ListExplorerStore.clearCurrentAction();
    }
    else if (currentAction == ListExplorerConstants.ActionTypes.RESTORE_TREE_NODE) {
      const activeNode = ListExplorerStore.activeNode;
      const categoryType = ListExplorerStore.contextNode && ListExplorerStore.contextNode.categoryType;
      if ((activeNode.symbol && categoryType === ListCategoryType.TEMPORARYLIST_ListCategory) || categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory) {
        const nodeId = activeNode.nodeId.low ? activeNode.nodeId.low : activeNode.nodeId;
        ListExplorerStore.nodeType = activeNode.categoryType;
        this.setDeleteNode();
        this.setAlertNode();
        this.fetchListCataglogData(nodeId, nodeId, activeNode.name);
      }
    }
    else if(currentAction == ListExplorerConstants.ActionTypes.SHOW_ORIGINAL){
      this.setDeleteNode();
    }
  }

  setDeleteNode() {
    let isFolderSelected = !StringUtil.isEmpty(ListExplorerStore.selectedFolder) ? true : false;
    let nodeToDelete = isFolderSelected ? ListExplorerStore.selectedFolder : ListExplorerStore.activeNode;
    if (ListExplorerStore.isDeleteAllowed(nodeToDelete.categoryType)) {
      this.setState({ isDeleteEnable: true, deleteNode: nodeToDelete });
    }
    else
      this.setState({ isDeleteEnable: false, deleteNode: null });
  }

  setAlertNode() {
    let isFolderSelected = !StringUtil.isEmpty(ListExplorerStore.selectedFolder) ? true : false;
    let nodeToAlert = isFolderSelected ? ListExplorerStore.selectedFolder : ListExplorerStore.activeNode;

    if (!isFolderSelected && !StringUtil.isEmpty(nodeToAlert)
      && (StringUtil.isEmpty(ListExplorerStore.selectedNodeIds) || ListExplorerStore.selectedNodeIds.length == 1)
      && ListExplorerStore.isListAlertEligible(nodeToAlert.rootParentNodeId) /*&& searchValue.listCategory != ListManagerListCategory.POSITIONHISTORYLIST_ListCategory*/) {
      this.setState({ isAlertEnable: true });
    }
    else {
      this.setState({ isAlertEnable: false });
    }
  }

  isAlertEnable(){
    let isFolderSelected = !StringUtil.isEmpty(ListExplorerStore.selectedFolder) ? true : false;
    let nodeToAlert = isFolderSelected ? ListExplorerStore.selectedFolder : ListExplorerStore.activeNode;

    if (!isFolderSelected && !StringUtil.isEmpty(nodeToAlert)
      && (StringUtil.isEmpty(ListExplorerStore.selectedNodeIds) || ListExplorerStore.selectedNodeIds.length == 1)
      && ListExplorerStore.isListAlertEligible(nodeToAlert.rootParentNodeId) /*&& searchValue.listCategory != ListManagerListCategory.POSITIONHISTORYLIST_ListCategory*/) {
      return true;
    }
    else {
      return false;
    }
  }

  onResize(width) {
    let self = this;
    this.resizeHandle = true;
    if(!this.state.resizeHandle)
      dispatch(updateIsResizing(true));
    self.setState({ listExplorerWidth: width, resizeHandle: true });
  }

  onStopResize() {
    let width = this.state.listExplorerWidth;
    ListStore.updateListExplorerPreviousWidth(ListStore.getListExplorerSettings().Width)
    if (width < this.listExplorerSettings.MinWidth) width = 0;
    ListStore.updateListExplorerWidth(width);
    ResizeActions.updateBrowserFilterWidths();
    dispatch(updateIsResizing(false));
    this.setState({ resizeHandle: false });
  }

  toggleHelp() {
    this.setState({ showHelp: !this.state.showHelp });
    if (!this.state.showHelp && (this.previousNodeId != this.state.listId)) {
      const nodeType= ListExplorerStore.isAnnoationList() ? ListType.ANNOTATIONLAYER_List : ListType.LIST_List
      ListActions.getNodeDetails(this.state.listId,nodeType);
      this.previousNodeId = this.state.listId;
      this.setState({ columnSetProperties: null });
    }
  }

  showExplorer() {
    this.setState({
      listExplorerWidth: ListStore.getListExplorerSettings().PreviousWidth,
      resizeHandle: false
    })
    ListStore.updateListExplorerPreviousWidth(ListStore.getListExplorerSettings().PreviousWidth);
    ListStore.updateListExplorerWidth(ListStore.getListExplorerSettings().PreviousWidth);

    window.setTimeout(() => {
      this.loadingListExplorer();
      if (StringUtil.isEmpty(ListExplorerStore.currentScrollPosition))
        ListExplorerUtil.getScrollPosition(this.state.listId);
      ResizeActions.updateBrowserFilterWidths();
    }, 0);
  }

  handleScroll(e) {
    if (!StringUtil.isEmpty(e))
      ListExplorerStore.currentScrollPosition = e.target.scrollTop;
  }

  loadingListExplorer() {
    this.setState({ loading: false });
  }

  render() {
    let listHeightClass = this.state.showHelp ? 'middel-inner w50p-h' : 'middel-inner';
    const isDeleteEnabled = ListExplorerStore.isActiveNodeDeleteAllowed();
    const isAlertEnabled = this.isAlertEnable();
    this.showExplorerWindow = this.state.listExplorerWidth <= this.listExplorerSettings.MinWidth && !this.state.resizeHandle ? false : true;
    return (
      <span>
        {this.showExplorerWindow ?
          <div className="left-list-view" id="listExplorer" style={{ width: this.state.listExplorerWidth + 2 }}>
            <ResizableWindow className="list-explorer-resize" minWidth={23} maxWidth={this.listExplorerSettings.MaxWidth} width={this.state.listExplorerWidth} height={'100%'} direction="Horizontal" onStartResize={this.onResize} onStopResize={this.onStopResize} pointerEvents="inherit">
              <ExplorerTop
                tabCollection={this.props.tabCollection}
                selectedTabKey={this.props.selectedTabKey}
                onTabSelectionChanged={this.props.onTabSelectionChanged}
                nodeId={this.state.listId}
                loading={this.state.loading}
              />
              <div className="tab-view-left-middel">
                <div className={listHeightClass}>
                  <div className="custom-scroll">
                    {this.state.loading ? <span className="explorer-loading"><div className="group-box "><div className="list-load"><div className="loading loading-spinner"></div></div></div></span> : ''}
                    <div id='explorerVerticalScroll' onScroll={this.handleScroll}>
                      <Explorer
                        dataSource={this.props.dataSource}
                        nodeId={this.state.listId}
                        loadingListExplorer={this.loadingListExplorer}
                        addNewItem={this.state.addNewItem}
                        renameNode={this.props.renameNode}
                        handleClick={this.handleClick}
                        handleFolderClick={this.handleFolderClick}
                        showOriginalClick={this.props.showOriginalClick}
                        previousSelectedNodeId={this.props.previousSelectedNodeId}
                        onExplorerContextMenuClick={this.onExplorerContextMenuClick}
                        showValidationWindow={this.showValidationWindow}
                        fontSizeClass={this.props.fontSizeClass}
                        disableCopyPasteAction={this.disableCopyPasteAction}
                        updateScrollBar={this.updateScrollBar} />
                      <ScrollBar scrollId="explorerVerticalScroll" vScroll={true} />
                    </div>
                  </div>
                </div>
                {this.state.showHelp && this.props.dataSource && <ListProperty
                  toggleHelp={this.toggleHelp}
                  columnSetProperties={this.state.columnSetProperties}
                  showHelp={this.state.showHelp}
                  listId={this.state.listId}
                  showOriginalClick={this.showOriginalClick}
                  showOriginal={this.showOriginal}
                  dataSource={this.props.dataSource} 
                  duplicateItem={this.duplicateItem}
                  selectedTabKey={this.props.selectedTabKey}
                  onExplorerContextMenuClick={this.onExplorerContextMenuClick}
                  />}
              </div>
              <ExplorerBottom
                addNewItem={this.addNewItem}
                toggleHelp={this.toggleHelp}
                renderRenameNode={this.props.renderRenameNode}
                duplicateItem={this.duplicateItem}
                onExplorerContextMenuClick={this.onExplorerContextMenuClick}
                isDeleteEnable={isDeleteEnabled}
                renameNode={this.props.renameNode}
                isAlertEnable={isAlertEnabled}
                deleteNode={this.state.deleteNode}
                nodeId={this.state.listId}
                loading={this.props.loading}
                showValidationWindow={this.showValidationWindow}
                selectedTabKey={this.props.selectedTabKey}
                fontSizeClass={this.props.fontSizeClass} />
            </ResizableWindow>
          </div>
          : <div className="left-list-view add-side-bar" id="listExplorer" role="button" tabIndex="-1" onClick={this.showExplorer}>
            <div className="dot-slide-bar"></div>
            <div className="dot-slide-bar1"></div>
          </div>}

        <ONeilListView ref="ONeilListView"
          setLoading={this.props.setLoading}
          listId={this.state.listId}
          actualListId={this.state.actualListId}
          listName={this.props.listName}
          loading={this.props.loading}
          fetchListCataglogData={this.fetchListCataglogData}
          onGridColumnContextMenuClick={this.onGridColumnContextMenuClick}
          onGridCustomFilterContextMenuClick={this.onGridCustomFilterContextMenuClick}
          onGridRowContextMenuClick={this.onGridRowContextMenuClick}
          updateGlobalFlag={this.props.updateGlobalFlag}
          onGridRowContextMenuItemClick={this.props.onGridRowContextMenuItemClick}
          fontSizeClass={this.props.fontSizeClass} />
      </span>
    );
  }
}
