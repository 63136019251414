import AlertConditionType from "../../../../../Constants/AlertConditionType";
import { connect } from "react-redux";
import ErrorBoundary from "ErrorBoundary";
import React from "react";
import StringUtil from "StringUtil";
import TabDataGraphAction from "../../../../../Actions/TabDataGraphAction"
import textWidth from "text-width";
import VirtualList from "VirtualList";
import { AlertTranslateHelper, PeriodicityTypeTranslateHelper, TrackPriceTranslateHelper, TranslateHelper } from "../../../../../Utils/TranslateHelper";
import { deleteAlert, handleHighlightAlert, openAndAlertDialog, openORAlertDialog, openPriceAlertDialog, toggleAlertStatus } from "../../../../../Actions/AlertActions";

class RIPanelAlertInfo extends React.Component {
    constructor(props) {
        super(props);
        this.onAlertMouseMove = this.onAlertMouseMove.bind(this);
        this.onAlertMouseLeave = this.onAlertMouseLeave.bind(this);
        this.ellipsisTextArea = this.ellipsisTextArea.bind(this);
        this.highlightAlert = this.highlightAlert.bind(this);
        this.openAlertDialog = this.openAlertDialog.bind(this);
    }

    onAlertMouseMove(e, alertName, note, isPriceAlert, isOverflowDiv) {
        if (isOverflowDiv) {
            this.toolTopBox.style.left = `${e.clientX - this.main.getBoundingClientRect().left + 10}px`;
            this.toolTopBox.style.top = `${e.clientY - this.main.getBoundingClientRect().top + 96}px`;
            this.toolTopBox.style.textAlign = "left";
            this.toolTopBox.style.wordWrap = "break-word";
            this.toolTopBox.style.width = `${this.main.getBoundingClientRect().right - e.clientX - 10}px`;
            this.toolTopBox.innerHTML = `<div><span>${isPriceAlert ? note : alertName}</span></div>`;
            this.toolTopBox.style.display = '';
        }
    }

    onAlertMouseLeave() {
        this.toolTopBox.style.left = 0;
        this.toolTopBox.style.top = 0;
        this.toolTopBox.innerHTML = '';
        this.toolTopBox.style.display = 'none';
    }

    getAlertView() {
        return !this.props.loading && StringUtil.isEmpty(this.props.riPanelAlertsList) ?
            this.props.showActive ? <div className="news-message large-normal">
                <p> {AlertTranslateHelper.NO_ALERTS} </p>
            </div> :
            <div className="news-message large-normal">
                <p> {AlertTranslateHelper.NO_TRIGGERED_ALERT} </p>
            </div> :
            <div className="ownership-content">
                {this.props.loading ? <div className="news-message  large-normal"><p>{TranslateHelper.Loading}</p></div> :
                    this.props.riPanelAlertsList.length === 0 ?
                        <div className="news-message  large-normal"><p>{AlertTranslateHelper.NO_DATA_FOUND}</p></div> :
                        <VirtualList id="alertListInfo" ref={(ref) => (this.RIPanelAlertList = ref)}
                            items={this.props.riPanelAlertsList}
                            itemHeight={96}
                            itemRenderer={(item, top, index) => this.getItemRenderer(item, top, index)}
                            showScrollOnHover={true}
                        />}
            </div>
    }

    getItemRenderer(item, top, index) {
        const listAlertRows = 5, rowWidth = 93;
        let priceAlertRows = 5;
        const divClass = this.props.highlightedAlertObj.alertId === item.AlertDataStructure.alertId ? "alert-item-hover" : "";
        const isPriceAlert = item.AlertDataStructure && item.AlertDataStructure.priceAlertData && !StringUtil.isEmpty(item.AlertDataStructure.priceAlertData.targetPrice);
        const isListAndAlert = (item.ConditionTypeId === AlertConditionType.And && item.AlertDataStructure.length > 0 && item.AlertDataStructure[0].StockAlertData && item.AlertDataStructure[0].StockAlertData.isStockAlert === false) ? true : false
        if (isPriceAlert) {
            item.AlertName.split("\n").map((i) => (priceAlertRows -= this.getRows(i, rowWidth)))
        }
        const messageInfo = isPriceAlert ? this.ellipsisTextArea(item.note, priceAlertRows, rowWidth) : this.ellipsisTextArea(item.AlertName, listAlertRows, rowWidth);
        if (item) {
            return (<div style={{ top: top }} className={`ownership-item medium-normal alert-item pointer ${divClass}`}
                onMouseEnter={() => this.highlightAlert(item.AlertDataStructure)}
                onMouseLeave={() => this.highlightAlert(null)} key={index}>
                {this.props.showActive === true ? <div className="ownership-item-column alert-column-1 pointer" >{item.UpdateDate}</div> :
                    <div className="ownership-item-column alert-column-1 pointer" >{item.AlertDate}</div>}
                <div style={{ height: "100%", overflow: "hidden" }} onMouseMove={(e) => this.onAlertMouseMove(e, item.AlertName, item.note, isPriceAlert, messageInfo.isOverflowDiv)} onMouseOut={this.onAlertMouseLeave} onBlur={this.onAlertMouseLeave} className="ownership-item-column alert-column-2 pointer">
                    <span>  {(isPriceAlert ? item.AlertName : messageInfo.innerStr).split("\n").map((i, key) =>
                        <div className="alertMsg" key={key}> <span dangerouslySetInnerHTML={{ __html: i }} /></div>
                    )}  </span>
                    {isPriceAlert ? <div className="alertMsg"> <span dangerouslySetInnerHTML={{ __html: messageInfo.innerStr }} /></div> : ''}
                </div>
                <div className="ownership-item-column alert-column-3 active-orderBy-column pointer">
                    {this.props.showActive === true && item.EditVisible === 'Visible' ?
                        <span><a onClick={() => this.openAlertDialog(item.AlertDataStructure)} >{TranslateHelper.Edit} </a>
                            |</span> : ""}
                    {this.props.showActive === true ?
                        <a className="" onClick={() => this.props.deleteAlert(item.AlertDataStructure)}> {TranslateHelper.Delete}</a>
                        : (item.AlertDataStructure.StockAlertData && item.AlertDataStructure.isStockAlert || item.ConditionTypeId === AlertConditionType.And) && !isListAndAlert && !item.IsTriggered ? <a onClick={() => this.openAlertDialog(item.AlertDataStructure)} >{TranslateHelper.Edit} </a> : <a className="" onClick={() => this.props.toggleAlertStatus(item.AlertDataStructure)}>
                            {item.AlertDataStructure.priceAlertData || item.AlertDataStructure.MAAlertData ? item.AlertStatus === "Inactive" ? AlertTranslateHelper.REACTIVATE : AlertTranslateHelper.DEACTIVATE : item.AlertStatus}</a>}
                </div>
            </div>);
        }
    }

    openAlertDialog (alertObj) {
        if (alertObj.priceAlertData) {
            this.props.openPriceAlertDialog(alertObj, true)
        }
        else if (alertObj.isStockAlert) {
            this.props.openORAlertDialog(this.props.SymbolInfo.MsId, this.props.SymbolInfo.Osid, this.props.SymbolInfo.Symbol, this.props.currencyStr)
        }
        else if (alertObj.length > 0) {
           this.props.openAndAlertDialog(this.props.SymbolInfo.MsId, this.props.SymbolInfo.Osid, this.props.SymbolInfo.Symbol, this.props.currencyStr)
        }
    }

    getRows(filterStr, rowWidth) {
        let textWidth = 0;
        let index = 0;
        const strArray = filterStr.split(/(\s)|([\u4e00-\u9fa5])|(-)/).filter((t) => t !== undefined && t !== "");
        for (let z = 0; z < strArray.length; z++) {
            for (const codePoint of strArray[z]) {
                textWidth += this.getTextWidth(codePoint, "calibri", 12);
                const rowW = textWidth % rowWidth;
                const nextStr = filterStr[index + 1];
                const nextWidth = nextStr ? this.getTextWidth(nextStr, "calibri", 12) : 0;
                if ((rowWidth - rowW) < nextWidth) {
                    textWidth += (rowWidth - rowW);
                }
                index++;
            }
            const rowW2 = textWidth % rowWidth;
            const nextStrArray = strArray[z + 1];
            const nextStrWidth = nextStrArray ? this.getTextWidth(nextStrArray, "calibri", 12) : 0;
            if ((rowWidth - rowW2) < nextStrWidth) {
                textWidth += (rowWidth - rowW2);
            }
        }
        const newRows = Math.floor(textWidth / rowWidth) + 1;
        return newRows;
    }


    ellipsisTextArea(filterStr, rows, rowWidth) {
        const d = '...';
        const dwidth = this.getTextWidth(d, "calibri", 12);
        let textWidth = 0;
        let index = 0;
        const textAreaWidth = rows * rowWidth;
        let innerStr = filterStr;
        let isOverflowDiv = false;
        const htm = filterStr.replace(/\s+/g, ' ');
        const strArray = htm.split(/(\s)|([\u4e00-\u9fa5])|(-)/).filter((t) => t !== undefined && t !== "");
        for (let z = 0; z < strArray.length; z++) {
            let n = 0;
            for (const codePoint of strArray[z]) {
                textWidth += this.getTextWidth(codePoint, "calibri", 12);
                if ((textWidth) > textAreaWidth) {
                    for (index; index > 0; index--) {
                        if ((textWidth - this.getTextWidth(htm[index], "calibri", 12) + dwidth) < textAreaWidth) {
                            isOverflowDiv = true;
                            innerStr = htm.substring(0, index) + d;
                            return { innerStr: innerStr, isOverflowDiv: isOverflowDiv };
                        }
                        textWidth -= this.getTextWidth(htm[index], "calibri", 12);
                    }
                }
                const rowW = textWidth % rowWidth;
                const nextStr = strArray[z][n + 1];
                const nextWidth = nextStr ? this.getTextWidth(nextStr, "calibri", 12) : 0;
                if ((rowWidth - rowW) < nextWidth) {
                    textWidth += (rowWidth - rowW);
                }
                n++;
                index++;
            }
            const rowW2 = textWidth % rowWidth;
            const nextStrArray = strArray[z + 1];
            const nextStrWidth = nextStrArray ? this.getTextWidth(nextStrArray, "calibri", 12) : 0;
            if ((rowWidth - rowW2) < nextStrWidth) {
                const preRow = Math.floor(textWidth / rowWidth) + 1;
                if (preRow === rows && nextStrArray) {
                    continue;
                }
                if (nextStrArray === ' ') {
                    z += 1;
                    index += 1;
                }
                textWidth += (rowWidth - rowW2);
            }
        }
        return { innerStr: innerStr, isOverflowDiv: isOverflowDiv };
    }

    getTextWidth(str, family, size) {
        const strWidth = textWidth(str, {
            family: family,
            size: size
        });
        return strWidth;
    }

    highlightAlert(alert) {
        let alertId;
        if (StringUtil.isEmpty(alert) || alert.isStockAlert || alert.TLAlertData || alert.length > 0 || alert.StockAlertData || !this.props.showActive) {
            alertId = 0;
            this.props.handleHighlightAlert({alertId}, 0, 0);
        }
        else {
            alertId = alert.length > 1 ? alert[0].alertId : alert.alertId
            const priceChartAlert = this.props.datagraphAlertsList.find((item)=> item.alertId === alert.alertId)
            if (priceChartAlert?.targetPrice) {
                const yPos = this.props.scale.ComputeY(priceChartAlert?.targetPrice)
                this.props.handleHighlightAlert(priceChartAlert, this.props.dimension.width, yPos);
            }
        }
        if (alertId !== this.props.highlightedAlertObj.alertId) {
            if (alertId === 0 || (alert.MAAlertData && this.props.periodicity === PeriodicityTypeTranslateHelper[alert.MAAlertData.periodicity])) {
                TabDataGraphAction.onMAAlertHover(alertId === 0 ? null : alert);
            }
        }
    }

    render() {
        return (
            <ErrorBoundary>
                <div ref={(ref) => (this.main = ref)} className="ownershipInfo">
                    <div className="ownership-header xx-small-normal">
                        <div className="ownership-header-column alert-column-header-1">{TrackPriceTranslateHelper['DATE']}</div>
                        <div className="ownership-header-column alert-column-header-2">{AlertTranslateHelper.ALERT_TYPE}</div>
                        {/* <div className="ownership-header-column alert-column-header-3">{!AlertStore.showActive ? 'STATUS':''}</div> */}
                    </div>
                    {this.getAlertView()}
                    {this.props.showActive ? <div className="alertCountBar"> {this.props.AlertsCountMessage} </div> : ""}
                    <div ref={(ref) => (this.toolTopBox = ref)} className="tooltip-box" style={{ display: 'none' }}></div>
                </div>
            </ErrorBoundary>
        );
    }
}
const mapStateToProps = ({ DatagraphReducers, alertReducer }) => {
    const { periodicity, SymbolInfo, currencyStr } = DatagraphReducers.DataGraphReducer;
    const { scale, dimension } = DatagraphReducers.PriceChartReducer
    const { riPanelAlertsList, datagraphAlertsList, loading, showActive, AlertsCountMessage, highlightedAlertObj } = alertReducer.DataGraphAlertReducer
    return { periodicity, SymbolInfo, riPanelAlertsList, datagraphAlertsList, loading, showActive, AlertsCountMessage, highlightedAlertObj, scale, dimension, currencyStr }
}

const mapDispatchToProps = (dispatch) => ({
    openPriceAlertDialog: (alertObj, isEditing)=> dispatch(openPriceAlertDialog(alertObj, isEditing)),
    openORAlertDialog: (msId, instrumentId, symbol, currencyCode)=> dispatch(openORAlertDialog(msId, instrumentId, symbol, currencyCode)),
    openAndAlertDialog: (msId, instrumentId, symbol, currencyCode)=> dispatch(openAndAlertDialog(msId, instrumentId, symbol, currencyCode)),
    handleHighlightAlert: (highlightedAlertObj, pointerXPos, pointerYPos) => dispatch(handleHighlightAlert(highlightedAlertObj, pointerXPos, pointerYPos)),
    deleteAlert: (alertObj)=> dispatch(deleteAlert(alertObj)),
    toggleAlertStatus: (alertObj)=> dispatch(toggleAlertStatus(alertObj))
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(RIPanelAlertInfo)