import AnimationLookbackSpan from "../../../Constants/AnimationLookbackSpan";
import BaseServiceApi from 'BaseServiceApi';
import DatagraphStore from "../../../Stores/NavModules/NavDataGraph/DataGraphStore";
import DateHelper from '../../../Utils/TimeLineHelper/Datehelper';
import ExtremeDataValue from '../../../Utils/ExtremeDataValue';
import GraphApi from "../../../ServiceApi/Apis/GraphApi";
import GraphComponent from "../../../Constants/GraphComponent";
import GraphType from '../../../Constants/GraphType';
import { initTimeLine } from "../../../Actions/TabDataGraphAction";
import { LineDialogPeriodicityTranslateHelper } from "../../../Utils/TranslateHelper";
import MiniListHelper from "../../../Utils/MiniListHelper";
import MiniListStore from "../../../Stores/NavModules/NavDataGraph/MiniList/MiniListStore";
import moment from "moment";
import PeriodicityHelper from '../../../Utils/PeriodicityHelper';
import { PeriodicityTabsConstants } from "../../../Constants/PeriodicityTabsConstants";
import QuarterlyData from "../../../Utils/Calcs/QuarterlyData";
import SettingsStore from "SettingsStore";
import SmartViewType from "../../../Constants/SmartViewType";
import StockMarketUtil from '../../../Utils/StockMarketUtil';
import SymbolTypeEnum from '../../../Constants/SymbolType';
import UserInfoUtil from "../../../Utils/UserInfoUtil";
import { fundametnalLineDataReady, resetFundamentalLineStates, updateFundamentalLineSelectPerShare, updateFundamentalLineStates } from '../../../Actions/PricePanelActions';
import { getDatagraphStates, getFundamentalLineStates, getTimeLine, getTimeLineEndDate, priceChartReducerselect, timeLineSelect } from '../../../Reducers/NavDataGraph/TabDataGraph/selectors';
import { PriceChartConst, PriceChartConstants } from "../../../Constants/PriceChartConstants";
import { takeLatest, put, call, select, takeEvery } from 'redux-saga/effects';
import { getPriceChartScale } from "../../../Utils/Scales/PriceScaleConst";

const { ActionTypes } = PriceChartConstants;
const EntitlementType = BaseServiceApi.rayData["EntitlementType"];
function getT4QMultipliers(_state) {
    const multipliers = [];
    multipliers.push(_state[PriceChartConst.BV] ? _state[PriceChartConst.BV] : NaN);
    multipliers.push(_state[PriceChartConst.CFS] ? _state[PriceChartConst.CFS] : NaN);
    multipliers.push(_state[PriceChartConst.DIV] ? _state[PriceChartConst.DIV] : NaN);
    multipliers.push(_state[PriceChartConst.FCF] ? _state[PriceChartConst.FCF] : NaN);
    return multipliers;
}

function* SetGraphEpsRpsQuarters({ item }) {
    try {
        const { viewsSettings, periodicity, nodeCount, InitialBufferSize } = yield select(getDatagraphStates);
        if (item.qtrs === 0) {
            viewsSettings.EarningLineSetting[GraphType.Daily].NumOfQuarters = 1;
            viewsSettings.EarningLineSetting[GraphType.Weekly].NumOfQuarters = 4;
            viewsSettings.EarningLineSetting[GraphType.Monthly].NumOfQuarters = 8;
            viewsSettings.EarningLineSetting[GraphType.Quarterly].NumOfQuarters = 8;
            viewsSettings.EarningLineSetting[GraphType.Annual].NumOfQuarters = 8;
        } else {
            viewsSettings.EarningLineSetting[item.periodicity].NumOfQuarters = item.qtrs;
        }
        if (item.periodicity === 'All' || periodicity === item.periodicity) {
            const lastDate = yield select(getTimeLineEndDate);
            yield put(initTimeLine(lastDate, nodeCount, InitialBufferSize, false, false));
            /* Fixed Bug: PANWEB-917 */
            // const fcQtrs = tabDataGraphSettings.StockViewSettings.EarningLineSetting[_state.periodicity].NumOfQuarters;
            // FinancialBlockStore.setQuarters(fcQtrs);
        }
        else {
            yield put({
                type: ActionTypes.PREPARE_PRICE_MENU
            })
        }
        SettingsStore.saveSettings();
    }
    catch (error) {
        console.error("Error occured in SetGraphEpsRpsQuarters of FundamenalLineSaga.js", error);
    }
}

function GetYAxis(x, x1, y1, x2, y2) {
    return ((y1 - y2) * (x - x2) / (x1 - x2)) + y2;
}

function getFundamentalLines(quarterlyLineData, scale, mtDates, multiplier, lineColor, startPoint, showHighLow, endDate, periodicity, nodeWidth, lastnodeIndex) {
    if (!endDate) {
        return [];
    }
    // let isFriday = _state.endDate.getDay() === 5;
    let diff = 1;
    switch (periodicity) {
        case GraphType.Daily:
            diff = 1;
            break;
        case GraphType.Weekly:
            diff = 2;
            break;
        case GraphType.Monthly:
            diff = StockMarketUtil.GetMEndDate(endDate) === endDate ? 0 : 1;
            break;
        case GraphType.Quarterly:
            diff = StockMarketUtil.GetQEndDate(endDate) === endDate ? 0 : 1;
            break;
        case GraphType.Annual:
            diff = StockMarketUtil.GetAEndDate(endDate) === endDate ? 0 : 1;
            break;
        default:
            diff = 1;
            break;
    }
    let startx = nodeWidth * (lastnodeIndex - diff);
    const fundLineData = [];
    let rstartx = startx;
    const nodeCount = quarterlyLineData.length;
    let QtrVal = 0.0;
    let QtrHVal = 0.0;
    let QtrLVal = 0.0;
    let pQtrVal = 0.0;
    let node = 0;
    let pActual = 0;
    let startup = false;
    let upDateEst = true;
    let qtrReportDate;
    let xPrev = startx;
    let yPrev;
    let yPrevh;
    let yPrevl;
    const mtDatesLen = mtDates.length;

    if (mtDatesLen < 1 || quarterlyLineData.length < 1) {
        return fundLineData;
    }

    for (let qtrNode = startPoint; qtrNode < nodeCount; qtrNode++) {
        QtrVal = quarterlyLineData[qtrNode].QtrData;
        QtrHVal = quarterlyLineData[qtrNode].QtrDataHigh;
        QtrLVal = quarterlyLineData[qtrNode].QtrDataLow;
        QtrVal *= multiplier;
        QtrHVal *= multiplier;
        QtrLVal *= multiplier;

        if (pQtrVal === 0) {
            pQtrVal = QtrVal;
        }

        let yAxis;

        if (QtrVal <= 0 && (scale.getObjectMapKey() === "LogScale" || scale.getObjectMapKey() === "FixedLogScale")) {
            yAxis = scale.ComputeY(0.000001);
        }
        else {
            yAxis = scale.ComputeY(QtrVal);
        }

        const qtrNodeDate = new Date(quarterlyLineData[qtrNode].Date);
        if (qtrNode > 0) {
            qtrReportDate = new Date(quarterlyLineData[qtrNode].ReportDate);
        }
        const actual = quarterlyLineData[qtrNode].Actual;

        if (mtDates[0].Date < qtrNodeDate) {
            continue;
        }
        let pnode = node;

        for (; node < mtDatesLen && mtDates[node] && mtDates[node].Date > qtrNodeDate; node++) {
            startx -= nodeWidth;
        }
        if (!QtrVal || !mtDates[node]) {
            continue;
        }
        let ryAxis = 0.0;

        if (qtrReportDate && qtrReportDate.getFullYear() > 1910 && quarterlyLineData[qtrNode].ShowRpt === 1) {
            rstartx = xPrev;
            for (; pnode < mtDatesLen && mtDates[pnode] && mtDates[pnode].Date > qtrReportDate; pnode++) {
                rstartx -= nodeWidth;
            }
            ryAxis = GetYAxis(rstartx, startx, yAxis, xPrev, yPrev);
        }
        else {
            rstartx = startx;
        }

        let yhAxis = scale.ComputeY(QtrHVal);
        let ylAxis = scale.ComputeY(QtrLVal);

        const qtrMonth = qtrNodeDate.getMonth();
        if (periodicity === GraphType.Annual && qtrMonth < 11) {
            continue;
        }

        if (xPrev === startx) {
            continue;
        }

        if (startup) {
            const info =
            {
                Date: quarterlyLineData[qtrNode].Date,
                RDate: quarterlyLineData[qtrNode].ReportDate,
                xPoint: xPrev,
                yPoint: yPrev,
                xAxis: xPrev,
                yPrice: yPrev,
                x1Line: startx,
                y1Line: yAxis,
                dotted: pActual,
                xRep: rstartx,
                yRep: ryAxis,
                LineColor: lineColor,
                ShowQtr: quarterlyLineData[qtrNode].ShowQtr,
                ShowRpt: quarterlyLineData[qtrNode].ShowRpt,
                Display: quarterlyLineData[qtrNode].Display,
                Surprise: quarterlyLineData[qtrNode].surprise,
                SurpriseDiff: quarterlyLineData[qtrNode].suprisePctDiff,
            };

            fundLineData.push(info);

            if (upDateEst && pActual === 0 && showHighLow) {
                upDateEst = yhAxis !== 0;

                yhAxis = (yhAxis === 0 && actual === 1) ? yAxis : yhAxis;

                const infoh =
                {
                    Date: quarterlyLineData[qtrNode].Date,
                    RDate: quarterlyLineData[qtrNode].ReportDate,
                    xPoint: xPrev,
                    yPoint: yPrevh,
                    xAxis: xPrev,
                    yPrice: yPrevh,
                    x1Line: startx,
                    y1Line: yhAxis,
                    dotted: 0,
                    LineColor: lineColor,
                    ShowQtr: quarterlyLineData[qtrNode].ShowQtr,
                    ShowRpt: quarterlyLineData[qtrNode].ShowRpt,
                    Display: quarterlyLineData[qtrNode].Display,
                    Surprise: quarterlyLineData[qtrNode].surprise,
                    SurpriseDiff: quarterlyLineData[qtrNode].suprisePctDiff,
                };

                fundLineData.push(infoh);

                ylAxis = (ylAxis === 0 && actual === 1) ? yAxis : ylAxis;

                const infol =
                {
                    Date: quarterlyLineData[qtrNode].Date,
                    RDate: quarterlyLineData[qtrNode].ReportDate,
                    xPoint: xPrev,
                    yPoint: yPrevl,
                    xAxis: xPrev,
                    yPrice: yPrevl,
                    x1Line: startx,
                    y1Line: ylAxis,
                    dotted: 0,
                    LineColor: lineColor,
                    ShowQtr: quarterlyLineData[qtrNode].ShowQtr,
                    ShowRpt: quarterlyLineData[qtrNode].ShowRpt,
                    Display: quarterlyLineData[qtrNode].Display,
                    Surprise: quarterlyLineData[qtrNode].surprise,
                    SurpriseDiff: quarterlyLineData[qtrNode].suprisePctDiff,
                };

                fundLineData.push(infol);
            }
        }


        pActual = actual;
        xPrev = startx;
        yPrev = yAxis;
        yPrevh = yhAxis;
        yPrevl = ylAxis;
        startup = true;
        pQtrVal = QtrVal;
    }

    return fundLineData;
}

function getFundamentalLineData(data) {
    const fundamentalLineData = {};
    fundamentalLineData[PriceChartConst.EPS] = data.EPSSnapshotData.Results.map((item) => ({
        ReportDate: DateHelper.getPSTFromLong(item.reportDt),
        FiscalDate: DateHelper.getPSTFromLong(item.fiscalDt),
        QtrData: item.eps,
        QtrDataHigh: item.epsHigh,
        QtrDataLow: item.epsLow,
        Type: item.EpsType,
        Display: item.display,
        EpsSuprisePctDiff: item.epsSurprisePctDiff,
        EpsSurprise: item.epsSurprise
    }));
    fundamentalLineData[PriceChartConst.RPS] = data.RPSSnapshotData.Results.map((item) => ({
        ReportDate: DateHelper.getPSTFromLong(item.reportDt),
        FiscalDate: DateHelper.getPSTFromLong(item.fiscalDt),
        QtrData: item.eps,
        QtrDataHigh: item.epsHigh,
        QtrDataLow: item.epsLow,
        Type: item.EpsType,
        Display: item.display
    }));
    ([PriceChartConst.BV, PriceChartConst.CFS, PriceChartConst.DIV, PriceChartConst.FCF]).map((value, index) => {
        fundamentalLineData[value] = data.T4QResults[index].Results.map((item) => ({
            ReportDate: DateHelper.getPSTFromLong(item.ReportDate),
            FiscalDate: DateHelper.getPSTFromLong(item.FiscalDate),
            QtrData: item.Value,
            QtrDataHigh: item.High,
            QtrDataLow: item.Low,
            Type: 1,
            Display: item.Display,
            LineColor: item.ColorString
        }))
        return null;
    });
    return fundamentalLineData;
}

const fundamentalLineConst = {
    [PriceChartConst.EPS]: {
        setting: "EarningLineSetting",
        multiplier: 20,
        isRps: false,
        nav: false,
        rptOk: true,
        isHistoric: false,
        multiplierSetting: "EPSMultiplierSettings"
    },
    [PriceChartConst.RPS]: {
        setting: "RevenueLineSetting",
        multiplier: 5,
        isRps: true,
        nav: false,
        rptOk: true,
        isHistoric: false,
        multiplierSetting: "RPSMultiplierSettings"
    },
    [PriceChartConst.BV]: {
        setting: "T4Q0LineSetting",
        multiplier: 1,
        isRps: true,
        nav: true,
        rptOk: false,
        isHistoric: false,
        multiplierSetting: "T4Q0MultiplierSettings"
    },
    [PriceChartConst.CFS]: {
        setting: "T4Q1LineSetting",
        multiplier: 20,
        isRps: true,
        nav: false,
        rptOk: false,
        isHistoric: false,
        multiplierSetting: "T4Q1MultiplierSettings"
    },
    [PriceChartConst.DIV]: {
        setting: "T4Q2LineSetting",
        multiplier: 20,
        isRps: true,
        nav: false,
        rptOk: false,
        isHistoric: false,
        multiplierSetting: "T4Q2MultiplierSettings"
    },
    [PriceChartConst.FCF]: {
        setting: "T4Q3LineSetting",
        multiplier: 13,
        isRps: true,
        nav: false,
        rptOk: false,
        isHistoric: false,
        multiplierSetting: "T4Q3MultiplierSettings"
    },
    [PriceChartConst.EXD]: {
        multiplierSetting: "EDMultiplierSettings"
    },
    [PriceChartConst.EPS_ANI]: {
        setting: "EarningLineSetting",
        multiplier: 20,
        isRps: false,
        nav: false,
        rptOk: true,
        isHistoric: false,
        epsAnimationSettings: "EPSAnimationSettings",
        multiplierSetting: "EPSMultiplierSettings"
    },
}

const fundamentalLineGraphComponent = {
    [PriceChartConst.EPS]: {
        auto: "epsAuto",
        fix: "epsFixed"
    },
    [PriceChartConst.RPS]: {
        auto: "rpsAuto",
        fix: "rpsFixed"
    },
    [PriceChartConst.BV]: {
        auto: "t4q0Auto",
        fix: "t4q0Fixed"
    },
    [PriceChartConst.CFS]: {
        auto: "t4q1Auto",
        fix: "t4q1Fixed"
    },
    [PriceChartConst.DIV]: {
        auto: "t4q2Auto",
        fix: "t4q2Fixed"
    },
    [PriceChartConst.FCF]: {
        auto: "t4q3Auto",
        fix: "t4q3Fixed"
    },
}
function round(value, decimals) {
    return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
}
function* updateFundamentalLineMultiplier({ currPt, prevPt, lineType }) {
    try {
        const _state = yield select(getFundamentalLineStates);
        const timeLine = yield select(timeLineSelect);
        const { endDate, scaleLabel, chartHeight } = yield select(priceChartReducerselect);
        const { periodicity, isIntraday, SymTypeEnum, majorPeriodicity, viewsSettings, nodeWidth } = yield select(getDatagraphStates);
        if (Math.abs(Math.round(prevPt) - Math.round(currPt)) < 2) {
            return;
        }
        const { fundamentalLineData, minPrice, maxPrice, selectedPerShare, multiplierPriceMenu, lastnodeIndex, multiplier, processedLineData, showPointer, lastQtrData, lastQtrY, rawLineData, fundamentalLineSettings, fundamentalLineMultipierSettings } = _state;
        const scale = getPriceChartScale(scaleLabel, isIntraday, SymTypeEnum)
        const minPt = _state.scale.ComputeY(0.001);
        prevPt = prevPt > minPt ? minPt : prevPt;
        currPt = currPt > minPt ? minPt : currPt;
        const firstValue = _state.scale.ComputePrice(prevPt);
        const lastValue = _state.scale.ComputePrice(currPt);
        multiplier[lineType] = multiplier[lineType] * lastValue / firstValue;
        multiplier[lineType] = Math.max(0.01, (multiplier[lineType] > 10 ? Math.round(multiplier[lineType], 0) : round(multiplier[lineType], 2)));
        const t4QMultipliers = getT4QMultipliers(multiplier);
        scale.InitScale(minPrice, maxPrice, chartHeight, periodicity, 1, 28 * nodeWidth, SymbolTypeEnum.USSTOCK, multiplier[PriceChartConst.EPS], multiplier[PriceChartConst.RPS], t4QMultipliers, /*_state.ExdTimeSeriesData.result ? _state.ExdTimeSeriesData.result.multiplier :*/ NaN);

        let actualPointer = 0;
        let startPoint = 0;
        let lng = rawLineData[lineType].length;
        if (lineType === PriceChartConst.EPS || lineType === PriceChartConst.RPS) {
            for (; actualPointer < lng; actualPointer++) {
                if (rawLineData[lineType][actualPointer].Actual > 0) {
                    break;
                }
            }
        }
        if (lng > 0 && actualPointer < lng) {
            lastQtrData[lineType] = rawLineData[lineType][actualPointer].QtrData;
            lastQtrData[lineType] = Math.abs(lastQtrData[lineType]) > 999 ? ExtremeDataValue.abbreviateFinancialValue(lastQtrData[lineType], 1) : ExtremeDataValue.showPrice(lastQtrData[lineType])
        }
        //eps Line is used for both NumOfQuarters
        startPoint = Math.max(0, actualPointer - viewsSettings.EarningLineSetting[majorPeriodicity].NumOfQuarters);
        processedLineData[lineType] = getFundamentalLines(rawLineData[lineType], scale, timeLine.dates, multiplier[lineType], "#FFFF00", startPoint, fundamentalLineSettings[lineType].showHighLowEstimate, endDate, periodicity, nodeWidth, lastnodeIndex);
        processedLineData[lineType].isDataChange = true;
        actualPointer = 0;
        lng = processedLineData[lineType].length;
        showPointer[lineType] = lng > 0;
        if (lineType === PriceChartConst.EPS || lineType === PriceChartConst.RPS) {
            for (; actualPointer < lng; actualPointer++) {
                if (processedLineData[lineType][actualPointer].dotted > 0) {
                    break;
                }
            }
        }
        if (lng > 0 && actualPointer < lng) {
            lastQtrY[lineType] = processedLineData[lineType][actualPointer].yPoint;
        }
        yield put(fundametnalLineDataReady(fundamentalLineData, selectedPerShare,
            rawLineData, processedLineData,
            lastQtrData, lastQtrY,
            showPointer, multiplierPriceMenu,
            scale, multiplier,
            fundamentalLineSettings, fundamentalLineMultipierSettings, lastnodeIndex, minPrice, maxPrice));
        if(!_state.isPlaying){
            yield put({
                type: ActionTypes.SET_PRICE_SCALE,
                scale
            });
        }
    }
    catch (error) {
        console.error("Error occured in updateFundamentalLineMultiplier of FundamenalLineSaga.js", error);
    }
}

function getMinMaxValue(padding, nodeCount, graphData) {
    let maxPrice = Number.NEGATIVE_INFINITY;
    let minPrice = Number.POSITIVE_INFINITY;
    const lastNode = Math.min(graphData.length, nodeCount);
    for (let i = padding; i < lastNode; i++) {
        if (!graphData[i].IsVisible) {
            continue;
        }
        if (maxPrice < graphData[i].High) {
            maxPrice = graphData[i].High;
        }
        if (minPrice > graphData[i].Low && graphData[i].Low > 0) {
            minPrice = graphData[i].Low;
        }
    }

    return [minPrice, maxPrice];
}

const labelOrdersConst = {
    [PriceChartConst.EPS]: [PriceChartConst.FCF, PriceChartConst.RPS, PriceChartConst.EXD, PriceChartConst.BV, PriceChartConst.CFS, PriceChartConst.DIV, PriceChartConst.EPS],
    [PriceChartConst.FFO]: [PriceChartConst.FCF, PriceChartConst.RPS, PriceChartConst.EXD, PriceChartConst.BV, PriceChartConst.CFS, PriceChartConst.DIV, PriceChartConst.EPS],
    [PriceChartConst.RPS]: [PriceChartConst.EXD, PriceChartConst.BV, PriceChartConst.CFS, PriceChartConst.DIV, PriceChartConst.EPS, PriceChartConst.FCF, PriceChartConst.RPS],
    [PriceChartConst.BV]: [PriceChartConst.CFS, PriceChartConst.DIV, PriceChartConst.EPS, PriceChartConst.FCF, PriceChartConst.RPS, PriceChartConst.EXD, PriceChartConst.BV],
    [PriceChartConst.CFS]: [PriceChartConst.DIV, PriceChartConst.EPS, PriceChartConst.FCF, PriceChartConst.RPS, PriceChartConst.EXD, PriceChartConst.BV, PriceChartConst.CFS],
    [PriceChartConst.DIV]: [PriceChartConst.EPS, PriceChartConst.FCF, PriceChartConst.RPS, PriceChartConst.EXD, PriceChartConst.BV, PriceChartConst.CFS, PriceChartConst.DIV],
    [PriceChartConst.FCF]: [PriceChartConst.RPS, PriceChartConst.EXD, PriceChartConst.BV, PriceChartConst.CFS, PriceChartConst.DIV, PriceChartConst.EPS, PriceChartConst.FCF],
    [PriceChartConst.EXD]: [PriceChartConst.BV, PriceChartConst.CFS, PriceChartConst.DIV, PriceChartConst.EPS, PriceChartConst.FCF, PriceChartConst.RPS, PriceChartConst.EXD]
}
function* switchLabels({ selectedPerShare, isSaveSetting }) {
    try {
        if (selectedPerShare === undefined) {
            return;
        }
        const { viewsSettings, majorPeriodicity, SymbolType } = yield select(getDatagraphStates);
        const state = yield select(getFundamentalLineStates);
        const orderedLabels = labelOrdersConst[selectedPerShare];
        if (selectedPerShare && SymbolType !== SmartViewType.INDEX) {
            selectedPerShare = '';
            for (const item of orderedLabels) {
                if (item !== PriceChartConst.EXD && viewsSettings[fundamentalLineConst[item].setting][majorPeriodicity].IsVisible) {
                    selectedPerShare = item;
                    break;
                }
                else if (item === PriceChartConst.EXD && state.isExternalDataVisible) {
                    selectedPerShare = item;
                    break;
                }
            }
        }
        else {
            selectedPerShare = viewsSettings.EPSLabelSettings[majorPeriodicity];
        }

        yield put(updateFundamentalLineSelectPerShare(selectedPerShare, isSaveSetting));
    }
    catch (error) {
        console.log(error)
    }
}

function* saveFundamentalLable({ selectedPerShare, isSaveSetting }) {
    try {
        if (isSaveSetting) {
            const { viewsSettings, majorPeriodicity, nodeCount, InitialBufferSize, SymbolType } = yield select(getDatagraphStates);
            if (SymbolType !== SmartViewType.INDEX) {
                const epsSettings = viewsSettings.EarningLineSetting[majorPeriodicity];
                const rpsSettings = viewsSettings.RevenueLineSetting[majorPeriodicity];
                if (((!epsSettings.IsVisible) && (!rpsSettings.IsVisible)) ||
                    ((epsSettings.IsVisible) && (!rpsSettings.IsVisible) && selectedPerShare === PriceChartConst.EPS) ||
                    ((!epsSettings.IsVisible) && (rpsSettings.IsVisible) && selectedPerShare === PriceChartConst.RPS)) {
                    const lastDate = yield select(getTimeLineEndDate);
                    yield put(initTimeLine(lastDate, nodeCount, InitialBufferSize, false, false,));
                }
            }

            viewsSettings.EPSLabelSettings[majorPeriodicity] = selectedPerShare;
            SettingsStore.saveSettings();
        }
    }
    catch (error) {
        console.log(error)
    }
}
const indexSymbols = ["0S&P5", "0DJI", "0DJT", "0DJU", "0DJIA", "0DJUA", "0DJTA", "0DJIC"]
function* initFundamentalLine(action) {
    try {
        if(action?.isStreamingProc){
            return;
        }
        const { lineType } = action;
        if (!lineType) {
            yield put(resetFundamentalLineStates())
        }
        const { pricePanelData, SymbolInfo, SymTypeEnum, viewsSettings, SymbolType, padding, periodicity, majorPeriodicity, startXPoint, nodeCount, nodeWidth, isIntraday, isHistoric } = yield select(getDatagraphStates);
        const isUserSPEntitled = UserInfoUtil.IsUserEntitled(EntitlementType.SP_Entitlement);
        
        if (viewsSettings.EarningLineSetting && pricePanelData.RPSSnapshotData && pricePanelData.RPSSnapshotData.Results.length >= 0 &&
            pricePanelData.EPSSnapshotData && pricePanelData.EPSSnapshotData.Results.length >= 0 && !isIntraday &&
            (SymbolType === SmartViewType.STOCK || (SymbolType === SmartViewType.INDEX && isUserSPEntitled && indexSymbols.includes(SymbolInfo.Symbol)))) {

            const timeLine = yield select(timeLineSelect);
            const { scaleLabel, endDate, chartHeight } = yield select(priceChartReducerselect);
            const quarterlyData = new QuarterlyData();
            let selectedPerShare = viewsSettings.EPSLabelSettings[majorPeriodicity];
            if (selectedPerShare && !viewsSettings[fundamentalLineConst[selectedPerShare].setting][majorPeriodicity].IsVisible) {
                selectedPerShare = ''
            }
            const numberOfQuarters = viewsSettings.EarningLineSetting[majorPeriodicity].NumOfQuarters;
            const fundamentalLineData = yield call(getFundamentalLineData, pricePanelData);;
            let fundamentalLineConstant = [];
            let lineMultipliers = {}
            const state = yield select(getFundamentalLineStates);
            if (lineType) {
                fundamentalLineConstant = lineType
                lineMultipliers = state.multiplier;
            }
            else {
                fundamentalLineConstant = SmartViewType.INDEX === SymbolType ? [PriceChartConst.EPS] : [PriceChartConst.EPS, PriceChartConst.RPS, PriceChartConst.BV, PriceChartConst.CFS, PriceChartConst.DIV, PriceChartConst.FCF]
            }

            const rawLineData = {}
            const fundamentalLineSettings = {}
            const fundamentalLineMultipierSettings = {}
            fundamentalLineConst[PriceChartConst.RPS].isHistoric = isHistoric;
            fundamentalLineConstant.map((item) => {
                fundamentalLineSettings[item] = viewsSettings[fundamentalLineConst[item].setting][majorPeriodicity];
                fundamentalLineMultipierSettings[item] = viewsSettings[fundamentalLineConst[item].multiplierSetting];
                rawLineData[item] = []
                const quaterlyMultiplier = quarterlyData.QuarterlyDataValues(fundamentalLineData[item],
                    pricePanelData.HsfData.HSFResults,
                    rawLineData[item],
                    periodicity,
                    startXPoint,
                    fundamentalLineMultipierSettings[item].Fixed,
                    fundamentalLineConst[item].multiplier,
                    SymbolInfo,
                    fundamentalLineConst[item].isRps,
                    fundamentalLineConst[item].nav,
                    fundamentalLineConst[item].rptOk,
                    fundamentalLineConst[item].isHistoric);
                lineMultipliers[item] = fundamentalLineData[item].length >= 0 ? quaterlyMultiplier : NaN;
                return null;
            })
            const t4QMultipliers = getT4QMultipliers(lineMultipliers);
            const scale = getPriceChartScale(scaleLabel, isIntraday, SymTypeEnum)
            // const scale = yield initScale(pricePanelData, pricePanelData.HsfData.HSFResults, lineMultipliers[PriceChartConst.EPS], lineMultipliers[PriceChartConst.RPS], t4QMultipliers, /*_state.ExdTimeSeriesData.result ? _state.ExdTimeSeriesData.result.multiplier :*/ NaN)
            const [minPrice, maxPrice] = getMinMaxValue(padding, nodeCount, pricePanelData.HsfData.HSFResults);
            scale.InitScale(minPrice, maxPrice, chartHeight, periodicity, 1, 28 * nodeWidth, SymbolTypeEnum.USSTOCK, lineMultipliers[PriceChartConst.EPS], lineMultipliers[PriceChartConst.RPS], t4QMultipliers, /*_state.ExdTimeSeriesData.result ? _state.ExdTimeSeriesData.result.multiplier :*/ NaN);
            const processedLineData = {};
            const lastQtrData = {};
            const lastQtrY = {};
            const showPointer = {};
            const multiplierPriceMenu = {}
            fundamentalLineConstant.forEach((item) => {
                let actualPointer = 0;
                let lng = rawLineData[item].length;
                if (item === PriceChartConst.EPS || item === PriceChartConst.RPS) {
                    for (; actualPointer < lng; actualPointer++) {
                        if (rawLineData[item][actualPointer].Actual > 0) {
                            break;
                        }
                    }
                }
                if (lng > 0 && actualPointer < lng) {
                    lastQtrData[item] = rawLineData[item][actualPointer].QtrData;
                    lastQtrData[item] = Math.abs(lastQtrData[item]) > 999 ? ExtremeDataValue.abbreviateFinancialValue(lastQtrData[item], 1) : ExtremeDataValue.showPrice(lastQtrData[item])
                }
                const startPoint = Math.max(0, actualPointer - numberOfQuarters);
                if (rawLineData[item].length > 0) {
                    processedLineData[item] = getFundamentalLines(rawLineData[item], scale, timeLine.dates, lineMultipliers[item], "#FFFF00", startPoint, fundamentalLineSettings[item].showHighLowEstimate, endDate, periodicity, nodeWidth, nodeCount);
                    processedLineData[item].isDataChange = true;
                }
                else {
                    processedLineData[item] = [];
                }
                actualPointer = 0;
                lng = processedLineData[item].length;
                showPointer[item] = lng > 0;
                if (item === PriceChartConst.EPS || item === PriceChartConst.RPS) {
                    for (; actualPointer < lng; actualPointer++) {
                        if (processedLineData[item][actualPointer].dotted > 0) {
                            break;
                        }
                    }
                }
                if (lng > 0 && actualPointer < lng) {
                    lastQtrY[item] = processedLineData[item][actualPointer].yPoint;
                }
                multiplierPriceMenu[item] = [{ header: "Auto Fit", isActive: fundamentalLineMultipierSettings[item].Auto, graphComponent: fundamentalLineGraphComponent[item].auto, symbol: null, refresh: false, resKey: 'ch_epsln_afit' },
                { header: "Fixed", isActive: fundamentalLineMultipierSettings[item].Fixed, graphComponent: fundamentalLineGraphComponent[item].fix, symbol: null, refresh: false, resKey: 'ch_epsln_afit' }]
            })
            yield put(fundametnalLineDataReady(fundamentalLineData, selectedPerShare,
                rawLineData, processedLineData,
                lastQtrData, lastQtrY,
                showPointer, multiplierPriceMenu,
                scale, lineMultipliers,
                fundamentalLineSettings, fundamentalLineMultipierSettings, nodeCount, minPrice, maxPrice));
                yield put({
                    type: ActionTypes.SET_PRICE_SCALE,
                    scale
                });
        }
    }
    catch (error) {
        console.error("Error occured in InitFundamentalLine of FundamenalLineSaga.js", error);
    }
}
function* processEPSAnimation() {
    try {
        const { pricePanelData, SymbolInfo, viewsSettings, SymbolType, periodicity, majorPeriodicity, startXPoint, nodeCount, nodeWidth, isIntraday, isHistoric } = yield select(getDatagraphStates);
        const isUserSPEntitled = UserInfoUtil.IsUserEntitled(EntitlementType.SP_Entitlement);
        
        if (viewsSettings.EarningLineSetting && pricePanelData.RPSSnapshotData && pricePanelData.RPSSnapshotData.Results.length >= 0 &&
            pricePanelData.EPSSnapshotData && pricePanelData.EPSSnapshotData.Results.length >= 0 && !isIntraday &&
            (SymbolType === SmartViewType.STOCK || (SymbolType === SmartViewType.INDEX && isUserSPEntitled && indexSymbols.includes(SymbolInfo.Symbol)))) {

            const timeLine = yield select(timeLineSelect);
            const { endDate, chartHeight } = yield select(priceChartReducerselect);
            const quarterlyData = new QuarterlyData();
            let selectedPerShare = viewsSettings.EPSLabelSettings[majorPeriodicity];
            if (selectedPerShare && !viewsSettings[fundamentalLineConst[selectedPerShare].setting][majorPeriodicity].IsVisible) {
                selectedPerShare = ''
            }
            const numberOfQuarters = viewsSettings.EarningLineSetting[majorPeriodicity].NumOfQuarters;
            const fundamentalLineData = yield call(getFundamentalLineData, pricePanelData);
            const { multiplier, scale, minPrice, maxPrice, fundamentalLineMultipierSettings, rawLineData,
                processedLineData, lastQtrData, lastQtrY, showPointer, fundamentalLineSettings } = yield select(getFundamentalLineStates);

            [PriceChartConst.EPS].map((item) => {
                rawLineData[item] = []
                quarterlyData.QuarterlyDataValues(fundamentalLineData[item],
                    pricePanelData.HsfData.HSFResults,
                    rawLineData[item],
                    periodicity,
                    startXPoint,
                    fundamentalLineMultipierSettings[item].Fixed,
                    fundamentalLineConst[item].multiplier,
                    SymbolInfo,
                    fundamentalLineConst[item].isRps,
                    fundamentalLineConst[item].nav,
                    fundamentalLineConst[item].rptOk,
                    fundamentalLineConst[item].isHistoric);
                return null;
            })
            const t4QMultipliers = getT4QMultipliers(multiplier);
            
            scale.InitScale(minPrice, maxPrice, chartHeight, periodicity, 1, 28 * nodeWidth, SymbolTypeEnum.USSTOCK, multiplier[PriceChartConst.EPS], multiplier[PriceChartConst.RPS], t4QMultipliers, /*_state.ExdTimeSeriesData.result ? _state.ExdTimeSeriesData.result.multiplier :*/ NaN);
        
            [PriceChartConst.EPS].forEach((item) => {
                let actualPointer = 0;
                let lng = rawLineData[item].length;
                if (item === PriceChartConst.EPS || item === PriceChartConst.RPS) {
                    for (; actualPointer < lng; actualPointer++) {
                        if (rawLineData[item][actualPointer].Actual > 0) {
                            break;
                        }
                    }
                }
                if (lng > 0 && actualPointer < lng) {
                    lastQtrData[item] = rawLineData[item][actualPointer].QtrData;
                    lastQtrData[item] = Math.abs(lastQtrData[item]) > 999 ? ExtremeDataValue.abbreviateFinancialValue(lastQtrData[item], 1) : ExtremeDataValue.showPrice(lastQtrData[item])
                }
                const startPoint = Math.max(0, actualPointer - numberOfQuarters);
                if (rawLineData[item].length > 0) {
                    processedLineData[item] = getFundamentalLines(rawLineData[item], scale, timeLine.dates, multiplier[item], "#FFFF00", startPoint, fundamentalLineSettings[item].showHighLowEstimate, endDate, periodicity, nodeWidth, nodeCount);
                    processedLineData[item].isDataChange = true;
                }
                else {
                    processedLineData[item] = [];
                }
                actualPointer = 0;
                lng = processedLineData[item].length;
                showPointer[item] = lng > 0;
                if (item === PriceChartConst.EPS || item === PriceChartConst.RPS) {
                    for (; actualPointer < lng; actualPointer++) {
                        if (processedLineData[item][actualPointer].dotted > 0) {
                            break;
                        }
                    }
                }
                if (lng > 0 && actualPointer < lng) {
                    lastQtrY[item] = processedLineData[item][actualPointer].yPoint;
                }
            })
            yield put(updateFundamentalLineStates({
                rawLineData, processedLineData,
                lastQtrData, lastQtrY,
                showPointer
            }))
        }
    }
    catch (error) {
        console.error("Error occured in processEPSAnimation of FundamenalLineSaga.js", error);
    }
}

function* openFundamentalLineDialog({ item }) {
    try {
        const { viewsSettings, periodicity, SymTypeEnum } = yield select(getDatagraphStates);
        const settings = viewsSettings[fundamentalLineConst[item.lineType].setting];
        const fundamentalLinesSettingData = [];
        const isShowHighLowEstimateAvailable = item.graphComponent === GraphComponent.EPSLine || (item.graphComponent === GraphComponent.RPSLine && (SymTypeEnum === SymbolTypeEnum.USSTOCK || SymTypeEnum === SymbolTypeEnum.ADR))
        for (const tab in PeriodicityTabsConstants) {
            fundamentalLinesSettingData.push({
                periodicity: tab,
                header: LineDialogPeriodicityTranslateHelper[tab],
                lineColor: settings[tab].lineColor,
                lineThickness: settings[tab].lineThickness,
                showEarningSurprise: settings[tab].showEarningSurprise,
                isShowHighLowEstimateAvailable,
                showHighLowEstimate: settings[tab].showHighLowEstimate,
            });
        }
        yield put({
            type: PriceChartConstants.ActionTypes.UPDATE_FUNDAMENTAL_LINE_DIALOG_SETTING,
            showSettingDialog: true,
            fundamentalLinesSettingData,
            selectedPeriodicity: periodicity,
            selectedFundamentalLine: item.lineType
        });
    }
    catch (error) {
        console.error("Error occured in openFundamentalLineDialog of FundamenalLineSaga.js", error);
    }
}

function* saveFudamentalEditPopup() {
    try {
        const state = yield select(getFundamentalLineStates);
        const { viewsSettings } = yield select(getDatagraphStates);
        const settings = viewsSettings[fundamentalLineConst[state.selectedFundamentalLine].setting];
        state.fundamentalLinesSettingData.forEach((item) => {
            settings[item.periodicity].lineColor = item.lineColor;
            settings[item.periodicity].lineThickness = item.lineThickness;
            settings[item.periodicity].showEarningSurprise = item.showEarningSurprise;
            settings[item.periodicity].showHighLowEstimate = item.showHighLowEstimate;
        });

        yield put({
            type: PriceChartConstants.ActionTypes.UPDATE_FUNDAMENTAL_LINE_DIALOG_SETTING,
            showSettingDialog: false,
            fundamentalLinesSettingData: [],
            selectedPeriodicity: state.selectedPeriodicity,
            selectedFundamentalLine: state.selectedFundamentalLine
        });
        yield call(initFundamentalLine, { lineType: [state.selectedFundamentalLine] })
        SettingsStore.saveSettings();
    }
    catch (error) {
        console.error("Error occured in saveFudamentalEditPopup of FundamenalLineSaga.js", error);
    }
}

function* restoreFundamentalLineSetting() {
    try {
        const state = yield select(getFundamentalLineStates);
        const defaultSettings = state.defaultSettings[state.selectedFundamentalLine];
        const fundamentalLinesSettingData = state.fundamentalLinesSettingData.map((item) => {
            if (item.periodicity === state.selectedPeriodicity) {
                item.lineColor = defaultSettings[item.periodicity].lineColor;
                item.lineThickness = defaultSettings[item.periodicity].lineThickness;
                item.showEarningSurprise = defaultSettings[item.periodicity].showEarningSurprise;
                item.showHighLowEstimate = defaultSettings[item.periodicity].showHighLowEstimate;
            }
            return item;
        });
        yield put({
            type: PriceChartConstants.ActionTypes.UPDATE_FUNDAMENTAL_LINE_DIALOG_SETTING,
            showSettingDialog: true,
            fundamentalLinesSettingData,
            selectedPeriodicity: state.selectedPeriodicity,
            selectedFundamentalLine: state.selectedFundamentalLine
        });
    }
    catch (error) {
        console.error("Error occured in restoreFundamentalLineSetting of FundamenalLineSaga.js", error);
    }
}

//**********************EPSAnimation  */
function* openEPSAnimationDialog({ item }) {
    try {
        const { viewsSettings, majorPeriodicity } = yield select(getDatagraphStates);
        const settings = viewsSettings[fundamentalLineConst[item.lineType].epsAnimationSettings];
        const epsAnimationSettingsData = [];
        for (const tab of [GraphType.Daily, GraphType.Weekly, GraphType.Monthly]) {
            epsAnimationSettingsData.push({
                periodicity: tab,
                header: LineDialogPeriodicityTranslateHelper[tab],
                IsAnimateWithChartLoad: settings[tab].IsAnimateWithChartLoad,
                IsLoopbackEnabled: settings[tab].IsLoopbackEnabled,
                AnimationLookbackSpan: settings[tab].AnimationLookbackSpan,
                AnimationSpeedEnum: settings[tab].AnimationSpeedEnum,
            });
        }
        yield put({
            type: PriceChartConstants.ActionTypes.UPDATE_EPS_ANIMATION_DIALOG_SETTING,
            isAnimationDialogOpen: true,
            epsAnimationSettingsData,
            selectedPeriodicity: majorPeriodicity,
            selectedFundamentalLine: item.lineType,
        });
    }
    catch (error) {
        console.error("Error occured in openEPSAnimationDialog of FundamenalLineSaga.js", error);
    }
}

function* saveEPSAnimationPopup() {
    try {
        const { viewsSettings, majorPeriodicity } = yield select(getDatagraphStates);
        const state = yield select(getFundamentalLineStates);
        const settings = viewsSettings[fundamentalLineConst[state.selectedFundamentalLine].epsAnimationSettings];
        state.epsAnimationSettingsData.forEach((item) => {
            settings[item.periodicity].IsAnimateWithChartLoad = item.IsAnimateWithChartLoad;
            settings[item.periodicity].IsLoopbackEnabled = item.IsLoopbackEnabled;
            settings[item.periodicity].AnimationLookbackSpan = item.AnimationLookbackSpan;
            settings[item.periodicity].AnimationSpeedEnum = item.AnimationSpeedEnum;
        });
        SettingsStore.saveSettings();
        yield put({
            type: PriceChartConstants.ActionTypes.UPDATE_EPS_ANIMATION_DIALOG_SETTING,
            isAnimationDialogOpen: false,
            epsAnimationSettingsData: [],
            selectedPeriodicity: '',
            selectedFundamentalLine: '',
        });
        yield put(updateFundamentalLineStates({
            AnimationSpeedEnum: settings[majorPeriodicity].AnimationSpeedEnum,
            IsAnimateWithChartLoad: settings[majorPeriodicity].IsAnimateWithChartLoad
        }))
        yield call(initFundamentalLine, { lineType: [state.selectedFundamentalLine] })
    }
    catch (error) {
        console.error("Error occured in saveEPSAnimationPopup of FundamenalLineSaga.js", error);
    }
}

function* restoreEPSAnimationSetting() {
    try {
        const state = yield select(getFundamentalLineStates);
        const epsAnimationSettingsData = state.epsAnimationSettingsData.map((item) => {
            if (item.periodicity === state.selectedPeriodicity) {
                item.IsAnimateWithChartLoad = state.epsAnimationDefaultSettingsData[item.periodicity].IsAnimateWithChartLoad;
                item.IsLoopbackEnabled = state.epsAnimationDefaultSettingsData[item.periodicity].IsLoopbackEnabled;
                item.AnimationLookbackSpan = state.epsAnimationDefaultSettingsData[item.periodicity].AnimationLookbackSpan;
                item.AnimationSpeedEnum = state.epsAnimationDefaultSettingsData[item.periodicity].AnimationSpeedEnum;
            }
            return item;
        });
        yield put({
            type: PriceChartConstants.ActionTypes.UPDATE_EPS_ANIMATION_DIALOG_SETTING,
            isAnimationDialogOpen: true,
            epsAnimationSettingsData,
            selectedPeriodicity: state.selectedPeriodicity,
            selectedFundamentalLine: state.selectedFundamentalLine
        });
    }
    catch (error) {
        console.error("Error occured in restoreEPSAnimationSetting of FundamenalLineSaga.js", error);
    }
}

function setAnimationTimeLine(endDate, TimeLine) {
    const lastDateNode = TimeLine.length - 1;
    let endDateNode = 0;
    TimeLine.forEach((item) => {
        if (item.Date >= endDate) {
            endDateNode++;
        }
    });
    return TimeLine.slice(endDateNode - 1, lastDateNode);
}

function getSnapshotLastVisibleIndex(lastVisibleDate, earningsAnimationResults, epsTimeLine) {
    let compareTimeLinePoint = [];
    if (earningsAnimationResults && earningsAnimationResults.length > 0 && epsTimeLine) {
        let lastSnapshotDate = new Date(earningsAnimationResults[0].SnapshotDate.getFullYear(), earningsAnimationResults[0].SnapshotDate.getMonth(), earningsAnimationResults[0].SnapshotDate.getDate());
        if (lastVisibleDate && earningsAnimationResults[0].SnapshotDate < lastVisibleDate) {
            lastSnapshotDate = new Date(lastVisibleDate.getFullYear(), lastVisibleDate.getMonth(), lastVisibleDate.getDate());
        }
        compareTimeLinePoint = epsTimeLine.filter((item) => item.Date >= lastSnapshotDate);
    }
    return compareTimeLinePoint.length > 0 ? epsTimeLine.length - compareTimeLinePoint.length : 0;
}
function getSnapshotLastStartIndex(epsTimeLine, snapshots, lastStartGraphValueIndex) {
    let compareTimeLinePoint = [];
    if (snapshots && snapshots.length > 0 && epsTimeLine) {
        const lastSnapshotDate = new Date(snapshots[0].SnapshotDate.getFullYear(), snapshots[0].SnapshotDate.getMonth(), snapshots[0].SnapshotDate.getDate());
        compareTimeLinePoint = epsTimeLine.filter((item) => item.Date >= lastSnapshotDate);
    }
    return compareTimeLinePoint.length > 0 ? epsTimeLine.length - compareTimeLinePoint.length : lastStartGraphValueIndex;
}
function* getEarningsAnimationSetFromController(action) {
    try {
        if(action?.isStreamingProc){
            return;
        }
        const { viewsSettings, periodicity, majorPeriodicity, Symbol, SymbolInfo, pricePanelData, nodeWidth } = yield select(getDatagraphStates);
        if (viewsSettings.EPSAnimationSettings) {
            const TimeLine = yield select(getTimeLine);
            const state = yield select(getFundamentalLineStates);
            const { chartWidth, endDate } = yield select(priceChartReducerselect);
            const nodeCount = parseInt(chartWidth / nodeWidth);
            const lastVisibleDate = TimeLine.length > 0 && TimeLine[nodeCount - 1] ? TimeLine[nodeCount - 1].Date : null;
            switch (majorPeriodicity) {
                case GraphType.Daily:
                case GraphType.Monthly:
                case GraphType.Weekly:
                    break;
                default:
                    return;
            }
            if (!pricePanelData) {
                return null;
            }
            const navDataGraphSettings = DatagraphStore.getNavDataGraphSettings();
            const isAnimationMessageDialogAvailable = navDataGraphSettings.isAnimationMessageDialogAvailable;
            const epsAnimationSettings = viewsSettings.EPSAnimationSettings[majorPeriodicity];
            const earningLineSetting = viewsSettings.EarningLineSetting ? viewsSettings.EarningLineSetting[majorPeriodicity] : undefined;
            const revenueLineSetting = viewsSettings.RevenueLineSetting ? viewsSettings.RevenueLineSetting[majorPeriodicity] : undefined;
            const fcQuarters = earningLineSetting === undefined || revenueLineSetting === undefined || (!earningLineSetting.IsVisible && !revenueLineSetting.IsVisible) ? 0 : earningLineSetting.NumOfQuarters;
            const isWon = navDataGraphSettings.PreferenceSettings.ReportedEarningsSettings.WonOnly === 0 ? "0" : "1";
            let epsBeginDate = lastVisibleDate;
            if (endDate === null || epsBeginDate === null) {
                return null;
            }
            const epsTimeLine = setAnimationTimeLine(endDate, TimeLine);
            const currentSpan = DateHelper.DaysDiff(epsBeginDate, endDate).Days;
            const requestedSpan = getLookBackSpan(epsAnimationSettings.AnimationLookbackSpan);
            if (currentSpan > requestedSpan) {
                const newStartDate = new Date();
                newStartDate.setTime(endDate.getTime() - requestedSpan * 24 * 3600 * 1000);
                epsBeginDate = newStartDate;
            }
            const epsStartDate = moment(epsBeginDate).format('YYYYMMDD');
            const epsEndDate = moment(endDate).format('YYYYMMDD');
            const isMiniListPlay = MiniListStore.getMiniListPlayingStatus();
            let epsScrubberVisiblity = false;
            if (SymbolInfo.SymTypeEnum && (SymbolInfo.SymTypeEnum === SymbolTypeEnum.ETF || SymbolInfo.SymTypeEnum === SymbolTypeEnum.FundClosedEnd)) {
                epsScrubberVisiblity = false;
            }
            const isEntitled = UserInfoUtil.IsUserEntitledtoSnP(SymbolInfo.Symbol ? SymbolInfo.Symbol : '');
            const request = {
                Symbol,
                MsId: SymbolInfo.MsId,
                epsStartDate: epsStartDate,
                epsEndDate: epsEndDate,
                periodicity: PeriodicityHelper.convertToPeriodicity(periodicity),
                isWon: isWon,
                fcQuarters: fcQuarters
            }
            if ((state.request && request.Symbol !== state.request.Symbol ||
                request.MsId !== state.request.MsId ||
                request.epsStartDate !== state.request.epsStartDate ||
                request.epsEndDate !== state.request.epsEndDate ||
                request.periodicity !== state.request.periodicity ||
                request.isWon !== state.request.isWon ||
                request.fcQuarters !== state.request.fcQuarters)
            ) {
                const ePSSnapshots = yield call(GraphApi.GetEPSSnapshotsRequest, request.Symbol, request.MsId, request.epsStartDate, request.epsEndDate, request.periodicity, request.isWon, request.fcQuarters)
                if (MiniListHelper.ActiveSymbolCheck(Symbol, isMiniListPlay)) {
                    const earningsAnimationResults = DatagraphStore.processEarningsAnimationDates(ePSSnapshots.data);
                    const { snapshots, lastVisibleGraphValueIndex, lastStartGraphValueIndex, currentGraphValueIndex } = processEarningsAnimationResults(epsBeginDate, majorPeriodicity, endDate, lastVisibleDate, earningsAnimationResults, epsTimeLine, state.lastStartGraphValueIndex);
                    yield put(updateFundamentalLineStates({
                        snapshots, isAnimationMessageDialogAvailable, lastVisibleGraphValueIndex, lastStartGraphValueIndex,
                        currentGraphValueIndex, isEPSAnimationDataReady: false, epsAnimationSettings, IsEarningsAnimationDisplayed: epsAnimationSettings.IsEarningsAnimationDisplayed,
                        IsEarningsAnimationDocked: epsAnimationSettings.IsEarningsAnimationDocked, epsTimeLine, epsEndDate: endDate, request, earningsAnimationResults,
                        epsScrubberVisiblity: pricePanelData.EPSSnapshotData && pricePanelData.EPSSnapshotData.Results.length > 0 && isEntitled || epsScrubberVisiblity,
                        IsAnimateWithChartLoad: epsAnimationSettings.IsAnimateWithChartLoad,
                        AnimationSpeedEnum: epsAnimationSettings.AnimationSpeedEnum,
                        isPlaying: epsAnimationSettings.IsEarningsAnimationDisplayed && epsAnimationSettings.IsAnimateWithChartLoad && !epsAnimationSettings.IsEarningsAnimationDocked
                    }))
                    return;
                }
            }

            const { snapshots, lastVisibleGraphValueIndex, lastStartGraphValueIndex, currentGraphValueIndex } = processEarningsAnimationResults(epsBeginDate, majorPeriodicity, endDate, lastVisibleDate, state.earningsAnimationResults, epsTimeLine, state.lastStartGraphValueIndex);
            yield put(updateFundamentalLineStates({
                snapshots, isAnimationMessageDialogAvailable, lastVisibleGraphValueIndex, lastStartGraphValueIndex,
                currentGraphValueIndex, epsAnimationSettings, IsEarningsAnimationDisplayed: epsAnimationSettings.IsEarningsAnimationDisplayed,
                IsEarningsAnimationDocked: epsAnimationSettings.IsEarningsAnimationDocked, epsTimeLine, epsEndDate: endDate,
                epsScrubberVisiblity: pricePanelData.EPSSnapshotData && pricePanelData.EPSSnapshotData.Results.length > 0 && isEntitled || epsScrubberVisiblity,
                IsAnimateWithChartLoad: epsAnimationSettings.IsAnimateWithChartLoad,
                AnimationSpeedEnum: epsAnimationSettings.AnimationSpeedEnum
            }));
        }
    }
    catch (error) {
        console.log(`Error occurs in FundamentalLineSaga.js, getEarningsAnimationSetFromController, ${error}`)
    }
}

function processEarningsAnimationResults(epsBeginDate, majorPeriodicity, endDate, lastVisibleDate, earningsAnimationResults, epsTimeLine, lastStartGraphValueIndex) {
    const snapshots = [];
    if (earningsAnimationResults && earningsAnimationResults.length > 0) {
        earningsAnimationResults.forEach((itemData) => {
            if (itemData.SnapshotDate >= epsBeginDate) {
                snapshots.push(itemData);
            }
        });

        const lastIndex = snapshots.length - 1;
        let snapshotContainsCurrent = false;
        if (lastIndex >= 0) {
            const lastDate = snapshots[lastIndex].SnapshotDate;
            if (majorPeriodicity === GraphType.Monthly) {
                snapshotContainsCurrent = lastDate.getFullYear() === endDate.getFullYear() && lastDate.getMonth() === endDate.getMonth();
            }
            else {
                snapshotContainsCurrent = lastDate.getDate() === endDate.getDate();
            }
        }

        if (snapshotContainsCurrent) {
            snapshots.splice(lastIndex, 1);
        }
    }

    const lastVisibleGraphValueIndex = getSnapshotLastVisibleIndex(lastVisibleDate, earningsAnimationResults, epsTimeLine);
    lastStartGraphValueIndex = getSnapshotLastStartIndex(epsTimeLine, snapshots, lastStartGraphValueIndex);
    let currentGraphValueIndex = 0
    if (earningsAnimationResults && earningsAnimationResults.length === 0) {
        currentGraphValueIndex = epsTimeLine.length;
    }
    return { snapshots, lastVisibleGraphValueIndex, lastStartGraphValueIndex, currentGraphValueIndex }
}
function getLookBackSpan(aLookbackSpan) {
    let timeSpan = 0; //days
    switch (aLookbackSpan) {
        case AnimationLookbackSpan.OneMonth:
            timeSpan = 30;
            break;
        case AnimationLookbackSpan.SixMonths:
            timeSpan = 180;
            break;
        case AnimationLookbackSpan.OneYear:
            timeSpan = 30 * 12;
            break;
        case AnimationLookbackSpan.ThreeYears:
            timeSpan = 90 * 12;
            break;
        case AnimationLookbackSpan.Max:
            timeSpan = Number.MAX_VALUE;
            break;
        default:
            break;
    }
    return timeSpan;
}

/***********************EPSAnimation  */
//watchers

export function* watchInitFundamentalLines() {
    yield takeLatest(ActionTypes.PRICE_DATA_READY, initFundamentalLine)
}
export function* watchInitEarningsAnimation() {
    yield takeLatest(ActionTypes.PRICE_DATA_READY, getEarningsAnimationSetFromController)
}
export function* watchUpdateFundamentalLines() {
    yield takeLatest(ActionTypes.UPDATE_FUNDAMENTAL_LINE, initFundamentalLine)
}
export function* watchSwitchLabels() {
    yield takeEvery(ActionTypes.SWITCH_FUNDAMENTAL_LINE_LABEL, switchLabels)
}
export function* watchShowFundamentalLineDialog() {
    yield takeLatest(ActionTypes.SHOW_FUNDAMENTAL_EDIT_DIALOG, openFundamentalLineDialog)
}
export function* watchSaveFundamentalLineDialog() {
    yield takeLatest(ActionTypes.SAVE_FUNDAMENTAL_EDIT_DIALOG, saveFudamentalEditPopup)
}
export function* watchRestoreFundamentalSetting() {
    yield takeLatest(ActionTypes.RESTORE_FUNDAMENTAL_LINE_SETTING, restoreFundamentalLineSetting)
}
export function* watchUpdateFundamentalLineMultiplier() {
    yield takeLatest(ActionTypes.UPDATE_FUNDAMENTAL_LINE_MULTIPLIER, updateFundamentalLineMultiplier)
}
export function* watchUpdateFundamentalQTR() {
    yield takeLatest(ActionTypes.UPDATE_FUNDAMENTAL_QTR, SetGraphEpsRpsQuarters)
}
export function* watchSaveFundamentalLable() {
    yield takeLatest(ActionTypes.UPDATE_SELECT_PER_SHARE, saveFundamentalLable)
}
export function* watchGetEarningsAnimationSetFromController() {
    yield takeLatest(ActionTypes.GET_EARNINGS_ANIMATION_SET_FROM_CONTROLLER, getEarningsAnimationSetFromController)
}
export function* watchOpenEPSAnimationDialog() {
    yield takeLatest(ActionTypes.OPEN_EPS_ANIMAITON_DIALOG, openEPSAnimationDialog)
}
export function* watchSaveEPSAnimationPopup() {
    yield takeLatest(ActionTypes.SAVE_EPS_ANIMAITON_DIALOG, saveEPSAnimationPopup)
}
export function* watchRestoreEPSAnimationSetting() {
    yield takeLatest(ActionTypes.RESET_EPS_ANIMAITON_DIALOG, restoreEPSAnimationSetting)
}
export function* watchProcessEPSAnimation() {
    yield takeLatest(ActionTypes.PROCESS_EPS_ANIMATION, processEPSAnimation)
}